import { useMemo } from 'react'
import { ContentImage, ContentText, ContentVideo, ContentButtonDownload } from './content-types'
import styled from 'styled-components'

const MediaTypes = {
  'medium_image': (data) => (<ContentImage data={data} />),
  'medium_video': (data) => (<ContentVideo data={data} />)
}

const ContentsTypes = {
  'text': (data) => (<ContentText data={data} />),
  'media': (data) => (MediaTypes[data.mediaType](data)),
  'empty': () => (<div className='empty'></div>),
  'spacer': () => (<div className='spacer'></div>),
  'button_download': (data) => (<ContentButtonDownload data={data} />)
}

const getContentByType = (content) => {
  const { columnType } = content || {}
  if (!content || !columnType || !ContentsTypes[columnType]) return <></>
  return (ContentsTypes[columnType](content))
}

export const Content = ({ content }) => {
  const ContentByType = useMemo(() => getContentByType(content), [content])

  return (
    <ContentArticle className={content.columnType}>
      {ContentByType}
    </ContentArticle>
  )
}


const ContentArticle = styled.article`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  &.media {
    margin: 0 auto;
  }
`
