import { memo } from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { mq, vw } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'
import { useToast } from '@dy/commons/hooks'
import { Input, Button } from '@dy/commons/components'
import { subscribe, inputPattern, cookie } from '@dy/commons/utils'

type Props = {
  hasLegend?: boolean,
  inputBgColor?: string,
  textColor?: string,
  buttonBgColor?: 'red' | 'darkred' | 'background' | 'white' | 'creme' | 'darkcreme'
}

export const Form = styled.form`
  button {
    &[type='submit'] {
      margin-left: ${vw(14, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-left: ${vw(16, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-left: ${vw(16, 'desktop')};
      }
    }
  }

  > div {
    align-items: flex-end;
    display: flex;
  }

  legend > h2 {
    margin-bottom: ${vw(30, 'desktop')};
  }

  .input--newsletter {
    margin-bottom: 0;
    padding: 0;

    input {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const FormNewsletter:FC<Props> = memo(({hasLegend = true, inputBgColor = 'red', textColor = 'white', buttonBgColor = 'creme'}) => {
  const { register, formState: { errors:formErrors }, handleSubmit } = useForm()
  const { t } = useTranslator()
  const { addToast } = useToast()

  const onSubmit = async (formData, _e) => {
    const success = await subscribe({ body: formData })
    if (typeof success === 'boolean' && success) {
      console.info('✅ subscribed')
      cookie.set({ name: 'DY_MODAL_NEWSLETTER', value: 'true', days: 2 })
      addToast({
        message: t('commons.newsletter_feedback.success') as string,
        type: 'info'
      })
    } else {
      addToast({
        message: t('commons.newsletter_feedback.error') as string,
        type: 'error'
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {hasLegend &&<legend><h2>{t('layout.footer.newsletter.title')}</h2></legend>}
      <div>
        <Input type='email' name='email' className='input--newsletter' placeholder={t('layout.footer.newsletter.input_inactive') as string} register={register} required={{
          required: t('form.errors.pattern_email') as string,
          pattern: inputPattern.email
        }} error={formErrors.email} placeholderVisible={true} textColor={textColor} bgColor={inputBgColor}/>
        <Button type='submit' bgType={buttonBgColor} className='creme'>{t('layout.footer.newsletter.button')}</Button>
      </div>
    </Form>
  )
})
