import { getTransition } from '@dy/commons/styles'

export const faqListVariants = {
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .3,
      delay: 0,
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  }
}