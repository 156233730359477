"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginCustomer = void 0;
const _1 = require(".");
const cookies_1 = require("./cookies");
const error = {
    message: 'Credentials error or inactive account',
    extensions: {
        code: 'CREDENTIALS_ERROR',
        attribute: 'customer'
    }
};
const loginCustomer = ({ email, password, push, token = null }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    try {
        const res = yield fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });
        if (res.ok) {
            const { data } = yield res.json();
            if (data.cookiesToSet) {
                let cookieToken = token;
                // LOGIN & ACTIVATION only (when signup we already got the token in the response)
                if (!token) {
                    const resToken = yield fetch('/api/auth/token', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email, password }),
                    });
                    const { data: dataToken } = yield resToken.json();
                    if ((_a = dataToken === null || dataToken === void 0 ? void 0 : dataToken.generateToken) === null || _a === void 0 ? void 0 : _a.ok)
                        cookieToken = (_b = dataToken.generateToken) === null || _b === void 0 ? void 0 : _b.token;
                }
                cookies_1.cookie.removeCookie('DY_CUSTOMER_TOKEN');
                cookies_1.cookie.removeCookie('BC_CUSTOMER_ID');
                cookies_1.cookie.removeCookie('SHOP_SESSION_TOKEN');
                cookies_1.cookie.removeCookie('SHOP_TOKEN');
                cookies_1.cookie.set({ name: 'DY_CUSTOMER_TOKEN', value: cookieToken, days: 5 });
                cookies_1.cookie.set({ name: 'BC_CUSTOMER_ID', value: (_d = (_c = data.login) === null || _c === void 0 ? void 0 : _c.customer) === null || _d === void 0 ? void 0 : _d.entityId, days: 6 });
                // cookie.set({ name: 'SHOP_SESSION_TOKEN', value: data.cookiesToSet.SHOP_SESSION_TOKEN.value, days: 6 })
                // cookie.set({ name: 'SHOP_TOKEN', value: data.cookiesToSet.SHOP_TOKEN.value, days: 6 })
                // push(`/welcome?d=${Date.now()}`)
                window.location.href = `https://${_1.HOSTNAME}/welcome?d=${Date.now()}`;
            }
        }
        else {
            console.log(push);
            return { error };
        }
    }
    catch (e) {
        console.error('❌ Login failed:', e);
        return { error };
    }
});
exports.loginCustomer = loginCustomer;
