
import { memo, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useModal, useInView } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { ModalVideo } from '../../modals'
import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP12_1, getP10 } from '@dy/commons/styles'

// import { Figure } from '@dy/commons/components'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type ContentTypeVideo = {
  col?: { from: number, expand: number},
  row?: { from: number, expand: number},
  medium: {
    type: 'video' | string,
    alt?: string,
    short_video?: string | number,
    long_video?: string | number,
    platform: 'vimeo' | 'youtube' | string
  },
  caption?: string,
  type: 'media' | string,
}

interface IContentVideo {
  data: ContentTypeVideo,
  longModal?: boolean,
  playsinline?: boolean,
  modalActive?: boolean
  insideModal?: boolean
}

// const hexagonIcon = {
//   type: 'svg' as const,
//   src: '/images/svg/i--hexagon-rounded.svg',
//   alt: 'Hexagon icon',
//   size: { width: 63, height: 69 }
// }


export const ContentVideo = memo(({ data, longModal = false, modalActive = false, playsinline = false, insideModal = false }:IContentVideo) => {
  const { medium: { platform, long_video, short_video } } = data
  const [playing, setPlaying] = useState(!longModal)
  const { ref, inView } = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: 0,
  })

  const url = `https://www.${platform}.com/${platform === 'youtube' ? 'watch?v=' : ''}${longModal ? long_video : short_video}`

  const { toggle } = useModal(MODALS.VIDEO)

  useEffect(() => {
    if(longModal) return
    setPlaying(inView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    if(longModal) setPlaying(modalActive)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActive])

  return (
    <>
      <VideoContent ref={ref} longModal={longModal} className='figure--video' $insideModal={insideModal} $isModalActive={modalActive}>
        <ReactPlayer url={url} playsinline={playsinline} controls={longModal} volume={longModal ? .5 : 0} muted={!longModal} loop={!longModal}playing={playing} />
        {
          long_video && !longModal &&
          <PlayIcon onClick={toggle}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src='/images/svg/i--hexagon-rounded.svg' alt='Icon Hexagon' width='63' height='69' />
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className='xs' src='/images/svg/i--hexagon-rounded.svg' alt='Icon Hexagon small'  width='63' height='69'/>
            <span>Play</span>
          </PlayIcon>
        }
      </VideoContent>
      {long_video && !longModal && <ModalVideo data={data} />}
    </>
  )
})


const VideoContent = styled.figure<any>`
  height: auto;
  position: relative;
  width: 100%;

  ${mq.greaterThan('desktop-wide')} {
    height: 600px;
  }

  img {
    width: 100%;
  }

  figcaption {
    ${getP12_1()}
    border-bottom: ${({ theme }) => hex2Rgba(theme.colors.black, .1)};
    padding-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-bottom: 20px;
    }
  }

  > div {
    height:  ${({ longModal }) => longModal ? '56.25vw' : `${vw(232, 'mobile')}`} !important;
    pointer-events: ${({ $insideModal, $isModalActive, longModal }) => $insideModal ? $isModalActive ? 'auto' : 'none' : longModal ? 'auto' : 'none'};
    width: 100% !important;

    ${mq.greaterThan('nexus7')} {
      height:  ${({ longModal }) => longModal ? '56.25vw' : `${vw(232, 'nexus7')}`} !important;
    }

    ${mq.greaterThan('tablet')} {
      height:  ${({ longModal }) => longModal ? '56.25vw' : `${vw(540, 'desktop')}`} !important;
      max-height: 100vh;
    }

    ${mq.greaterThan('desktop-wide')} {
      height:  ${({ longModal }) => longModal ? '56.25vw' : '540px'} !important;
      max-height: 100vh;
    }
  }

  iframe {
    aspect-ratio: 16 / 9;
    height: auto !important;
    width: 100% !important;

    @media (max-aspect-ratio: 16/9) {
      height: 100% !important;
    }

    ${mq.greaterThan('desktop-wide')} {
      height: 100% !important;
      width: 100% !important;
    }
  }

`


const PlayIcon = styled.button<any>`
  align-items: center;
  border-radius: 100%;
  display: flex;
  height: ${vw(90, 'mobile')};
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: height 300ms ${({ theme }) => theme.ease}, width 300ms ${({ theme }) => theme.ease};
  width: ${vw(80, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(90, 'nexus7')};
    width: ${vw(80, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(120, 'desktop')};
    width: ${vw(110, 'desktop')};

    &:hover {
      img {
        &:first-child {
          width: ${vw(110, 'desktop')};
        }
        &:nth-child(2) {
          width: ${vw(82, 'desktop')};
        }
      }
    }
  }

  ${mq.greaterThan('desktop')} {
    height: 120px;
    width: 110px;

    &:hover {
      img {
        &:first-child {
          width: 110px;
        }
        &:nth-child(2) {
          width: 82px;
        }
      }
    }
  }

  span {
    ${getP10()}
    color: #ffffff;
    left: 50%;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  img {
    left: 50%;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms width ${({ ease }) => ease };
    user-select: none;
    width: ${vw(73, 'mobile')};

    &:first-child {
      transition-delay: 150ms;
    }

    ${mq.greaterThan('nexus7')} {
      width: ${vw(73, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      width: ${vw(91, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 91px;
    }

    &.xs {
      opacity: 1;
      width: ${vw(50, 'mobile')};
      z-index: 2;

      ${mq.greaterThan('nexus7')} {
        width: ${vw(50, 'nexus7')};
      }

      ${mq.greaterThan('desktop')} {
        width: 63px;
      }
    }
  }
`
