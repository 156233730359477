"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productGrid = exports.product = void 0;
/* eslint-disable quotes */
const utils_1 = require("../../../utils");
const getColorVariants = (options) => {
    let colorVariants = [];
    if (!Array.isArray(options))
        return colorVariants;
    for (let option of options) {
        if (option.optionType === 'color') {
            colorVariants = Array.isArray(option.optionValues) ? option.optionValues : [];
            break;
        }
    }
    return colorVariants;
};
const TRANSLATIONS = {
    'de': {
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "Keine Rückgaberegelung",
            "description": "Dieser Artikel wird wie besehen verkauft und kann nicht zurückgegeben werden."
        }
    },
    'en': {
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "No Return Policy",
            "description": "This item is sold as-is and cannot be returned."
        }
    },
    'es': {
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "Política de Devolución No Disponible",
            "description": "Este artículo se vende tal cual y no se puede devolver."
        }
    },
    'fr': {
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "Politique de Retour Non Disponible",
            "description": "Cet article est vendu tel quel et ne peut pas être retourné."
        }
    },
    'pt': {
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "Política de Devolução Não Disponível",
            "description": "Este artigo é vendido como está e não pode ser devolvido."
        }
    }
};
const product = ({ data, region = 'ES', locale = 'es', productPage = true, gridUrl = null }) => {
    var _a, _b, _c, _d, _e;
    const { brand, name, description, variant, variants, sku, pack, slug, labels, filters } = data;
    const currentVariant = variants ? variants[0] : null;
    const image = (_d = (_c = (_b = (_a = currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.media) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.medium) === null || _c === void 0 ? void 0 : _c.default) === null || _d === void 0 ? void 0 : _d.src;
    const url = `https://${utils_1.HOSTNAME}/${productPage ? `product/${slug[locale]}` : `catalog/${gridUrl}#${slug[locale]}`}`;
    const filter = filters === null || filters === void 0 ? void 0 : filters.map((item) => item.name);
    const keywords = labels.map((item) => item.name);
    const offers = (item) => {
        var _a, _b, _c, _d, _e;
        return (Object.assign(Object.assign({ "@type": "Offer" }, (slug && { "url": url })), { "priceCurrency": `${(_b = (_a = utils_1.currencyBasedOnRegion[region]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : 'EUR'}`, "price": `${((_c = item.prices) === null || _c === void 0 ? void 0 : _c.price_inc_tax) ? item.prices.price_inc_tax : '0.00'}`, 
            // ...(item.price.validUntil && { "priceValidUntil": `${item.price.validUntil}` }),
            "itemCondition": "https://schema.org/NewCondition", "availability": `https://schema.org/${(currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.stock) > 1 ? 'InStock' : 'OutOfStock'}`, "inventoryLevel": `${(_d = currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.stock) !== null && _d !== void 0 ? _d : '0'}`, "sku": `${Array.isArray(item.sku) ? (_e = item.sku) === null || _e === void 0 ? void 0 : _e[0] : item.sku}`, "seller": {
                "@type": "Organization",
                "name": "Mammafiore",
            } }));
    };
    const productSchema = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (productPage && { "@context": "https://schema.org/" })), { "@type": `${pack ? 'ProductGroup' : 'Product'}`, "name": `${name}` }), (image && { "image": `${image}` })), (description && { "description": `${description}` })), (data.mpn && { "mpn": `${data.mpn}` })), (brand && { "brand": { "@type": "Brand", "name": `${brand.name}` } })), { "sku": `${sku}` }), ((filters === null || filters === void 0 ? void 0 : filters.length) > 0 && { "category": `${filter.toString()}` })), (labels.length > 0 && { "keywords": `${keywords.toString()}` })), (currentVariant && { "weight": `${currentVariant.name}` })), ((variants === null || variants === void 0 ? void 0 : variants.length) > 0 && { "hasVariant": variants.map((item) => `${item.sku}`) })), ((variants === null || variants === void 0 ? void 0 : variants.length) > 0 && { "offers": variants.map((item) => offers(item)) })), (variant && { "offers": offers(variant) })), { "hasMerchantReturnPolicy": Object.assign({}, (_e = TRANSLATIONS[locale]) === null || _e === void 0 ? void 0 : _e.hasMerchantReturnPolicy) });
    return productPage ? JSON.stringify(productSchema) : productSchema;
};
exports.product = product;
const productGrid = ({ data, region, locale }) => {
    var _a, _b, _c;
    const { media, product, sku, price, ean } = data;
    const { description, name, slug, brand, filters, labels, stock } = product;
    const image = (_a = media === null || media === void 0 ? void 0 : media[0]) === null || _a === void 0 ? void 0 : _a.medium;
    const filter = filters === null || filters === void 0 ? void 0 : filters.map((item) => item.name);
    const keywords = labels === null || labels === void 0 ? void 0 : labels.map((item) => item.name);
    const url = `https://${utils_1.HOSTNAME}/catalog/product/${slug[locale]}`;
    const productSchema = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ "@type": "Product", "name": `${name}`, "sku": `${sku}`, "gtin13": `${ean}` }, (image && { "image": `${image.default.src}` })), (description && { "description": `${description}` })), (brand && { "brand": { "@type": "Brand", "name": `${brand.name}` } })), ((filters === null || filters === void 0 ? void 0 : filters.length) > 0 && { "category": `${filter.toString()}` })), ((labels === null || labels === void 0 ? void 0 : labels.length) > 0 && { "keywords": `${keywords.toString()}` })), (data.mpn && { "mpn": `${data.mpn}` })), { "offers": Object.assign(Object.assign({ "@type": "Offer" }, (slug && { "url": url })), { "priceCurrency": `${(_c = (_b = utils_1.currencyBasedOnRegion[region]) === null || _b === void 0 ? void 0 : _b.code) !== null && _c !== void 0 ? _c : 'EUR'}`, "price": `${price !== null && price !== void 0 ? price : '0.00'}`, 
            // ...(item.price.validUntil && { "priceValidUntil": `${item.price.validUntil}` }),
            "itemCondition": "https://schema.org/NewCondition", "availability": `https://schema.org/${stock > 1 ? 'InStock' : 'OutOfStock'}`, "inventoryLevel": `${stock !== null && stock !== void 0 ? stock : '0'}`, "sku": `${sku}`, "gtin13": `${ean}`, "seller": {
                "@type": "Organization",
                "name": "Mammafiore",
            } }) });
    return productSchema;
};
exports.productGrid = productGrid;
