import {  getTransition } from '@dy/commons/styles'
import { vw } from '@dy/commons/styles'

const transition = { ...getTransition(1) }

export const logoVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .3,
    }
  },
}

export const wrapperVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  active: {
    opacity: 1,
    transition: {
      ...getTransition(1),
      delay: 1.5
    }
  }
}

export const text_variants_title = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: (singleImage) => ({
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: singleImage ? 2 : .3
    }
  })
}

export const text_variants_subtitle = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: (singleImage) => ({
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: singleImage ? 2.1 : .5
    }
  })
}

export const text_variants_button = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: (singleImage) => ({
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: singleImage ? 2.2 : .3
    }
  })
}

export const img_variants_left = {
  inactive: {
    opacity: 0,
    rotate: '-10deg',
    y: vw(260, 'desktop')
  },
  active: (mobile) => ({
    opacity: 1,
    rotate: '0deg',
    y: mobile ? 0 : vw(65, 'desktop'),
    transition: { 
      ...getTransition(1)
    }
  })
}

export const img_variants_right = {
  inactive: (mobile) => ({
    opacity: 0,
    rotate: '-15deg',
    y: mobile ? vw(350, 'mobile') : vw(170, 'desktop')
  }),
  active: (mobile) => ({
    opacity: 1,
    rotate: '0deg',
    y: mobile ? 0 : vw(-100, 'desktop'),
    transition: { 
      ...getTransition(1),
      delay: .2
    }
  })
}

export const img_variants_center = {
  inactive: (mobile) => ({
    opacity: 0,
    rotate: '17deg',
    y: mobile ? vw(350, 'mobile') : vw(300, 'desktop')
  }),
  active: (mobile) => ({
    opacity: 1,
    rotate: '0deg',
    y: mobile ? 0 : vw(130, 'desktop'),
    transition: { 
      ...getTransition(1),
      delay: .4
    }
  })
}

export const img_variants_single = {
  inactive: {
    opacity: 0
  },
  active: {
    opacity: 1,
    transition: { 
      ...getTransition(.5),
      delay: 1.5
    }
  }
}

export const imageVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: 1,
      delay: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

export const text_variants_title_highlights = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: {
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: .3
    }
  }
}

export const text_variants_subtitle_highlights = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: {
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: .4
    }
  }
}

export const text_variants_button_highlights = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: {
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: .5
    }
  }
}