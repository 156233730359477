"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendGiftCard = exports.getFeaturedFilters = exports.slugMatch = exports.getFiltersFromQuery = exports.generateQueryParams = exports.updateActiveFilters = exports.getFilters = exports.getFilteredProducts = exports.hasQueryParams = void 0;
const hasQueryParams = (asPath, param = null) => {
    return !!asPath.split(`?${param ? `${param}=` : ''}`)[1];
};
exports.hasQueryParams = hasQueryParams;
const sortProducts = (variants, sortBy = null) => {
    const _products = Array.from(variants);
    if (!sortBy || Object.keys(sortBy)[0] === 'recommended') {
        return _products;
    }
    const ascending = Object.keys(sortBy)[0] === 'low-price';
    _products.sort((a, b) => {
        var _a, _b;
        const a_price_per_unit = (_a = a.price) === null || _a === void 0 ? void 0 : _a.salePrice;
        const b_price_per_unit = (_b = b.price) === null || _b === void 0 ? void 0 : _b.salePrice;
        return ascending ? a_price_per_unit - b_price_per_unit : b_price_per_unit - a_price_per_unit;
    });
    return _products;
};
const getFilteredProducts = (variants, appliedFilters) => {
    var _a, _b;
    if ((variants === null || variants === void 0 ? void 0 : variants.length) < 1)
        return [];
    if (Object.keys(appliedFilters).length < 1)
        return sortProducts(variants);
    // Parse appliedFilters into keyparams as array of true values
    // sample: { size: ['s', 'm'], color: ['white', 'blue', 'yellow']}
    const parsedFilters = {};
    for (const filterParam in appliedFilters) {
        if (typeof appliedFilters[filterParam] === 'boolean') {
            parsedFilters[filterParam] = true;
        }
        else {
            if (filterParam !== 'sort') {
                const values = appliedFilters[filterParam];
                if (typeof values === 'object' && Object.keys(values).length > 0 && !(JSON.stringify(Object.keys(values)) === '[""]'))
                    parsedFilters[filterParam] = values;
            }
        }
    }
    if (Object.keys(parsedFilters).length < 1)
        return sortProducts(variants, appliedFilters.sort);
    // If something to filter
    const filteredProducts = [];
    for (const variant of variants) {
        let matchAllFilters = false;
        // Product must match all filterParam
        for (const filterParam in parsedFilters) {
            if ((_b = (_a = variant === null || variant === void 0 ? void 0 : variant.product) === null || _a === void 0 ? void 0 : _a.filters) === null || _b === void 0 ? void 0 : _b.some(filter => filter.param === filterParam)) {
                // product has this filter param, now lets check if match at least one param value
                for (const filter of variant.product.filters) {
                    if (filter.param === filterParam) {
                        if (filter.type === 'simple') {
                            matchAllFilters = true;
                        }
                        else {
                            const values = filter.values.map((value) => {
                                return value.value;
                            });
                            //@ts-ignore
                            const matchedValues = [...new Set(Object.keys(parsedFilters[filterParam]).filter(val => values.includes(val)))];
                            matchAllFilters = matchedValues.length > 0;
                        }
                        break;
                    }
                }
            }
            else {
                matchAllFilters = false;
                break;
            }
            if (!matchAllFilters)
                break;
        }
        if (matchAllFilters)
            filteredProducts.push(variant);
    }
    return sortProducts(filteredProducts, appliedFilters.sort);
};
exports.getFilteredProducts = getFilteredProducts;
const TRANSLATIONS_SORT_BY_PRIVATE = {
    'es': {
        name: 'Ordenar',
        values: [
            { name: 'Recomendados', value: 'recommended' },
            { name: 'Precio más bajo', value: 'low-price' },
            { name: 'Precio más alto', value: 'high-price' },
        ]
    },
    'ca': {
        name: 'Ordenar',
        values: [
            { name: 'Recomanats', value: 'recommended' },
            { name: 'Preu més baix', value: 'low-price' },
            { name: 'Preu més alt', value: 'high-price' },
        ]
    },
    'en': {
        name: 'Sort',
        values: [
            { name: 'Recommended', value: 'recommended' },
            { name: 'Lowest Price', value: 'low-price' },
            { name: 'Highest Price', value: 'high-price' },
        ]
    },
    'fr': {
        name: 'Trier',
        values: [
            { name: 'Recommandés', value: 'recommended' },
            { name: 'Prix le plus bas', value: 'low-price' },
            { name: 'Prix le plus élevé', value: 'high-price' },
        ]
    },
    'pt': {
        name: 'Ordenar',
        values: [
            { name: 'Recomendados', value: 'recommended' },
            { name: 'Preço mais baixo', value: 'low-price' },
            { name: 'Preço mais alto', value: 'high-price' },
        ]
    },
    'de': {
        name: 'Sortieren',
        values: [
            { name: 'Empfohlen', value: 'recommended' },
            { name: 'Niedrigster Preis', value: 'low-price' },
            { name: 'Höchster Preis', value: 'high-price' },
        ]
    },
    'it': {
        name: 'Ordina',
        values: [
            { name: 'Consigliati', value: 'recommended' },
            { name: 'Prezzo più basso', value: 'low-price' },
            { name: 'Prezzo più alto', value: 'high-price' },
        ]
    }
};
const TRANSLATIONS_SORT_BY_PUBLIC = {
    'es': {
        name: 'Ordenar',
        values: [
            { name: 'Recomendados', value: 'recommended' }
        ]
    },
    'ca': {
        name: 'Ordenar',
        values: [
            { name: 'Recomanats', value: 'recommended' }
        ]
    },
    'en': {
        name: 'Sort',
        values: [
            { name: 'Recommended', value: 'recommended' }
        ]
    },
    'fr': {
        name: 'Trier',
        values: [
            { name: 'Recommandés', value: 'recommended' }
        ]
    },
    'pt': {
        name: 'Ordenar',
        values: [
            { name: 'Recomendados', value: 'recommended' }
        ]
    },
    'de': {
        name: 'Sortieren',
        values: [
            { name: 'Empfohlen', value: 'recommended' }
        ]
    },
    'it': {
        name: 'Ordina',
        values: [
            { name: 'Consigliati', value: 'recommended' }
        ]
    }
};
const getFilters = (variants = [], lang, isPrivate = false) => {
    if ((variants === null || variants === void 0 ? void 0 : variants.length) === 0)
        return null;
    const finalFilters = {};
    if (lang) {
        isPrivate ? finalFilters['sort'] = Object.assign(Object.assign({}, TRANSLATIONS_SORT_BY_PRIVATE[lang]), { type: 'multiple' }) : finalFilters['sort'] = Object.assign(Object.assign({}, TRANSLATIONS_SORT_BY_PUBLIC[lang]), { type: 'multiple' });
    }
    const mergeFilters = (filters) => {
        for (let filter of filters) {
            const { param, name, type, values } = filter;
            if (!(param in finalFilters)) {
                finalFilters[param] = (type === 'multiple' || type === 'brand') ? { name, type, values } : { name, type };
            }
            else {
                if (type === 'multiple' || type === 'brand') {
                    const mergedValues = [...finalFilters[param].values, ...values];
                    // @ts-ignore
                    const uniqValues = [...new Map(mergedValues.map(item => [item.value, item])).values()];
                    finalFilters[param].values = Array.from(uniqValues);
                }
            }
        }
    };
    variants.map((variant) => {
        var _a;
        const { product } = variant;
        if (((_a = product.filters) === null || _a === void 0 ? void 0 : _a.length) > 0)
            mergeFilters(product.filters);
    });
    return [finalFilters, Object.keys(finalFilters)];
};
exports.getFilters = getFilters;
const updateActiveFilters = (currentFilters, filter) => {
    let incomingFilters = Object.assign({}, currentFilters);
    const param = incomingFilters[filter.param];
    if (filter.param === 'sort') {
        if (param && param[filter.value]) {
            delete incomingFilters[filter.param];
        }
        else {
            incomingFilters = Object.assign(Object.assign({}, incomingFilters), { [filter.param]: { [filter.value]: true } });
        }
    }
    else if (param) {
        if (filter.type === 'simple') {
            delete incomingFilters[filter.param];
        }
        else {
            param[filter.value] ? delete param[filter.value] : param[filter.value] = true;
        }
    }
    else {
        if (filter.type === 'simple') {
            incomingFilters[filter.param] = true;
        }
        else {
            incomingFilters[filter.param] = { [filter.value]: true };
        }
    }
    return incomingFilters;
};
exports.updateActiveFilters = updateActiveFilters;
const generateQueryParams = (filters) => {
    if (Object.keys(filters).length < 1)
        return {};
    const queryParams = {};
    let stringParams = '';
    for (const key in filters) {
        if (typeof filters[key] === 'boolean') {
            queryParams[key] = 'true';
            stringParams += `${stringParams.length > 1 ? '&' : ''}${key}=true`;
        }
        else {
            let values = [];
            for (let value in filters[key]) {
                values.push(`${value}`);
            }
            queryParams[key] = values;
            stringParams += `${stringParams.length > 1 ? '&' : ''}${key}=${values.join(',')}`;
        }
    }
    return { queryParams, stringParams };
};
exports.generateQueryParams = generateQueryParams;
const getFiltersFromQuery = (url, params) => {
    var _a, _b;
    const queryFilters = {};
    const urlParams = new URL(`https://frontend.rules${url}`).searchParams;
    queryFilters['sort'] = { [(_a = urlParams.get('sort')) !== null && _a !== void 0 ? _a : 'recommended']: true };
    for (const param of params) {
        const values = (_b = urlParams.get(param)) === null || _b === void 0 ? void 0 : _b.split(',');
        if (values) {
            if ((values === null || values === void 0 ? void 0 : values.length) === 1) {
                queryFilters[param] = values[0] === 'true' ? true : { [values[0]]: true };
            }
            else {
                for (const value of values) {
                    if (queryFilters[param]) {
                        queryFilters[param][value] = true;
                    }
                    else {
                        queryFilters[param] = { [value]: true };
                    }
                }
            }
        }
    }
    return queryFilters;
};
exports.getFiltersFromQuery = getFiltersFromQuery;
const slugMatch = (arr1, arr2) => {
    let arr = arr1;
    if (typeof arr1 === 'string')
        arr = [arr1];
    if (arr.length !== arr2.length)
        return false;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== arr2[i])
            return false;
    }
    return true;
};
exports.slugMatch = slugMatch;
const getFeaturedFilters = (activeFilters, filters) => {
    var _a;
    const incomingfeaturedFilters = [];
    for (const keyparam in activeFilters) {
        const filter = filters === null || filters === void 0 ? void 0 : filters[keyparam];
        if (filter) {
            for (const keyvalue in activeFilters[keyparam]) {
                const matchFilter = (_a = filter === null || filter === void 0 ? void 0 : filter.values) === null || _a === void 0 ? void 0 : _a.filter(value => value.value === keyvalue)[0];
                if (matchFilter && matchFilter.media)
                    incomingfeaturedFilters.push(Object.assign(Object.assign({}, matchFilter), { param: keyparam }));
            }
        }
    }
    return incomingfeaturedFilters;
};
exports.getFeaturedFilters = getFeaturedFilters;
const appendGiftCard = (products, width) => {
    //hardCoded GifcardOBJECT!
    const giftCardObj = {
        'giftCard': true,
        'bigcommerce_id': null,
        'bigcommerce_slug': null,
        'name': null,
        'slug': null,
        'sku': null,
        'variant': null,
        'pack': false,
        'labels': null,
        'filters': [
            {
                'name': 'Marca',
                'param': 'marca',
                'type': 'multiple',
                'values': [
                    {
                        'name': 'Mammafiore!',
                        'value': 'mamma-value',
                        'description': '',
                        'media': null
                    }
                ]
            }
        ],
        'brand': null,
        'categories': null
    };
    let clonedArray = [...products];
    let columns = width >= 1650 ? 5 : (width < 603 || (width >= 768 && width <= 1649)) ? 4 : 3;
    let variableCount = width >= 1650 ? 39 : 41;
    let productCount = variableCount + columns;
    for (let idx = 1; idx <= clonedArray.length; idx++) {
        if (idx === columns && !clonedArray[idx].giftCard) {
            clonedArray.splice(columns, 0, giftCardObj);
        }
        else if ((idx % productCount === 0)) {
            clonedArray.splice(idx, 0, giftCardObj);
        }
    }
    return clonedArray;
};
exports.appendGiftCard = appendGiftCard;
