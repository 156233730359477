'use client'
import { type FC, memo, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQuery, useMutation } from '@apollo/client'
import { Form, Fieldset, DisplayError, Input, Select, Checkbox, Button } from '@dy/commons/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { SIGNUP_MUTATION, GET_POSTAL_CODES_VALID } from '@/api'
import { useTranslator, useCustomer } from '@/hooks'
import { autoComplete, getLocaleCountry, validate, inputPattern, ADDRESS_TYPE, loginCustomer, shippingPostalCodeValid } from '@dy/commons/utils'
import type { TypeSectionAuthLevel } from '@/components'
import type { TypeQueryValidPostalCodes, TypeProvince } from '@dy/commons/schema'
import { defaultAuthModalLevel, getLegend } from '@/components'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload?: { level: string, [key: string]: any }
  animation?: any
}

type TypeFormInputs = {
  vatNumber: string
  email: string
  password: string
  billing: {
    companyName: string
    firstName: string
    lastName: string
    countryCode: string
    provinceCode: string
    city: string
    streetLine: string
    streetLine2?: string
    postalCode: string
  }
  shipping?: {
    firstName: string
    lastName: string
    countryCode: string
    provinceCode: string
    city: string
    streetLine: string
    streetLine2?: string
    postalCode: string
  }
  businessType: 'restaurant' | 'commercial' | 'distributor'
  sameShippingBillingAddress?: boolean
  noCommercialContact?: boolean
  directDebit?: boolean
  iban?: string
}

const businessOptions = [
  {
    name: 'Restaurante',
    value: 'RESTAURANT'
  },
  {
    name: 'Comercial',
    value: 'COMMERCIAL'
  },
  {
    name: 'Distribuidor',
    value: 'DISTRIBUTOR',
  }
]
// const businessOptions = [
//   {
//     name: 'Restaurant',
//     value: 'RESTAURANT'
//   },
//   {
//     name: 'Commercial',
//     value: 'COMMERCIAL'
//   },
//   {
//     name: 'Distributor',
//     value: 'DISTRIBUTOR',
//   }
// ]

export const FormUserSignUp:FC<Props> = memo(({ updateLevel, animation, payload }) => {
  const { toggle: toggleModalRegion } = useModal(MODALS.REGION_LANG)
  const { register, handleSubmit, formState: { errors:formErrors }, setValue, watch } = useForm<TypeFormInputs>()
  const { setLoaderActive } = useCustomer()

  // Input being watched
  const sameShippingBillingAddress = watch('sameShippingBillingAddress')
  const directDebit = watch('directDebit')

  // @ts-ignore
  const billingPostalCode = watch('billing[postalCode]')
  // @ts-ignore
  const shippingPostalCode = watch('shipping[postalCode]')

  const { t } = useTranslator()
  const { locale, push } = useRouter()
  const countryCode = useMemo(() => getLocaleCountry(locale).split('-')[1], [locale])
  const legend = useMemo(() => getLegend(locale, t, toggleModalRegion), [locale, t, toggleModalRegion])

  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [validBillingPostalCodeForShipping, setValidBillingPostalCodeForShipping] = useState(false)
  const [validShippingPostalCode, setValidShippingPostalCode] = useState(false)

  const [customerRegister, { loading, error:apolloError }] = useMutation(SIGNUP_MUTATION)
  const { data:resPostalCodes } = useQuery<TypeQueryValidPostalCodes>(GET_POSTAL_CODES_VALID, {
    context: {
      isPrivatePath: true
    }
  })

  const onSubmit: SubmitHandler<TypeFormInputs> = async formData => {
    setLoaderActive(true)
    const { email, password, billing, shipping, sameShippingBillingAddress } = formData

    const billingAddress = {
      firstName: billing.firstName,
      lastName: billing.lastName,
      address1: billing.streetLine,
      address2: billing.streetLine2,
      countryCode: billing.countryCode,
      stateOrProvince: provinces.find(item => item.value === billing.provinceCode)?.name,
      city: billing.city,
      postalCode: billing.postalCode,
      addressType: ADDRESS_TYPE.BILLING
    }

    const addresses = [
      billingAddress,
      ...sameShippingBillingAddress ? [{
        ...billingAddress,
        addressType: ADDRESS_TYPE.SHIPPING
      }] : [{
        firstName: shipping.firstName,
        lastName: shipping.lastName,
        address1: shipping.streetLine,
        address2: shipping.streetLine2,
        countryCode: shipping.countryCode,
        stateOrProvince: provinces.find(item => item.value === shipping.provinceCode)?.name,
        city: shipping.city,
        postalCode: shipping.postalCode,
        addressType: ADDRESS_TYPE.SHIPPING
      }]
    ]

    const formFields = {
      vatNumber: formData.vatNumber,
      businessType: formData.businessType,
      sameShippingBillingAddress: formData.sameShippingBillingAddress,
      noCommercialContact: formData.noCommercialContact,
      directDebit: formData.directDebit,
      iban: formData.iban
    }

    try {
      const { data } = await customerRegister({
        variables: {
          email,
          password,
          firstName: billing.firstName,
          lastName: billing.lastName,
          companyName: billing.companyName,
          formFields,
          addresses
        },
        context: {
          isPrivatePath: true
        }
      })

      const res = data?.customerRegister
      await loginCustomer({ email, password, push, token: res?.token })
      updateLevel(defaultAuthModalLevel, {}, true)
    } catch(e) {
      setLoaderActive(false)
      console.log('SignUp catch e:', e)
      // reset()
    }
  }

  const onError = (errors, e) => {
    console.warn('🟡 Errors found before Submit')
    console.log(errors, e)
  }

  useEffect(() => {
    async function getProvinces(countryCode) {
      let data = await import(`/public/locales/countries/${countryCode}-provinces.json`) as TypeProvince[]

      if (data) setProvinces(Array.from(data))
    }

    async function getCountries() {
      // @ts-ignore
      let data = await import('/public/locales/countries/ALL-countries.json')
      if (data) setCountries(Array.from(data))
    }

    if (countries.length === 0 && typeof locale === 'string') {
      getCountries()
    }
    if (provinces.length === 0 && typeof locale === 'string') {
      if (typeof countryCode === 'string' && countryCode !== 'EU') getProvinces(countryCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    if (typeof billingPostalCode !== 'string') return

    const regex = new RegExp(inputPattern.postalCode[countryCode])
    const patternValid = regex.test(billingPostalCode)

    if (patternValid) {
      const arr = resPostalCodes?.validPostalCodes ?? []
      const billingCheck = shippingPostalCodeValid(arr, billingPostalCode)
      setValidBillingPostalCodeForShipping(billingCheck)
      if (sameShippingBillingAddress) setValidShippingPostalCode(billingCheck)
    }

  }, [resPostalCodes, billingPostalCode, countryCode, sameShippingBillingAddress])

  useEffect(() => {
    if (typeof shippingPostalCode !== 'string') return

    const regex = new RegExp(inputPattern.postalCode[countryCode])
    const patternValid = regex.test(shippingPostalCode)

    if (patternValid) {
      const arr = resPostalCodes?.validPostalCodes ?? []
      setValidShippingPostalCode(shippingPostalCodeValid(arr, shippingPostalCode))
    }
  }, [resPostalCodes, shippingPostalCode, countryCode])

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit, onError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={legend}>
        <Input name='vatNumber' register={register} label={t('form.fields.cif') as string} required={{
          required: true,
          // cif validation only available for Spain by now
          ...countryCode === 'ES' && {
            validate: (value) => validate.cif(value) || (t('form.errors.validate_cif') as string)
          }
        }} defaultValue={payload.vatNumber ?? ''} error={formErrors.vatNumber} bgColor={'white'} readOnly={!!payload.vatNumber} />
        <Input type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} bgColor={'white'} />
        <Input type='password' name='password' register={register} label={t('form.fields.password') as string} autoComplete={autoComplete.password.new} placeholder='password' bgColor={'white'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
      </Fieldset>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('account.order.billing_address') as string}>
        <Input key='billing[firstName]' name={'billing[firstName]'} col={6} register={register} label={t('form.fields.first_name') as string} required error={formErrors.billing?.firstName} bgColor={'white'} />
        <Input key='billing[lastName]' name={'billing[lastName]'} col={6} register={register} label={t('form.fields.last_name') as string} required error={formErrors.billing?.lastName} bgColor={'white'} />
        <Input key='billing[companyName]' name={'billing[companyName]'} register={register} label={t('form.fields.company_name') as string} required error={formErrors.billing?.companyName} bgColor={'white'} />
        <Select key='billing[countryCode]' name='billing[countryCode]' col={6} register={register} label={t('form.fields.country') as string} options={countries} setFormValue={setValue} bgColor={'white'} readOnly defaultValue='ES'/>

        <Select key='billing[provinceCode]' name='billing[provinceCode]' col={6} register={register} label={t('form.fields.province') as string} options={provinces} setFormValue={setValue} bgColor={'white'} />
        <Input key='billing[city]' name='billing[city]' col={12} register={register} label={t('form.fields.city') as string} bgColor={'white'} autoComplete={autoComplete.address.cityOrTown} />
        <Input key='billing[postalCode]' name='billing[postalCode]' col={12} register={register} label={t('form.fields.postal_code') as string} required={{
          required: t('form.errors.empty') as string,
          pattern: inputPattern.postalCode[countryCode]
        }} error={formErrors.billing?.postalCode} bgColor={'white'} autoComplete={autoComplete.address.postalCode} />
        <Input key='billing[streetLine]' name='billing[streetLine]' register={register} label={t('form.fields.street_line1') as string} required error={formErrors.billing?.streetLine} bgColor={'white'} autoComplete={autoComplete.address.street} />
        <Input key='billing[streetLine2]' name='billing[streetLine2]' col={6} register={register} label={t('form.fields.street_line2') as string} error={formErrors.billing?.streetLine2} bgColor={'white'} autoComplete={autoComplete.address.street2} />
        <Select key='businessType' name='businessType' col={6} register={register} label={'Business Type'} error={formErrors.businessType} options={businessOptions} setFormValue={setValue} bgColor={'white'} />
        <Checkbox key='check-same' name='sameShippingBillingAddress' col={12} register={register} label={t('form.fields.shipping_as_billing') as string} bgColor={'white'} defaultChecked={true} />
      </Fieldset>
      {!sameShippingBillingAddress && <Fieldset disabled={loading} ariaBusy={loading} legend={t('account.order.shipping_address') as string}>
        <Input key='shipping[firstName]' name={'shipping[firstName]'} col={6} register={register} label={t('form.fields.first_name') as string} required error={formErrors.shipping?.firstName} bgColor={'white'} />
        <Input key='shipping[lastName]' name={'shipping[lastName]'} col={6} register={register} label={t('form.fields.last_name') as string} required error={formErrors.shipping?.lastName} bgColor={'white'} />
        <Input key='shipping[countryCode]' name='shipping[countryCode]' col={6} register={register} label={t('form.fields.country') as string} readOnly={true} defaultValue='ES' required bgColor={'white'} />
        <Select key='shipping[provinceCode]' name='shipping[provinceCode]' col={6} register={register} label={t('form.fields.province') as string} options={provinces.filter(item => item.in === 'ES-CT')} setFormValue={setValue} bgColor={'white'} />
        <Input key='shipping[city]' name='shipping[city]' col={12} register={register} label={t('form.fields.city') as string} bgColor={'white'} autoComplete={autoComplete.address.cityOrTown} />
        <Input key='shipping[postalCode]' name='shipping[postalCode]' col={12} register={register} label={t('form.fields.postal_code') as string} required={{
          required: true,
          pattern: inputPattern.postalCode[countryCode]
        }} error={formErrors.shipping?.postalCode} bgColor={'white'} autoComplete={autoComplete.address.postalCode} />
        <Input key='shipping[streetLine]' name='shipping[streetLine]' register={register} label={t('form.fields.street_line1') as string} required error={formErrors.shipping?.streetLine} bgColor={'white'} autoComplete={autoComplete.address.street} />
        <Input key='shipping[streetLine2]' name='shipping[streetLine2]' col={6} register={register} label={t('form.fields.street_line2') as string} error={formErrors.shipping?.streetLine2} bgColor={'white'} autoComplete={autoComplete.address.street2} />
      </Fieldset>}
      <Fieldset>
        <Checkbox key='check-nocommerical' name='noCommercialContact' col={12} register={register} label={'No quiero ser contactado por un commercial'} bgColor={'white'} />
        <Checkbox key='directdebit' name='directDebit' col={12} register={register} label={'Domiciliacion Bancaria (optional)'} bgColor={'white'} />
        <p className='p-tiny'>{t('modals.auth.signup.iban')}</p>
        {!!directDebit && <Input key='iban' name='iban' col={12} register={register} label={'Bank account'} error={formErrors.iban} bgColor={'white'} required={{
          required: t('form.errors.empty') as string,
          validate: (value) => (value?.length > 1 ? validate.iban(value, countryCode) : true) || (t('form.errors.validate_iban') as string)
        }} />}
      </Fieldset>
      <Button disabled={Object.keys(formErrors).length > 0 || (!validBillingPostalCodeForShipping && billingPostalCode === shippingPostalCode) || !validShippingPostalCode} type='submit'>{t('commons.actions.signup')}</Button>
      <DisplayError key='display-error' error={apolloError} />
    </Form>
  )
})
