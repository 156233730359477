"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushAnalyticsEvent = void 0;
const getSelectItemObject = (data) => {
    var _a, _b, _c, _d, _e;
    const { name, price, product, idx, ga_list, locale, variant } = data;
    return {
        'event': 'select_item',
        'ecommerce': {
            'items': [Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'item_name': product === null || product === void 0 ? void 0 : product.name, 'item_variant': name, 'item_id': (_a = data.sku) !== null && _a !== void 0 ? _a : product === null || product === void 0 ? void 0 : product.sku }, ((price === null || price === void 0 ? void 0 : price.retailPrice) && { 'discount': (price === null || price === void 0 ? void 0 : price.retailPrice) - (price === null || price === void 0 ? void 0 : price.salePrice) })), { 'price': price === null || price === void 0 ? void 0 : price.salePrice, 'index': idx, 'item_list_name': ga_list.name, 'item_list_id': ga_list.id, 'affiliation': `Mammafiore ${locale} Store`, 'item_brand': (_c = (_b = product === null || product === void 0 ? void 0 : product.brand) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : 'Packs' }), ((product === null || product === void 0 ? void 0 : product.categories) && { 'item_category': product === null || product === void 0 ? void 0 : product.categories[0].name })), (((_d = product === null || product === void 0 ? void 0 : product.categories) === null || _d === void 0 ? void 0 : _d.length) === 2 && { 'item_category2': product === null || product === void 0 ? void 0 : product.categories[1].name })), (((_e = product === null || product === void 0 ? void 0 : product.categories) === null || _e === void 0 ? void 0 : _e.length) === 3 && { 'item_category3': product === null || product === void 0 ? void 0 : product.categories[2].name })), { 'currency': 'EUR', 'quantity': 1 })],
            'item_list_name': ga_list.name,
            'item_list_id': ga_list.id
        }
    };
};
const getViewItemObject = (data) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!data)
        return;
    const { name, brand, categories, sku, variant, variants, idx } = data;
    const variantData = variant ? variants.find(item => item.bigcommerceId === variant.variant_id) : variants === null || variants === void 0 ? void 0 : variants[0];
    return {
        'event': 'view_item',
        'ecommerce': {
            'items': [Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'item_name': name, 'item_variant': variantData === null || variantData === void 0 ? void 0 : variantData.name, 'item_id': (variantData === null || variantData === void 0 ? void 0 : variantData.sku) ? variantData === null || variantData === void 0 ? void 0 : variantData.sku : sku }, (((_a = variantData === null || variantData === void 0 ? void 0 : variantData.price) === null || _a === void 0 ? void 0 : _a.retailPrice) && { 'discount': ((_b = variantData === null || variantData === void 0 ? void 0 : variantData.price) === null || _b === void 0 ? void 0 : _b.retailPrice) - ((_c = variantData === null || variantData === void 0 ? void 0 : variantData.price) === null || _c === void 0 ? void 0 : _c.salePrice) })), { 'price': (_d = variantData === null || variantData === void 0 ? void 0 : variantData.price) === null || _d === void 0 ? void 0 : _d.salePrice, 'item_brand': (_e = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _e !== void 0 ? _e : 'Packs' }), (categories && { 'item_category': (_f = categories[0]) === null || _f === void 0 ? void 0 : _f.name })), ((categories === null || categories === void 0 ? void 0 : categories.length) === 2 && { 'item_category2': (_g = categories[1]) === null || _g === void 0 ? void 0 : _g.name })), ((categories === null || categories === void 0 ? void 0 : categories.length) === 3 && { 'item_category3': (_h = categories[2]) === null || _h === void 0 ? void 0 : _h.name })), ((variant === null || variant === void 0 ? void 0 : variant.ga_list) && {
                    'item_list_name': (_j = variant.ga_list) === null || _j === void 0 ? void 0 : _j.name,
                    'item_list_id': (_k = variant.ga_list) === null || _k === void 0 ? void 0 : _k.id
                })), (idx && { 'index': idx })), { 'quantity': 1 })]
        }
    };
};
const getViewItemListObject = (data) => {
    const { products, ga_list } = data;
    const items = products.map((productEl, idx) => {
        var _a, _b, _c, _d;
        const { name, product, price } = productEl;
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'item_name': product === null || product === void 0 ? void 0 : product.name, 'item_variant': name, 'item_id': product.sku }, ((price === null || price === void 0 ? void 0 : price.retailPrice) && { 'discount': (price === null || price === void 0 ? void 0 : price.retailPrice) - (price === null || price === void 0 ? void 0 : price.salePrice) })), { 'price': price === null || price === void 0 ? void 0 : price.salePrice, 'item_brand': (_b = (_a = product === null || product === void 0 ? void 0 : product.brand) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Packs' }), ((product === null || product === void 0 ? void 0 : product.categories) && { 'item_category': product === null || product === void 0 ? void 0 : product.categories[0].name })), (((_c = product === null || product === void 0 ? void 0 : product.categories) === null || _c === void 0 ? void 0 : _c.length) === 2 && { 'item_category2': product === null || product === void 0 ? void 0 : product.categories[1].name })), (((_d = product === null || product === void 0 ? void 0 : product.categories) === null || _d === void 0 ? void 0 : _d.length) === 3 && { 'item_category3': product === null || product === void 0 ? void 0 : product.categories[2].name })), { 'item_list_name': ga_list === null || ga_list === void 0 ? void 0 : ga_list.name, 'item_list_id': ga_list === null || ga_list === void 0 ? void 0 : ga_list.id, 'index': idx + 1, 'quantity': 1 });
    });
    return {
        'event': 'view_item_list',
        'ecommerce': {
            'items': items
        }
    };
};
const getCartItemObject = (data, add = true) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { name, brand, categories, variant, units } = data;
    const quantity = units !== null && units !== void 0 ? units : 1;
    const salePrice = (_b = (_a = variant === null || variant === void 0 ? void 0 : variant.price) === null || _a === void 0 ? void 0 : _a.salePrice) !== null && _b !== void 0 ? _b : 0;
    const discount = ((_c = variant === null || variant === void 0 ? void 0 : variant.price) === null || _c === void 0 ? void 0 : _c.discount) ? (_d = variant === null || variant === void 0 ? void 0 : variant.price) === null || _d === void 0 ? void 0 : _d.discount : (((_e = variant === null || variant === void 0 ? void 0 : variant.price) === null || _e === void 0 ? void 0 : _e.retailPrice) && typeof ((_f = variant === null || variant === void 0 ? void 0 : variant.price) === null || _f === void 0 ? void 0 : _f.retailPrice) === 'number') ? Math.round((((_g = variant === null || variant === void 0 ? void 0 : variant.price) === null || _g === void 0 ? void 0 : _g.retailPrice) - salePrice + Number.EPSILON) * 100) / 100 : 0;
    return {
        'event': add ? 'add_to_cart' : 'remove_from_cart',
        'ecommerce': {
            'currency': 'EUR',
            'value': Math.round(((salePrice * quantity) + Number.EPSILON) * 100) / 100,
            'items': [Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'item_id': variant.sku, 'item_name': name }, (discount && { 'discount': discount })), (variant.idx && { 'index': variant.idx })), { 'item_brand': (_h = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _h !== void 0 ? _h : 'Packs' }), ((categories === null || categories === void 0 ? void 0 : categories[0]) && { 'item_category': categories[0].name })), ((categories === null || categories === void 0 ? void 0 : categories[1]) && { 'item_category2': categories[1].name })), ((categories === null || categories === void 0 ? void 0 : categories[2]) && { 'item_category3': categories[2].name })), ((categories === null || categories === void 0 ? void 0 : categories[3]) && { 'item_category4': categories[3].name })), ((categories === null || categories === void 0 ? void 0 : categories[4]) && { 'item_category5': categories[4].name })), (variant.ga_list && {
                    'item_list_id': variant.ga_list.id,
                    'item_list_name': variant.ga_list.name
                })), { 'item_variant': variant.name, 'price': salePrice, 'quantity': quantity })]
        }
    };
};
// Data from BC
const getCheckoutObj = (data) => {
    const items = data.line_items.physical_items.map((item) => {
        const { name, extended_sale_price, sku, variant_id, quantity } = item;
        return {
            'item_name': name,
            'item_id': sku,
            'price': extended_sale_price,
            'item_variant': variant_id,
            'quantity': quantity
        };
    });
    return {
        'event': 'begin_checkout',
        'ecommerce': {
            'items': items
        }
    };
};
// Data from BC
const getPurchaseObj = (data) => {
    var _a;
    const { id, cart_amount, locale, line_items, currency } = data;
    const items = line_items.physical_items.map((item) => {
        const { name, extended_sale_price, sku, variant_id, quantity } = item;
        return {
            'item_name': name,
            'item_id': sku,
            'price': extended_sale_price,
            'item_variant': variant_id,
            'quantity': quantity
        };
    });
    return {
        'event': 'purchase',
        'ecommerce': {
            'transaction_id': id,
            'affiliation': `Mammafiore ${locale} Store`,
            'value': cart_amount,
            // 'tax': '4.90',
            // 'shipping': '5.99',
            'currency': (_a = currency === null || currency === void 0 ? void 0 : currency.code) !== null && _a !== void 0 ? _a : 'EUR',
            'items': items
        }
    };
};
const events = {
    'view_item_list': (data) => getViewItemListObject(data),
    'select_item': (data) => getSelectItemObject(data),
    'view_item': (data) => getViewItemObject(data),
    'add_to_cart': (data) => getCartItemObject(data, true),
    'remove_from_cart': (data) => getCartItemObject(data, false),
    'begin_checkout': (data) => getCheckoutObj(data),
    'purchase': (data) => getPurchaseObj(data),
    'search': (q) => ({ 'search_term': q })
};
const pushAnalyticsEvent = (event_type, data) => {
    if (typeof window !== 'undefined') {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });
        window['dataLayer'].push(events[event_type](data));
    }
};
exports.pushAnalyticsEvent = pushAnalyticsEvent;
