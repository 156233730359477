
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP12_1, getP18_2, getP12, hex2Rgba } from '@dy/commons/styles'


export const ModalSummary = styled<any>(motion.dt)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greylight};
  border-top: 1px solid ${({ theme }) => hex2Rgba(theme.colors.red, .1)};
  color: ${({ theme }) => theme.colors.darkred};
  display: flex;
  ${getP18_2()}
  font-weight: bold;
  padding: ${vw(18, 'mobile')} ${vw(18, 'mobile')} ${vw(22, 'mobile')};
  position: sticky;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${vw(10, 'mobile')};
  grid-row-gap: ${vw(15, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(18, 'nexus7')} ${vw(18, 'nexus7')} ${vw(22, 'nexus7')};
    grid-column-gap: ${vw(10, 'nexus7')};
    grid-row-gap: ${vw(15, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(18, 'desktop')} ${vw(18, 'desktop')} ${vw(22, 'desktop')};
    grid-column-gap: ${vw(10, 'desktop')};
    grid-row-gap: ${vw(15, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 18px 18px 22px;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
  }

  ${({ $type }) => $type === 'new' && css`border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;`};

  > span:nth-child(2) {
    justify-self: end;
    color: ${({ theme }) => theme.colors.red};
  }

  > button {
    font-weight: 700;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      padding: 0;
      height: ${vw(39, 'desktop')};
      max-width: ${vw(130, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 39px;
      max-width: 130px;
    }

    > div:last-of-type {
      ${getP12_1()}
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 75%;
      height: auto;
      display: inline;
    }
  }
`

export const _Price = styled<any>(motion.div)`
  color: ${({ theme }) => theme.colors.red};
  ${getP12()}
`

