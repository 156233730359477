"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastProvider = exports.ToastContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Toast_1 = require("../components/Toast");
exports.ToastContext = (0, react_1.createContext)(undefined);
const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = (0, react_1.useState)([]);
    const toastIdRef = (0, react_1.useRef)(0);
    const addToast = (0, react_1.useCallback)((toastData) => {
        setToasts((prevToasts) => [
            ...prevToasts,
            Object.assign(Object.assign({}, toastData), { id: toastIdRef.current++ }),
        ]);
    }, [setToasts]);
    return ((0, jsx_runtime_1.jsxs)(exports.ToastContext.Provider, Object.assign({ value: { addToast } }, { children: [children, (0, jsx_runtime_1.jsx)(Toast_1.ToastWrapper, Object.assign({ "aria-live": 'assertive', "aria-atomic": 'true' }, { children: toasts.map((toast) => ((0, jsx_runtime_1.jsx)(Toast_1.Toast, Object.assign({}, toast, { onClose: () => setToasts((prev) => prev.filter((t) => t.id !== toast.id)) }), toast.id))) }))] })));
};
exports.ToastProvider = ToastProvider;
