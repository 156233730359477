// import { getLocaleCountry } from '@dy/commons/utils'

// const NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE
// const localeCountry = getLocaleCountry(NEXT_PUBLIC_LOCALE)
// const LOCALE = localeCountry.split('-')[0]
// const REGION = localeCountry.split('-')[1]
// const DY_API_URL = `${process.env.DY_API}/${REGION}/${LOCALE}`
const DY_API_URL = `https://${process.env.DY_CMS}/api/v1`
const STORE_HASH = process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH

export const endpoints = {
  'layout': () => `${DY_API_URL}/layout.json`,
  'search': (query) => `${DY_API_URL}/search?q=${query.q}`,
  'search-products': (query) =>  `${DY_API_URL}/products/search?q=${query.q}`,
  'home': () => `${DY_API_URL}/pages/home.json`,
  'categories': () => `${DY_API_URL}/categories/product.json`,
  'category' : (query) => `${DY_API_URL}/categories/product/${query.slug}.json`,
  'products': () => `${DY_API_URL}/products.json`,
  'product': (query) => `${DY_API_URL}/products/${query.slug}.json`,
  'relateds': (query) => `${DY_API_URL}/products/${query.slug}/related.json`,
  'faqs': () => `${DY_API_URL}/faqs.json`,
  'pages': () => `${DY_API_URL}/pages.json`,
  'page': (query) => `${DY_API_URL}/pages/${query.slug}.json`,
  'user-usual-purchases': (query) => `${DY_API_URL}/customers/${query.customerId}/products`,
  'brand': (query) => `${DY_API_URL}/brands/${query.slug}`,
  'brands': () => `${DY_API_URL}/brands/`,
  'brand-categories': (query) => `${DY_API_URL}/brands/${query.slug}/categories/`,
  'brand-category': (query) => `${DY_API_URL}/brands/${query.slug}/categories/${query.categorySlug}`,
  'download-customer-invoice-pdf': (query) => `${DY_API_URL}/invoices/pdf/${query.slug}`,
  'checkout-url': (query) => `https://api.bigcommerce.com/stores/${STORE_HASH}/v3/carts/${query.cartId}/redirect_urls`
}
