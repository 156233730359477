"use strict";
// PENDING TODO improve locale on base file instead of here since its a general type
// type TypeLocale = 'en-EU' | 'es-ES' | 'es-CT' | 'es-CN' | 'es-MD' | 'en-GB' | 'fr-FR' | 'de-DE'
// type TypeLocaleCountry = 'es-ES' | 'en-GB' | 'fr-FR' | 'de-DE'
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRegion = exports.getLang = exports.getStoreUrlByLocale = exports.getStoreByLocale = exports.getLocaleCountry = void 0;
// export function getLocaleCountry(locale: TypeLocale): TypeLocaleCountry {
function getLocaleCountry(locale) {
    switch (locale) {
        case 'es-CN':
        case 'es-CT':
        case 'es-MD':
            return 'es-ES';
        case 'en-EU':
            return 'en-GB';
        default:
            return locale;
    }
}
exports.getLocaleCountry = getLocaleCountry;
exports.getStoreByLocale = {
    'en-EU': 'Europe',
    'es-CN': 'Canarias',
    'es-CT': 'Cataluña',
    'es-MD': 'Madrid',
    'fr-FR': 'France',
    'en-GB': 'United Kingdom',
    'de-DE': 'Deutschland',
    'pt-PT': 'Portugal',
};
const getStoreUrlByLocale = (locale, PRO) => {
    const url = {
        'en-EU': 'mammafiore.eu',
        'es-ES': 'mammafiore.es?zone=es-ES',
        'es-CN': 'canarias.mammafiore.es?zone=es-CN',
        'es-CT': 'cat.mammafiore.es?zone=es-CT',
        'es-MD': 'madrid.mammafiore.es?zone=es-MD',
        'fr-FR': 'mammafiore.fr?zone=fr-FR',
        'en-GB': 'mammafiore.co.uk?zone=en-GB',
        'de-DE': 'mammafiore.de?zone=de-DE',
        'pt-PT': 'mammafiore.pt?zone=pt-PT',
    };
    if (!url[locale])
        return undefined;
    return (!PRO ? 'staging.' : '') + url[locale];
};
exports.getStoreUrlByLocale = getStoreUrlByLocale;
function getLang(str) {
    if (typeof str !== 'string')
        throw new Error('getLang parameter must be typeof "string"');
    return str.substring(2, 0);
}
exports.getLang = getLang;
function getRegion(str) {
    if (typeof str !== 'string')
        throw new Error('getRegion parameter must be typeof "string"');
    return str.split('-')[1];
}
exports.getRegion = getRegion;
