import { createContext, useEffect, useState } from 'react'
import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import type { TypeCustomer } from '@dy/commons/schema'
import { cookie } from '@dy/commons/utils'
import { useQuery } from '@apollo/client'
import { GET_CUSTOMER } from '@/api'

type TypeCustomerState = {
  customer: TypeCustomer,
  setCustomer: Dispatch<SetStateAction<TypeCustomer>>
  loaderActive: boolean,
  setLoaderActive: Dispatch<SetStateAction<boolean>>,
}

export const CustomerContext = createContext<TypeCustomerState | null>(null)

interface props {
  data?: TypeCustomer
  children: ReactNode
}

export const CustomerProvider: FC<props> = ({ data = null, children }) => {
  const [customer, setCustomer] = useState<TypeCustomer>(data)
  const [loaderActive, setLoaderActive] = useState<boolean>(false)

  const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
  const { data:customerData } = useQuery<TypeCustomerState>(GET_CUSTOMER, {
    context: {
      isPrivatePath: true,
      DYCustomerToken
    },
    skip: !DYCustomerToken, // Skip the query if no customer token
  })
  const allowedPaymentMethods = ['giro','check','delivery','transferencia']

  useEffect(() => {
    const customer = customerData?.customer
    if (customer) {
      setCustomer(customer)
      cookie.set({ name: 'DY_CUSTOMER_PAYMENT_METHOD', value: customer.paymentMethod && allowedPaymentMethods.indexOf(customer.paymentMethod) !== -1 ? customer.paymentMethod : 'fallback' })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData])

  return (
    <CustomerContext.Provider value={{ customer, setCustomer, loaderActive, setLoaderActive }}>
      {children}
    </CustomerContext.Provider>
  )
}
