import { memo } from 'react'
import styled from 'styled-components'
import { mq, vw } from '@dy/commons/styles'
import { Figure, Link } from '@dy/commons/components'

interface ILogo {
  fillColor: any
}

const mediaLogo = {
  type: 'image' as const,
  alt: 'Mammafiore logo',
  default: {
    src:'',
    size: { width: 261, height: 66 }
  }
}

export const Logo = memo<ILogo>(({ fillColor }) => {
  const mediaLogoWithSrc = {...mediaLogo, default:{...mediaLogo.default, src: fillColor === 'white' ? '/images/svg/logo--white.svg' : '/images/svg/logo.svg'} }
  return (
    <Link href='/' className='logo'>
      <LogoStyled>
        <Figure media={mediaLogoWithSrc}></Figure>
        <span className='srt-only'>Go to Homepage</span>
      </LogoStyled>
    </Link>
  )
})


export const LogoStyled = styled.div`
  margin: ${vw(20, 'mobile')} auto;
  order: 2;
  padding: 0 ${vw(30, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(20, 'nexus7')} auto;
    padding: 0 ${vw(30, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin: ${vw(40, 'desktop')} auto;
    grid-column: 1 / span 3;
    order: 0;
    padding: 0 ${vw(50, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: 40px auto;
    padding: 0 50px;
  }


  figure {
    margin: 0 auto;
    height: ${vw(23, 'mobile')};
    position: relative;
    width: ${vw(123, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(23, 'nexus7')};
      width: ${vw(123, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(44, 'desktop')};
      width: ${vw(184, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 44px;
      width: 184px;
    }
  }
`


