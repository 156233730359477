"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconByName = void 0;
const altByIcon = {
    'close': 'Close icon',
};
const iconByName = (iconName) => ({
    type: 'svg',
    src: `/images/svg/i--${iconName}.svg`,
    alt: altByIcon[iconName],
    size: { width: 24, height: 24 }
});
exports.iconByName = iconByName;
