import { memo, useMemo } from 'react'
import { Content } from './Content'
import styled from 'styled-components'
import { mq, vw } from '@dy/commons/styles'
interface IContent {
  type: 'text' | 'empty' | 'media' | 'spacer',
  col: { from: number, expand: number },
  row: { from: number, expand: number },
  text?: string,
  media?: any,
  color?: string,
  align?: { v: string, h: string },
  [key: string]: any
}

interface ISectionContents {
  section: {
    rows: number,
    columns: IContent[]
  }
}

const getContentsByColumns = (columns) => columns.map((content, idx) => {
  return(
    <Content key={idx + 'column'} content={content} />
  )
})

export const SectionContents = memo(({ section }:ISectionContents) => {
  const { columns } = section
  const ContentsByColumns = useMemo(() => getContentsByColumns(columns), [columns])

  return (
    <ContentSection>
      {ContentsByColumns}
    </ContentSection>
  )
})


const ContentSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / span 6;
  margin-bottom: ${vw(5, 'mobile')};
  padding: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(53, 'nexus7')};
    padding: ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 3 / span 8;
    margin-bottom: ${vw(60, 'desktop')};
    padding: 0;
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 60px;
  }

  &:has(.contact) {
    margin-bottom: 0;
    padding: 0;
    ${mq.greaterThan('tablet')} {
      grid-column: 2/ span 12;
    }

 
  }
  > article {
    &.media {
      width: 100%;
    }

    &.text {
      min-width: 100%;
      ${mq.greaterThan('tablet')} {
        padding: 0 ${vw(120, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 0 auto;
      }

      ${mq.greaterThan('desktop-wide')} {
        padding: 0;
      }

      &:has(.contact) {
          padding: 0;
        
      }
    }
  }

  .spacer {
    height: ${vw(40, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(40, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(50, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 50px;
    }
  }
`