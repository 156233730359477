'use client'
import { memo, useMemo, useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useCustomer, useTranslator } from '@/hooks'
import { useModal, useScrollTracker } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { vw, mq, hex2Rgba, getP17NavLinks, getP18_2, createUnderline, getTransition, getP18_3, getP9 } from '@dy/commons/styles'
import { Link, Figure } from '@dy/commons/components'
import { ButtonTriggerSearch } from '@/components'
import { Burger } from './Burger'
import { AnimatePresence, motion } from 'framer-motion'
import { useDeviceType, useCart } from '@dy/commons/hooks'
import { userLoggedInVariants, firstNameVariants } from '../layoutVariants'
import { DY_LOGIN_ALLOWED, HOSTNAME, cookie, getStoreByLocale } from '@dy/commons/utils'

interface INavbar {
  firstCategory: any,
  setShowCart: (boolean) => void,
  hasBanners?: boolean
}

const accountIcon = (fillColor, onHover) => ({
  type: 'svg' as const,
  src: `/images/svg/i--account${onHover ? '--creme' : fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'User account icon',
  size: { width: 18, height: 18 }
})

const cartIcon = (fillColor) => ({
  type: 'svg' as const,
  src: `/images/svg/i--cart${fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'Cart icon',
  size: { width: 21, height: 18 }
})

const logo = (fillColor = 'darkred') => ({
  type: 'svg' as const,
  src: `/images/svg/logo${fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'Logo',
  size: { width: 261, height: 66 }
})

export const locationIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--location.svg',
  alt: 'Location icon',
  size: { width: 14, height: 18 }
}

const NavLinkAccount = ({ fillColor }) => {
  const { t } = useTranslator()
  const { toggle:toggleModalAuth } = useModal(MODALS.AUTH)
  const { toggle:toggleModalMenuAccount } = useModal(MODALS.MENU_ACCOUNT)
  const { isMobile } = useDeviceType()
  const [onHover, setOnHover] = useState(false)
  const { customer } = useCustomer()
  const clickAction = useMemo(() => !!customer && isMobile ? toggleModalMenuAccount : toggleModalAuth, [isMobile, customer, toggleModalMenuAccount, toggleModalAuth])

  return (
    <AnimatePresence>
      {!isMobile && customer ?
        <motion.a href={'/account/orders'} title={t('layout.nav.account.name') as string} className={'account'} variants={userLoggedInVariants} initial='initial' exit='exit' animate={'animate'}>
          <Figure media={accountIcon(fillColor, onHover)} fill={false} />
          {customer.firstName && <motion.span className='firstname' initial='initial' animate='animate' exit='exit' variants={firstNameVariants}>{customer.firstName}</motion.span>}
        </motion.a>
        :
        <motion.button onClick={clickAction} title={'Account'} className='account' variants={userLoggedInVariants} initial='initial' exit='exit' animate={'animate'} onMouseEnter={() => setOnHover(!!customer)} onMouseLeave={() => setOnHover(false)}>
          <Figure media={accountIcon(fillColor, onHover)} fill={false} />
          <span className='account_s'>{t('layout.nav.account.name')}</span>
        </motion.button>
      }
    </AnimatePresence>
  )
}

const NavLinkCart = ({ fillColor = 'red' }) => {
  const { cartLines } = useCart()
  const { toggle:toggleAuth } = useModal(MODALS.AUTH)
  const { customer } = useCustomer()

  return (
    <>
      {!!customer ?
        <CartLink href={`https://${HOSTNAME}/cart?d=${Date.now()}`} title={'Cart icon'} className='cart'>
          <Figure media={cartIcon(fillColor)} fill={false} />
          <ItemsInCartCircle visible={cartLines.length > 0 ? true : false}>
            <span className='quantity'>{cartLines?.length}</span>
          </ItemsInCartCircle>
        </CartLink>
        :
        <button onClick={toggleAuth} title={'Cart icon'} className='cart'>
          <Figure media={cartIcon(fillColor)} fill={false} />
        </button>
      }
    </>
  )
}


export const Navbar = memo<INavbar>(({ firstCategory, setShowCart, hasBanners }) => {
  const { pathname, locale } = useRouter()
  const { t } = useTranslator()
  const scrollPos = useScrollTracker(1)
  const [storeLocation, setStoreLocation] = useState('')
  const navbarRef= useRef(null)
  const { size:deviceSize } = useDeviceType()
  const scrollTop = useMemo(() => scrollPos === 'TOP', [scrollPos])
  const animate = useMemo(() => deviceSize.width < 603 ? scrollTop ? 'mobile' : 'mobileCompact' : (deviceSize.width >= 603 && deviceSize.width < 768) ? scrollTop ? 'nexus7' : 'nexus7Compact' : scrollTop ? 'initial' : 'desktopCompact', [scrollTop, deviceSize.width])

  const { isActive:isActiveModalMenu, toggle: toggleModalMenu } = useModal(MODALS.MENU)
  const { toggle: toggleRegion } = useModal(MODALS.REGION_LANG)
  const fillColor = useMemo(() => !isActiveModalMenu || (deviceSize.width >= 768) ? 'darkred' : 'white', [isActiveModalMenu, deviceSize])
  const { customer } = useCustomer()

  useEffect(() => {
    const cookieCatalogRegion = cookie.get('DY_CATALOG_REGION')
    const countryCode = 'ES'

    async function getCurrentLocation() {
      const data = await import(`/public/locales/countries/${countryCode}-subdivisions.json`, { assert: { type: 'json' } })
      const dataArr = Array.from(data)
      // @ts-ignore
      const incomingLocation = dataArr.filter(item => item.value === cookieCatalogRegion.toUpperCase())?.[0]
      // @ts-ignore
      setStoreLocation(incomingLocation ? incomingLocation.name as string : 'España')
    }

    if(storeLocation) return
    else if (getStoreByLocale[locale]) {
      const store = getStoreByLocale[locale]
      setStoreLocation(store)
    } else if (!cookieCatalogRegion) {
      const store = getStoreByLocale[locale]
      setStoreLocation(store ?? 'España')
      return
    } else getCurrentLocation()
  }, [locale, setStoreLocation, storeLocation])

  return (
    <NavStyled role='navigation' ref={navbarRef} $isMenuActive={isActiveModalMenu} $scrollTop={scrollPos === 'TOP'} variants={navVariants({ mq: deviceSize?.width, hasBanners })} initial='initial' animate={animate} $boxShadow={pathname !== '/shop/[...slug]' && pathname !== '/catalog/[...slug]'}>
      <ul>
        <li>
          <Burger onClick={toggleModalMenu} menuOpen={isActiveModalMenu} />
        </li>
        <li className='li--search'>
          <ButtonTriggerSearch fillColor={fillColor} />
        </li>
        <li className={`li--logo${!customer ? ' no-cart' : ''}`}>
          <Link href='/' title={t('layout.nav.home.name') as string}>
            <Figure media={logo(fillColor)} fill={false} />
          </Link>
        </li>
        <li className='li--links'>
          <ul>
            <li>
              <Link href={`/catalog/${firstCategory}`}>
                {customer ? t('layout.nav.shop.name') : t('layout.nav.products.name') }
              </Link>
            </li>
            {!customer && <>
              <li>
                <Link href={t('layout.nav.about_us.pathname')}>
                  {t('layout.nav.about_us.name')}
                </Link>
              </li>
              <li>
                <Link href={t('layout.nav.events.pathname')}>
                  {t('layout.nav.events.name')}
                </Link>
              </li>
              <li>
                <Link href={t('layout.nav.catalogue.pathname')}>
                  {t('layout.nav.catalogue.name')}
                </Link>
              </li>
              <li>
                <Link href={t('layout.nav.panettiamo.pathname')}>
                  {t('layout.nav.panettiamo.name')}
                </Link>
              </li>
              <li>
                <Link href={t('layout.nav.contact_us.pathname')}>
                  {t('layout.nav.contact_us.name')}
                </Link>
              </li>
            </>}
            {customer && <li>
              <Link href={t('layout.nav.my_usuals.pathname')}>
                {t('layout.nav.my_usuals.name')}
              </Link>
            </li>}
          </ul>
        </li>
        <li className='li--location'>
          <button onClick={toggleRegion} title={'Region'} >
            <Figure media={locationIcon} fill={false} />
            <span>{storeLocation}</span>
          </button>
        </li>
        {(DY_LOGIN_ALLOWED && (locale === 'es-CT' || locale === 'es-MD')) ?
          <li className='li--account'>
            <NavLinkAccount fillColor={fillColor} />
          </li>
          :
          <li className='li--account slot'></li>
        }
        {customer &&
          <li className='li--cart' onMouseEnter={() => { if(pathname !== '/cart') setShowCart(true) }}>
            <NavLinkCart fillColor={fillColor} />
          </li>
        }
      </ul>
    </NavStyled>
  )
})

const CartLink = styled.a`
  position: relative;

  &.loading {
    pointer-events: none;
    user-select: none;
  }
`

const NavStyled = styled(motion.nav)<{ $scrollTop: boolean, $isMenuActive: boolean, $boxShadow:boolean }>`
  background-color: ${({ $isMenuActive, theme }) => ($isMenuActive) ? theme.colors.red : theme.colors.background};
  box-shadow: ${({ $isMenuActive, $scrollTop, $boxShadow, theme: { colors } }) => ($boxShadow && !$scrollTop && !$isMenuActive) ? `0 4px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
  color: ${({ $isMenuActive, theme }) => theme.colors[$isMenuActive ? 'white' : 'red']};
  height: ${vw(60, 'mobile')};
  position: fixed;
  transition: 300ms background-color ease-out, 300ms box-shadow ease-out;
  width: 100vw;
  z-index: 600;
  font-family: ${({ theme }) => theme.fonts.tertiaryFont};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(60, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.red};
    height: ${vw(120, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 120px;
  }

  ul {
    margin: 0;
  }

  > ul {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    margin: 0;
    position: relative;
    width: 100%;

    ${mq.greaterThan('desktop-wide')} {
      margin: 0 auto;
      max-width: 1650px;
    }

    &:before {
      background-color: ${({ theme }) => theme.colors.red};
      content: '';
      height: 100%;
      pointer-events: none;
      position: absolute;
      user-select: none;
      width: ${({ $scrollTop, $isMenuActive }) => (!$isMenuActive || (!$scrollTop && $isMenuActive)) ? 0 : '100vw'};
      transition: 300ms width ease-out;
      z-index: -1;

      ${mq.greaterThan('tablet')} {
        background-color: ${({ theme }) => theme.colors.background};
      }
    }

    li:not(.li--links li) {
      &:first-child {
        ${mq.greaterThan('tablet')} {
          display: none;
        }
      }
    }
  }

  li {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .li--logo {
    margin: ${vw(12, 'mobile')} auto 0;
    user-select: none;

    ${mq.greaterThan('nexus7')} {
      margin: ${vw(12, 'nexus7')} auto 0;
    }

    ${mq.greaterThan('tablet')} {
      margin: ${vw(12, 'desktop')} ${vw(30, 'desktop')} 0 ${vw(50, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 12px 30px 0 50px;
    }

    &.no-cart {
      transform: translateX(-${vw(14, 'mobile')});

      ${mq.greaterThan('nexus7')} {
        transform: translateX(-${vw(14, 'nexus7')});
      }

      ${mq.greaterThan('tablet')} {
        transform: translateX(0);
      }
    }
  }

  .li--links {
    display: none;

    ${mq.greaterThan('tablet')} {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    ul, li {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    li {
      ${mq.greaterThan('tablet')} {
        margin: 0 ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin: 0 15px;
      }
    }

    a, button {
      ${createUnderline({ color: 'red', height:2 })}
      ${getP17NavLinks}
    }
  }

  .li--location {
    display: flex;

    ${mq.greaterThan('tablet')} {
      margin: 0 ${vw(16, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 0 16px;
    }

    button {
      font-weight: 500;
    }

    figure {
      margin-right: ${vw(10, 'mobile')};
      width: ${vw(14, 'mobile')};

      ${mq.greaterThan('tablet')} {
        margin-right: ${vw(10, 'desktop')};
        width: ${vw(14, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-right: 10px;
        width: 14px;
      }
    }

    span {
      display: none;

      ${mq.greaterThan('tablet')} {
        max-width: ${vw(136, 'desktop')};
      }

      ${mq.greaterThan('tablet')} {
        display: inline-block;
        line-height: normal !important;
        max-width: ${vw(136, 'desktop')};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ${mq.greaterThan('desktop')} {
        max-width: 136px;
      }
    }
  }

  .li--account,
  .li--cart {
    a,
    button {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: relative;
      width: ${vw(39, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(39, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        min-width: ${vw(50, 'desktop')};
        width: unset;
      }

      ${mq.greaterThan('desktop')} {
        min-width: 50px;
      }
    }
  }

  .li--location,
  .li--account,
  .li--cart {
    ${mq.greaterThan('tablet')} {
    order: 2;
    }

    span:not(.quantity) {
      ${getP18_2()}
    }

    figure {
      flex-shrink: 0;
    }
  }

  .li--cart,
  .li--account {
    &.slot {
      width: ${vw(39, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(39, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: auto;
      }
    }

    &:last-of-type {
      ${mq.greaterThan('tablet')} {
        margin-right: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-right: 40px;
      }
    }
  }

  .li--account {
    button {
      margin-right:  unset;

      ${mq.greaterThan('tablet')} {
        margin-right:  ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-right: 20px;
      }
    }

    .firstname {
      display: none;

      ${mq.greaterThan('tablet')} {
        ${getP18_3()}
        display: inline-block;
        max-width: 9vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    figure {
      margin-right: ${vw(10, 'mobile')};
      width: ${vw(21, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-right: ${vw(10, 'nexus7')};
        width: ${vw(21, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw(18, 'desktop')};
        margin-right: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 18px;
        margin-right: 10px;
      }
    }

    .account_s {
      display: none;

      ${mq.greaterThan('tablet')} {
        display: block;
        font-weight: 500;
      }
    }
  }

  .li--cart {
    figure {
      width: ${vw(25, 'mobile')};
      margin-right: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-right: ${vw(10, 'nexus7')};
        width: ${vw(25, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-right: 0;
        width: ${vw(21.7, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 20.7px;
        margin-right: 0;
      }
    }
  }

  .li--logo {
    figure {
      width: ${vw(123, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(123, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw(184, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 184px;
      }
    }
  }

  .li--search {
    ${mq.greaterThan('tablet')} {
      margin: 0 ${vw(15, 'desktop')} 0 auto;
      order: 1;
    }

    ${mq.greaterThan('desktop')} {
      margin: 0 15px 0 auto;
    }

    figure {
      width: ${vw(25, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(25, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw(21.7, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 20.7px;
      }
    }
  }

  p {
    margin: 0;
  }

  button,
  a {
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration-color: transparent;
    transition: 300ms color ease-out, 300ms text-decoration-color ease-out;

    &:hover {
      text-decoration-color: white;
    }
  }
`

const ItemsInCartCircle = styled.div<any>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orange};
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  bottom: 20%;
  display: flex;
  height: ${vw(15, 'mobile')};
  justify-content: center;
  opacity: 0;
  opacity: ${({ visible }) => visible ? 1 : 0};
  position: absolute;
  pointer-events: none;
  right: 5%;
  transition: 250ms opacity ${({ ease }) => ease }, 250ms border-color ${({ ease }) => ease };
  user-select: none;
  width: ${vw(15, 'mobile')};

  ${mq.greaterThan('tablet')} {
    bottom: 38%;
    height: ${vw(8, 'desktop')};
    right: 18%;
    width: ${vw(8, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 8px;
    width: 8px;
  }

  .quantity {
    ${getP9()}
    margin-left: 0;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    ${mq.greaterThan('tablet')} {
      display: none;
    }
  }
`
const navVariants = ({ mq, hasBanners }) => ({
  mobile: {
    height: vw(hasBanners ? 100 : 80, 'mobile'),
    paddingTop: hasBanners ? '40px' : 0,
    transition: {
      ...getTransition()
    }
  },
  mobileCompact: {
    height: vw(60, 'mobile'),
    paddingTop: 0,
    transition: {
      ...getTransition()
    }
  },
  nexus7: {
    height: vw(hasBanners ? 100 : 80, 'nexus7'),
    paddingTop: hasBanners ? '40px' : 0,
    transition: {
      ...getTransition()
    }
  },
  nexus7Compact: {
    height: vw(60, 'nexus7'),
    paddingTop: 0,
    transition: {
      ...getTransition()
    }
  },
  initial: {
    height: mq > 1440 ? '120px' : vw(120, 'desktop'),
    paddingTop: hasBanners ? mq > 1440 ? '30px' : vw(30, 'desktop') : 0,
    transition: {
      ...getTransition()
    }
  },
  desktopCompact: {
    height: mq > 1440 ? '80px' : vw(80, 'desktop'),
    paddingTop: 0,
    transition: {
      ...getTransition(),
    }
  }
})
