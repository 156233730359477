"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDropdown = void 0;
const react_1 = require("react");
const useDropdown = ({ onChange } = { onChange: null }) => {
    const triggerRef = (0, react_1.useRef)(null);
    const dropdownRef = (0, react_1.useRef)(null);
    const [expanded, setExpanded] = (0, react_1.useState)(false);
    const toggleExpanded = (0, react_1.useCallback)(() => {
        setExpanded((state) => !state);
    }, []);
    const onTriggerFocus = (0, react_1.useCallback)(() => {
        if (!expanded)
            setExpanded(true);
    }, [expanded]);
    const onMouseClick = (0, react_1.useCallback)(({ target }) => {
        const triggerClicked = triggerRef.current ? triggerRef.current.contains(target) : null;
        const dropdownClicked = dropdownRef.current ? dropdownRef.current.contains(target) : null;
        if (!triggerClicked && !dropdownClicked) { // outside click
            setExpanded(false);
        }
        else if (dropdownClicked) { // dropdown list click
            if (target instanceof HTMLAnchorElement || (target instanceof HTMLButtonElement && target.dataset.dismiss) || target.dataset.value) {
                const value = target.dataset.value;
                const text = target.textContent;
                onChange && onChange(value, text);
                // NOTE setExpanded shouldBeManaged in the onClick option event, to avoid this mouseCLick to run before and close dropdown before all events have been run.
                // setTimeout(() => {
                //   setExpanded(false)
                // }, 90)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onEsc = (0, react_1.useCallback)(e => {
        if (e.keyCode === 27 && expanded === true)
            setExpanded(false);
    }, [expanded]);
    (0, react_1.useEffect)(() => {
        document[`${expanded ? 'add' : 'remove'}EventListener`]('mousedown', onMouseClick);
        window[`${expanded ? 'add' : 'remove'}EventListener`]('keyup', onEsc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);
    return [triggerRef, dropdownRef, expanded, onTriggerFocus, toggleExpanded];
};
exports.useDropdown = useDropdown;
