import { gql } from '@apollo/client'
import { FRAGMENT_PRODUCT_REQUIRED, FRAGMENT_VARIANT_REQUIRED } from './'

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($type:CategoryTypeEnum) {
    categories(type: $type) {
      id
      name
      slug
      categoryType
      subcategories {
        id
        name
        slug
        categoryType
      }
    }
  }
`

export const GET_CATEGORY = gql`
  query GET_CATEGORY($slug: String!) {
    category(slug: $slug) {
      name
      seo
      slug
      categoryType
      subcategories {
        id
        name
        slug
        categoryType
      }
      variants {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
            packItems {
              name
              sku
            }
            product {
              ...FragmentProductRequired
              brand {
                name
                slug
              }
              labels {
                name
              }
              filters {
                name
                param
                type: filterType
                values {
                  name: value
                  description
                  media: image
                  value:parameterizedValue
                }
              }
              categories {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_REQUIRED}
  ${FRAGMENT_PRODUCT_REQUIRED}
`

export const GET_LAYOUT = gql`
  query GET_LAYOUT($bannerType: BannerTypeEnum, $type:CategoryTypeEnum ) {
    banners(bannerType: $bannerType) {
      title
      titleSize
      bannerType
    }
    categories(type: $type) {
      id
      name
      slug
      categoryType
      subcategories {
        id
        name
        slug
        categoryType
      }
    }
    modals {
      edges {
        cursor
        node {
          linkPath
          linkTitle
          modalType
          text
          title
          visibleFrom
          visibleUntil
        }
      }
    }
    footers {
      id
      itemType
      title
      url
    }
  }
`

export const GET_FAQS = gql`
  query GET_FAQS($type: CategoryTypeEnum) {
    categories(type: $type) {
        id
        name
        seo
        slug
        faqs {
          answer
          parameterize
          question
        }
    }
  }
`

export const GET_PAGE_CATEGORY = gql`
  query GET_PAGE_CATEGORY($slug: String!, $categorySlug: String!) {
    page(slug: $slug) {
      pageType
      slug
      title
      subtitle
      seo
      contents {
        rows
        columns {
          columnType
          download
          horizontalAlignment
          imageCaption
          longVideo
          mediaSize
          mediaType
          medium
          shortVideo
          text
          verticalAlignment
          videoType
        }
      }
      variants(categorySlug: $categorySlug) {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
            packItems {
              name
              sku
            }
            product {
              ...FragmentProductRequired
              brand {
                name
                slug
              }
              labels {
                name
              }
              filters {
                name
                param
                type: filterType
                values {
                  name: value
                  description
                  media: image
                  value:parameterizedValue
                }
              }
              categories {
                id
                name
                slug
              }
            }
          }
        }
      }
      category(slug: $categorySlug) {
        name
        slug
        subcategories {
          name
          slug
          parentId
        }
      }
      categories {
        name
        parentId
        slug
        subcategories {
          name
          slug
          parentId
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_REQUIRED}
  ${FRAGMENT_VARIANT_REQUIRED}
`

export const GET_PAGE = gql`
  query GET_PAGE($slug: String!) {
    page(slug: $slug) {
      pageType
      slug
      title
      subtitle
      seo
      contents {
        rows
        columns {
          columnType
          download
          horizontalAlignment
          imageCaption
          longVideo
          mediaSize
          mediaType
          medium
          shortVideo
          text
          verticalAlignment
          videoType
        }
      }
      variants {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
            packItems {
              name
              sku
            }
            product {
              ...FragmentProductRequired
              brand {
                name
                slug
              }
              labels {
                name
              }
              filters {
                name
                param
                type: filterType
                values {
                  name: value
                  description
                  media: image
                  value:parameterizedValue
                }
              }
              categories {
                id
                name
                slug
              }
            }
          }
        }
      }
      categories {
        name
        parentId
        slug
        subcategories {
          name
          slug
          parentId
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_REQUIRED}
  ${FRAGMENT_VARIANT_REQUIRED}
`

export const GET_HOMEPAGE = gql`
  query GET_HOMEPAGE {
    homepage {
      title
      subtitle
      slug
      seo
      visible
      pageType
      videos {
        title
      }
      banners {
        bannerType
        mainType
        productId
        variant {
          sku
        }
        textAlign
        textValign
        textPosition
        mediaType
        videoType
        shortVideo
        title
        subtitle
        link
        titleSize
        subtitleSize
        ingredients
        leftImage
        centerImage
        rightImage
        media
        backImage
      }
      highlighted {
        ...FragmentVariantRequired
        packItems {
          name
          sku
        }
        product {
          ...FragmentProductRequired
          labels {
            name
          }
          brand {
            name
            slug
          }
          filters {
            name
            param
            type: filterType
            values {
              name: value
              description
              media: image
            }
          }
          categories {
            id
            name
            slug
          }
        }
      }
      topSellers {
        ...FragmentVariantRequired
        packItems {
          name
          sku
        }
        product {
          ...FragmentProductRequired
          labels {
            name
          }
          brand {
            name
            slug
          }
          filters {
            name
            param
            type: filterType
            values {
              name: value
              description
              media: image
            }
          }
          categories {
            id
            name
            slug
          }
        }
      }
      services {
        title
        titleSize
      }
      brands {
        name
        slug
        logo
      }
      contents {
        rows
        columns {
          columnType
          text
          imageCaption
          medium
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_REQUIRED}
  ${FRAGMENT_VARIANT_REQUIRED}
`

export const GET_PAGES = gql`
  query GET_PAGES($type: PageTypeEnum) {
    pages(type: $type) {
      slug
    }
  }
`

export const GET_POSTAL_CODES_VALID = gql`
  query GET_POSTAL_CODES_VALID {
    validPostalCodes {
      from
      to
      value
      valueType
    }
  }
`

export const GET_SITEMAP = gql`
  query GET_SITEMAP {
    categories(type: PRODUCT) {
      slug
      updatedAt
      subcategories {
        slug
        updatedAt
      }
    }
    products{
      slug
      updatedAt
    }
    brands {
      slug
      updatedAt
    }
    contents:pages(type: REGULAR) {
      slug
      updatedAt
    }
    faqsCategories:categories(type: FAQ) {
      slug
      updatedAt
      subcategories {
        slug
        updatedAt
      }
    }
  }
`
