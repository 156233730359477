"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputPhone = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../../styles");
const media_1 = require("../media");
const dropdown_1 = require("../dropdown");
const framer_motion_1 = require("framer-motion");
const _1 = require(".");
const countries = [
    { value: '+44', longCode: '0044', name: 'United Kingdom', flag: '🇬🇧', locale: 'UK' },
    { value: '+34', longCode: '0034', name: 'España', flag: '🇪🇸', locale: 'ES' },
    { value: '+33', longCode: '0033', name: 'France', flag: '🇫🇷', locale: 'FR' },
    // Add more countries as needed
];
const hasContentState = () => (0, styled_components_1.css) `
  ${(0, styles_1.getP18InputHasContentLabel)()}
  opacity: 1;
  transform: translateY(-${(0, styles_1.vw)(25, 'mobile')});

  ${styles_1.mq.greaterThan('tablet')} {
    transform: translateY(-${(0, styles_1.vw)(30, 'desktop')});
  }

  ${styles_1.mq.greaterThan('desktop')} {
    transform: translateY(-30px);
  }
`;
const mediaPasswordIcon = {
    type: 'image',
    alt: 'Password eye icon',
    default: {
        src: '/images/svg/i--password-eye.svg',
        size: { width: 18, height: 18 }
    }
};
// aria-invalid https://react-hook-form.com/advanced-usage
const getIcon = (icon, type, setInputType) => {
    if (!icon)
        return false;
    if (typeof icon === 'boolean' || typeof icon === 'string') {
        if (type === 'password') {
            const Icon = (0, jsx_runtime_1.jsx)("button", Object.assign({ type: 'button', className: 'btn--toggle-password', onClick: (e) => {
                    e.preventDefault();
                    setInputType(prevType => prevType === 'password' ? 'text' : 'password');
                } }, { children: (0, jsx_runtime_1.jsx)(media_1.Figure, { media: mediaPasswordIcon }) }), 'icon');
            return [Icon, true];
        }
        else if (type === 'search') {
            // Search and clear action
        }
    }
    else if ((0, react_1.isValidElement)(icon)) {
    }
};
const getOptionsList = (data, onClickOption, currentValue, prefixType) => data.map((option, idx) => {
    var _a;
    return ((0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${currentValue === option[prefixType] ? 'current' : ''}${option.store ? ` online-store${((_a = data[idx + 1]) === null || _a === void 0 ? void 0 : _a.store) ? '' : ' last-store'}` : ''}` }, { children: (0, jsx_runtime_1.jsxs)("button", Object.assign({ type: 'button', onClick: () => { onClickOption(option); }, "data-dismiss": true }, { children: [option[prefixType], " ", option.value] })) }), `${option.value}-${idx}`));
});
const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null });
// Attributes https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
let TIMEOUT_ID = null;
const InputPhone = ({ type = 'tel', name = '', nameCode = 'countryCode', defaultValue = '', label, autoComplete, placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, col, colSelect, register = noRegister, icon, className, textColor = 'darkred', bgColor = 'background', error, autoFocus = false, onKeyUp = null, includePrefixValue = false, displayPrefixOption = true, prefixType = 'flag', setFormValue, unregister, dialogStyles = null }) => {
    const requireProps = typeof required === 'boolean' ? { required } : required;
    const { onChange: registerOnChange, onBlur: registerOnBlur, name: registerName, ref: registerRef } = register(name, requireProps);
    const { name: registerNameCode, ref: registerRefCode } = register('countryCode', { required: includePrefixValue ? includePrefixValue : false });
    const ref = (0, react_1.useRef)(null);
    const [triggerRef, dropdownRef, expanded, onTriggerFocus, toggleExpanded] = (0, dropdown_1.useDropdown)();
    const [fancyValue, setFancyValue] = (0, react_1.useState)(countries[1][prefixType] + ' ' + countries[1].value);
    const [currentOptions, setCurrentOptions] = (0, react_1.useState)(countries);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(countries[1][prefixType]);
    const [inputType, setInputType] = (0, react_1.useState)(type);
    const [inputValue, setInputValue] = (0, react_1.useState)('');
    const handleChange = e => {
        registerOnChange && registerOnChange(e);
        onChange && onChange(e);
        const trimedInputValue = e.target.value.toString().trim().replace(/ /g, '');
        const country = countries.find(el => trimedInputValue.includes(el.longCode) || trimedInputValue.includes(el.value));
        const startSlice = trimedInputValue.includes(country === null || country === void 0 ? void 0 : country.value) ? country === null || country === void 0 ? void 0 : country.value : country === null || country === void 0 ? void 0 : country.longCode;
        const strippedPhoneNumber = trimedInputValue.slice(startSlice === null || startSlice === void 0 ? void 0 : startSlice.length);
        setInputValue(strippedPhoneNumber);
        if (country) {
            setFancyValue(country[prefixType] + ' ' + country.value);
            setCurrentValue(country[prefixType]);
            setFormValue(name, strippedPhoneNumber.replace(/ /g, ''));
            setFormValue(nameCode, country.value);
        }
    };
    const onClickOption = (0, react_1.useCallback)((option) => {
        !includePrefixValue && unregister && unregister(nameCode);
        setFormValue(nameCode, option === null || option === void 0 ? void 0 : option.value);
        setCurrentValue(option === null || option === void 0 ? void 0 : option.value);
        setFancyValue((option === null || option === void 0 ? void 0 : option[prefixType]) + ' ' + (option === null || option === void 0 ? void 0 : option.value));
        toggleExpanded();
    }, [includePrefixValue, nameCode, prefixType, setFormValue, toggleExpanded, unregister]);
    const handleBlur = e => {
        var _a;
        registerOnBlur && registerOnBlur(e);
        setInputValue((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
    };
    const ButtonIcon = (0, react_1.useMemo)(() => getIcon(icon, type, setInputType), [icon, type]);
    (0, react_1.useEffect)(() => {
        if (error)
            console.log(`input ${name} error`, error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    (0, react_1.useEffect)(() => {
        if (autoFocus && ref.current)
            ref.current.focus();
    }, [autoFocus]);
    const Options = (0, react_1.useMemo)(() => getOptionsList(currentOptions, onClickOption, currentValue, prefixType), [currentOptions, currentValue, onClickOption, prefixType]);
    const filterBySearch = (0, react_1.useCallback)(({ value, valid }) => __awaiter(void 0, void 0, void 0, function* () {
        if (valid) {
            //filter by name of country, by code +44 or long code 0044
            const data = countries.filter(option => option.name.toLowerCase().includes(value.toLowerCase()) || option.value.toLowerCase().includes(value.toLowerCase()) || option.longCode.toLowerCase().includes(value.toLowerCase()));
            setCurrentOptions(data);
        }
        else {
            setCurrentOptions(countries);
        }
    }), []);
    const handleSearch = e => {
        var _a;
        const value = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        setFancyValue(value);
        if (typeof value === 'string') {
            const valid = value.length > 1;
            TIMEOUT_ID && clearTimeout(TIMEOUT_ID);
            TIMEOUT_ID = setTimeout(() => filterBySearch({ value, valid }), 300);
        }
    };
    const onBlurSearch = () => {
        var _a;
        const value = fancyValue;
        let matchOption = null;
        if (typeof value === 'string' && value.length > 2) {
            matchOption = (_a = countries.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))) === null || _a === void 0 ? void 0 : _a[0];
        }
        setFormValue(nameCode, matchOption ? matchOption.value : '');
        setCurrentValue(matchOption ? matchOption.value : '');
        setFancyValue(matchOption ? matchOption[prefixType] + ' ' + matchOption.value : '');
        setCurrentOptions(countries);
    };
    (0, react_1.useEffect)(() => {
        setCurrentOptions(countries);
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        let matchOption = null;
        if (defaultValue) {
            matchOption = (_a = countries.filter(option => option.value === defaultValue)) === null || _a === void 0 ? void 0 : _a[0];
            setFormValue(name, matchOption ? matchOption.value : '');
            setFormValue(nameCode, matchOption ? matchOption.value : '');
            setCurrentValue(matchOption ? matchOption.value : '');
            setFancyValue(matchOption ? matchOption.name : '');
        }
    }, [defaultValue, name, nameCode, setFormValue]);
    return ((0, jsx_runtime_1.jsxs)(DialogStyled, Object.assign({}, (dialogStyles && { $dialogStyles: dialogStyles }), { children: [displayPrefixOption &&
                (0, jsx_runtime_1.jsxs)(Div, Object.assign({ ref: triggerRef, "$col": colSelect, "$readOnly": readOnly }, { children: [(0, jsx_runtime_1.jsx)("input", Object.assign({ type: 'hidden', name: nameCode !== null && nameCode !== void 0 ? nameCode : registerNameCode, "aria-invalid": !!error, ref: (e) => {
                                ref.current = e;
                                includePrefixValue && registerRefCode && registerRefCode(e);
                            } }, defaultValue && { defaultValue }, (currentValue && { value: currentValue }))), (0, jsx_runtime_1.jsxs)(LabelSelect, Object.assign({ "$hasContent": !!fancyValue, "$placeholderVisible": placeholderVisible, "$textColor": textColor, "$bgColor": bgColor }, className && { className }, { children: [(0, jsx_runtime_1.jsx)("input", { type: 'text', name: `${nameCode}_searcher`, disabled: disabled, readOnly: readOnly, onChange: handleSearch, onFocus: onTriggerFocus, onBlur: onBlurSearch, value: fancyValue, autoComplete: autoComplete }), icon ? icon : (0, jsx_runtime_1.jsx)(media_1.Figure, { media: textColor === 'white' ? _1.arrowIconWhite : _1.arrowIcon, fill: false, lazy: true, className: 'chevron' })] })), !readOnly && (0, jsx_runtime_1.jsx)(Ul, Object.assign({ ref: dropdownRef, initial: 'collapsed', variants: _1.optionsListVariants, animate: expanded ? 'expanded' : 'collapsed', "$textColor": textColor, "$dropdownTextColor": textColor }, { children: Options }))] })), (0, jsx_runtime_1.jsxs)(Label, Object.assign({ "$hasContent": !!defaultValue || !!inputValue, "$hidden": inputType === 'hidden', "$type": type, "$col": col, "$buttonIcon": !!ButtonIcon, "$placeholderVisible": placeholderVisible, "$textColor": textColor, "$bgColor": bgColor, "$hasError": !!error }, className && { className }, { children: [(0, jsx_runtime_1.jsx)("input", Object.assign({ type: inputType, name: registerName !== null && registerName !== void 0 ? registerName : name, disabled: disabled, readOnly: readOnly, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, onChange: handleChange, onBlur: handleBlur, "aria-invalid": !!error, onKeyUp: onKeyUp }, typeof required === 'boolean' && { required }, { ref: (e) => {
                            ref.current = e;
                            registerRef && registerRef(e);
                        } }, autoComplete && { autoComplete }, defaultValue && { defaultValue }, (!defaultValue && !registerRef && inputValue && { value: inputValue }))), label && (0, jsx_runtime_1.jsx)("span", { children: label }), ButtonIcon && ButtonIcon, (error === null || error === void 0 ? void 0 : error.message) && (0, jsx_runtime_1.jsx)("span", Object.assign({ role: 'alert' }, { children: error.message }))] }))] })));
};
exports.InputPhone = InputPhone;
const Label = styled_components_1.default.label `
  display: flex;
  flex-direction: ${({ $type }) => $type === 'tel' ? 'row' : 'column'};
  margin-bottom: ${(0, styles_1.vw)(20, 'mobile')};
  padding: 0 ${(0, styles_1.vw)(4.25, 'mobile')};
  position: relative;
  width: 100%;

  ${styles_1.mq.greaterThan('nexus7')} {
    margin-bottom: ${(0, styles_1.vw)(20, 'nexus7')};
    padding: 0 ${(0, styles_1.vw)(4.25, 'nexus7')};
  }

  ${styles_1.mq.greaterThan('tablet')} {
    margin-bottom: ${(0, styles_1.vw)(30, 'desktop')};
    padding: 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  }

  ${styles_1.mq.greaterThan('desktop')} {
    margin-bottom: 30px;
    padding: 0 12.5px;
  }

  ${({ $hidden }) => $hidden && (0, styled_components_1.css) `
    opacity: 0;
    pointer-events: none;
    position: absolute;
    user-select: none;
    visibility: hidden;
  `}

  > input {
    ${(0, styles_1.getP18_3)()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor, $hasError }) => (0, styles_1.hex2Rgba)(theme.colors[$hasError ? 'input_error' : $textColor], $hasError ? 1 : .4)};
    color: inherit;
    height: ${(0, styles_1.vw)(60, 'mobile')};
    padding: ${(0, styles_1.vw)(25, 'mobile')} 0 ${(0, styles_1.vw)(12, 'mobile')};
    transition: 300ms border-color ease-out;
    width: 100%;

    ${styles_1.mq.greaterThan('nexus7')} {
      height: ${(0, styles_1.vw)(60, 'nexus7')};
      padding: ${(0, styles_1.vw)(25, 'nexus7')} 0 ${(0, styles_1.vw)(12, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      height: ${(0, styles_1.vw)(60, 'desktop')};
      padding: ${(0, styles_1.vw)(25, 'desktop')} 0 ${(0, styles_1.vw)(12, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      height: 60px;
      padding: 25px 0 12px;
    }

    &[readonly],
    &[disabled] {
      cursor: auto;
      pointer-events: none;
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }

    &[disabled] {
      opacity: .2;

      + span {
        opacity: .2;
      }
    }

    ${({ $buttonIcon }) => $buttonIcon && (0, styled_components_1.css) `
      padding-right: ${(0, styles_1.vw)(40, 'iphone6')};

      ${styles_1.mq.greaterThan('tablet')} {
        padding-right: ${(0, styles_1.vw)(40, 'desktop')};
      }

      > button {
        align-items: center;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        bottom: ${(0, styles_1.vw)(5, 'mobile')};
        display: flex;
        height: ${(0, styles_1.vw)(21, 'mobile')};
        position: absolute;
        right: ${(0, styles_1.vw)(4.25, 'mobile')};
        user-select: none;

        ${styles_1.mq.greaterThan('nexus7')} {
          bottom: ${(0, styles_1.vw)(5, 'nexus7')};
          height: ${(0, styles_1.vw)(21, 'nexus7')};
          right: ${(0, styles_1.vw)(4.25, 'nexus7')};
        }

        ${styles_1.mq.greaterThan('tablet')} {
          bottom: ${(0, styles_1.vw)(5, 'desktop')};
          height: ${(0, styles_1.vw)(21, 'desktop')};
          right: ${(0, styles_1.vw)(12.5, 'desktop')};
        }

        ${styles_1.mq.greaterThan('desktop')} {
          bottom: 5px;
          height: 21px;
          right: 12.5px;
        }
      }
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      -webkit-box-shadow: 0 0 0 500px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      box-shadow: 0 0 0 100px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      color: inherit;
    }

    ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
    `)}

    &:focus {
      border-color: ${({ theme, $textColor, $hasError }) => $hasError ? theme.colors['input_error'] : (0, styles_1.hex2Rgba)(theme.colors[$textColor], 1)};

      + span {
        ${hasContentState()}
      }
    }
  }

  > span {
    /* FLOATING LABEL */
    &:first-of-type {
      ${({ $placeholderVisible }) => $placeholderVisible ? hasContentState() : (0, styles_1.getP18InputLabel)()}
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      bottom: ${(0, styles_1.vw)(12, 'mobile')};
      color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      opacity: .4;
      position: absolute;
      transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
      white-space: nowrap;

      ${styles_1.mq.greaterThan('nexus7')} {
        bottom: ${(0, styles_1.vw)(12, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        bottom: ${(0, styles_1.vw)(12, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        bottom: 12px;
      }

      ${({ $hasContent }) => $hasContent ? (0, styled_components_1.css) `${hasContentState()}` : ''}
    }

    /* ERROR MESSAGE */
    &[role='alert'] {
      ${(0, styles_1.getP12_2)()}
      color: ${({ theme }) => theme.colors['input_error']};
      pointer-events: none;
      position: absolute;
      top: ${(0, styles_1.vw)(62, 'mobile')};

      ${styles_1.mq.greaterThan('nexus7')} {
        top: ${(0, styles_1.vw)(62, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        top: ${(0, styles_1.vw)(65, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        top: 65px;
      }
    }
  }

  figure {
    height: ${(0, styles_1.vw)(15, 'mobile')};
    position: absolute;
    right: ${(0, styles_1.vw)(17, 'mobile')};
    top: 40%;
    width: ${(0, styles_1.vw)(23, 'mobile')};

    img {
      height: 100%;
      width: 100%;
    }

    ${styles_1.mq.greaterThan('nexus7')} {
      height: ${(0, styles_1.vw)(15, 'nexus7')};
      right: ${(0, styles_1.vw)(17, 'nexus7')};
      width: ${(0, styles_1.vw)(23, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      height: ${(0, styles_1.vw)(15, 'desktop')};
      right: ${(0, styles_1.vw)(17, 'desktop')};
      width: ${(0, styles_1.vw)(23, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      height: 15px;
      right: 17px;
      width: 23px;
    }
  }

`;
const Div = styled_components_1.default.div `
  padding: 0 ${(0, styles_1.vw)(4.25, 'mobile')};
  position: relative;
  pointer-events: ${({ $readOnly }) => $readOnly ? 'none' : 'auto'};
  width: 100%;

  ${styles_1.mq.greaterThan('nexus7')} {
    padding: 0 ${(0, styles_1.vw)(4.5, 'nexus7')};
  }

  ${styles_1.mq.greaterThan('tablet')} {
    padding: 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  }

  ${styles_1.mq.greaterThan('desktop')} {
    padding: 0 12.5px;
  }

  ${({ $readOnly }) => {
    if ($readOnly)
        return (0, styled_components_1.css) `
    .chevron {
      opacity: 0;
    }
  `;
}}
`;
const Ul = (0, styled_components_1.default)(framer_motion_1.motion.ul) `
  background: white;
  margin-top: 1px;
  max-height: ${(0, styles_1.vw)(20 * 5.5 + 4.25 * 6, 'mobile')};
  overflow-y: auto;
  padding: ${(0, styles_1.vw)(8.5, 'mobile')} 0 ${(0, styles_1.vw)(12.5, 'mobile')};
  position: absolute;
  top: ${(0, styles_1.vw)(60, 'mobile')};
  width: calc(100% - ${(0, styles_1.vw)(8.5, 'mobile')});
  z-index: 5;

  ${styles_1.mq.greaterThan('nexus7')} {
    max-height: ${(0, styles_1.vw)(20 * 5.5 + 4.25 * 6, 'nexus7')};
    padding: ${(0, styles_1.vw)(8.5, 'nexus7')} 0 ${(0, styles_1.vw)(12.5, 'nexus7')};
    top: ${(0, styles_1.vw)(60, 'nexus7')};
    width: calc(100% - ${(0, styles_1.vw)(8.5, 'nexus7')});
  }

  ${styles_1.mq.greaterThan('tablet')} {
    max-height: ${(0, styles_1.vw)(32 * 5.5 + 4.25 * 6, 'desktop')};
    padding: ${(0, styles_1.vw)(8.5, 'desktop')} 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    top: ${(0, styles_1.vw)(60, 'desktop')};
    width: calc(100% - ${(0, styles_1.vw)(25, 'desktop')});
  }

  ${styles_1.mq.greaterThan('desktop')} {
    max-height: 208.5px;
    padding: 8.5px 0 12.5px;
    top: 60px;
    width: calc(100% - 25px);
  }

  li {
    margin-bottom: ${(0, styles_1.vw)(4.25, 'mobile')};

    ${styles_1.mq.greaterThan('nexus7')} {
      margin-bottom: ${(0, styles_1.vw)(4.25, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      margin-bottom: ${(0, styles_1.vw)(4.25, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      margin-bottom: 4.25px;
    }


  }

  button {
    ${(0, styles_1.getP18_3)()}
    align-items: center;
    color: ${({ theme, $textColor, $dropdownTextColor }) => $dropdownTextColor ? theme.colors[$dropdownTextColor] : theme.colors[$textColor]};
    display: flex;
    justify-content: space-between;
    opacity: .4;
    overflow-x: hidden;
    padding: ${(0, styles_1.vw)(2, 'mobile')} ${(0, styles_1.vw)(4.25, 'mobile')};
    text-overflow: ellipsis;
    transition: 300ms opacity ease-out;
    white-space: nowrap;
    width: 100%;

    ${styles_1.mq.greaterThan('nexus7')} {
      padding: ${(0, styles_1.vw)(2, 'nexus7')} ${(0, styles_1.vw)(4.25, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      padding: ${(0, styles_1.vw)(8, 'desktop')} ${(0, styles_1.vw)(12.5, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      padding: 8px 12.5px;
    }

    &:hover {
      opacity: 1;
    }

  }
`;
const LabelSelect = styled_components_1.default.label `
  display: flex;
  flex-direction: column;
  margin-bottom: ${(0, styles_1.vw)(20, 'mobile')};
  position: relative;
  width: 100%;

  ${styles_1.mq.greaterThan('nexus7')} {
    margin-bottom: ${(0, styles_1.vw)(20, 'nexus7')};
  }

  ${styles_1.mq.greaterThan('tablet')} {
    margin-bottom: ${(0, styles_1.vw)(30, 'desktop')};
  }

  ${styles_1.mq.greaterThan('desktop')} {
    margin-bottom: 30px;
  }

  > input {
    ${(0, styles_1.getP18_3)()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], .4)};
    color: ${({ theme, $textColor }) => $textColor ? theme.colors[$textColor] : theme.colors.darkred};
    height: ${(0, styles_1.vw)(60, 'mobile')};
    padding: ${(0, styles_1.vw)(25, 'mobile')} 0 ${(0, styles_1.vw)(12, 'mobile')};
    transition: 300ms border-color ease-out;
    width: 100%;

    ${styles_1.mq.greaterThan('nexus7')} {
      height: ${(0, styles_1.vw)(60, 'nexus7')};
      padding: ${(0, styles_1.vw)(25, 'nexus7')} 0 ${(0, styles_1.vw)(12, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      height: ${(0, styles_1.vw)(60, 'desktop')};
      padding: ${(0, styles_1.vw)(25, 'desktop')} 0 ${(0, styles_1.vw)(12, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      height: 60px;
      padding: 25px 0 12px;
    }

    ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
      opacity: ${$placeholderVisible ? .4 : 0};
    `)}

    ${({ $buttonIcon }) => $buttonIcon && (0, styled_components_1.css) `
      padding-right: ${(0, styles_1.vw)(40, 'mobile')};

      ${styles_1.mq.greaterThan('tablet')} {
        padding-right: ${(0, styles_1.vw)(40, 'desktop')};
      }

      > button {
        align-items: center;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        bottom: ${(0, styles_1.vw)(5, 'mobile')};
        display: flex;
        height: ${(0, styles_1.vw)(21, 'mobile')};
        position: absolute;
        right: ${(0, styles_1.vw)(4.25, 'mobile')};
        user-select: none;

        ${styles_1.mq.greaterThan('nexus7')} {
          bottom: ${(0, styles_1.vw)(5, 'nexus7')};
          height: ${(0, styles_1.vw)(21, 'nexus7')};
          right: ${(0, styles_1.vw)(12.5, 'nexus7')};
        }

        ${styles_1.mq.greaterThan('tablet')} {
          bottom: ${(0, styles_1.vw)(5, 'desktop')};
          height: ${(0, styles_1.vw)(21, 'desktop')};
          right: ${(0, styles_1.vw)(12.5, 'desktop')};
        }

        ${styles_1.mq.greaterThan('desktop')} {
          bottom: 5px;
          height: 21px;
          right: 12.5px;
        }
      }
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      -webkit-box-shadow: 0 0 0 500px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      box-shadow: 0 0 0 100px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      color: inherit;
    }

    ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
    `)}

    &:focus {
      border-color: ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], 1)};

      + span {
        ${hasContentState()}
      }
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }
  }

  figure {
    &.chevron {
      bottom: ${(0, styles_1.vw)(21, 'mobile')};
      height: ${(0, styles_1.vw)(8, 'mobile')};
      pointer-events: none;
      position: absolute;
      right: ${(0, styles_1.vw)(20, 'mobile')};

      ${styles_1.mq.lessThan('tablet')} {
        width: ${(0, styles_1.vw)(12, 'mobile')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        bottom: ${(0, styles_1.vw)(21, 'desktop')};
        height: ${(0, styles_1.vw)(8, 'desktop')};
        right: ${(0, styles_1.vw)(20, 'desktop')};
        width: ${(0, styles_1.vw)(12, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        bottom: 21px;
        height: 8px;
        right: 20px;
        width: 12px;
      }

      svg {
        height: 100%;
        object-fit: contain;
        transition: 300ms transform ease-out;
        width: 100%;
      }
    }
  }
`;
const DialogStyled = styled_components_1.default.div `
  display: flex;
  flex-direction: row;

  ${({ $dialogStyles }) => $dialogStyles && $dialogStyles}

`;
