import { memo, useMemo } from 'react'
import { motion } from 'framer-motion'
import { Button, Figure } from '@dy/commons/components'
import { useDeviceType } from '@dy/commons/hooks'
import { img_variants_single, img_variants_left, img_variants_right, img_variants_center, text_variants_title, text_variants_subtitle, text_variants_button } from './homeVariants'
import styled, { css }  from 'styled-components'
import { mq, vw, designGrid, getP141, getP113, getP93_1, getP53, getP26, getP30_1, getP20_2, getP15_1 } from '@dy/commons/styles'

interface IHeaderHome {
  data: any
}


const getImages = (images, main_type, isMobile) => images.map((img, idx) => {
  const _variants = main_type === 'media' ? img_variants_single : img?.position == 'left' ? img_variants_left : img?.position == 'right' ? img_variants_right : img_variants_center

  return (
    <motion.div key={idx} className={'homeheaderimage-' + (main_type === 'media' ? 'center' : img?.position)} variants={_variants} animate={'active'} initial='inactive' custom={isMobile}>
      <Figure lazy={false} media={img} />
    </motion.div>
  )
})

export const HeaderHome = memo<IHeaderHome>(({ data }) => {
  const { title, subtitle, link, leftImage, centerImage, rightImage, textPosition: position, textAlign: alignment, media, mainType, textValign, titleSize, subtitleSize } = data

  const images = useMemo(() => {
    if (mainType === 'product') {
      return [leftImage, centerImage, rightImage]
    } else {
      return [media]
    }
  }, [leftImage, centerImage, rightImage, media, mainType])

  const { isMobile } = useDeviceType()
  const ImageList = useMemo(() => getImages(images, mainType, isMobile), [images, mainType, isMobile])
  const filteredImages = images.filter(el => el != null)
  const singleImage = filteredImages?.length === 1

  return (
    <HomeHeader data-length={filteredImages.length} >
      {ImageList}
      <TextWrapper className={'home-header-pos-' + position} $title_size={titleSize} $subtitle_size={subtitleSize} $text_valign={textValign}>
        <motion.h2 custom={singleImage} variants={text_variants_title} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-title'}>{title}</motion.h2>
        <motion.p custom={singleImage} variants={text_variants_subtitle} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-subtitle'}>{subtitle}</motion.p>
        <a href={link?.path}>
          <Button custom={singleImage} variants={text_variants_button} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-button'}>
            {link?.title}
          </Button>
        </a>
      </TextWrapper>
    </HomeHeader>
  )
})


const HomeHeader = styled.header<any>`
 ${designGrid({withoutGutter:true})}
  place-items: center;
  grid: auto-flow / repeat(8, ${vw(64, 'mobile')});
  overflow-x: hidden;
  margin-top: 60px;

  div[class*='homeheaderimage'] > figure {
    user-select: none;
    pointer-events: none;
    filter: drop-shadow(8px 13px 1px rgb(78 57 14 / 7%));
    height: ${vw(447, 'mobile')};

    ${mq.greaterThan('tablet')} {
      height: 100%;
    }
  }

  &[data-length='1'] {
    /* margin-bottom: ${vw(90, 'mobile')}; */
    margin-top: 60px;
    color: #FFF;

    div[class*='homeheaderimage'] {
      grid-area: 1 / 2 / span 1 / span 4;
      transform: none;
      width: 100%;
      z-index: -1;

      &.figure--video {
        grid-area: 1 / 2 / span 1 / span 6;
        filter: none;
        z-index: -1;
        height: ${vw(447, 'mobile')};
        overflow: hidden;

        & + [class*='home-header-pos'] {
          grid-area: 1 / 3 / span 2 / span 4;
          margin-top: 0;
        }
      }

      &.homeheaderimage-center {
        grid-area: 1 / 2 / span 1 / span 6;
        z-index: -1;
        height: ${vw(447, 'mobile')};

        > figure {
          filter: none;
        }

        img {
          object-fit: cover;
          height: 100%;
        }

        & + [class*='home-header-pos'] {
          grid-area: 1 / 3 / span 2 / span 4;
          margin-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  &[data-length='2'] {
    div[class*='homeheaderimage']:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(2) { grid-area: 1 / 5 / span 1 / span 4; }
  }

  &[data-length='3'] {
    div[class*='homeheaderimage']:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(2) { grid-area: 1 / 3 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(3) { grid-area: 1 / 5 / span 1 / span 4; }
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(750, 'desktop')};
    // margin-bottom: ${vw(50, 'desktop')};
    margin-top: ${vw(120, 'desktop')};
    overflow-x: unset;

    div[class*='homeheaderimage'] {
      width: ${vw(720, 'desktop')};
      height: ${vw(750, 'desktop')};
    }

    &[data-length] div[class*='homeheaderimage'] {
      > figure {
        filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
      }

      &.homeheaderimage-left {
        grid-area: 1 / 1 / span 1 / span 6;
        margin: 0;
      }
      &.homeheaderimage-center { grid-area: 1 / 4 / span 1 / span 6; }
      &.homeheaderimage-right { grid-area: 1 / 7 / span 1 / span 6; }
    }

    &[data-length='1'] {
      margin-top: ${vw(120, 'desktop')};
      // margin-bottom: ${vw(50, 'desktop')};

      div[class*='homeheaderimage'] {
        grid-area: 1 / 1 / span 3 / span 12;

        &.homeheaderimage-center {
          grid-area: 1 / 1 / span 1 / span 12;
          padding: 0 ${vw(50, 'desktop')};
          transform: none;
          width: 100%;
          height: inherit;

          & + [class*='home-header-pos'] {
            &.home-header-pos-left { grid-area: 1 / 2 / span 1 / span 5; }
            &.home-header-pos-center { grid-area: 1 / 3 / span 1 / span 8; }
            &.home-header-pos-right { grid-area: 1 / 7 / span 1 / span 5; }
          }
        }
      }

      figure {
        &.figure--video {
          grid-area: 1 / 1 / span 3 / span 12;
          height: ${vw(715, 'desktop')};

          & + [class*='home-header-pos'] {
            &.home-header-pos-left { grid-area: 1 / 2 / span 3 / span 5; }
            &.home-header-pos-center { grid-area: 1 / 3 / span 3 / span 8; }
            &.home-header-pos-right { grid-area: 1 / 7 / span 3 / span 5; }
          }
        }
      }
    }
  }

  ${mq.greaterThan('desktop')} {
    height: 750px;
    margin-top: 120px;

    div[class*='homeheaderimage'] {
      width: 720px;
      height: 750px;
    }

    &[data-length='1'] {
      margin-top: 120px;
      // margin-bottom: 50px;

      div[class*='homeheaderimage'] {
        &.homeheaderimage-center {
          padding: 0 50px;
        }
      }

      figure {
        &.figure--video {
          height: 715px;
        }
      }
    }
  }

  ${mq.greaterThan('desktop-wide')} {
    margin: 0 auto;
    max-width: 1650px;
  }
`


const TextWrapper = styled.div<any>`
  grid-area: 2 / 3 / span 1 / span 4;
  margin-top: -${vw(40, 'mobile')};
  padding-bottom: ${vw(80, 'mobile')}; // fix for animations
  width: 100%;

  ${({ $text_valign }) => $text_valign === 'top' ? css`padding-top: ${vw(20, 'mobile')}; align-self: start;` : $text_valign === 'bottom' ? css`padding-bottom: ${vw(30, 'mobile')}; align-self: end;` : css`align-self: center;` };

  ${mq.greaterThan('nexus7')} {
    margin-top: -${vw(40, 'nexus7')};
    padding-bottom: ${vw(80, 'nexus7')}; // fix for animations
    ${({ $text_valign }) => $text_valign === 'top' ? css`padding-top: ${vw(20, 'nexus7')}; align-self: start;` : $text_valign === 'bottom' ? css`padding-bottom: ${vw(30, 'nexus7')}; align-self: end;` : css`align-self: center;`}
  }

  ${mq.greaterThan('tablet')} {
    grid-area: 1 / 5 / span 1 / span 4;
    margin-top: 0;
    padding-bottom: 0;
    ${({ $text_valign }) => $text_valign === 'top' ? css`padding-top: ${vw(60, 'desktop')}; align-self: start;` : $text_valign === 'bottom' ? css`padding-bottom: ${vw(70, 'desktop')}; align-self: end;` : css`align-self: center;` };
  }

  ${mq.greaterThan('desktop')} {
    ${({ $text_valign }) => $text_valign === 'top' ? css`padding-top: 60px ; align-self: start;` : $text_valign === 'bottom' ? css`padding-bottom: 70px; align-self: end;` : css`align-self: center;` };
  }

  h2 {
    ${({ $title_size }) => $title_size === 'xl' ? getP141() : $title_size === 'l' ? getP113() : $title_size === 'm' ? getP93_1() : getP53() };
  }

  p {
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    ${({ $subtitle_size }) => $subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : $subtitle_size === 'm' ? css`${getP30_1()} font-weight: 100;` : getP15_1() };
    margin: ${vw(15, 'mobile')} 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')} {
      ${({ $subtitle_size }) => $subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : $subtitle_size === 'm' ? css`${getP26()} font-weight: 100;` : getP20_2() };

      &.home-header-text-subtitle {
        ${({ $subtitle_size }) => $subtitle_size === 'm' ? css`letter-spacing: -1.5px;` : ''};
      }
    }
  }

  .home-header-align-left,
  .home-header-align-center,
  .home-header-align-right {
    text-align: center;
  }

  .home-header-align-left {
    ${mq.greaterThan('tablet')} {
      text-align: left;
    }
  }

  .home-header-align-right {
    ${mq.greaterThan('tablet')} {
      text-align: right;
    }
  }

  ${mq.greaterThan('tablet')} {
    &.home-header-pos-left {
      grid-area: 1 / 2 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    &.home-header-pos-center {
      grid-area: 1 / 3 / span 1 / span 8;

      p {
        &.home-header-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12 * 4) ${vw(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 6) ${vw(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12 * 4);
        }
      }
    }

    &.home-header-pos-right {
      grid-area: 1 / 7 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    button.home-header-align-left { margin: 0 auto 0 0; }
    button.home-header-align-right { margin: 0 0 0 auto; }
  }

  ${mq.greaterThan('desktop')} {
    &.home-header-pos-left {
      p {
        &.home-header-align-left {
          margin:15px calc(100vw / 12) 30px 0;
        }
        &.home-header-align-center {
          margin:15px calc(100vw / 24) 30px;
        }
        &.home-header-align-right {
          margin:15px 0 30px calc(100vw / 12);
        }
      }
    }

    &.home-header-pos-center {
      p {
        &.home-header-align-left {
          margin: 15px calc(100vw / 12 * 4) 30px 0;
        }
        &.home-header-align-center {
          margin: 15px calc(100vw / 6) 30px;
        }
        &.home-header-align-right {
          margin: 15px 0 30px calc(100vw / 12 * 4);
        }
      }
    }

    &.home-header-pos-right {
      grid-area: 1 / 7 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: 15px calc(100vw / 12) 30px 0;
        }
        &.home-header-align-center {
          margin: 15px calc(100vw / 24) 30px;
        }
        &.home-header-align-right {
          margin: 15px 0 30px calc(100vw / 12);
        }
      }
    }
  }
`