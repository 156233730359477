"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reset = exports.theme = void 0;
const _1 = require("./");
exports.theme = (0, _1.overwriteDefaultTheme)(_1.themeProject);
const extraGlobalCss = `
  html {
    overflow-x: hidden;
  }

  body {
    color: ${_1.themeProject.colors.text};
    font-family: ${_1.themeProject.fonts.primaryFont};

    &.slider-being-dragged {
      .slider--flickity {
        button,
        a {
          pointer-events: none !important;
        }
      }
    }
  }

  button {
    outline: 0;
    padding: 0;
    background-color: transparent;

    &:active,
    &:focus {
      outline: 0;
    }
  }

  nav {
    + main {
      flex-grow: 2;
    }
  }

  a {
    color: unset;
    text-decoration: none;
  }

  input {
    background: transparent;
    margin-bottom: 0;
  }

  input::-webkit-search-cancel-button {
    display: none;
  }

  html, body {
    font-size: ${(0, _1.vw)(15, 'mobile')};

    @media (min-width: 603px) {
      font-size:  ${(0, _1.vw)(15, 'nexus7')};
    }

    @media (min-width: 768px) {
      font-size: ${(0, _1.vw)(18, 'desktop')};
    }

    @media (min-width: 1440px) {
      font-size: 18px;
    }
  }

  figure {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  .modal-as-tooltip {
    justify-content: flex-end;

    .overlay {
      opacity: .2;
    }
  }

  .slider--flickify {
    outline: none;
  }

  .flickify-viewport {
    overflow: hidden;
    position: relative;
  }


  #at-cv-lightbox-message > span {
    color: ${_1.themeProject.colors.text};
    font-family: ${_1.themeProject.fonts.platform};
    text-align: center;
  }

  .at-branding-logo {
    display: none !important;
  }

  .cookiefirst-root > div {
    margin-left: auto !important;
    max-width: 890px !important;
  }

  .fade .flickity-viewport,
  .fade .flickity-slider {
    transform: none !important;
  }

  .fade .flickity-cell {
    opacity: 0 !important;
    transform: none !important;
    transition: 200ms opacity ease-in-out !important;
    z-index: auto;
  }

  .fade .flickity-cell.is-selected {
    opacity: 1 !important;
    transform: none !important;
    transition: 200ms opacity ease-in-out !important;
    z-index: 2;
  }

  .flickity-prev-next-button {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    transition: 250ms border-color linear;
  }

  .flickity-prev-next-button:hover {
    border-color: ${_1.themeProject.colors.darkred};
  }

  .flickity-prev-next-button > svg.flickity-button-icon {
    height: 50%;
    min-height: 20px;
    min-width: 20px;
    left: auto;
    position: relative;
    top: auto;
    width: 50%;
  }
`;
exports.Reset = (0, _1.withGlobalStyle)(exports.theme, extraGlobalCss);
