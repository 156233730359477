import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { Link } from '@dy/commons/components'
import { NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'
import { mq, vw } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'

type SearchByCategoryProps = {
  data:any,
  close : () => void
}

const LANG = NEXT_PUBLIC_LOCALE.split('-')[0]

const getListItems = (data, close) => data.map((item, idx) => (
  <Dd key={`${idx}-result`} onClick={close}>
    <Link href={`/catalog/${item?.slug[LANG]}`}>
      {item.name}
    </Link>
  </Dd>
))

export const SearchByCategory: FC<SearchByCategoryProps> = ({data, close}) => {
  const ListItems = useMemo(() => getListItems(data, close), [data, close])
  const { t } = useTranslator()

  return (
    <Dl>
      <dt>{t('layout.search.search_by_category')}</dt>
      {ListItems}
    </Dl>
  )
}

const Dl = styled.dl`
  display: none;
  grid-column: 1 / span 6;
  margin-bottom: 0;
  max-height: 100%;
  padding: ${vw(20, 'mobile')} 0;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(20, 'nexus7')} 0;
  }

  ${mq.greaterThan('tablet')} {
    align-items: flex-start;
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    grid-column: 7 / span 5;
    grid-row: 2 / span 1;
    justify-content: center;
    overflow: auto;
    padding: ${vw(50, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 50px 0;
  }

  dt {
    color: ${({ theme }) => theme.colors.darkred};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    font-weight: 300;
    margin-bottom: ${vw(60, 'mobile')};
    text-align: center;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(60, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 60px;
    }
  }
`

const Dd = styled.dd`
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(20, 'nexus7')};
  }

${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 20px;
  }

  a {
    align-items: center;
    color: ${({ theme }) => theme.colors.red};
    display: flex;
    font-weight: bold;
    height: ${vw(40, 'mobile')};
    padding: 0 ${vw(25, 'mobile')};
    text-decoration: none;
    width: auto;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(40, 'nexus7')};
      padding: 0 ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(40, 'desktop')};
      padding: 0 ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 40px;
      padding: 0 25px;
    }
  }
`
