"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlider = exports.SLIDE_TIME = void 0;
const react_1 = require("react");
const hooks_1 = require("../hooks");
exports.SLIDE_TIME = 5000;
const useSlider = (maxSize, always = false) => {
    const [currentSlide, setSlide] = (0, react_1.useState)(0);
    const TIMEOUT_ID = (0, react_1.useRef)(null);
    const { ref, inView } = (0, hooks_1.useInView)({
        rootMargin: '0px',
        threshold: 0,
    });
    const nSlides = (0, react_1.useMemo)(() => maxSize, [maxSize]);
    const tick = (0, react_1.useCallback)((current) => {
        TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
        const newSlideIndex = current + 1;
        const nextSlide = newSlideIndex < 0 ? nSlides - 1 : newSlideIndex % nSlides;
        TIMEOUT_ID.current = setTimeout(() => tick(nextSlide), exports.SLIDE_TIME);
        setSlide(nextSlide);
    }, [nSlides]);
    const startAnimation = (0, react_1.useCallback)(() => {
        tick(currentSlide);
    }, [currentSlide, tick]);
    const nextSlide = (0, react_1.useCallback)(() => {
        tick(currentSlide);
    }, [currentSlide, tick]);
    const prevSlide = (0, react_1.useCallback)(() => {
        tick(currentSlide - 2);
    }, [currentSlide, tick]);
    (0, react_1.useEffect)(() => {
        if (inView || always) {
            TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
            TIMEOUT_ID.current = setTimeout(() => startAnimation(), exports.SLIDE_TIME);
        }
        else {
            TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
            TIMEOUT_ID.current = null;
        }
        return () => {
            TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, startAnimation]);
    return { currentSlide, triggerRef: ref, nextSlide, prevSlide, setSlide, startAnimation };
};
exports.useSlider = useSlider;
