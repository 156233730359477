"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToTop = exports.removeIndexFromArray = void 0;
const removeIndexFromArray = (array, idx) => {
    const index = array.indexOf(idx);
    if (index > -1)
        array.splice(index, 1);
};
exports.removeIndexFromArray = removeIndexFromArray;
const scrollToTop = () => {
    window === null || window === void 0 ? void 0 : window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};
exports.scrollToTop = scrollToTop;
