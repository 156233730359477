import { memo, useEffect } from 'react'
import type { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { hex2Rgba } from '@dy/commons/styles'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'

export const LoadingScreen: FC<{ isActive: boolean }> = memo(({ isActive }) => {
  const { someModalIsActive } = useModal(MODALS.MENU)
  useEffect(() => {
    document.body.style.overflow = (isActive || someModalIsActive) ? 'hidden' : 'initial'
  }, [isActive, someModalIsActive])

  return (<Div $isActive={isActive}><div className='loader'></div></Div>)
})

const Div = styled<any>(motion.div)`
  align-items: center;
  background-color: ${({ theme }) => hex2Rgba(theme.colors.darkred, .95)};
  bottom: 0;
  color: white;
  display: flex;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  left: 0;
  opacity: ${({ $isActive }) => $isActive ? 1 : 0};
  pointer-events: ${({ $isActive }) => $isActive ? 'auto' : 'none'};
  position: fixed;
  right: 0;
  top: 0;
  transition: 150ms all ease-out;
  user-select: ${({ $isActive }) => $isActive ? 'auto' : 'none'};
  visibility: ${({ $isActive }) => $isActive ? 'visible' : 'hidden'};
  width: 100vw;
  width: 100dvw;
  z-index: ${({ $isActive }) => $isActive ? 9999 : -1};

  .loader,
  .loader:before,
  .loader:after {
    background: ${({ theme }) => theme.colors.creme};
    animation: load1 1s infinite ease-in-out;
    width: .3em;
    height: 20em;
  }

  .loader {
    animation-delay: -.16s;
    color: ${({ theme }) => theme.colors.creme};
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    pointer-events: none;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
    }

    &:before {
      animation-delay: -.32s;
      left: -1.5em;
    }

    &:after {
      left: 1.5em;
    }
  }

  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 20em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 17em;
    }
  }
`
