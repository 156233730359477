import { memo } from 'react'
import styled from 'styled-components'
import { Button } from '@dy/commons/components'
import { useTranslator } from 'hooks/useTranslator'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { mq, vw, hex2Rgba, getP53, getP30_1, getP15_1, designGrid } from '@dy/commons/styles'


export const Subscribe = memo(()=>{
  const { t } = useTranslator()
  const { toggle } = useModal(MODALS.AUTH)

  return(
    <Div>
      <div>
        <h1>{t('section_subscribe.welcome')}</h1>
        <h2>{t('section_subscribe.register')}</h2>
        <ul>
          <li>{t('section_subscribe.open')}</li>
          <li>{t('section_subscribe.order')}</li>
          <li>{t('section_subscribe.offer')}</li>
          <li>{t('section_subscribe.save_products')}</li>
          <li>{t('section_subscribe.check_invoices')}</li>
          <li>{t('section_subscribe.contact_commercial')}</li>
        </ul>
        <Button onClick={toggle} bgType='red' className='red'> {t('section_subscribe.register_button')} </Button>
      </div>
    </Div>
  )
})


const Div = styled.div`
  ${designGrid({ withoutGutter: true })}
  grid: auto-flow / repeat(8, ${vw(64, 'mobile')});
  margin-bottom:unset;

  ${mq.greaterThan('tablet')} {
    margin-bottom:${vw(100, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 100px;
  }

  ${mq.greaterThan('desktop-wide')} {
    margin: 0 auto 100px;
    max-width: 1650px;
  }

  > div {
    align-items: center;
    background-color: ${({ theme }) => hex2Rgba(theme.colors.creme, .4)};
    display: flex;
    flex-direction: column;
    height: ${vw(629, 'mobile')};
    grid-area: 1 / 2 / span 1 / span 6;
    margin: 0  0 ${vw(20, 'mobile')};
    padding: ${vw(60, 'mobile')}  ${vw(14, 'mobile')} ;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(687, 'nexus7')};
      margin: 0  0 ${vw(20, 'nexus7')};
      padding: ${vw(60, 'nexus7')}  ${vw(14, 'nexus7')} ;
    }

    ${ mq.greaterThan('tablet') } {
      grid-column: 1 / span 12;
      height:  ${vw(687, 'desktop')};
      margin: 0 ${vw(50, 'desktop')};
      padding: ${vw(80, 'desktop')} ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 687px;
      margin: 0 50px;
      padding: 80px 50px;
    }

    >:first-child {
      ${getP53()}
      color: ${({ theme }) => theme.colors.red};
    }

    >:nth-child(2) {
      ${getP30_1()}
      color: ${({ theme }) => theme.colors.red};
      font-weight: 400;
    }

    > :last-child {
      margin: ${vw(50, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin: ${vw(40, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin: 60px;
      }
    }
  }

  h1,h2 {
    padding: 20px;
  }

  ul {
    text-align: center;

    ${mq.greaterThan('tablet')} {
      display: grid;
      grid-template-columns: ${vw(315, 'desktop')} auto;
      text-align: start;
    }

    ${mq.greaterThan('desktop-wide')} {
      grid-template-columns: 400px auto;
    }

    li {
      ${getP15_1()}
      color: ${({ theme }) => theme.colors.red};
      font-weight:500;
      padding: ${vw(14, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(14, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(14, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 14px;
      }

      &:before {
        color: ${({ theme }) => theme.colors.creme};
        content: "✓";
        display: inline-block;
        font-weight: 700;
        margin-left: -1.3em;
        width: 1.3em;
      }
    }
  }
`
