import { memo, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { mq, vw, getP18_1, getP18_4, createUnderline, getP15 } from '@dy/commons/styles'
import { Button, Select } from '@dy/commons/components'
import { getStoreUrlByLocale, PRO, cookie, removeIndexFromArray } from '@dy/commons/utils'
import { NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'
import { useTranslator } from '@/hooks'

interface IStorePicker {
  readonly bgInputColor?: TypeThemeColors,
  readonly textColorInput?: TypeThemeColors,
  readonly colorWrapper?: TypeThemeColors,
  readonly textColorSubdividions?: TypeThemeColors,
  readonly isModal?: boolean,
  readonly toggle?: () => void
  readonly dropdownTextColor?: TypeThemeColors,
}

type TypeFormStoresZoneInputs = {
  country: string
  subdivision?: string,
}

export const getCommercials = (data, textColorSubdividions, isModal, t) => data.map((commercial, idx) => {
  const { client } = commercial
  const shouldDisplayClient = (NEXT_PUBLIC_LOCALE !== 'en-GB' && NEXT_PUBLIC_LOCALE !== 'fr-FR' && NEXT_PUBLIC_LOCALE !== 'de-DE' && NEXT_PUBLIC_LOCALE !== 'pt-PT') 
  const shouldDisplayLinkB2c = (NEXT_PUBLIC_LOCALE !== 'de-DE' && NEXT_PUBLIC_LOCALE !== 'pt-PT') 
  // const { client, area, address, contact: { name, tel, email }, operate_in } = commercial

  return (
    <ListItem key={`commercial-${idx}`} $textColorSubdividions={textColorSubdividions} $isModal={isModal}>
      {/* {operate_in?.toString() === 'ES-IB' &&  <span>{area}</span>} */}
      {shouldDisplayClient && <span>{client}</span>}
      <span>{t('modals.comercial_region.write_us')} <a href={t('modals.comercial_region.mail_to_link')}>{t('modals.comercial_region.mail_to')}</a> {t('modals.comercial_region.more_info')}</span>
      {/* {address && <span>{address}</span>} */}
      {/* {name && <span>{name}</span>} */}
      {/* {tel?.value && <a href={`tel:${tel.value}`}>{tel.label}</a>} */}
      {/* <a href={`mailto:${email}`}>{email}</a> */}
      {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
      {shouldDisplayLinkB2c && <span>{t('modals.comercial_region.b2c')} <a href={t('modals.comercial_region.link_to_b2c')}target='_blank'>{t('modals.comercial_region.link_b2c')}</a></span>}
    </ListItem>
  )
})

export const defaultCommercial = [{
  client: 'MAMMAFIORE DISTRIBUTION',
  contact: {
    email: 'comercial@mammafiore.eu'
  }
}]

let displayComercial = false
let buttonDisabled = true

const getStore = (regionCode, subdivisionCode, subdivisions) => {
  if(regionCode?.length > 0) buttonDisabled = false

  let store = null
  if(regionCode === 'ES') {
    buttonDisabled = true
    if (!subdivisionCode) return store
    const subdivisionData = subdivisions.filter(o => o.value === subdivisionCode)[0]
    const val = subdivisionData.value
    const locale = `${val.split('-')[0].toLowerCase()}-${val.slice(-2)}`
    if (!subdivisionData?.store) displayComercial = true
    store = { url: getStoreUrlByLocale(locale, PRO), locale }
    buttonDisabled = false
  } else if (regionCode === 'GB') {
    store = { url: getStoreUrlByLocale('en-GB', PRO),  locale: 'en-GB', query: { zone: 'GB' }}
  } else if (regionCode === 'DE') {
    store = { url: getStoreUrlByLocale('de-DE', PRO),  locale: 'de-DE', query: { zone: 'DE' }}
  } else if (regionCode === 'FR') {
    store = { url: getStoreUrlByLocale('fr-FR', PRO), locale: 'fr-FR', query: { zone: 'FR' }}
  } else if (regionCode === 'PT') {
    store = { url: getStoreUrlByLocale('pt-PT', PRO), locale: 'pt-PT', query: { zone: 'PT' }}
  }

  return store
}

type TypeRegionItem = {
  value: string
  name: string,
}

export type TypeRegionJson = TypeRegionItem[]

export async function getCountries() {
  // @ts-ignore
  let data = await import('/public/locales/countries/ALL-countries.json')
  return data
}

export async function getCommercialsByCountry(country = 'ES') {
  // @ts-ignore
  if(country !== 'ES') return []
  let data = await import(`/public/locales/countries/${country}-commercials.json`)
  return data
}

export async function getSubdivisions(countryCode) {
  const data:TypeRegionJson = await import(`/public/locales/countries/${countryCode}-subdivisions.json`, { assert: { type: 'json' } })
  const dataArr = Array.from(data)
  const subdivisionWithData = []

  if(countryCode === 'ES') {
    const dataStores:TypeRegionJson = await import(`/public/locales/countries/${countryCode}-subdivisions-with-stores.json`, { assert: { type: 'json' } })
    const subdivisionsWithStores = Array.from(dataStores)

    for(let item of subdivisionsWithStores) {
      // @ts-ignore
      const idx = dataArr.find(o => o.value === item.value) as number

      if(idx) {
        subdivisionWithData.push({ ...dataArr[idx] as object, ...item as object })
        removeIndexFromArray(dataArr, idx)
      }
    }
  }

  return subdivisionWithData.concat(dataArr)
}

let prevCountry = null
export const FormStore = memo<IStorePicker>(({ bgInputColor = 'red', textColorInput = 'darkred', colorWrapper, textColorSubdividions = 'white', isModal = false, toggle, dropdownTextColor = null }) => {
  const { t } = useTranslator()
  const { locale } = useRouter()
  // const { push } = useRouter()
  const { register, handleSubmit, setValue, watch } = useForm<TypeFormStoresZoneInputs>({ mode: 'onChange' })
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const selectedCountry = watch('country')
  const selectedSubdivision = watch('subdivision')
  const [countries, setCountries] = useState([])
  const [subdivisions, setSubdivisions] = useState([])
  const [commercials, setCommercials] = useState([])

  const Comercials = useMemo(() => getCommercials(commercials.length > 0 ? commercials : defaultCommercial, textColorSubdividions, isModal, t), [commercials, textColorSubdividions, isModal, t])
  const storeData = useMemo(() => getStore(selectedCountry, selectedSubdivision, subdivisions), [selectedCountry, selectedSubdivision, subdivisions])
  const cookieCatalogRegion = cookie.get('DY_CATALOG_REGION')

  const onClickStore = (storeData) => {
    const value = storeData.locale?.toUpperCase()
    cookie.removeCookie('DY_CATALOG_REGION')
    cookie.set({ name: 'DY_CATALOG_REGION', value, days: 365 })
    if (storeData.locale !== locale) window.location.href = `https://${storeData.url}`
  }

  const onClickCatalogue = (subdivisionValue, storeData) => {
    const value = subdivisionValue?.toUpperCase()
    cookie.removeCookie('DY_CATALOG_REGION')
    cookie.set({ name: 'DY_CATALOG_REGION', value, days: 365 })
    console.log('value', value)
    console.log('storeData', storeData)
    if (subdivisionValue !== locale) window.location.href = `https://${storeData?.url ?? 'mammafiore.es'}`
  }

  const onSubmit: SubmitHandler<TypeFormStoresZoneInputs> = async (formData, _e) => {
    console.log('FormStore submit data:', formData)
    toggle && toggle()
  }

  const onError = (errors, e) => { console.log(errors, e) }

  useEffect(() => {
    if(countries?.length === 0) {
      getCountries().then((res)=>{
        setCountries(Array.from(res))
      })
    }
  }, [countries])

  useEffect(() => {
    if(prevCountry !== selectedCountry || !selectedSubdivision) {
      if (selectedCountry === 'ES') {
        prevCountry = selectedCountry

        getSubdivisions(selectedCountry).then((res)=> {
          const data = Array.from(res).filter(item => !item.hidden).map(item => {
            const value = ['es-CT', 'es-MD', 'es-CN'].includes[locale] ? locale?.toUpperCase() : selectedSubdivision
            if (item.value === value) return {...item, selected: true }
            return item
          })
          const allSpainSelected = cookieCatalogRegion === 'ES' && locale === 'es-ES'
          data.push({ name: 'Resto de España', value: 'ES', selected: allSpainSelected })

          setSubdivisions(data)
        })
      } else {
        setSubdivisions([])
      }
    }
    // if ((selectedCountry !== '' || !selectedCountry) && (selectedSubdivision !== '' || !selectedSubdivision)) {
    //   return () => subscription.unsubscribe()
    // }
  }, [selectedCountry, selectedSubdivision, locale, cookieCatalogRegion])

  useEffect(() => {
    if(selectedCountry === 'ES') {
      getCommercialsByCountry(selectedCountry).then((res) => {
        const arrOfCommercials = Array.from(res)
        if (selectedSubdivision) {
          // @ts-ignore
          const commercialsFiltered = arrOfCommercials.filter(item => item.operate_in.find(area => area === selectedSubdivision))
          setCommercials(commercialsFiltered)
        }
      })
    }
  }, [selectedCountry, selectedSubdivision])

  return (
    <>
      <Wrapper $selectedCountry={selectedCountry} $colorWrapper={colorWrapper} className='wrapper_store_picker' $isModal={isModal}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Select name='country' placeholder={t('form.fields.country') as string} placeholderVisible options={countries} register={register} setFormValue={setValue} textColor={textColorInput} bgColor={bgInputColor} dropdownTextColor={dropdownTextColor} defaultValue={NEXT_PUBLIC_LOCALE.split('-')[0]?.toUpperCase() === 'EN' ? 'GB' : NEXT_PUBLIC_LOCALE.split('-')[0]?.toUpperCase() }/>
          <Select name='subdivision' placeholder={'Región'} placeholderVisible options={subdivisions} register={register} setFormValue={setValue} textColor={textColorInput} bgColor={bgInputColor} dropdownTextColor={dropdownTextColor} defaultValue={NEXT_PUBLIC_LOCALE === 'es-ES' ? cookieCatalogRegion : NEXT_PUBLIC_LOCALE.toUpperCase()} />
          {displayComercial && subdivisions.length > 0?
            <Div $active={selectedSubdivision} $textColorSubdividions={textColorSubdividions} $isModal={isModal} className='subdivisions'>
              <dl>
                <dt>{t('commons.region_picker.in')} {subdivisions.filter(o => o.value === selectedSubdivision)[0]?.name} {t('commons.region_picker.work_with')}</dt>
                {Comercials}
              </dl>
              <Button type='submit' onClick={() => onClickCatalogue(subdivisions.filter(o => o.value === selectedSubdivision)[0]?.value, storeData)} bgType={isModal? 'red' : 'creme'} className='creme'> {t('commons.region_picker.go_catalogue')} </Button>
            </Div>
            :
            <Button type='submit' onClick={() => { onClickStore(storeData) }} bgType={isModal? 'red' : 'creme'}className={(buttonDisabled && !isModal ) ? 'disabled' : ''}>{t('commons.actions.enter')}!</Button>
          }
        </form>
      </Wrapper>
    </>
  )
})


const Wrapper = styled.div<any>`
  color: ${({ theme, $colorWrapper }) => $colorWrapper ? theme.colors[$colorWrapper] : theme.colors.white};
  margin: 0 auto;
  max-width: ${vw(480, 'mobile')};
  padding:  0;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    max-width: ${vw(480, 'nexus7')};
    padding:  0;
  }

  ${mq.greaterThan('tablet')} {
    max-width: ${vw(480, 'desktop')};
    padding: 0;
  }

  form {
    > div {
      margin: 0 auto;

      &:first-child {
        z-index: 5;
      }

      &:last-of-type {
        opacity: ${({ $selectedCountry }) => $selectedCountry === 'ES' ? 1 : 0};
        pointer-events: ${({ $selectedCountry }) => $selectedCountry === 'ES' ? 'auto' : 'none'};
        user-select: ${({ $selectedCountry }) => $selectedCountry === 'ES' ? 'auto' : 'none'};
      }
    }
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
  }
  button[type='submit'] {
    width: ${({ $isModal }) => $isModal ? '100%' : 'auto'};
    margin-top: ${({ $isModal }) => $isModal ? vw(50, 'desktop') : 'unset'};
  }
`

const Div = styled.div<any>`
  opacity: ${({ $active }) => $active ? 1 : 0};
  padding: ${vw(30, 'mobile')} ${vw(4.25, 'mobile')};
  text-align: center;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(30, 'nexus7')} ${vw(4.25, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(20, 'desktop')} ${vw(12.5, 'desktop')};
  }
  ${mq.greaterThan('desktop')} {
    padding: 20px 12.5px;
  }

  dl {
    margin: 0 0 ${vw(25, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin: 0 0 ${vw(25, 'desktop')};
    }
  }

  dt {
    ${getP18_1()}
    color: ${({ theme, $textColorSubdividions }) => theme.colors[$textColorSubdividions ?? 'white']};
    padding-bottom: ${vw(10, 'mobile')};
    text-align:  ${({ $isModal }) => $isModal ? 'left' : 'center'};
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(10, 'nexus7')};
      margin-bottom: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(10, 'desktop')};
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  }

  dd {
    margin-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ListItem = styled.dd<any>`
  color: ${({ theme, $textColorSubdividions }) => theme.colors[$textColorSubdividions ?? 'white']};
  display: flex;
  flex-direction: column;
  text-align:  ${({ $isModal }) => $isModal ? 'left' : 'center'};

  > span {
    ${getP15()}
    text-align:  ${({ $isModal }) => $isModal ? 'left' : 'center'};
    display: block;
    margin-bottom: 2px;
    padding-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(10, 'desktop')};
    }

    &:first-child {
      ${getP18_4()}
    }
  }

  a {
    ${createUnderline({ height: 1, color: 'darkred', reverse: true })}
    color: ${({ theme }) => theme.colors.darkred};
    display: inline-block;
    width: fit-content;
  }
`
