import { memo } from 'react'
import styled from 'styled-components'
import { mq, vw, getP26, getP15_1, getP15_1B } from '@dy/commons/styles'
import { FormNewsletter } from '../../forms/FormNewsletter'

export const SectionNewsletter = memo(() => {
  return (
    <Section>
      <FormNewsletter  />
    </Section>
  )
})

const Section = styled.section`
  grid-column: 1 / span 6;
  margin-bottom: ${vw(60, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(60, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 9 / span 4;
    margin-bottom: 0;
  }

  h2 {
    ${getP26()}
    color: ${({ theme }) => theme.colors.creme};
    font-family: ${({ theme }) => theme.fonts.tertiaryFont};
    text-align: left;
    margin-bottom: 0;

  }

  .input--newsletter {
    width: ${vw(286, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width:100%;
    }

    ${mq.greaterThan('tablet')} {
      width: ${vw(240, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 240px;
    }

    > input {
      ${getP15_1()}
      border-bottom: 1px solid ${({ theme }) => theme.colors.creme};
    }
  }

  button {
    ${getP15_1B()}
    cursor: pointer;

    height: ${vw(50, 'mobile')};
    width: ${vw(74, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(50, 'nexus7')};
      width: ${vw(74, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(43, 'desktop')};
      width: ${vw(95, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 43px;
      width: 95px;
    }
  }
`
