"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cookie = void 0;
const cookie_1 = require("cookie");
const _1 = require("./");
// interface CookieSerializeOptions {
// 	domain?: string | undefined;
// 	encode?(value: string): string;
// 	expires?: Date | undefined;
// 	httpOnly?: boolean | undefined;
// 	maxAge?: number | undefined;
// 	path?: string | undefined;
// 	sameSite?: true | false | 'lax' | 'strict' | 'none' | undefined;
// 	secure?: boolean | undefined;
// }
const HOST = _1.HOSTNAME.split('.mammafiore.');
// To manage subdomains URLs: staging.cat.mammafiore.es, .cat.mammafiore.es and also
// to manage root domain URLs: mammafiore.es, mammafiore.it, ...etc.
const DOMAIN = HOST.length > 1 ? '.mammafiore.' + HOST[HOST.length - 1] : '.' + HOST[0];
exports.cookie = {
    set: ({ name, value, expire = null, seconds = null, days = 365, path = '/', sameSite = null }) => {
        if (typeof window === 'undefined')
            return;
        const date = new Date();
        if (seconds) {
            date.setTime(date.getTime() + (seconds * 1000));
        }
        else if (days) {
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        }
        let expires = `; expires=${expire !== null && expire !== void 0 ? expire : date.toUTCString()}`;
        const incomingCookie = `${name}=${(value || '')}${expires}; path=${path}; domain=${_1.DEV ? 'localhost' : `${DOMAIN}`};${sameSite ? ` sameSite=${sameSite};` : ''}${_1.PRO ? ' secure;' : ''}`;
        document.cookie = incomingCookie;
    },
    setHeader: (res, name, value, options = {}) => {
        res.setHeader('Set-Cookie', (0, cookie_1.serialize)(name, value, Object.assign(Object.assign({}, options), { domain: !_1.DEV ? `${DOMAIN}` : 'localhost' })));
    },
    get: (name) => {
        if (typeof window === 'undefined' || typeof document === 'undefined')
            return null;
        const nameEQ = `${name}=`;
        const arrayOfCookies = document === null || document === void 0 ? void 0 : document.cookie.split(';');
        for (let rawCookie of arrayOfCookies) {
            let cookie = rawCookie;
            while (cookie.charAt(0) == ' ')
                cookie = cookie.substring(1, cookie.length);
            if (cookie.indexOf(nameEQ) == 0)
                return cookie.substring(nameEQ.length, cookie.length);
        }
        return null;
    },
    removeCookie: (name) => {
        if (typeof window !== 'undefined') {
            if (_1.DEV) {
                document.cookie = name + '=; Max-Age=-99999999;';
                return;
            }
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; domain=${_1.DEV ? 'localhost' : `${DOMAIN}`}`;
        }
    },
    extract: (cookieString, name) => {
        const cookiePairs = cookieString.split(', ');
        for (const pair of cookiePairs) {
            const [cookie] = pair.split('; ');
            const [cookieName, value] = cookie.split('=');
            if (cookieName.trim() === name) {
                const cookieObj = { name: cookieName.trim(), value: value.trim() };
                return cookieObj;
            }
        }
        return undefined;
    }
};
