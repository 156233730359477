import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, vw, getP15_1 } from '@dy/commons/styles'
import { getLang } from '@dy/commons/utils'
import { Link } from '@dy/commons/components'
import { useRouter } from 'next/router'

export type TypePage = {
  readonly type: 'simple' | 'external',
  readonly title: string,
  readonly url: {
    page: 'content' | 'page' | 'products' | 'product',
    slug: {
      en?: null | string,
      es?: null | string,
      fr?: null | string,
      it?: null | string,
      de?: null | string
    }
  }
}

const pathGenerator = {
  'content': (slug) => `/page/${slug}`,
  'simple': (slug) => `/page/${slug}`,
  'faqs': (slug) => slug ? `/faqs/${slug}` : '/faqs',
  'product': (slug) => `/product/${slug}`,
  'shop': (slug) => `/shop/${slug}`,
  'category': (slug) => `/shop/${slug}`
}

interface ISectionPages {
  pages: TypePage[]
}

const getPagesLink = (pages, lang) => pages.map((page, idx) => {
  const { type, title, url: { page:pageType, slug } } = page
  if(!title || title.indexOf('translation missing') !== -1) return null
  const path = pathGenerator[pageType] ? pathGenerator[pageType](slug?.[lang]) : ''

  return (
    <li key={`footer-pages-${idx}`}>
      {type === 'external' ?
        <a href={slug[lang]} rel='noreferrer' target='_blank' title={title}>{title}</a>
        :
        <>
          {path.includes('cookies')  ?
            <a href={path} title={title}>{title}</a>
            :
            <Link href={path || '/'} title={title}>{title}</Link>
          }
        </>
      }
    </li>
  )
})

export const SectionPages = memo(({ pages }:ISectionPages) => {
  const { locale:routerLocale } = useRouter()
  const lang = getLang(routerLocale)
  const Pages = useMemo(() => getPagesLink(pages, lang), [pages, lang])

  return (
    <Section>
      <ul className='list--pages'>
        {Pages}
      </ul>
    </Section>
  )
})

const Section = styled.section`
  grid-column: 4 / span 3;
  padding: 0;

  ${mq.greaterThan('tablet')} {
    align-items: flex-end;
    grid-column: 6 / span 2;
    grid-row: 1 / span 2;
  }

  .list--pages {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style-type: none;
    margin-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: 0;
    }

    li {
      align-items: center;
      display: flex;
      margin-bottom: ${vw(15, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(15, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: ${({ theme }) => theme.colors.background};
      display: inline-block;
      font-family: ${({ theme }) => theme.fonts.tertiaryFont};
      ${getP15_1()}
      font-size: ${vw(15, 'mobile')};
      opacity: 1;
      text-decoration: none;
      transition: 300ms opacity ease-out;

      ${mq.greaterThan('nexus7')} {
        font-size: ${vw(15, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        font-size: ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        font-size: 15px;
      }

      &:hover {
        opacity: .4;
      }
    }
  }
`

