import { memo, ReactNode, useEffect, useState } from 'react'
import { Figure } from '@dy/commons/components'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP18_1, getP15_5, getP20, getTransition  } from '@dy/commons/styles'

interface IButtonFilterAccordion {
  activeValues: {
    [key: string]: boolean
  },
  children: ReactNode,
  data: any,
  clickedParam?: string | null
}

const getActiveNames = (values, activeValues) => {
  if(typeof activeValues !== 'object') return ''
  return Object.keys(activeValues).map((activeVal, idx) => {
    const name = values?.filter(val => val.value.includes(activeVal))[0]?.name
    return idx < 1 ? name : `, ${name}`
  })
}

const mediaChevron = {
  type: 'image' as const,
  alt: 'Icon chevron',
  default: {
    src: '/images/svg/i--chevron.svg',
    size: { width: 6, height: 8 }
  }
}

export const ButtonFilterAccordion = memo(({ activeValues, children, data, clickedParam = null }:IButtonFilterAccordion) => {
  const ActiveNames = getActiveNames(data.values, activeValues)
  const id = `btn-controls-${data.param}`
  const [isExpanded, setIsExpanded] = useState(clickedParam === data.param ?? false)

  const onClick = () => {
    setIsExpanded(!isExpanded)
  }
  useEffect(()=> {
    if(clickedParam === data.param) {
      setIsExpanded(true)
    } else {
      setIsExpanded(false)
    }
  },[clickedParam, data])


  return (
    <>
      <AccordionButton onClick={onClick} aria-controls={id} $isExpanded={isExpanded} >
        <div className='txt-wrapper'>
          {data.name}
          <span className={`values${!isExpanded ? ' visible' : ''}`}>{ActiveNames}</span>
        </div>
        <Chevron aria-hidden={true} animate={{ rotate: isExpanded ? 90 : 0}} transition={getTransition()}>
          <Figure media={mediaChevron} />
        </Chevron>
      </AccordionButton>
      <AccordionWrapper id={id} aria-expanded={isExpanded} tabIndex={-1} variants={accordionVariants} initial='initial' exit='exit' animate={isExpanded ? 'animate' : 'exit'}>
        {children}
      </AccordionWrapper>
    </>
  )
})

const Chevron = styled(motion.span)`
  display: inline-block;
  position: relative;
  user-select: none;
  width: ${vw(25, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    width: ${vw(25, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    width: ${vw(25, 'desktop')};
  }

  figure {
    height: ${vw(8, 'mobile')};
    width: ${vw(6, 'mobile')};

    ${mq.greaterThan('tablet')} {
      height: ${vw(8, 'desktop')};
      width: ${vw(6, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 8px;
      width: 8px;
    }
  }
`

const AccordionWrapper = styled<any>(motion.div)`
  overflow: hidden;

  &[aria-expanded=false] {
    button {
      pointer-events: none;
      user-select: none;
    }
  }

  button {
    ${getP18_1()}
    margin-top: ${vw(2, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(2, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(2, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 2px;
    }
  }
`

const AccordionButton = styled.button<any>`
  align-items: center;
  border: 0;
  color: ${({ theme }) => theme.colors.darkred};
  display: flex;
  ${getP20()}
  height: ${vw(50, 'mobile')};
  justify-content: space-between;
  padding: 0 ${vw(40, 'mobile')};
  position: relative;
  width: 100%;
  white-space: normal;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(50, 'nexus7')};
    padding: 0 ${vw(40, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(50, 'desktop')};
    padding: 0 ${vw(40, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 50px;
    padding: 0 40px;
  }

  > div {
    position: relative;

    &.txt-wrapper {
      ${getP20()}
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      font-family: ${({ theme }) => theme.fonts.primaryFont};
      font-weight: 700;
      justify-content: space-between;
      text-align: left;
      width: calc(100% - 40px);

    }

    &:last-child {
      flex-shrink: 0;

      span {
        width: 27px;
      }
    }
  }

  .values {
    bottom: -${vw(20, 'mobile')};
    color: ${({ theme }) => theme.colors.darkred};
    ${getP15_5()}
    font-family: ${({ theme }) => theme.fonts.tertiaryFont};
    font-weight: 500;
    letter-spacing: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    transition: 200ms opacity ${({ theme }) => theme.ease};
    user-select: none;
    width: 100%;
    white-space: nowrap;

    ${mq.greaterThan('nexus7')} {
      bottom: -${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      bottom: -${vw(21, 'desktop')};
      letter-spacing: 0;
    }

    ${mq.greaterThan('desktop')} {
      bottom: -21px;
    }

    &.visible {
      opacity: .5;
      user-select: auto;
    }
  }
`

const accordionVariants = {
  initial: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .3,
      delay: .25,
    }
  },
  exit: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  }
}
