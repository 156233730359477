import { memo, useEffect, useState, useMemo } from 'react'
import { Figure } from '@dy/commons/components'
import { cookie } from '@dy/commons/utils'
import { GET_CUSTOMER_GRID_MY_PRODUCTS } from '@/api'
import { useLazyQuery } from '@apollo/client'
import styled from 'styled-components'
import { mq, vw } from '@dy/commons/styles'

const getMyProducts = (products) => products.map((product, idx)=> {
  const {media, bigcommerceId} = product

  return (
    <Li key={idx}>
      {product ?
        <Figure key={`grid-img-${bigcommerceId}`} className='product-grid-image' media={media?.[0]?.medium} quality={65} />
        :
        <p>No image</p>
      }
    </Li>
  )
})


export const GridMyProducts = memo(() => {
  const [getDataForMyProducts, {}] = useLazyQuery(GET_CUSTOMER_GRID_MY_PRODUCTS)
  const [currentProducts, setCurrentProducts] = useState([])

  useEffect(() => {
    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')

    async function getUsualPurchases() {
      const { data } = await getDataForMyProducts({
        variables: {
          first: 8
        },
        context: {
          isPrivatePath: true,
          DYCustomerToken
        }
      })

      if(typeof data?.customer === 'object') {
        const { myUsual } = data.customer
        const arrayOfVariantNodes = Array.isArray(myUsual.edges) ? myUsual.edges.map(item => item.node) : []
        setCurrentProducts(arrayOfVariantNodes)
      }
    }

    if (DYCustomerToken && currentProducts.length < 1) getUsualPurchases()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListItems = useMemo(() => getMyProducts(currentProducts), [currentProducts])

  return (
    <Div>
      <ul>
        {ListItems}
      </ul>
    </Div>
  )})



const Li = styled.li`
  background-color: white;
  border-radius: 10%;
  height:${vw(70, 'mobile')};
  width: ${vw(81, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height:${vw(70, 'nexus7')};
    width: ${vw(81, 'nexus7')};
  }


  ${mq.greaterThan('tablet')} {
    height:${vw(115, 'desktop')};
    width: ${vw(130, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 115px;
    width: 130px;
  }

  figure {
    align-items: center;
    height: 100% !important;
    margin: 0 auto;
    width: 80% !important;

    img {
      width:100% !important
    }
  }
`

const Div = styled.div`
  padding: ${vw(40, 'mobile')} 0 ${vw(50, 'mobile')};
  pointer-events: none;
  user-select: none;

  ${mq.greaterThan('nexus7')} {
    padding:${vw(40, 'nexus7')} 0 ${vw(50, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    padding:${vw(40, 'desktop')} 0 ${vw(50, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 40px 0 50px;
  }

  ul {
    display: grid;
    column-gap: ${vw(5, 'mobile')};
    row-gap: ${vw(6, 'mobile')};
    grid-template-columns: repeat(4, 1fr);

    ${mq.greaterThan('nexus7')} {
      column-gap: ${vw(5, 'nexus7')};
      row-gap: ${vw(6, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      column-gap: ${vw(5, 'desktop')};
      row-gap: ${vw(6, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      column-gap: 5px;
      row-gap: 6px;
    }
  }
`
