'use client'
import { memo } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useTranslator } from '@/hooks'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { mq, vw, hideScrollbar, hex2Rgba, getP15_3B } from '@dy/commons/styles'
import { Button } from '@dy/commons/components'
import { Modal } from './'

interface IModalFilters {
  filters: JSX.Element[],
  clearFilters: () => void,
  activeFiltersLength: number | null,
  resultsLength: number | null
}

export const ModalFilters = memo(({ filters, clearFilters, activeFiltersLength, resultsLength }:IModalFilters) => {
  const { isActive, toggle } = useModal(MODALS.FILTER)
  const { t } = useTranslator()

  return (
    <Modal isActive={isActive} close={toggle} title={t('modals.filter.title') as string} xAlign={'right'} size={'s'} dialogStyles={dialogStyles} zIndex={100000} isModalFilters={true}>
      <main>
        <section>
          <motion.ul initial='inactive' >
            {filters}
          </motion.ul>
        </section>
        <Actions isActive={isActive}>
          <Button onClick={toggle} bgType={'red'}>{t('commons.actions.show')}{activeFiltersLength > 0 && ` (${resultsLength})`}</Button>
          <Button onClick={clearFilters} bgType={'red'} outline={true}>{t('commons.actions.delete_all')}</Button>
        </Actions>
      </main>
    </Modal>
  )
})

const dialogStyles = css<any>`
  background-color: ${({ theme }) => theme.colors.lightred};
  height: 100%;
  min-height: 100%;

  .modal__body {
    height: calc(100% - ${vw(50, 'mobile')});
    overflow: hidden scroll;
    max-height: none;
    padding-bottom: 0;

    ${mq.greaterThan('nexus7')} {
      height: calc(100% - ${vw(50, 'nexus7')});
    }

    ${mq.greaterThan('tablet')} {
      height: calc(100% - ${vw(50, 'desktop')});
    }

    ${mq.greaterThan('desktop')} {
      height: calc(100% - 50px);
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(10, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-bottom: 10px;
    }
  }

  section {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    max-height: 100%;
    overflow-x: clip;
  }

  ul {
    height: auto;
    overflow: auto;
    width: 100%;
    ${hideScrollbar()}

    li {
      margin-bottom: ${vw(40, 'mobile')};
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(40, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 40px;
      }

      > button,
      [id*='btn-controls'] button {
        padding: 0 ${vw(10, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          padding: 0 ${vw(10, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          padding: 0 ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          padding: 0 10px;
        }
      }

    }
  }
`
const Actions = styled.div<any>`
  background: linear-gradient(0deg, ${({ theme }) => hex2Rgba(theme.colors.lightred, .85)} 60%, ${({ theme }) => hex2Rgba(theme.colors.lightred, 0)} 100%);
  bottom: ${vw(10, 'mobile')};
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: ${vw(80, 'mobile')} 0 0;
  pointer-events: none;
  position: sticky;
  width: 100%;
  max-height: 100%;

  ${mq.greaterThan('nexus7')} {
    bottom: ${vw(10, 'nexus7')};
    padding: ${vw(80, 'nexus7')} 0 0;
  }

  ${mq.greaterThan('tablet')} {
    bottom: ${vw(10, 'desktop')};
    max-height: 100%;
    padding: ${vw(80, 'desktop')} 0 0;
  }

  button {
    height: ${vw(60, 'mobile')};
    pointer-events: ${({ isActive }) => isActive ? 'auto' : 'none'};
    white-space: nowrap;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(60, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 60px;
    }

    > div:last-of-type {
      ${getP15_3B()}
    }

    &:first-child {
      margin-right: ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-right: ${vw(20, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-right: ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-right: 20px;
      }
    }

    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.darkred};
    }
  }
`
