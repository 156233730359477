import { memo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { getP20_1, vw, mq, createUnderline } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'


export const ModalPublicInterested: FC = memo(() => {
  const { isActive, toggle } = useModal(MODALS.PUBLIC_INTERESTED)
  const { t } = useTranslator()
  return (
    <Modal isActive={isActive} close={toggle} title={t('modals.public_interested.title') as string} xAlign='center' dialogStyles={dialogStyles} size='s' >
      <p>{t('modals.public_interested.contact')} <a href={`mailto:${t('modals.public_interested.mail')}`}>{t('modals.public_interested.mail')}</a></p>
      {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
      <p>{t('modals.public_interested.particular')} <br/>{t('modals.public_interested.cta_hm')} <a target='_blank' href='https://hellomamma.es'>{t('modals.public_interested.online')}</a></p>
    </Modal>
  )
})

const dialogStyles = css`
  > .modal__body {
    padding-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-bottom: 30px;
    }

    p {
      ${getP20_1()}
      text-align: center;
      color: ${({ theme }) => theme.colors.text};
      margin-bottom:${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom:${vw(20, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom:${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 20px;
      }
    }
    a {      
      color: ${({ theme }) => theme.colors.red};
      ${createUnderline({ color: 'red', height: 1 })}
    }
  }
`