import dynamic from 'next/dynamic'
import { SectionNewsletter } from './SectionNewsletter'
import { TypePage, SectionPages } from './SectionPages'
import { CallUs } from './CallUs'
import { List } from './List'
import { PreFooter } from './PreFooter'
import styled from 'styled-components'
import { mq, vw, designGrid } from '@dy/commons/styles'


const DynamicSectionRrss = dynamic(() => import('./SectionRrss'))

interface IFooter {
  pages?: TypePage[] | [],
  firstCategory: any | null,
  services: string,
}

export const Footer = ({ pages = [], firstCategory = null, services = '' }:IFooter) => {
  return (
    <>
      {services && <PreFooter services={services} />}
      <FooterStyled>
        <div>
          <CallUs />
          <SectionNewsletter />
          <List firstCategory={firstCategory} />
          {pages?.length > 0 && <SectionPages pages={pages}/>}
          <DynamicSectionRrss />
        </div>
      </FooterStyled>
    </>
  )
}


const FooterStyled = styled.footer`
  background-color: ${({ theme }) => theme.colors.red};

  > div {
    ${designGrid({})}
    background-color: ${({ theme }) => theme.colors.red};
    padding: ${vw(70, 'mobile')} ${vw(20, 'mobile')} ${vw(50, 'mobile')};
    position: relative;
    z-index: 2;
    ${mq.greaterThan('nexus7')} {
      padding: ${vw(70, 'nexus7')} ${vw(20, 'nexus7')} ${vw(50, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(100, 'desktop')} 0 ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 100px 0 60px;
    }

    ${mq.greaterThan('desktop-wide')} {
      margin: 0 auto;
      max-width: 1650px;
    }
  }
`
