"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.article = void 0;
/* eslint-disable quotes */
const article = ({ data, region, locale, hostname, articlePage = true }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!data)
        return null;
    const image = articlePage ? (_f = (_e = (_d = (_c = (_b = (_a = data.header) === null || _a === void 0 ? void 0 : _a.media) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.medium) === null || _d === void 0 ? void 0 : _d.default) === null || _e === void 0 ? void 0 : _e['2x']) === null || _f === void 0 ? void 0 : _f.regular : (_k = (_j = (_h = (_g = data.image) === null || _g === void 0 ? void 0 : _g.media) === null || _h === void 0 ? void 0 : _h.default) === null || _j === void 0 ? void 0 : _j['2x']) === null || _k === void 0 ? void 0 : _k.regular;
    const url = `${hostname}/${locale}/${region}/stories/${data.slug[locale]}`;
    const articleSchema = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ "@context": "https://schema.org", "@type": "NewsArticle", "mainEntityOfPage": url, "url": url, "headline": `${data.name}` }, (data.shortDescription && { "description": `${data.shortDescription}` })), (data.shortDescription && { "articleBody": `${data.shortDescription}` })), (image && { "image": [image] })), (data.publishedAt && { "datePublished": data.publishedAt.split('.').reverse().join('-') })), { 
        // "dateModified": "2021-02-05T09:20:00+08:00",
        "publisher": {
            "@type": "Organization",
            "name": "Mammafiore",
            "logo": {
                "@type": "ImageObject",
                "url": `${hostname}/images/logo--mammafiore.png`
            }
        } });
    return articlePage ? JSON.stringify(articleSchema) : articleSchema;
};
exports.article = article;
