"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxHeightContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const framer_motion_1 = require("framer-motion");
const clamp_1 = require("./clamp");
const variants = {
    active: {
        height: 'auto',
        transition: {
            type: 'tween',
            ease: [.25, .1, .25, 1],
            duration: .3,
        }
    },
    init: {
        height: '0px',
        transition: {
            type: 'tween',
            ease: [.25, .1, .25, 1],
            duration: .3,
        }
    }
};
exports.MaxHeightContainer = (0, react_1.memo)(({ className = '', data, elipsis, force_link = false }) => {
    const elementRef = (0, react_1.useRef)(null);
    const buttonRef = (0, react_1.useRef)(null);
    const [isRendered, setRender] = (0, react_1.useState)(false);
    const [currentData, setData] = (0, react_1.useState)(data);
    const [isExpanded, setExpanded] = (0, react_1.useState)(false);
    // const mediaType = useDeviceType()
    const handleHover = (isHover) => () => {
        if (buttonRef.current && !force_link) {
            isHover ? buttonRef.current.classList.add('active') : buttonRef.current.classList.remove('active');
        }
    };
    (0, react_1.useEffect)(() => {
        if (!elementRef.current)
            return;
        const isClamped = (0, clamp_1.clamp)(elementRef.current, elementRef.current.offsetHeight, { ellipsis: elipsis });
        buttonRef.current = elementRef.current.getElementsByClassName('dy-max-button')[0];
        if (force_link && buttonRef.current) {
            buttonRef.current.addEventListener('click', handleExpanded);
        }
        setExpanded(!isClamped);
    }, [currentData]);
    (0, react_1.useEffect)(() => {
        buttonRef.current = elementRef.current.getElementsByClassName('dy-max-button')[0];
    }, []);
    const handleExpanded = () => {
        if (!buttonRef.current)
            return;
        buttonRef.current.remove();
        setExpanded(true);
    };
    const handleClick = (0, react_1.useRef)(handleExpanded);
    (0, react_1.useEffect)(() => {
        if (!isRendered)
            return;
        setData(data);
    }, [data]);
    (0, react_1.useEffect)(() => {
        setRender(true);
    }, []);
    return isExpanded ? (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { animate: 'active', variants: variants, onClick: null, className: `dy-max expanded ${className}`, dangerouslySetInnerHTML: { __html: currentData } }) :
        (0, jsx_runtime_1.jsx)("div", { onClick: !force_link ? handleClick.current : null, onMouseEnter: handleHover(true), onMouseLeave: handleHover(false), ref: elementRef, className: `dy-max ${className}`, dangerouslySetInnerHTML: { __html: currentData } });
});
