import { memo } from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, getP26_4, getP93 } from '@dy/commons/styles'

interface IHeaderContents {
  children: ReactNode
}

const Header = styled.header<any>`
  ${designGrid({ withoutGutter: true })}
  align-content: flex-end;
  align-items: center;
  padding-top: ${vw(60, 'mobile')};
  margin-bottom:${vw(30, 'mobile')} ;

  ${mq.greaterThan('tablet')} {
    padding-top: ${vw(120, 'desktop')};
    margin-bottom:${vw(30, 'desktop')} ;
  }

  ${mq.greaterThan('desktop')} {
    padding-top: 120px;
    margin-bottom: 30px;
  }

  h1, p {
    grid-column: 1 / span 6;
    text-align: center;
    width: 100%;
  }

  h1 {
    ${getP26_4()}
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
    margin-bottom: ${vw(30, 'mobile')};
    padding-top: ${vw(50, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(30, 'nexus7')};
      padding-top: ${vw(50, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 3 / span 8;
      margin-bottom: ${vw(30, 'desktop')};
      padding-top: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 30px;
      padding-top: 60px;
    }
  }

  p {
    ${getP93(50, 100)}
    color: ${({ theme }) => theme.colors.darkred};
    margin-bottom: ${vw(50, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(50, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 10;
      margin-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 60px;
    }
  }
`

export const HeaderContents = memo<IHeaderContents>(({ children }) => {
  return (
    <Header>
      {children}
    </Header>
  )
})
