import { memo, useEffect } from 'react'
import { useInView } from '@dy/commons/hooks'
import styled from 'styled-components'
import { mq, vw, getP26_2, getP18_2 } from '@dy/commons/styles'

type TypeFaqQuestion = {
  question: string,
  answer: string,
  parameterize: string
}

interface IQuestion {
  data: TypeFaqQuestion,
  setActiveQuestion: any
}

export const Question = memo(({ data, setActiveQuestion }:IQuestion) => {
  const { ref, inView } = useInView({
    rootMargin: '100px 0px -50% 0px',
    threshold: 1,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView && data?.parameterize) setActiveQuestion(data.parameterize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <>
      <QuestionTitle ref={ref}>
        <a href=''></a>
        <div id={`${data.parameterize}`} className='hash'></div>
        <h3>{data.question}</h3>
      </QuestionTitle>
      <QuestionAnswer dangerouslySetInnerHTML={{ __html: data.answer}} />
    </>
  )
})


const QuestionTitle = styled.dt`
  margin-bottom: ${vw(10, 'mobile')};
  position: relative;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(10, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 10px;
  }

  &:first-child {
    margin-top: 0;
  }

  h3 {
    color: ${({ theme }) => theme.colors.red};
    ${getP26_2()}
    font-family: ${({ theme }) => theme.fonts.tertiaryFont};
    text-align: left;
  }

  .hash {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: -110px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      top: -140px;
    }
  }
`

const QuestionAnswer = styled.dd<any>`
  ${getP18_2()}
  color: ${({ theme }) => theme.colors.darkred};
  margin-bottom: ${vw(48, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(48, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(60, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 60px;
  }

  p, ul, dl, ol {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding-right: ${vw(70, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-right: ${vw(70, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-right: 0;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.red};
    text-decoration: underline;
  }
`
