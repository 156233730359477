
import { memo } from 'react'
import dynamic from 'next/dynamic'
import { FormStore } from '@/components'
import { Logo } from '../layout'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'
import type { TypeMarqueeProps } from '@dy/commons/components'

const DynamicMarquee = dynamic<TypeMarqueeProps>(() => import('@dy/commons/components').then((mod) => mod.Marquee), { ssr: false })

export const SectionStorePicker = memo(() => {
  const { t } = useTranslator()

  return (
    <Main>
      <Logo fillColor='white'></Logo>
      <DynamicMarquee data={t('section_subscribe.welcome') as string} />
      <FormStore dropdownTextColor='darkred' textColorInput={'white'}/>
    </Main>
  )
})

const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.red};
  min-height: 100dvh;
  min-height: 100vh;
  width: 100vw;
  overflow-x: clip;

  .marquee {
    background-color: unset !important;
  }
`
