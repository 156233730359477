"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInView = void 0;
const react_1 = require("react");
const useInView = (options = {}) => {
    const ref = (0, react_1.useRef)(null);
    const [inView, setInView] = (0, react_1.useState)((options === null || options === void 0 ? void 0 : options.initialInView) || false);
    const [entry, setEntry] = (0, react_1.useState)(undefined);
    (0, react_1.useEffect)(() => {
        if (options.skip || !ref)
            return;
        const observerOptions = {
            threshold: options.threshold || 0,
            rootMargin: options.rootMargin || '0px',
        };
        let observer;
        const handleIntersection = (entries) => {
            const [firstEntry] = entries;
            setInView(firstEntry.isIntersecting);
            setEntry(firstEntry);
            if (options.triggerOnce && firstEntry.isIntersecting)
                observer.disconnect();
        };
        if (typeof IntersectionObserver !== 'undefined') {
            observer = new IntersectionObserver(handleIntersection, observerOptions);
            const currentRef = ref.current;
            if (currentRef)
                observer.observe(currentRef);
        }
        else {
            // Fallback behavior when IntersectionObserver is not available
            console.warn('IntersectionObserver is not supported in this browser. Please consider using a polyfill.');
        }
        return () => { if (observer)
            observer.disconnect(); };
    }, [options]);
    return { ref, inView, entry };
};
exports.useInView = useInView;
