'use client'
import { FC, useEffect } from 'react'
import { memo} from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import { getStoreByLocale, getRegion, validate, getDynamicText } from '@dy/commons/utils'
import type { TypeSectionAuthLevel } from '@/components'
import { CUSTOMER_EMAIL_INVITE } from '@/api'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload?: { level: string, [key: string]: any }
  animation?: any
}

type TypeFormInputs = {
  vatNumber: string
}

export const FormUserCheckCIF:FC<Props> = memo(({ updateLevel, animation }) => {
  const { locale } = useRouter()
  const countryCode = getRegion(locale)
  const { register, handleSubmit, formState: { errors: formErrors }, reset } = useForm<TypeFormInputs>()
  const { t } = useTranslator()
  const [customerEmailInvite, { loading, error:apolloError }] = useMutation(CUSTOMER_EMAIL_INVITE, { errorPolicy: 'all' })
  const { toggle:toggleModalRegion } = useModal(MODALS.REGION_LANG)

  const onSubmit: SubmitHandler<TypeFormInputs> = async data => {
    const { vatNumber } = data

    const { data:resData } = await customerEmailInvite({
      variables: {
        vatNumber
      },
      context: {
        isPrivatePath: true
      }
    })

    const res = resData?.customerEmailInvite
    if(!res) throw new Error('Unhandled customerEmailInvite error encountered, probably ApolloError')
    if(res?.ok) {
      updateLevel('feedback_email_invite', { emailObfuscated: res.customer.emailObfuscated })
    } else if (res?.customer?.onboardingStatus === 'active') {
      updateLevel('feedback_cif_already_active', { emailObfuscated: res.customer.emailObfuscated })
    } else {
      updateLevel('feedback_cif_not_found', { vatNumber })
    }

    reset()
  }

  useEffect(() => {
    if (apolloError) console.warn('apolloError:', apolloError)
  },[apolloError])

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('modals.auth.login_check_cif.text') as string}>
        <p key='p-light' className='light'>
          {getDynamicText(t('modals.auth.login_check_cif.text2') as string, {
            storeName:getStoreByLocale[locale]
          })} <button type='button' onClick={() => { toggleModalRegion() }}>{t('modals.auth.login_check_cif.text2_action')}</button>.</p>

        <Input key='vatNumber' name='vatNumber' register={register} label={t('form.fields.cif') as string} required={{
          required: true,
          // cif validation only available for Spain by now
          ...countryCode === 'ES' && {
            validate: (value) => validate.cif(value) || (t('form.errors.validate_cif') as string)
          }
        }} error={formErrors.vatNumber} bgColor={'white'} />
      </Fieldset>
      <Button type='submit' bgType={'red'} outline={true}>{t('commons.actions.continue')}</Button>
      <p className='subtext'>{t('modals.auth.prelogin.subtext')} <button type='button' onClick={() => { updateLevel('signup_cif') }}>{t('modals.auth.prelogin.subtext_action')}</button>.</p>
    </Form>
  )
})
