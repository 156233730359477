import { memo, useCallback, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { updateActiveFilters, NEXT_PUBLIC_LOCALE, getLang } from '@dy/commons/utils'
import { useActiveVariant } from '@/hooks'
import { SliderFlickity } from '@dy/commons/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { ButtonFilterCheckbox } from './ButtonFilterCheckbox'
import { ButtonFilterAccordion } from './ButtonFilterAccordion'
import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP15_5 } from '@dy/commons/styles'

const DynamicModalFilters = dynamic(() => import('@/components').then(mod => mod.ModalFilters), { ssr: false })
const LANG = getLang(NEXT_PUBLIC_LOCALE)

type TypeGetFilterButtons = {
  readonly filters: {
    [key: string]: string | string[]
  },
  readonly target: 'filters-bar'|'filters-modal',
  readonly onClickParam?: (param) => void,
  readonly clickedParam?: string | null,
  readonly onClick: (filter, activeFilter) => void
  readonly activeFilters: {
    [key: string]: any
  },
  isBrandPage?: boolean
}

interface ISectionFilters {
  readonly filters: {
    [key: string]: string | string[]
  },
  readonly activeFilters: {
    [key: string]: any
  },
  resultsLength: number | null
  setActiveFilters: any,
  isScrolling?: boolean,
  isBrandPage?: boolean
}

//hardcoded!! need to check all idioms
const TRANSLATIONS = {
  'en': {
    'brand': 'brand'
  },
  'es': {
    'brand': 'marca'
  },
  'fr': {
    'brand': 'marque'
  }
}

const getFilterButtons = ({ filters, target, onClickParam, clickedParam, onClick, activeFilters, isBrandPage }:TypeGetFilterButtons) => {
  let params = Object.keys(filters)
  if(isBrandPage) {
    const mutatedArray = params.filter((item)=> item !== TRANSLATIONS[LANG]?.brand)
    params = mutatedArray
  }
  const item = {
    'filters-bar': (param, filter, idx) => {
      const paramMultipleActive = (filter.type === 'multiple' || filter.type === 'brand') && typeof activeFilters[param] === 'object' && Object.keys(activeFilters[param]).length > 0
      const isActive = (filter.type === 'multiple' || filter.type === 'brand') ? paramMultipleActive : activeFilters[param]
      return (
        <li key={`target-${idx}`}>
          <FiltersButton onClick={(filter.type === 'multiple' || filter.type === 'brand') ? () => { onClickParam(param) } : () => { onClick({...filter, param}, activeFilters) }} isActive={param !== 'sort' && isActive}>{filter?.name ? filter.name : 'Ordenar'}{paramMultipleActive && param !== 'sort' && ` (${Object.keys(activeFilters[param]).length})`}</FiltersButton>
        </li>
      )
    },
    'filters-modal': (param, filter, idx) => {
      return (
        <li key={`target-${idx}`}>
          {filter.type === 'simple' ?
            <ButtonFilterCheckbox data={filter} onClick={() => { onClick({...filter, param}, activeFilters)}} isActive={activeFilters[param]}/>
            :
            <ButtonFilterAccordion data={{...filter, param}} activeValues={activeFilters[param]} clickedParam={clickedParam}>
              {filter?.values?.map((value, idx) => <ButtonFilterCheckbox key={`filter-val-${idx}`} data={{ ...value, param, value, type: 'multiple'}} onClick={() => { onClick({ ...value, param }, activeFilters)}} isActive={activeFilters[param]?.[value.value]}/>)}
            </ButtonFilterAccordion>
          }
        </li>
      )}
  }

  return params.map((param, idx) => item[target](param, filters[param], idx))
}

const scrollToTopGrid = () => {
  if(typeof window !== 'undefined') {
    const main = document.getElementById('main-top')
    if(main) setTimeout(() => { main.scrollIntoView({ behavior: 'smooth' }) }, 300)
  }
}

export const SectionFilters = memo(({ filters = {}, activeFilters, resultsLength = 0, setActiveFilters, isScrolling = null, isBrandPage = false}:ISectionFilters) => {
  const { toggle } = useModal(MODALS.FILTER)
  const [clickedParam, setClickedParam] = useState(null)
  const { cardSelected } = useActiveVariant()

  const onClick = useCallback((filter, actives) => {
    const incomingActives = updateActiveFilters(actives, filter)
    setActiveFilters(incomingActives)
    scrollToTopGrid()
  }, [setActiveFilters])

  const onClickParam = useCallback((param) => {
    setClickedParam(param)
    toggle()
  },[toggle, setClickedParam])

  const clearFilters = useCallback(() => {
    setActiveFilters({ sort: {recommended: true} })
    scrollToTopGrid()

  },[setActiveFilters])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const FilterButtons = useMemo(() => getFilterButtons({ filters, target: 'filters-bar', onClickParam, activeFilters, onClick, isBrandPage }), [filters, onClickParam, activeFilters, onClick, isBrandPage])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const FilterButtonsModal = useMemo(() => getFilterButtons({ filters, target: 'filters-modal', activeFilters, onClick, clickedParam, isBrandPage }), [filters, activeFilters, onClick, clickedParam, isBrandPage])

  const length = useMemo(() => Object.keys(activeFilters).length, [activeFilters])
  return (
    <>
      <FiltersSection $activeVariant={!!cardSelected?.variant_id} isScrolling={isScrolling}>
        <ul>
          <li>
            <FiltersButton onClick={toggle} isActive={length > 0}>{'Order and filter'}{length > 0 && ` (${length})`}</FiltersButton>
          </li>
          {FilterButtons.length < 8 ?
            <>{FilterButtons}</>
            :
            <li className='slider-bar-wrapper'>
              <SliderFlickity className={'slider--filters-bar'} options={{pageDots:false, prevNextButtons:false, freeScroll: true, cellAlign: 'left'}}>{FilterButtons}</SliderFlickity>
            </li>
          }
        </ul>
      </FiltersSection>
      <DynamicModalFilters filters={FilterButtonsModal} activeFiltersLength={length} resultsLength={resultsLength} clearFilters={clearFilters} />
    </>
  )
})


const FiltersSection = styled.section<any>`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  height: ${vw(60, 'mobile')};
  position: sticky;
  top: ${vw(60, 'mobile')};
  transition: 300ms width ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease};
  z-index: 20;
  will-change: top;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(60, 'nexus7')};
    top: ${vw(60, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 4 / span 9;
    height: auto;
    margin-top: -${vw(10, 'desktop')};
    top: ${vw(80, 'desktop')};
    z-index: 105;
    width: ${({ $activeVariant }) => $activeVariant ? 'calc(100% + 25vw)' : '100%'};
  }

  ${mq.greaterThan('desktop')} {
    margin-top: -10px;
    top: 80px;
  }

  ul {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ isScrolling, theme: { colors } }) => isScrolling ? `0 4px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
    display: flex;
    height: ${vw(60, 'mobile')};
    justify-content: center;
    padding: 0 ${vw(10, 'mobile')};
    transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
    z-index: 2;
    width: 100vw;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(60, 'nexus7')};
      padding: 0 ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      box-shadow: none;
      height: ${vw(60, 'desktop')};
      justify-content: flex-start;
      padding: 0 ${vw(20, 'desktop')};
      transform: translate(${({ $activeVariant }) => $activeVariant ? `-${vw(120 * 3, 'desktop')}` : 0}, 0);
      width: 100%;
    }

    ${mq.greaterThan('desktop')} {
      height: 60px;
      padding: 0 20px;
      transform: translate(${({ $activeVariant }) => $activeVariant ? `-${120 * 3}px` : 0}, 0);
    }

    li {
      display: none;

      ${mq.greaterThan('tablet')} {
        display: inline-block;
      }

      &:not(:nth-child(2)) {
        ${mq.greaterThan('tablet')} {
          margin-left: ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-left: 10px;
        }
      }

      &:first-child {
        display: inline-block;
        height: ${vw(50, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(50, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          display: none;
        }

        button {
          height: 100%;
          justify-content: center;
          min-width: ${vw(250, 'mobile')};

          ${mq.greaterThan('nexus7')} {
            min-width: ${vw(250, 'nexus7')};
          }
        }
      }

      &.slider-bar-wrapper {
        width: 100%;
      }
    }
  }
`

const FiltersButton = styled.button<any>`
  align-items: center;
  background-color: ${({ theme, isActive }) => hex2Rgba(theme.colors.darkred, isActive ? 1 : 0)};
  border: 1px solid ${({ theme, isActive }) => hex2Rgba(theme.colors.grey, isActive ? 1 : .3)};
  border-radius: 90px;
  color: ${({ theme, isActive }) => isActive ? theme.colors.background : hex2Rgba(theme.colors.darkred, .5)};
  display: flex;
  ${getP15_5()}
  font-weight: 500;
  height: ${vw(40, 'mobile')};
  transition: 200ms background-color ${({ ease }) => ease}, 200ms border-color ${({ ease }) => ease}, 200ms color ${({ ease }) => ease};
  padding: 0 ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(40, 'nexus7')};
    padding: 0 ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(40, 'desktop')};
    padding: 0 ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 40px;
    padding: 0 20px;
  }

  &:hover {
    background-color: ${({ theme, isActive }) => hex2Rgba(theme.colors.darkred, isActive ? 1 : 0)};
    border: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, 1)};
    color: ${({ theme, isActive }) => isActive ? theme.colors.background : hex2Rgba(theme.colors.darkred, .5)};
  }
`
