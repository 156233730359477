'use client'
import type { FC } from 'react'
import { memo} from 'react'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload?: { level: string, [key: string]: any }
  animation?: any
}

export const FeedbackCIFAlreadyActive:FC<Props> = memo(({ updateLevel, payload }) => {
  const { t } = useTranslator()

  return (
    <Form>
      <Fieldset legend={`${t('modals.auth.feedback_cif_already_active.text')} ${payload.emailObfuscated}.` as string}>
        <p key='p-light-active' className='light'>
          {t('modals.auth.feedback_cif_already_active.text2')}
          <button type='button' onClick={() => { updateLevel('login') }}>{t('modals.auth.feedback_cif_already_active.text2_actions.login')}</button>
          {t('modals.auth.feedback_cif_already_active.text2_2')}
          <button type='button' onClick={() => { updateLevel('forgot_password') }}>
            {t('modals.auth.feedback_cif_already_active.text2_actions.restore_password')}
          </button>.
        </p>
        {/* Update - To put in customers same CIF */}
        <p className='light'>{t('modals.auth.feedback_email_invite.text2')}</p>
      </Fieldset>
      <Button type='button' bgType='red' outline={true} onClick={() => {
        updateLevel('login', {}, true)
      }}>{t('commons.actions.close')}</Button>
    </Form>
  )
})
