import { memo, useCallback, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { getLang, pushAnalyticsEvent } from '@dy/commons/utils'
import { useDeviceType, useInView } from '@dy/commons/hooks'
import { CategoryCard } from '@dy/commons/components'
import { ProductCard } from '@/components'
// import { GiftCard } from '@dy/commons/components'
import { useActiveVariant, useCustomer, useTranslator } from '@/hooks'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP30 } from '@dy/commons/styles'
import { listVariants } from './shopVariants'

interface ISectionProductList {
  className?: string,
  products: any,
  readonly usualPurchases?: boolean,
  readonly packsGrid?: boolean,
  readonly ga_list?: {
    id: string,
    name: string
  },
  readonly categories?: any
}

const getProducts = (products, onClick, ga_list, categories, t) => products.map((product, idx) => {
  if(product?.category_card) return <li key={idx}> <CategoryCard data={categories}></CategoryCard></li>
  // else if(product?.giftCard) {
  //   return (
  //     <li key={idx} className={(conditionalClassName === 'regular' && idx % 2 === 0 ? 'giftcardleft': conditionalClassName !=='regular' ? 'giftcardleft': 'giftcardright')}>
  //       <GiftCard data={''} onClick={ e =>{
  //         e.preventDefault()
  //         onClick(product,idx)
  //       }}/>
  //     </li>
  //   )
  // }
  else {
    return (
      <li key={idx}>
        <ProductCard isLazy={idx > 5} data={{...product, ga_list, idx: idx + 1}} onClick={e => {
          e.preventDefault()
          onClick(product, idx)
        }} lastUnits={t('product_page.product.last_units')}/>
      </li>
    )
  }
})

const scrollToTopGrid = () => {
  if(typeof window !== 'undefined') {
    const main = document.getElementById('main-top')
    if(main) setTimeout(() => { main.scrollIntoView({ behavior: 'smooth' }) }, 300)
  }
}

export const SectionProductList = memo(({ className = '', products = [], usualPurchases = false, packsGrid = false, ga_list = { id: 'list_grid_shop', name: 'List Shop (grid)' }, categories=[] }:ISectionProductList) => {
  const { asPath, locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const { cardSelected, setCardSelected } = useActiveVariant()
  const { customer } = useCustomer()

  const { isMobile } = useDeviceType()
  const { ref } = useInView({
    rootMargin: '-60px 0px 0px',
    threshold: 1,
    initialInView: true
  })

  const onClick = useCallback((variant, idx) => {
    pushAnalyticsEvent('select_item', { ...variant, idx: idx + 1, ga_list, locale })
    if (cardSelected?.variant_id !== variant.bigcommerceId) {
      setCardSelected({
        variant_id: variant.bigcommerceId,
        slug: variant.product.slug[locale],
        ga_list,
        idx: idx + 1
      })

      if (!isMobile && idx < 4) scrollToTopGrid()
    }

    const url = `${customer ? '/shop' : '/catalog'}/product/${variant.product.slug[locale]}`
    window.history.pushState('', '', url)
  }, [cardSelected, setCardSelected, isMobile, ga_list, locale, customer])

  const Products = useMemo(() => getProducts(products, onClick, ga_list, categories, t), [products, onClick, ga_list, categories, t])

  return (
    <ProductListSection ref={ref} className={className} $activeVariant={!!cardSelected?.variant_id} $packsGrid={packsGrid} $usualPurchases={usualPurchases}>
      <AnimatePresence mode={'wait'}>
        {products.length > 0 ?
          <ProductList initial={'inactive'} animate={'active'} exit={'inactive'} variants={listVariants} key={asPath} $activeVariant={!!cardSelected?.variant_id} $packsGrid={packsGrid}>
            {Products}
          </ProductList> :
          <NoProductsMessage initial={'inactive'} animate={'active'} exit={'inactive'} variants={listVariants} key={asPath}>
            {!usualPurchases ? t('shop.no_filter') : t('shop.no_products')}
          </NoProductsMessage>
        }
      </AnimatePresence>
    </ProductListSection>
  )
})


const ProductListSection = styled(motion.section)<any>`
  grid-column: 1 / span 6;
  grid-row: 3 / span 1;
  margin-bottom: ${vw(100, 'mobile')};
  padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${vw(20, 'mobile')}`};
  opacity: ${({ $activeVariant }) => $activeVariant ? 0 : 1};
  transition: 300ms all ease-in-out;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(100, 'nexus7')};
    padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${vw(20, 'nexus7')}`};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: ${({ $packsGrid }) => $packsGrid ? '2 / span 10' : '4 / span 9'};
    grid-row: 2 / span 4;
    margin-bottom: ${vw(100, 'desktop')};
    padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${vw(45, 'desktop')}`};
    opacity: 1;
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 100px;
    padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : '45px'};
    grid-column: ${({ $packsGrid }) => $packsGrid ? '2 / span 10' : '4 / span 9'};
  }
`

const ProductList = styled(motion.ul)<any>`
  display: grid;
  grid-auto-flow: dense;
  grid-gap: ${vw(10, 'mobile')};
  grid-template-columns: repeat(2, 1fr);
  padding: 0 ${vw(10, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    grid-gap: ${vw(10, 'desktop')};
    grid-template-columns: repeat(${({ $packsGrid }) => $packsGrid ? 2 : 3}, 1fr);
    padding: 0 ${vw(10, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-gap: ${({ $packsGrid, $activeVariant }) => ($packsGrid && !$activeVariant) ? `${vw(60, 'desktop')}` : `${vw(10, 'desktop')}`};
    grid-template-columns: repeat(${({ $packsGrid }) => $packsGrid ? 3 : 4}, 1fr);
    /* padding-right: ${({ $activeVariant }) => $activeVariant ? 0 : `${vw(10, 'desktop')}`}; */
    transform: translateX(${({ $activeVariant, $packsGrid }) => $activeVariant ? `-${vw(120 * ($packsGrid ? 1 : 3), 'desktop')}` : 0});
    transition: 200ms width ${({ theme }) => theme.ease}, 200ms transform ${({ theme }) => theme.ease};
    width: ${({ $activeVariant, $packsGrid }) => 100 - ($activeVariant ? $packsGrid ? 29.166666666666668 : 22.22222222222222 : 0)}%;
  }

  ${mq.greaterThan('desktop')} {
    grid-gap: ${({ $packsGrid, $activeVariant }) => ($packsGrid && !$activeVariant) ? '60px' : '10px'};
    /* padding-right: ${({ $activeVariant }) => $activeVariant ? 0 : '10px'}; */
    transform: translateX(${({ $activeVariant, $packsGrid }) => $activeVariant ? `-${120 * ($packsGrid ? 1 : 3)}px` : 0});
  }

  ${mq.greaterThan('desktop-wide')} {
    grid-template-columns: repeat(${({ $packsGrid }) => $packsGrid ? 4 : 5}, 1fr);
    grid-gap: ${({ $packsGrid, $activeVariant }) => ($packsGrid && !$activeVariant) ? '60px' : '10px'};
    /* padding-right: ${({ $activeVariant }) => $activeVariant ? 0 : '10px'}; */
    transform: translateX(${({ $activeVariant, $packsGrid }) => $activeVariant ? `-${120 * ($packsGrid ? 1 : 3)}px` : 0});
  }

  li.giftcardleft, li.giftcardright {
    height:100%;
    grid-column: auto / span 2;
  }

  article {
    .product-card-image {
      + div {
        max-width: ${vw(188, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          max-width: ${vw(188, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          display: grid;
          max-width: ${({ $packsGrid, $activeVariant }) => vw($activeVariant ? $packsGrid ? 260 : 195 : $packsGrid ? 360 : 260, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          max-width: ${({ $packsGrid, $activeVariant }) => $activeVariant ? $packsGrid ? '260px' : '195px' : $packsGrid ? '360px' : '260px'};
        }
      }
    }

    .stacked,
    .product-card_info {
      ${mq.greaterThan('tablet')} {
        ${({ $activeVariant }) => { if($activeVariant) return css`
          padding-left: ${vw(15, 'desktop')};
          padding-right: ${vw(15, 'desktop')};
        `}}
      }

      ${mq.greaterThan('desktop')} {
        ${({ $activeVariant }) => { if($activeVariant) return css`
          padding-left: 15px;
          padding-right: 15px;
        `}}
      }
    }

    &.pack {
      ${({ $activeVariant }) => { if(!$activeVariant) return css`width: auto;`}}
    }
  }

  li {
    display: inline-grid;
    grid-column: auto / span 1;

    ${mq.greaterThan('tablet')} {
      height: ${vw(360, 'desktop')};
      width: auto;
    }

    ${mq.greaterThan('desktop')} {
      height: 360px;
    }

    article {
      margin: 0;
      width: auto;
    }

    article:not(.pack, .giftcardleft, .giftcardright) {
      width: auto;

      .product-card-image {
        ${mq.greaterThan('tablet')} {
          max-width: ${vw(260, 'desktop')};
          min-width: ${vw(180, 'desktop')};
          width: 100%;
        }

        ${mq.greaterThan('desktop')} {
          max-width: 260px;
          min-width: 180px;
        }
      }
    }
  }
`


const NoProductsMessage = styled(motion.p)<any>`
  ${getP30()}
  font-weight: 400;
  opacity: .4;
  padding: ${vw(40, 'mobile')} ${vw(20, 'mobile')};
  text-align: center;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(40, 'nexus7')} ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 4 / span 9;
    margin: 0 auto;
    max-width: ${vw(120 * 6, 'desktop')};
    padding: ${vw(40, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    max-width: 720px;
    padding: 40px 0;
  }
`
