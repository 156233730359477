"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.search = void 0;
/* eslint-disable quotes */
const search = ({ locale }) => {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": `https://www.mammafiore.${locale.toLowerCase()}`,
        "potentialAction": {
            "@type": "SearchAction",
            "target": `https://mammafiore.${locale.toLowerCase()}`,
            "query-input": "required name=search_term_string"
        }
    });
};
exports.search = search;
