"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllCategories = exports.hasPrivateStore = exports.getPathnameType = exports.privatePaths = exports.DY_LOGIN_ALLOWED = exports.DY_SIGNUP_ALLOWED = exports.PRO = exports.STAGING = exports.DEV = exports.HOSTNAME = exports.NEXT_PUBLIC_LOCALE = void 0;
exports.NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE;
exports.HOSTNAME = process.env.NEXT_PUBLIC_PROJECT_HOSTNAME;
exports.DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development';
exports.STAGING = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
exports.PRO = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
exports.DY_SIGNUP_ALLOWED = process.env.DY_SIGNUP_ALLOWED === 'true';
exports.DY_LOGIN_ALLOWED = process.env.DY_LOGIN_ALLOWED === 'true';
exports.privatePaths = [
    '/welcome',
    '/shop',
    '/account',
    '/checkout',
    '/cart',
    '/shop/search'
];
const getPathnameType = (pathname) => {
    for (let path of exports.privatePaths) {
        if (pathname.startsWith(path))
            return 'private';
    }
    return 'public';
};
exports.getPathnameType = getPathnameType;
exports.hasPrivateStore = {
    'en-EU': false,
    'es-ES': false,
    'es-CT': true,
    'es-MD': false,
    'es-CN': false,
    'fr-FR': false,
    'en-GB': false,
    'pt-PT': false,
    'de-DE': false,
};
const TRANSLATIONS = {
    'es': 'Todos los productos de esta marca',
    'ca': 'Tots els productes d´aquesta marca',
    'en': 'All products of this brand',
    'fr': 'Tous les produits de cette marque',
    'pt': 'Todos os produtos desta marca',
    'de': 'Alle Produkte dieser Marke',
    'it': 'Tutti i prodotti di questo marchio' // Italian
};
const translations_info = {
    'es': 'Categorías de la marca:',
    'ca': 'Categories de la marca:',
    'en': 'Categories of the brand:',
    'fr': 'Catégories de la marque:',
    'pt': 'Categorias da marca:',
    'de': 'Kategorien der Marke:',
    'it': 'Categorie del marchio:'
};
const getAllCategories = (parentSlug, locale, categories) => {
    if (categories.length === 0)
        return;
    const firstCategory = {
        '__typename': 'Category',
        'name': `${TRANSLATIONS[locale]}`,
        'parentId': null,
        'slug': parentSlug,
        'isFirstCategory': true,
        'subcategories': []
    };
    const informativeText = {
        '__typename': 'Category',
        'name': `${translations_info[locale]}`,
        'parentId': null,
        'slug': parentSlug,
        'isInformativeText': true,
        'subcategories': []
    };
    if (categories[0].name !== TRANSLATIONS[locale])
        return [firstCategory, informativeText, ...categories];
    else
        return categories;
};
exports.getAllCategories = getAllCategories;
__exportStar(require("./strings"), exports);
__exportStar(require("./enums"), exports);
__exportStar(require("./dates"), exports);
__exportStar(require("./handyMethods"), exports);
__exportStar(require("./copy_clipboard"), exports);
__exportStar(require("./pdf_downloader"), exports);
__exportStar(require("./currency"), exports);
__exportStar(require("./cookies"), exports);
__exportStar(require("./analytics"), exports);
__exportStar(require("./newsletter"), exports);
__exportStar(require("./grid"), exports);
__exportStar(require("./locales"), exports);
__exportStar(require("./inputAutoComplete"), exports);
__exportStar(require("./inputValidations"), exports);
__exportStar(require("./inputPatterns"), exports);
__exportStar(require("./inputPostalCodeValidations"), exports);
__exportStar(require("./login"), exports);
__exportStar(require("./icons"), exports);
