import { memo, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { getFilters, getFilteredProducts, getFeaturedFilters, generateQueryParams, getFiltersFromQuery } from '@dy/commons/utils'
import { useScrollTracker, useMount } from '@dy/commons/hooks'
import { SectionFilters, SectionProductList, NavShop, DynamicSectionFiltersFeatured } from '../shop'
import { cookie, getLocaleCountry } from '@dy/commons/utils'
import styled from 'styled-components'
import { mq, designGrid } from '@dy/commons/styles'
import type { TypeMarqueeProps } from '@dy/commons/components'

const DynamicMarquee = dynamic<TypeMarqueeProps>(() => import('@dy/commons/components').then((mod) => mod.Marquee), { ssr: false })


interface ISectionShop {
  data: any,
  pageCategory?: string
}
let firstLoad = true

const updateQueryParams = ({ query, activeFilters, push }) => {
  const slug = typeof query.slug === 'string' ? query.slug : query.slug?.join('/')
  const { stringParams} = generateQueryParams(activeFilters)
  if(query.category) {
    push('/page/[slug]/category/[...category]', `/page/${slug}/category/${query?.category.join('/')}?${stringParams}`, { shallow: true })
  } else {
    push('/page/[...slug]', `/page/${slug}?${stringParams}`, { shallow: true })
  }

}

export const SectionShop = memo(({ data, pageCategory = '' }:ISectionShop) => {
  const { categories, variants } = data
  const { locale:localeRouter, asPath, query, push, isFallback } = useRouter()
  const localeRegion = getLocaleCountry(localeRouter)

  const isMounted = useMount()
  const [activeFilters, setActiveFilters] = useState({ sort: {recommended: true} })
  const [featuredFilters, setFeaturedFilters] = useState([])
  const [currentProducts, setCurrentProducts] = useState({
    all: Array.from(variants),
    current: Array.from(variants)
  })

  const scrollState = useScrollTracker(160)
  const isScrolling = useMemo(() => scrollState !== 'TOP', [scrollState])
  const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
  const isPrivate = useMemo(() => DYCustomerToken ? true : false, [DYCustomerToken] )
  const filters = useMemo(() => getFilters(variants, localeRegion, isPrivate), [variants, localeRegion, isPrivate])

  useEffect(() => {
    if (window.history.scrollRestoration !== 'manual') {
      window.history.scrollRestoration = 'manual'
    }

    return () => {
      window.history.scrollRestoration = 'auto'
    }
  }, [])

  useEffect(() => {
    if(isMounted) {
      if(firstLoad) { firstLoad = false }
      else {
        updateQueryParams({ query, activeFilters, push })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, activeFilters])

  useEffect(() => {
    // @ts-ignore
    if(!firstLoad) {
      setCurrentProducts({
        all: variants,
        current: getFilteredProducts(variants, activeFilters)
      })
      setFeaturedFilters(getFeaturedFilters(activeFilters, filters?.[0]))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, activeFilters])

  // Get param filters from URL
  useEffect(() => {
    if(filters) {
      const incomingActiveFiltersFromUrl = getFiltersFromQuery(asPath, filters[1])
      //@ts-ignore
      setActiveFilters(incomingActiveFiltersFromUrl)
    }
  }, [filters, asPath])

  if(isFallback) return <></>

  return (
    <>
      <DynamicMarquee data={'Productos destacados'} />
      <ShopSection>
        <span id='main-top'></span>
        <NavShop categories={categories} pageCategory={pageCategory}/>
        <SectionFilters isScrolling={isScrolling} filters={filters?.[0]} activeFilters={activeFilters} setActiveFilters={setActiveFilters} resultsLength={currentProducts?.current?.length} />
        <DynamicSectionFiltersFeatured filters={featuredFilters} />
        <SectionProductList key={`list-products-${asPath}`} products={currentProducts.current} usualPurchases={true} ga_list={{ id: 'list_grid_user_usual_purchases', name: 'List user Usual Purchases (grid)' }} categories={categories} />
      </ShopSection>
    </>
  )
})


const ShopSection = styled.section<any>`
  ${designGrid({ withoutGutter: true })}
  grid-column: 1 / span 6;
  padding: 0;
  position: relative;
  z-index:1;

  #main-top {
    display: block;
    height: 2px;
    opacity: .01;
    position: absolute;
    top: 0;
    transform: translateY(-80px);
    user-select: none;
    width: 100%;
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
  }
`
