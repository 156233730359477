"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.products = void 0;
/* eslint-disable quotes */
const _1 = require("./");
const products = ({ data, region, locale }) => {
    const items = data.variants;
    const productsPage = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            items.map((productItem, idx) => ({
                "@type": "ListItem",
                "position": `${idx}`,
                "item": (0, _1.productGrid)({ data: productItem, region, locale })
            }))
        ]
    };
    return JSON.stringify(productsPage);
};
exports.products = products;
