import { memo, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { mq, vw, designGrid } from '@dy/commons/styles'
import { Layout, DetailHeader, SectionNutritionChart, SectionIngredients, SectionMoreInfo, NavShop } from '@/components'
import type { TypeProductPage } from '@dy/commons/schema'
import { HeadTag } from '@dy/commons/components'
import { getLang, NEXT_PUBLIC_LOCALE, pushAnalyticsEvent } from '@dy/commons/utils'
import { initializeApollo, GET_PRODUCT_CMS, GET_PRODUCTS_STATIC_PATHS, GET_CATEGORIES, GET_LAYOUT } from '@/api'
import { useLazyQuery } from '@apollo/client'
import { CATEGORIES, BANNER } from '@dy/commons/utils'

const DynamicSectionRelated = dynamic(() => import('@/components').then(mod => mod.SectionRelated))

export async function getStaticPaths() {
  if (NEXT_PUBLIC_LOCALE === 'en-EU') return { paths: [], fallback: 'blocking' }

  const apolloClient = initializeApollo()
  const { data:resData } = await apolloClient.query({
    query: GET_PRODUCTS_STATIC_PATHS
  })
  if (!resData) return { paths: [], fallback: true }

  const paths = []
  // for(let locale of locales) {
  //   if (locale === 'en-EU') continue
  //   if (locale === 'es-MD' || locale === 'es-CT' || locale === 'ca-CT' || locale === 'es-CN') continue
  //   const lang = locale.split('-')[0]

  //   for(let product of resData.products) {
  //     const slug = product?.slug?.[lang]
  //     const params = { slug, locale }
  //     if(slug) paths.push({ params })
  //   }
  // }
  return { paths, fallback: 'blocking' }
}

export async function getStaticProps({ params }) {
  const { slug } = params
  const apolloClient = initializeApollo()
  const { data:resData } = await apolloClient.query({
    query: GET_PRODUCT_CMS,
    variables:{
      slug
    }
  })

  const { data:resLayout } = await apolloClient.query({
    query: GET_LAYOUT,
    variables: {
      bannerType: BANNER.SLIM,
      type: CATEGORIES.PRODUCT
    }
  })

  let modals
  if(resLayout?.modals) modals = resLayout?.modals?.edges?.map(modal => modal.node)
  if (!resData || !resData?.product || !resLayout ) return { notFound: true }

  return {
    props: {
      data: resData.product,
      layout: {...resLayout, modals},
      initialApolloState: apolloClient.cache.extract()
    },
    revalidate: 300
  }
}
const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children

export const getVariantByQueryParam = (data, variantName) => { 
  const variant = data?.variants?.find(item => item.name === variantName)
  return variant ? variant : data?.variants?.[0] ?? null
}

const CatalogProductPage: NextPage<TypeProductPage> & { Layout?:FC } = memo(({ data, variant = null }) => {
  const { locale:localeQuery, pathname, isFallback, query } = useRouter()
  const locale = getLang(localeQuery)
  const isPDP = pathname === '/catalog/product/[slug]'
  const round = false
  const [productData, setProductData] = useState(data)
  const [getDataForProducts, {}] = useLazyQuery(GET_PRODUCT_CMS)
  const [getDataForCategories, {}] = useLazyQuery(GET_CATEGORIES)
  const [getDataForRelateds, {}] = useLazyQuery(GET_PRODUCT_CMS)

  const [currentVariant, setCurrentVariant] = useState(isPDP ? query?.v ? getVariantByQueryParam(data, query.v): data?.variants?.[0] : null)
  const [relateds, setRelateds] = useState([])
  const [categoriesMenu, setCategoriesMenu] = useState([])
  const { id, bigcommerceId, name, brand, slug, filters, description, nutrition, ingredients, infoSpecifications, variants, labels, seo, sku, categories } = productData || {}

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const header = useMemo(() => ({ id, bigcommerceId, name, brand, filters, description, variants, sku, slug, categories }), [productData])

  // useEffect for PDP nested in the grid page
  useEffect(() => {
    async function getProductData() {
      const { data } = await getDataForProducts({ variables: { slug: variant?.slug } })

      if(data?.product) {
        setProductData(data.product)
        pushAnalyticsEvent('view_item', { ...data.product, variant, isPDP: true })
      }
    }

    if(variant) getProductData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant])
  // updating currentVariant
  useEffect(() => {
    if (productData) {
      setCurrentVariant(variant ? variants.filter((variantItem => variantItem.bigcommerceId === variant.variant_id))[0] : query?.v ? getVariantByQueryParam(data, query.v) : variants[0])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, query.v])

  useEffect(() => {
    async function getCategoriesMenu() {
      const { data } = await getDataForCategories({ variables: { type: CATEGORIES.PRODUCT } })

      if(data) setCategoriesMenu(data?.categories)
    }
    getCategoriesMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getRelateds() {
      const { data } = await getDataForRelateds({ variables: { slug: productData?.slug[locale] } })
      if(data) setRelateds(data?.product?.related)
    }

    if(productData) getRelateds()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData])

  if(isFallback) return <></>
  if(!data && isPDP || !data && !variant && !isPDP) return null
  if(!productData || !currentVariant) return null

  return (
    <>
      {data && <HeadTag seo={seo} jsonld={{ data:data, pageType: 'product' }} slug={slug['es']} pageType='product' />}
      <ConditionalWrapper condition={isPDP} wrapper={children =>
        <Main>
          <span id='main-top'></span>
          <NavShop categories={categoriesMenu} />
          {children}
        </Main>}>
        <ProductWrapper {...(slug && { id: typeof slug === 'string' ? slug : slug['es'] })} isPack={false} isPDP={isPDP}>
          <DetailHeader data={header} labels={labels} currentVariant={currentVariant} setCurrentVariant={setCurrentVariant} isPack={false} round={round} isPrivate={false} />
          <ConditionalWrapper condition={!isPDP} wrapper={children =>
            <main>
              {children}
            </main>
          }>
            {nutrition?.length > 0 && <SectionNutritionChart data={nutrition} />}
            {ingredients?.length > 0 && <SectionIngredients data={ingredients} />}
            {infoSpecifications?.length > 0  && <SectionMoreInfo data={infoSpecifications} />}
            {relateds?.length > 0 && <DynamicSectionRelated data={relateds} gaList={{
              id: 'list_relateds',
              name: 'List relateds (slider) [public]'
            }}/>}
          </ConditionalWrapper>
        </ProductWrapper>
      </ConditionalWrapper>
    </>
  )
})

CatalogProductPage.Layout = Layout
export default CatalogProductPage


const ProductWrapper = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.white};
  grid-column: unset;
  grid-row: unset;
  margin: ${({ isPDP }) => isPDP ? '60px 20px 200px' : '60px auto 200px'};
  padding: ${vw(20, 'mobile')};
  width: 90vw;

  ${mq.greaterThan('nexus7')} {
    grid-column: 1 /span 6;
    padding: ${vw(20, 'nexus7')};
    margin: ${({ isPDP }) => isPDP ? `${vw(70, 'nexus7')} auto 200px` : `${vw(70, 'nexus7')} auto 200px`};

  }

  ${mq.greaterThan('tablet')} {
    border-radius: 20px;
    grid-column: 5 / span 9;
    grid-row: 1 / span 4;
    margin: ${({ isPDP }) => isPDP ? `${vw(120, 'desktop')} 0 200px` : `${vw(120, 'desktop')} auto 200px`};
    padding: ${vw(30, 'desktop')};
    width: ${vw(580, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: ${({ isPDP }) => isPDP ? '120px 80px 200px' : '120px auto 200px'};
    padding: 30px;
    width: 580px;
  }
`

const Main = styled.main`
  ${designGrid({withoutGutter:true})}
  flex-grow: 2;
  padding: 0;
  position: relative;

  ${mq.greaterThan('desktop-wide')} {
    margin: 0 auto;
    max-width: 1650px;
  }

  #main-top {
    background-color: ${({ theme }) => theme.colors.background};
    display: block;
    height: 2px;
    opacity: .01;
    position: absolute;
    top: 120px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      transform: translateY(-${vw(20, 'desktop')});
    }

    ${mq.greaterThan('desktop')} {
      transform: translateY(-20px);
    }
  }

  section:not( div > section) {
    margin: unset;
    ${mq.greaterThan('tablet')} {
      margin: ${vw(120, 'desktop')} 0;
    }

    ${mq.greaterThan('desktop')} {
      margin: 120px 0;
    }
  }
`
