import {  memo, useEffect, useMemo, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import { getLang, currencyFormatter } from '@dy/commons/utils'
import { useScrollTracker, useCart } from '@dy/commons/hooks'
import { useTranslator } from '@/hooks'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, hex2Rgba } from '@dy/commons/styles'
import { ModalSummary } from '.'
import { CartItem } from './CartItem'
import { itemVariants, listVariants } from './cartVariants'
import { NewCartItemsContext } from '../cart/AddToCart'

interface ICartItemsNew {
  products: any,
  hasBanners: boolean
}

const getProducts = (products) => products.map((product, idx) => (
  <CartItem key={`cartitem-${idx}`} product={product} id={`cartitem-${idx}`} type={'new'} />
))


export const CartItemsNew = memo<ICartItemsNew>(({ products, hasBanners }) => {
  const scrollState = useScrollTracker(1)
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const Products = useMemo(() => getProducts(products.slice(0,5)), [products])
  const { setCartItems } = useContext(NewCartItemsContext) || {}
  const [clickTimestamp, setClickTimestamp] = useState(0)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const { subtotalExTax } = useCart()
  useEffect(()=> {
    if(products.length > 0) {
      const now = Date.now()
      if (timeoutId && (now - clickTimestamp < 900)) clearTimeout(timeoutId)

      const newTimeoutId = setTimeout(async () => {
        setCartItems([])
      }, 2000)

      setTimeoutId(newTimeoutId)
      setClickTimestamp(now)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length])

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [timeoutId])

  if(!Products) return null
  return (
    <AnimatePresence mode={'wait'}>
      {products.length > 0 && (
        <ModalNewList key='itemsincart' variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'} $hasBanners={hasBanners} $scrollTop={scrollState === 'TOP'}>
          {Products}
          <ModalSummary key={'itemincart-dt'} variants={itemVariants} $type={'new'}>
            <span>{t('commons.cart.total')} ({products.length})</span>
            <span>{ typeof subtotalExTax === 'number' && currencyFormatter({ price: subtotalExTax, localeRegion: locale, round: false, trailingZeros: true })}</span>
          </ModalSummary>
        </ModalNewList>
      )}
    </AnimatePresence>
  )
})


const ModalNewList = styled(motion.dl)<{ $scrollTop: boolean, $hasBanners: boolean }>`
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: flex-start;
  pointer-events: none;
  position: fixed;
  right: ${vw(5, 'mobile')};
  top: 70px;
  transition: 300ms top ease-out;
  user-select: none;
  width: ${vw(300, 'mobile')};
  z-index: 5000;


  ${mq.greaterThan('nexus7')} {
    right: ${vw(5, 'nexus7')};
    width: ${vw(300, 'nexus7')};
  }


  ${mq.greaterThan('tablet')} {
    display: flex;
    right: ${vw(15, 'desktop')};
    top: ${({$scrollTop,  $hasBanners}) => vw($scrollTop ? $hasBanners ? 110 : 95 : 70, 'desktop')};
    width: ${vw(308, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    right:15px;
    top: ${({$scrollTop,  $hasBanners}) => $scrollTop ? $hasBanners ? '110px': '95px': '70px'};
    width:308px;

  }

  ${mq.greaterThan('desktop-wide')} {
    right: calc((100% - 1575px) / 2);
  }


  > dt,
  > dd {
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
  }
`
