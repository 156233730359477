import { memo } from 'react'
import { useTranslator } from '@/hooks'
import styled from 'styled-components'
import { mq, vw, createUnderline, getP53, getP26_1, getP20_Mobile } from '@dy/commons/styles'
import { NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'

export const CallUs = memo(() => {
  const { t } = useTranslator()

  return (
    <Div>
      <div>{t('layout.footer.questions')} <br /><a href={NEXT_PUBLIC_LOCALE === 'fr-FR' ? `${t('layout.footer.email')}` :`mailto:${t('layout.footer.email')}`}>{t('layout.footer.write_us')}</a> {t('layout.footer.call_us')}<br /></div>
      <a href={`tel:+${t('layout.footer.phone_link')}`} className='phone'>{t('layout.footer.phone_number')}</a>
    </Div>
  )
})


const Div = styled.div`
  color: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.tertiaryFont};
  ${getP20_Mobile()}
  grid-column: 1 / span 4;
  letter-spacing: -.23px;
  margin-bottom: ${vw(50, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    grid-column: 1 / span 6;
    margin-bottom: ${vw(50, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    ${getP26_1()}
    grid-column: 9 / span 4;
    letter-spacing: -.3px;
    margin-bottom: ${vw(60, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 60px;
  }

  a {
    ${createUnderline({ color: 'white', reverse: true })}

    &.phone {
      ${createUnderline({ color: 'creme', height: 3 })}
    }
  }

  .phone {
    ${getP53()}
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    display: inline-block;
    margin-top: ${vw(10, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 15px;
    }
  }
`
