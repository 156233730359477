'use client'
import type { FC } from 'react'
import { useEffect, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_CUSTOMERS_BY_VAT_NUMBER, CUSTOMER_EMAIL_INVITE } from '@/api'
import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { MODALS } from '@dy/commons/context'
import { useTranslator } from '@/hooks'
import { useModal } from '@dy/commons/hooks'
import { getLocaleCountry, getStoreByLocale, validate, getDynamicText } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'

type Props = {
  updateLevel?: (level: TypeSectionAuthLevel, extraPayload?: {[key:string]: any }, close?: boolean) => void
  animation?: any,
  payload?: { level: string, [key: string]: any },
  bgColor?: TypeThemeColors
}

type TypeFormInputs = {
  vatNumber: string,
}

export const getLegend = (locale, t, toggleModalRegion) => {
  return <>
    {getDynamicText(t('modals.auth.signup_cif.text') as string, {
      storeName:getStoreByLocale[locale]
    })} <button onClick={toggleModalRegion}>{t('modals.auth.signup_cif.text_action') as string}</button>.
  </>
}

export const FormUserSignUpCIF:FC<Props> = memo(({ updateLevel, animation }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, formState: { errors:formErrors } } = useForm<TypeFormInputs>()
  const { locale } = useRouter()
  const countryCode = useMemo(() => getLocaleCountry(locale).split('-')[1], [locale])
  const { toggle:toggleModalRegion } = useModal(MODALS.REGION_LANG)
  const legend = useMemo(() => getLegend(locale, t, toggleModalRegion), [locale, t, toggleModalRegion])


  const [customers, { loading }] = useLazyQuery(GET_CUSTOMERS_BY_VAT_NUMBER)

  const [customerEmailInvite] = useMutation(CUSTOMER_EMAIL_INVITE, { errorPolicy: 'all' })

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData, e) => {
    e.preventDefault()

    try {
      const { data } = await customers({
        variables: {
          vatNumber: formData.vatNumber
        },
        context: {
          isPrivatePath: true
        }
      })

      const customer = data?.customers?.edges?.[0]
      if (!customer) {
        updateLevel('signup', { vatNumber: formData.vatNumber })
      } else {
        // If customer
        const onboardingStatus: 'active' | 'inactive' = customer.onboardingStatus === 'invited' ? 'inactive' : customer.onboardingStatus

        if (onboardingStatus === 'inactive') {

          const { data: resData } = await customerEmailInvite({
            variables: {
              vatNumber: formData.vatNumber
            },
            context: { isPrivatePath: true }
          })

          const res = resData?.customerEmailInvite
          if (res?.ok) {
            updateLevel('feedback_email_invite', {
              vatNumber: formData.vatNumber,
              emailObfuscated: customer.emailObfuscated
            })
          }
        } else {
          updateLevel('feedback_cif_already_active', { emailObfuscated: customer.emailObfuscated })
        }
      }
    } catch(e) {
      console.error('customerByVatNumber failed:', e)
    }
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  useEffect(() => {
    if(Object.keys(formErrors).length > 0) console.log('customerByVatNumber formErrors:', formErrors)
  }, [formErrors])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={legend}>
        <Input key='vatNumber' name='vatNumber' register={register} label={t('form.fields.cif') as string} required={{
          required: true,
          // cif validation only available for Spain by now
          ...countryCode === 'ES' && {
            validate: (value) => validate.cif(value) || (t('form.errors.validate_cif') as string)
          }
        }} error={formErrors.vatNumber} bgColor={'white'} />
      </Fieldset>
      <Button type='submit' bgType='red'>{t('commons.actions.continue')}</Button>
      <p className='subtext'>{t('modals.auth.signup_cif.subtext')} <button onClick={() => { updateLevel('login')}}>{t('modals.auth.signup_cif.subtext_action')}</button>.</p>
    </Form>
  )
})

