'use client'
import type { FC } from 'react'
import { memo} from 'react'
import { useTranslator } from '@/hooks'
import { Form, Fieldset } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload?: { level: string, [key: string]: any }
  animation?: any
}

export const FeedbackCIFNotFound:FC<Props> = memo(({ updateLevel, payload }) => {
  const { t } = useTranslator()

  return (
    <Form>
      <Fieldset legend={`${t('modals.auth.feedback_cif_not_found.text')} ${payload.vatNumber} ${t('modals.auth.feedback_cif_not_found.text_2')}` as string}>
        <p key='p-light' className='light'>{t('modals.auth.feedback_cif_not_found.text2')} <a href={`mailto:${t('modals.auth.feedback_cif_not_found.text2_actions.email') as string}`}>{t('modals.auth.feedback_cif_not_found.text2_actions.email')}</a> / {t('modals.auth.feedback_cif_not_found.text2_actions.phone')}.</p>
        <p key='p-light-2' className='light'>{t('modals.auth.feedback_cif_not_found.text3')} <button type='button' onClick={() => { updateLevel('signup', { vatNumber: payload.vatNumber}) }}>{t('modals.auth.feedback_cif_not_found.text3_action')}</button>.</p>
      </Fieldset>
    </Form>
  )
})
