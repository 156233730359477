"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Price = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const router_1 = require("next/router");
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../styles");
const utils_1 = require("../utils");
const TRANSLATIONS = {
    'en': 'VAT',
    'es': 'IVA',
    'fr': 'TVA',
    'de': 'MwSt.',
    'pt': 'IVA'
};
const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;
exports.Price = (0, react_1.memo)(({ type = 'card', price, className = '', withTax = false, displayRetailPrice = true, pricePer, round = true, stockWarning, stock, lastUnits, minSaleUnits }) => {
    const { locale: localeRouter } = (0, router_1.useRouter)();
    const localeRegion = (0, utils_1.getLocaleCountry)(localeRouter);
    const LANG = (0, utils_1.getLang)(localeRouter);
    if (!price)
        return;
    const retailPrice = typeof price.retailPrice === 'object' ? price === null || price === void 0 ? void 0 : price.retailPrice : { exTax: price === null || price === void 0 ? void 0 : price.retailPrice, incTax: null };
    const salePrice = typeof price.salePrice === 'object' ? price.salePrice : { exTax: price.salePrice, incTax: null };
    const hasDiscount = retailPrice.exTax > salePrice.exTax || retailPrice.incTax > salePrice.incTax;
    const displayStockWarning = stock >= minSaleUnits && stockWarning;
    const retailPriceCurrency = (0, utils_1.currencyFormatter)({ price: retailPrice[withTax ? 'incTax' : 'exTax'], localeRegion, round });
    const salePriceCurrency = (0, utils_1.currencyFormatter)({ price: salePrice[withTax ? 'incTax' : 'exTax'], localeRegion, round });
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(Div, Object.assign({ "$type": type, "$hasDiscount": hasDiscount, className: className }, { children: (0, jsx_runtime_1.jsxs)(ConditionalWrapper, Object.assign({ condition: (displayStockWarning && !hasDiscount), wrapper: children => (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: 'last_units' }, { children: lastUnits })), children] }) }, { children: [(0, jsx_runtime_1.jsxs)("span", Object.assign({ className: 'sale' }, { children: [salePriceCurrency, pricePer && (0, jsx_runtime_1.jsx)("span", { children: pricePer })] })), (displayRetailPrice && hasDiscount) &&
                        (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'flex' }, { children: [(0, jsx_runtime_1.jsxs)("span", Object.assign({ className: 'retail' }, { children: [retailPriceCurrency, pricePer && (0, jsx_runtime_1.jsx)("span", { children: pricePer })] })), type === 'pdp' && (0, jsx_runtime_1.jsxs)("span", Object.assign({ className: 'plus-iva' }, { children: ["+", TRANSLATIONS[LANG]] })), displayStockWarning && lastUnits && (0, jsx_runtime_1.jsx)("p", Object.assign({ className: 'last_units discount' }, { children: lastUnits }))] }))] })) })) }));
});
const Div = styled_components_1.default.div `
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: fit-content;
  justify-content: flex-start;
  padding: 0 ${(0, styles_1.vw)(20, 'mobile')} ${(0, styles_1.vw)(10, 'mobile')};

  ${styles_1.mq.greaterThan('nexus7')} {
    padding: 0 ${(0, styles_1.vw)(10, 'nexus7')} ${(0, styles_1.vw)(14, 'nexus7')};
  }

  ${styles_1.mq.greaterThan('tablet')} {
    padding: 0 ${(0, styles_1.vw)(15, 'desktop')} ${(0, styles_1.vw)(20, 'desktop')};
  }

  ${styles_1.mq.greaterThan('desktop')} {
    padding: 0 15px 20px;
  }

  p {
    ${(0, styles_1.getP12_3)()}
    color: ${({ theme }) => theme.colors.red};

    &.discount {
      margin-left: 5px;
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
  }

  /* SHARED FOR ALL THE TYPES using card as default */
  span {
    color: ${({ theme }) => theme.colors.darkred};
  }

  /* BOTH PRICES STYLED HERE */
  /* Sale Price */
  /* &:first-child {} */

  /* Retail Price */
  .retail {
    display: inline-block;
    height: fit-content;
    opacity: .3;
    font-size: 11px;
    position: relative;
    width: fit-content;

    ${styles_1.mq.greaterThan('tablet')} {
      ${(0, styles_1.getP12_3)()}
    }

    ::after {
      content: '';
      background-color: ${({ theme }) => theme.colors.darkred};
      display: block;
      height: ${(0, styles_1.vw)(1, 'desktop')};
      min-height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      ${styles_1.mq.greaterThan('nexus7')} {
        height: ${(0, styles_1.vw)(1, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        height: ${(0, styles_1.vw)(1, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        height: 1px;
      }
    }
  }

  /* SPECIFIC FOR TYPES */
  ${({ $type }) => $type === 'card' && (0, styled_components_1.css) `
    align-items: flex-start;
    color: ${({ theme }) => theme.colors.darkred};
    flex-direction: column;

    span:first-of-type:not(.flex > span) {
      ${(0, styles_1.getP26_3)()}
      margin-top: ${(0, styles_1.vw)(4, 'desktop')};
      order: 1;

      ${styles_1.mq.greaterThan('nexus7')} {
        margin-top: ${(0, styles_1.vw)(4, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        margin-top: ${(0, styles_1.vw)(6, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        margin-top: 6px;
      }

      span {
        ${(0, styles_1.getP15_3)()}
      }
    }

    .flex span:first-of-type {
      ${(0, styles_1.getP15_3)()}

      span {
        ${(0, styles_1.getP12)()}
      }
    }

  `}

  ${({ $type }) => $type === 'pdp' && (0, styled_components_1.css) `
    color: ${({ theme }) => theme.colors.darkred};
    flex-direction: row;
    padding: 0 0 ${(0, styles_1.vw)(14, 'mobile')} 0;

    ${styles_1.mq.greaterThan('nexus7')} {
      padding: 0 0 ${(0, styles_1.vw)(14, 'nexus7')} 0;
    }

    ${styles_1.mq.greaterThan('tablet')} {
      padding: 0 0 ${(0, styles_1.vw)(20, 'desktop')} 0;
    }

    ${styles_1.mq.greaterThan('desktop')} {
      padding: 0 0 20px 0;
    }

    > span {
      &:first-child {
        ${(0, styles_1.getP53_2)()}
        margin-right: ${(0, styles_1.vw)(4, 'desktop')};

        ${styles_1.mq.greaterThan('nexus7')} {
          margin-right: ${(0, styles_1.vw)(4, 'nexus7')};
        }

        ${styles_1.mq.greaterThan('tablet')} {
          margin-right: ${(0, styles_1.vw)(6, 'desktop')};
        }

        ${styles_1.mq.greaterThan('desktop')} {
          margin-right: 6px;
        }

        span {
          ${(0, styles_1.getP30_2)()}
        }
      }

      &:nth-child(2) {
        ${(0, styles_1.getP26_3)()}

        span {
          ${(0, styles_1.getP15_3)()}
        }
      }
    }

    .plus-iva {
      ${(0, styles_1.getP13_3)()}
      font-family: ${({ theme }) => theme.fonts.platform};
      font-weight: 400;
      margin-left: ${(0, styles_1.vw)(6, 'desktop')};
      opacity: .4;

      ${styles_1.mq.greaterThan('nexus7')} {
        margin-left: ${(0, styles_1.vw)(6, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        margin-left: ${(0, styles_1.vw)(7, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        margin-left: 7px;
      }
    }
  `}

  ${({ $type }) => $type === 'cart' && (0, styled_components_1.css) `
    align-items: flex-end;
    flex-direction: column;

    > span {
      &:first-child {
        ${(0, styles_1.getP26_3)()}
        color: ${({ theme }) => theme.colors.darkcreme};
        margin-bottom: ${(0, styles_1.vw)(4, 'mobile')};

        ${styles_1.mq.greaterThan('nexus7')} {
          margin-bottom: ${(0, styles_1.vw)(4, 'nexus7')};
        }

        ${styles_1.mq.greaterThan('tablet')} {
          margin-bottom: ${(0, styles_1.vw)(6, 'desktop')};
        }

        ${styles_1.mq.greaterThan('desktop')} {
          margin-bottom: 6px;
        }
      }
    }

    div:last-child {
      color: ${({ theme }) => theme.colors.darkred};
      span {
        ${(0, styles_1.getP15_3)()}
      }
    }
  `}
`;
