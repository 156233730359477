import { memo, useCallback, useMemo } from 'react'
import type { FC } from 'react'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { DY_SIGNUP_ALLOWED } from '@dy/commons/utils'
import { FormUserInitial, FormUserPreLogin, FormUserLogIn, FormUserCheckCIF, FormUserSignUpCIF, FormUserSignUp, FormUserForgotPassword, FormUserResetPassword, FeedbackEmailInvite, FeedbackCIFNotFound, FeedbackCIFAlreadyActive, FeedbackAccountActivated, FeedbackPasswordUpdated, FeedbackEmailResetPassword, FormUserActivateAccount } from '@/components'

type Props = {
  payload: any,
  updatePayload: any
}

const Section = styled.section`
`

const formByLevel = {
  'start': FormUserInitial,
  'signup_cif': FormUserSignUpCIF,
  'signup': FormUserSignUp,
  'prelogin': FormUserPreLogin,
  'login_check_cif': FormUserCheckCIF, // but REVIEW with network.result ->refactor
  'login': FormUserLogIn,
  'forgot_password': FormUserForgotPassword,
  'reset_password': FormUserResetPassword,
  'activation': FormUserActivateAccount,
  'feedback_account_active': FeedbackAccountActivated,
  'feedback_cif_already_active': FeedbackCIFAlreadyActive,
  'feedback_cif_not_found': FeedbackCIFNotFound,
  'feedback_email_invite': FeedbackEmailInvite,
  'feedback_email_reset_password': FeedbackEmailResetPassword,
  'feedback_password_updated': FeedbackPasswordUpdated
}

export const defaultAuthModalLevel = DY_SIGNUP_ALLOWED ? 'start' : 'login'
export type TypeSectionAuthLevel = keyof typeof formByLevel

const stepVariants = {
  initial: {
    opacity: 0,
    x: -250,
    transition: {
      duration: 0.3
    }
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3
    }
  }
}

const animation = {
  initial: 'initial',
  animate: 'animate',
  exit: 'initial',
  variants: stepVariants
}

export const SectionUserAuth: FC<Props> = memo(({ payload, updatePayload }) => {
  const CurrentForm = useMemo(() => formByLevel[payload?.level ?? defaultAuthModalLevel], [payload])

  const updateLevel = useCallback((newLevel, extraPayload = {}, close = false) => {
    const payload = { level: newLevel, ...extraPayload && extraPayload }
    updatePayload({ payload, close })
  }, [updatePayload])

  return (
    <Section>
      <AnimatePresence>
        <CurrentForm key={`form--${payload?.level}`} animation={animation} updateLevel={updateLevel} payload={payload} />
      </AnimatePresence>
    </Section>
  )
})
