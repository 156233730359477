import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useState } from 'react'
import { TypeVariant } from '@dy/commons/schema'

// type Filter = {
//   param: string
//   values: string[]
// }

type TypeGridVariantsContext = {
  allProducts: TypeVariant[],
  setAllProducts: Dispatch<SetStateAction<TypeVariant[]>>
  // rawProducts: TypeVariant[]
  // filters: Record<string, string[]>
  // activeFilters: Record<string, string[]>
  // filteredProducts: TypeVariant[]
  // setFilters: (filters: Filter[]) => void
}

export const GridVariantsContext = createContext<TypeGridVariantsContext | undefined>(undefined)

interface Props {
  children: ReactNode
}

// type ProductsAction = { type: 'SET_PRODUCTS', payload: TypeVariant[] }
// type FiltersAction = { type: 'SET_FILTERS', payload: Record<string, string[]> }

// const filteredProductsReducer = (state: TypeVariant[], action: ProductsAction) => {
//   switch (action.type) {
//     case 'SET_PRODUCTS':
//       return action.payload
//     default:
//       return state
//   }
// }

// const activeFiltersReducer = (state: Record<string, string[]>, action: FiltersAction) => {
//   switch (action.type) {
//     case 'SET_FILTERS':
//       return action.payload
//     default:
//       return state
//   }
// }

export const GridVariantsProvider: FC<Props> = ({ children }) => {
  const [allProducts, setAllProducts] = useState<TypeVariant[]>([])
  // const [rawProducts, setRawProducts] = useState<TypeVariant[]>([])
  // const [filters, setFilters] = useState<Record<string, string[]>>({})
  // const [activeFilters, filtersDispatch] = useReducer(activeFiltersReducer, {})
  // const [filteredProducts, filteredProductsDispatch] = useReducer(filteredProductsReducer, [])

  // rawProducts,
  // filters,
  // activeFilters,
  // filteredProducts,
  // setFilters: (newFilters) => {
  //   // Logic to set filters and update activeFilters and filteredProducts
  // }

  return <GridVariantsContext.Provider value={{
    allProducts,
    setAllProducts
  }}>{children}</GridVariantsContext.Provider>
}
