'use client'
import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { Form, Fieldset, Input, Select } from '@dy/commons/components'
import { GET_POSTAL_CODES_VALID } from '@/api'
import { useTranslator } from '@/hooks'
import { getLocaleCountry, shippingPostalCodeValid, autoComplete, cookie, inputPattern } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'
import type { TypeAddress, TypeQueryValidPostalCodes, TypeProvince } from '@dy/commons/schema'

type Props = {
  animation?: any,
  bgColor?: TypeThemeColors,
  data: {
    billing: TypeAddress,
    shipping: TypeAddress,
  }
}

type TypeFormInputs = {
  billing: {
    firstName: string
    lastName: string
    countryCode: string
    provinceCode: string
    city: string
    streetLine: string
    streetLine2?: string
    postalCode: string
  }
  shipping: {
    firstName: string
    lastName: string
    countryCode: string
    provinceCode: string
    city: string
    streetLine: string
    streetLine2?: string
    postalCode: string
  }
}

export const FormCustomerUpdateAddresses:FC<Props> = memo(({ animation, data }) => {
  const { t } = useTranslator()
  const { locale } = useRouter()
  const countryCode = useMemo(() => getLocaleCountry(locale).split('-')[1], [locale])

  const { register, handleSubmit, formState: { errors: formErrors }, setValue, watch } = useForm<TypeFormInputs>()

  // @ts-ignore
  const shippingPostalCode = watch('shipping[postalCode]')

  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [validShippingPostalCode, setValidShippingPostalCode] = useState(false)

  console.log('validShippingPostalCode', validShippingPostalCode)
  const { data: resPostalCodes } = useQuery<TypeQueryValidPostalCodes>(GET_POSTAL_CODES_VALID, {
    context: {
      isPrivatePath: true
    }
  })

  const onSubmit: SubmitHandler<TypeFormInputs> = async formData => {
    const { billing, shipping } = formData

    const billingAddress = {
      bigcommerceId: data.billing.bigcommerceId,
      firstName: billing.firstName,
      lastName: billing.lastName,
      street1: billing.streetLine,
      street2: billing.streetLine2,
      countryCode: billing.countryCode,
      region: provinces.find(item => item.value === billing.provinceCode)?.name,
      city: billing.city,
      postalCode: billing.postalCode
    }

    const shippingAddress = {
      bigcommerceId: data.shipping.bigcommerceId,
      firstName: shipping.firstName,
      lastName: shipping.lastName,
      street1: shipping.streetLine,
      street2: shipping.streetLine2,
      countryCode: shipping.countryCode,
      region: provinces.find(item => item.value === shipping.provinceCode)?.name,
      city: shipping.city,
      postalCode: shipping.postalCode
    }

    const addresses = [billingAddress, shippingAddress]

    try {
      const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
      const res = await fetch('/api/customer/update-addresses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ addresses, DYCustomerToken }),
      })


      if(res.ok) {
        const { data } = await res.json()
        console.log('@@@ customerUpdateAddresses onSubmit res data', data)
      }
    } catch(e) {
      console.error('Customer Addresses Update catch error:', e)
    }
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  useEffect(() => {
    async function getProvinces(countryCode) {
      let data = await import(`/public/locales/countries/${countryCode}-provinces.json`) as TypeProvince[]
      if (data) setProvinces(Array.from(data))
    }

    async function getCountries() {
      // @ts-ignore
      let data = await import('/public/locales/countries/ALL-countries.json')
      if (data) setCountries(Array.from(data))
    }

    if (countries.length === 0 && typeof locale === 'string') {
      getCountries()
    }

    if (provinces.length === 0 && typeof locale === 'string') {
      if (typeof countryCode === 'string' && countryCode !== 'EU') getProvinces(countryCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    if (typeof shippingPostalCode !== 'string') return

    const regex = new RegExp(inputPattern.postalCode[countryCode])
    const patternValid = regex.test(shippingPostalCode)

    if (patternValid) {
      const arr = resPostalCodes?.validPostalCodes ?? []
      setValidShippingPostalCode(shippingPostalCodeValid(arr, shippingPostalCode))
    }
  }, [resPostalCodes, shippingPostalCode, countryCode])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      {data.billing &&
        <Fieldset disabled={true} ariaBusy={false} legend={t('account.order.billing_address') as string}>
          <Input readOnly={true} name={'billing[firstName]'} defaultValue={data.billing.firstName} col={6} register={register} label={t('form.fields.first_name') as string} required error={formErrors.billing?.firstName} />
          <Input readOnly={true} name={'billing[lastName]'} defaultValue={data.billing.lastName} col={6} register={register} label={t('form.fields.last_name') as string} required error={formErrors.billing?.lastName} />
          <Select readOnly={true} name='billing[countryCode]' defaultValue={data.billing.countryCode} col={6} register={register} label={t('form.fields.country') as string} options={countries} setFormValue={setValue} />

          <Select readOnly={true} name='billing[provinceCode]' defaultValue={provinces.find(item => item.name == data.billing.region)?.value} col={6} register={register} label={t('form.fields.province') as string} options={provinces} setFormValue={setValue} />
          <Input readOnly={true} name='billing[city]' defaultValue={data.billing.city} col={12} register={register} label={t('form.fields.city') as string} autoComplete={autoComplete.address.cityOrTown} />
          <Input readOnly={true} name='billing[postalCode]' defaultValue={data.billing.postalCode} col={12} register={register} label={t('form.fields.postal_code') as string} required={{
            required: t('form.errors.empty') as string,
            pattern: inputPattern.postalCode[countryCode]
          }} error={formErrors.billing?.postalCode} autoComplete={autoComplete.address.postalCode} />
          <Input readOnly={true} name='billing[streetLine]' defaultValue={data.billing.street1} register={register} label={t('form.fields.street_line1') as string} required error={formErrors.billing?.streetLine} autoComplete={autoComplete.address.street} />
          <Input readOnly={true} name='billing[streetLine2]' defaultValue={data.billing.street2} col={6} register={register} label={t('form.fields.street_line2') as string} error={formErrors.billing?.streetLine2} autoComplete={autoComplete.address.street2} />
        </Fieldset>
      }
      {data.shipping &&
        <Fieldset disabled={true} ariaBusy={false} legend={t('account.order.shipping_address') as string}>
          <Input readOnly={true} name={'shipping[firstName]'} defaultValue={data.shipping.firstName} col={6} register={register} label={t('form.fields.first_name') as string} required error={formErrors.shipping?.firstName} />
          <Input readOnly={true} name={'shipping[lastName]'} defaultValue={data.shipping.lastName} col={6} register={register} label={t('form.fields.last_name') as string} required error={formErrors.shipping?.lastName} />
          <Select name='shipping[countryCode]' defaultValue={data.shipping.countryCode} col={6} register={register} label={t('form.fields.country') as string} options={countries} readOnly={true} setFormValue={setValue} />
          <Select readOnly={true} name='shipping[provinceCode]' defaultValue={provinces.find(item => item.name == data.shipping.region)?.value} col={6} register={register} label={t('form.fields.province') as string} options={provinces.filter(item => item.in === 'ES-CT')} setFormValue={setValue} />
          <Input readOnly={true} name='shipping[city]' defaultValue={data.shipping.city} col={12} register={register} label={t('form.fields.city') as string} autoComplete={autoComplete.address.cityOrTown} />
          <Input readOnly={true} name='shipping[postalCode]' defaultValue={data.shipping.postalCode} col={12} register={register} label={t('form.fields.postal_code') as string} required={{
            required: true,
            pattern: inputPattern.postalCode[countryCode]
          }} error={formErrors.shipping?.postalCode} autoComplete={autoComplete.address.postalCode} />
          <Input readOnly={true} name='shipping[streetLine]' defaultValue={data.shipping.street1} register={register} label={t('form.fields.street_line1') as string} required error={formErrors.shipping?.streetLine} autoComplete={autoComplete.address.street} />
          <Input readOnly={true} name='shipping[streetLine2]' defaultValue={data.shipping.street2} col={6} register={register} label={t('form.fields.street_line2') as string} error={formErrors.shipping?.streetLine2} autoComplete={autoComplete.address.street2} />
        </Fieldset>
      }
      {/* <Button className='srt-only' disabled={Object.keys(formErrors).length > 0 || !validShippingPostalCode} type='submit' bgType={'red'}>{t('commons.actions.save') as string}</Button> */}
    </Form>
  )
})

