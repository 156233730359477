import { memo } from 'react'
import styled from 'styled-components'
import { mq, vw, getP10 } from '@dy/commons/styles'
import { Figure } from '@dy/commons/components'

type ContentTypeImage = {
  col: { from: number, expand: number },
  row: { from: number, expand: number },
  medium: any,
  alt: string,
  caption?: string,
  type: 'image' | 'media',
  imageCaption?: string
  mediaSize?: 'small' | 'medium' | 'big'
}

interface IContentImage {
  data: ContentTypeImage
}

const Fig = styled(Figure)<any>`
  height: auto;
  width: ${({ $size }) => $size === 'small' ? `${vw(69 * 4, 'mobile')}` : $size === 'medium' ? `${vw(69 * 5, 'mobile')}` : '100%'};

  ${mq.greaterThan('tablet')} {
    width: ${({ $size }) => $size === 'small' ? `${vw(120 * 4, 'desktop')}` : $size === 'medium' ? `${vw(120 * 6, 'desktop')}` : '100%'};
  }

  ${mq.greaterThan('desktop')} {
    width: ${({ $size }) => $size === 'small' ? '480px' : $size === 'medium' ? '650px' : '100%'};
  }

  > span {
    position: relative !important;
    height: auto;
    width: 100%;
  }

  img {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
  }

  figcaption {
    color: ${({ theme }) => theme.colors.greendark};
    font-family: ${({ theme }) => theme.fonts.platform};
    font-style: normal;
    ${getP10()}
    margin-top: ${vw(10, 'mobile')};
    opacity: .5;
    text-align: center;
    text-transform: uppercase;

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(10, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 10px;
    }
  }
`

export const ContentImage = memo(({ data }: IContentImage) => {
  const { medium, mediaSize } = data

  return (
    <Fig media={medium} $size={mediaSize} />
  )
})
