"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSeo = exports.getDynamicText = exports.slugify = void 0;
const slugify = (text) => text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -
exports.slugify = slugify;
function getDynamicText(text, variables) {
    // Use a regular expression to find all placeholders between double curly braces
    const placeholderRegex = /\{\{([^}]+)\}\}/g;
    // Replace each placeholder with the corresponding value from the variables object
    const dynamicText = text.replace(placeholderRegex, (match, placeholder) => {
        // Trim the placeholder to remove any leading or trailing spaces
        const keyName = placeholder.trim();
        // Check if the keyName exists in the variables object
        if (variables.hasOwnProperty(keyName)) {
            // If the keyName exists, return its value
            return variables[keyName].toString();
        }
        else {
            // If the keyName does not exist, return the original placeholder
            console.warn(`keyName: ${keyName} doesn't exist for the text: ${text}`);
            return match;
        }
    });
    return dynamicText;
}
exports.getDynamicText = getDynamicText;
const getSeo = (name, locale) => {
    const seo = {
        'es': {
            title: `Mammafiore | Marca ${name}`,
            description: `Pagina de marca ${name}`
        },
        'en': {
            title: `Mammafiore | Brand ${name}`,
            description: `Brand Page ${name}`
        },
        'de': {
            title: `Mammafiore | Marke ${name}`,
            description: `Markenseite ${name}`
        },
        'ca': {
            title: `Mammafiore | Marca ${name}`,
            description: `Pàgina de marca ${name}`
        },
        'fr': {
            title: `Mammafiore | Marque ${name}`,
            description: `Page de marque ${name}`
        },
        'pt': {
            title: `Mammafiore | Marca ${name}`,
            description: `Página da marca ${name}`
        }
    };
    return seo[locale];
};
exports.getSeo = getSeo;
