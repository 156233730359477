"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.articles = void 0;
/* eslint-disable quotes */
const _1 = require(".");
const articles = ({ data, region = 'ESP', locale = 'en', hostname }) => {
    const items = data;
    const productsPage = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            items.map((item, idx) => ({
                "@type": "ListItem",
                "position": `${idx + 1}`,
                "item": (0, _1.article)({ data: item, region, locale, articlePage: false, hostname })
            }))
        ]
    };
    return JSON.stringify(productsPage);
};
exports.articles = articles;
