"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autoComplete = void 0;
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete
exports.autoComplete = {
    name: {
        prefix: 'honorific-prefix',
        fullname: 'name',
        firstName: 'given-name',
        secondName: 'additional-name',
        lastName: 'family-name',
        suffix: 'honorific-suffix',
        nickname: 'nickname',
        username: 'username'
    },
    password: {
        new: 'new-password',
        current: 'current-password'
    },
    address: {
        street: 'street-address',
        street1: 'address-line1',
        street2: 'address-line2',
        postalCode: 'postal-code',
        countryCode: 'country',
        countryName: 'country-name',
        provinceOrstate: 'address-level1',
        cityOrTown: 'address-level2',
        district: 'address-level3',
    },
    card: {
        fullName: 'cc-name',
        firstName: 'cc-given-name',
        secondName: 'cc-additional-name',
        lastName: 'cc-family-name',
        number: 'cc-number',
        expirationDate: 'cc-exp',
        expirationMonth: 'cc-exp-month',
        expirationYear: 'cc-exp-year',
        cvv: 'cc-csc',
        type: 'cc-type'
    },
    transaction: {
        currency: 'transaction-currency',
        amount: 'transaction-amount'
    },
    language: 'language',
    gender: 'sex',
    tel: {
        internationalNumber: 'tel',
        countryCodeNumber: 'tel-country-code',
        nationalNumber: 'tel-national',
        extension: 'tel-extension'
    },
    birthday: {
        date: 'bday',
        day: 'bday-day',
        month: 'bday-month',
        year: 'bday-year'
    },
    email: 'email',
    company: {
        name: 'organization',
        jobPosition: 'organization-title'
    }
};
