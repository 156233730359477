"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModal = void 0;
const react_1 = require("react");
const context_1 = require("../context");
const useModal = (id, openOnLoad = false) => {
    const { modals, setModal, someModalIsActive } = (0, react_1.useContext)(context_1.ModalsContext);
    const { isActive, payload } = (0, react_1.useMemo)(() => { var _a; return (_a = modals[id]) !== null && _a !== void 0 ? _a : { isActive: openOnLoad, payload: null }; }, [modals, id, openOnLoad]);
    const toggle = (0, react_1.useCallback)(() => {
        setModal({ [id]: { isActive: !isActive, payload } });
    }, [id, setModal, isActive, payload]);
    const updatePayload = (0, react_1.useCallback)(({ payload = null, close = false, open = false }) => {
        setModal({ [id]: { isActive: open ? open : close ? false : isActive, payload } });
    }, [id, setModal, isActive]);
    const handleEsc = (0, react_1.useCallback)((e) => {
        if (e.key === 'Escape')
            toggle();
    }, [toggle]);
    (0, react_1.useEffect)(() => {
        if (!modals[id])
            setModal({ [id]: { isActive: openOnLoad, payload: null } });
    }, [id, modals, setModal, openOnLoad]);
    (0, react_1.useEffect)(() => {
        if (isActive)
            window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [isActive, handleEsc]);
    return { isActive, toggle, payload, updatePayload, someModalIsActive };
};
exports.useModal = useModal;
