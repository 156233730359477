import { memo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { getP35, getP26, vw, mq } from '@dy/commons/styles'
import { Button } from '@dy/commons/components'

type MenuProps = {
  data: any
}

export const ModalPromo: FC<MenuProps> = memo(({data}) => {
  const { isActive, toggle } = useModal(MODALS.PROMO)
  const { title, text, linkPath, linkTitle } = data

  return (
    <Modal isActive={isActive} close={toggle} title={''} xAlign='flex-end' dialogStyles={dialogStyles} size='s' >
      <h2>{title}</h2>
      <p>{text}</p>
      <a href={linkPath}>
        <Button>
          {linkTitle}
        </Button>
      </a>
    </Modal>
  )
})

const dialogStyles = css`
  background-color: ${({ theme }) => theme.colors.background};

  header {
    border-bottom: none;
    height: ${vw(80, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(80, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(80, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 80px;
    }


    button {
      background-color: ${({ theme }) => theme.colors.background};
      border-radius: 50%;
      height: ${vw(40, 'mobile')};
      margin: ${vw(20, 'mobile')};
      width: ${vw(40, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(40, 'nexus7')};
        margin: ${vw(20, 'nexus7')};
        width: ${vw(40, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(40, 'desktop')};
        margin: ${vw(20, 'desktop')};
        width: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 40px;
        margin: 20px;
        width: 40px;
      }

      figure {
        height: ${vw(18, 'mobile')};
        width: ${vw(18, 'mobile')};

        ${mq.greaterThan('nexus7')} {
           height: ${vw(18, 'nexus7')};
           width: ${vw(18, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
           height: ${vw(18, 'desktop')};
           width: ${vw(18, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 18px;
          width: 18px;
        }
      }
    }
  }


  .modal__body {
    padding: 0 ${vw(40 - 4.25, 'mobile')} ${vw(75, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(40 - 4.25, 'nexus7')} ${vw(75, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(40 - 12.5, 'desktop')} ${vw(75, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 27.5px 75px;
    }

     h2 {
      ${getP26()}
      text-align: center;
    }
    form {
      padding: 0;

      ${mq.greaterThan('tablet')} {
        padding: ${vw(20, 'desktop')} ${vw(20, 'desktop')} 0;
      }

      ${mq.greaterThan('desktop')} {
        padding: 20px 20px 0;
      }
    }

    > p {
      ${getP35()}
      color: ${({ theme }) => theme.colors.creme};
      text-align: left;
      margin: ${vw(20, 'mobile')} 0 0;

      ${mq.greaterThan('nexus7')} {
        margin: ${vw(20, 'nexus7')} 0 0;
      }

      ${mq.greaterThan('tablet')} {
        text-align: center;
        margin: ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin: 20px;
      }
    }
  }
`