import { memo } from 'react'
import styled, { css } from 'styled-components'
import { Button } from '@dy/commons/components'

type ContentTypeButtonDownload = {
  columnType: 'button_download'
  horizontalAlignment: 'left' | 'center' | 'right'
  text: string
  download: string
}

interface IContentButtonDownload {
  data: ContentTypeButtonDownload
}

export const ContentButtonDownload = memo(({ data }: IContentButtonDownload) => {
  const { text, download, horizontalAlignment } = data

  const onClick = () => {
    window.open(download)
  }

  return (
    <ButtonDownload onClick={onClick} $xAlign={horizontalAlignment} bgType='darkred'>
      {text}
    </ButtonDownload>
  )
})

const ButtonDownload = styled(Button)<{ $xAlign: 'left' | 'center' | 'right'}>`
  ${({ $xAlign }) => $xAlign === 'left' ? css`margin-left: 0;` : $xAlign === 'right' ? css`margin-right: 0;` : css`margin: 0 auto;`}
`
