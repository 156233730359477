"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POSTAL_CODE_TYPE = exports.CART_LINE_TYPE = exports.ADDRESS_TYPE = exports.PAGES = exports.BANNER = exports.CATEGORIES = void 0;
var CATEGORIES;
(function (CATEGORIES) {
    CATEGORIES["PRODUCT"] = "PRODUCT";
    CATEGORIES["FAQ"] = "FAQ";
})(CATEGORIES = exports.CATEGORIES || (exports.CATEGORIES = {}));
var BANNER;
(function (BANNER) {
    BANNER["SLIM"] = "SLIM";
})(BANNER = exports.BANNER || (exports.BANNER = {}));
var PAGES;
(function (PAGES) {
    PAGES["REGULAR"] = "REGULAR";
})(PAGES = exports.PAGES || (exports.PAGES = {}));
var ADDRESS_TYPE;
(function (ADDRESS_TYPE) {
    ADDRESS_TYPE["BILLING"] = "BILLING";
    ADDRESS_TYPE["SHIPPING"] = "SHIPPING";
})(ADDRESS_TYPE = exports.ADDRESS_TYPE || (exports.ADDRESS_TYPE = {}));
var CART_LINE_TYPE;
(function (CART_LINE_TYPE) {
    CART_LINE_TYPE["PHYSICAL"] = "PHYSICAL";
    CART_LINE_TYPE["GIFT_CERTIFICATE"] = "GIFT_CERTIFICATE";
})(CART_LINE_TYPE = exports.CART_LINE_TYPE || (exports.CART_LINE_TYPE = {}));
var POSTAL_CODE_TYPE;
(function (POSTAL_CODE_TYPE) {
    POSTAL_CODE_TYPE["SINGLE"] = "SINGLE";
    POSTAL_CODE_TYPE["RANGE"] = "RANGE";
})(POSTAL_CODE_TYPE = exports.POSTAL_CODE_TYPE || (exports.POSTAL_CODE_TYPE = {}));
