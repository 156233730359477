import { memo, useMemo, type MouseEvent } from 'react'
import styled, { css }  from 'styled-components'
import { Button, Figure, SliderFlickity } from '@dy/commons/components'
import { mq, vw, designGrid, getP141, getP113, getP93_1, getP53, getP30_1, getP26, getP15_1, getP20_2  } from '@dy/commons/styles'
import { useRouter } from 'next/router'


export interface ISectionHighlights {
  data: any
}
// interface ITimeline {
//   currentIdx: number,
//   slides: any
// }


// const Bullet = ({ idx, slidesLength, currentIdx }) => {
//   return (
//     <DivBullet style={{ width: Math.round(100 / slidesLength) + '%' }} $isActive={currentIdx === idx }  $filled={currentIdx > idx}>
//       <div className={`progress-bar ` } />
//     </DivBullet>
//   )
// }
// const Timeline = memo<ITimeline>(({ currentIdx, slides }) => {
//   return (
//     <Div >
//       {slides.map((_, idx) => <Bullet key={`${currentIdx}-${idx}`} idx={idx} slidesLength={slides.length} currentIdx={currentIdx} />)}
//     </Div>
//   )
// })

const getSlide = (slides, push) => slides.map((slide, idx) => {
  const { title, subtitle, link, leftImage, centerImage, rightImage, textPosition: position, textAlign: alignment, media, mainType, textValign, titleSize, subtitleSize } = slide
  let images

  if (mainType === 'product') {
    images = [leftImage, centerImage, rightImage]
  } else {
    images = [media]
  }
  const onClick = (e:MouseEvent) => {
    e.stopPropagation()
    push(link.path)
  }
  const filteredImages = images.filter(el => el != null)

  return (
    <div  key= {idx} className={mainType === 'media' ? 'media contentWrapper' : 'contentWrapper'} data-length={filteredImages.length}>
      <Figure lazy={false} className={'product-highlight-image-center'} media={media} />
      <HighlightsTextWrapper $position={position} className={'product-highlight-pos-' + position} title_size={titleSize} subtitle_size={subtitleSize} text_valign={textValign}>
        <h2 className={'product-highlight-align-' + alignment + ' product-highlight-text-title'}>{title}</h2>
        <p className={'product-highlight-align-' + alignment + ' product-highlight-text-subtitle'}>{subtitle}</p>
        <Button onClick={onClick} className={'product-highlight-align-' + alignment + ' product-highlight-text-button'}>
          {link.title}
        </Button>
      </HighlightsTextWrapper>
    </div>
  )
})

export const SectionHighlights = memo<ISectionHighlights>(({ data }) => {
  const { push } = useRouter()
  const Slides = useMemo(() => getSlide(data, push), [data, push])


  return (
    <HighlightsSection className='product-highlight-wrapper'>
      <SliderFlickity className={'slider--product-highlight fade'} options={{ draggable: false, autoPlay:4000, pauseAutoPlayOnHover: false, initialIndex: 0 }} >
        {Slides}
      </SliderFlickity>
      {/* {nSlides > 1 && <Timeline currentIdx={activeIndex} slides={Slides}/>} */}
    </HighlightsSection>
  )
})


export const HighlightsSection = styled.section`
  margin-bottom: ${vw(50, 'mobile')};
  height: ${vw(447, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(447, 'nexus7')};
    margin-bottom: ${vw(50, 'nexus7')};
    margin-top: ${vw(70, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(715, 'desktop')};
    margin-bottom: ${vw(200, 'desktop')};
    margin-top: ${vw(150, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 715px;
    margin-bottom:200px;
    margin-top: 150px;
  }

  ${mq.greaterThan('desktop-wide')} {
    height: 715px;
    margin: 150px auto 200px;
    max-width: 1650px;
  }

  > div:first-of-type {
    height: inherit;
  }

  .contentWrapper {
    ${designGrid({})}
    height: inherit;

    figure {
      user-select: none;
      pointer-events: none;
      filter: drop-shadow(8px 13px 1px rgb(78 57 14 / 7%));

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &.media {
      color: #FFF;
    }

    &[data-length='1'] {

      figure {
        grid-area: 1 / 2 / span 1 / span 4;
        filter: none;

        &.figure--video {
          grid-area: 1 / 1 / span 2 / span 6;
          filter: none;

          iframe {
            width: 200% !important;
            transform: translateX(-50%);
          }
        }

        &.product-highlight-image-center {
          grid-area: 1 / 1 / span 2 / span 6;
          filter: none;
        }
      }

      .product-highlight-pos-center {
        grid-area: 1 / 2 / span 2 / span 4;
        align-self: center;
        margin: 0;
      }
    }

    &[data-length='2'] {
      figure:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

      figure:nth-of-type(2) { grid-area: 1 / 3 / span 1 / span 4; }
    }

    &[data-length='3'] {
      figure:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

      figure:nth-of-type(2) { grid-area: 1 / 2 / span 1 / span 4; }

      figure:nth-of-type(3) { grid-area: 1 / 3 / span 1 / span 4; }
    }
  }


  ${mq.greaterThan('tablet')} {
    .contentWrapper {
      height: unset;

      &[data-length] figure {
        filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        height: ${vw(700, 'desktop')};
        width: ${vw(672, 'desktop')};

        &.product-highlight-image-left {
          grid-area: 1 / 1 / span 1 / span 6;
        }
        &.product-highlight-image-center {
          grid-area: 1 / 4 / span 1 / span 6;
        }
        &.product-highlight-image-right {
          grid-area: 1 / 7 / span 1 / span 6;
        }
      }

      &[data-length='1'] {
        figure {
          &.product-highlight-image-center {
            grid-area: 1 / 1 / span 1 / span 12;
            margin: 0 ${vw(50, 'desktop')};
            width: auto;
            filter: none;
          }

          &.figure--video {
            grid-area: 1 / 1 / span 1 / span 12;

            iframe {
              height: 200% !important;
              width: calc(100% - ${vw(100, 'desktop')}) !important;
              transform: translate(${vw(50, 'desktop')}, -25%);
            }
          }
        }

        .product-highlight-pos-center {
          grid-area: 1 / 3 / span 1 / span 8;
        }
      }
    }
  }

  ${mq.greaterThan('desktop')} {
    .contentWrapper {
      height: unset;

      &[data-length] figure {
        filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        height: 700px;
        width: 672px;

        &.product-highlight-image-left {
          grid-area: 1 / 1 / span 1 / span 6;
        }
        &.product-highlight-image-center {
          grid-area: 1 / 4 / span 1 / span 6;
        }
        &.product-highlight-image-right {
          grid-area: 1 / 7 / span 1 / span 6;
        }
      }

      &[data-length='1'] {
        figure {
          &.product-highlight-image-center {
            grid-area: 1 / 1 / span 1 / span 12;
            margin: 0 50px;
            width: auto;
            filter: none;
          }

          &.figure--video {
            grid-area: 1 / 1 / span 1 / span 12;

            iframe {
              height: 200% !important;
              width: calc(100% - 100px) !important;
              transform: translate(50px, -25%);
            }
          }
        }

        .product-highlight-pos-center {
          grid-area: 1 / 3 / span 1 / span 8;
        }
      }
    }
  }

  ${mq.greaterThan('desktop-wide')} {
    .contentWrapper {
      height: unset;

      &[data-length] figure {
        filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        height: 700px;
        width: ${vw(672, 'desktop-wide')};

        &.product-highlight-image-left {
          grid-area: 1 / 1 / span 1 / span 6;
        }
        &.product-highlight-image-center {
          grid-area: 1 / 4 / span 1 / span 6;
        }
        &.product-highlight-image-right {
          grid-area: 1 / 7 / span 1 / span 6;
        }
      }

      &[data-length='1'] {
        figure {
          &.product-highlight-image-center {
            filter: none;
            grid-area: 1 / 1 / span 1 / span 12;
            margin: 0 50px;
            width: auto;
          }

          &.figure--video {
            grid-area: 1 / 1 / span 1 / span 12;

            iframe {
              height: 200% !important;
              transform: translate(50px, -25%);
              width: calc(100% - ${vw(100, 'desktop-wide')}) !important;
            }
          }
        }

        .product-highlight-pos-center {
          grid-area: 1 / 3 / span 1 / span 8;
        }
      }
    }
  }
  .slider--product-highlight {
    .flickity-viewport,
    .flickity-slider,
    .flickity-cell {
      pointer-events: none;
    }

    .flickity-cell {
      button, a {
        pointer-events: auto;
      }
    }
  }
  .flickity-viewport {
    display: flex ;
  }

  .flickity-slider,
  .flickity-cell {
    display: grid ;
    transform: translateX(0) !important;
  }

  .flickity-cell {
    opacity: 0;
    grid-column: 1/ span 1;
    grid-row: 1/ span 1;
    transform: translate(0, 0) !important;
    transition: 500ms opacity linear;

    h2,
    p,
    button {
      opacity: 0;
      transition: 500ms opacity linear 50ms, 600ms transform linear 50ms;
      transform: translateY(10px);
    }

    /* h2 {
      transition-delay: 300ms;
    }

    p {
      transition-delay: 400ms;
    }

    button {
      transition-delay: 500ms;
    } */

    &.is-selected {
      opacity: 1;

      h2,
      p,
      button {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

`

const HighlightsTextWrapper = styled.div<any>`
  grid-area: 1 / 2 / span 2 / span 4;
  text-align: center;
  width: 100%;
  ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${vw(20, 'mobile')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${vw(30, 'mobile')}; align-self: end;` : css`align-self: center;`};
  z-index: 1;

  ${mq.greaterThan('tablet')} {
    grid-area: ${({ $position }) => `1 / ${$position === 'left' ? 2 : $position === 'right' ? 7 : 3} / span 1 / span ${$position === 'center' ? 8 : 5}`};
    ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${vw(60, 'desktop')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${vw(70, 'desktop')}; align-self: end;` : css`align-self: center;`};
  }

  h2 {
    ${({ title_size }) => title_size === 'xl' ? getP141() : title_size === 'l' ? getP113() : title_size === 'm' ? getP93_1() : getP53()};
    color: ${({ theme }) => theme.colors.red};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP30_1()} font-weight: 100;` : getP15_1()};
    color: ${({ theme }) => theme.colors.red};
    margin: ${vw(15, 'mobile')} 0 ${vw(20, 'mobile')};
  }

  h2,
  p,
  button {
    text-align: center;
  }

  ${mq.greaterThan('tablet')} {
    &.product-highlight-pos-left {
      p {
        &.product-highlight-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    &.product-highlight-pos-center {
      p {
        &.product-highlight-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12 * 4) ${vw(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 6) ${vw(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12 * 4);
        }
      }
    }

    &.product-highlight-pos-right {
      p {
        &.product-highlight-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    .product-highlight-align-left { text-align: left; }
    .product-highlight-align-center { text-align: center; }
    .product-highlight-align-right { text-align: right; }

    button.product-highlight-align-left { margin: 0 auto 0 0; }
    button.product-highlight-align-right { margin: 0 0 0 auto; }

    p {
      ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP26()} font-weight: 100;` : getP20_2()};

      &.home-header-text-subtitle {
        ${({ subtitle_size }) => subtitle_size === 'm' ? css`letter-spacing: -1.5px;` : ''};
      }
    }
  }

  ${mq.greaterThan('desktop')} {
      ${({ text_valign }) => text_valign === 'top' ? css`padding-top: 60px; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: 70px; align-self: end;` : css`align-self: center;`};

    &.product-highlight-pos-left {

      p {
        &.product-highlight-align-left {
          margin: 15px calc(100vw / 12) 30px 0;
        }
        &.product-highlight-align-center {
          margin: 15px calc(100vw / 24) 30px;
        }
        &.product-highlight-align-right {
          margin: 15px 0 30px calc(100vw / 12);
        }
      }
    }
    &.product-highlight-pos-center {

      p {
        &.product-highlight-align-left {
          margin: 15px calc(100vw / 12 * 4) 30px 0;
        }
        &.product-highlight-align-center {
          margin: 15px calc(100vw / 6) 30px;
        }
        &.product-highlight-align-right {
          margin: 15px 0 30px calc(100vw / 12 * 4);
        }
      }
    }
    &.product-highlight-pos-right {

      p {
        &.product-highlight-align-left {
          margin:15px calc(100vw / 12) 30px 0;
        }
        &.product-highlight-align-center {
          margin:15px calc(100vw / 24) 30px;
        }
        &.product-highlight-align-right {
          margin:15px 0 30px calc(100vw / 12);
        }
      }
    }

  }
`


//

// const Div = styled.div<{ $filled?: boolean, $isSafari?:boolean }>`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   height: 20px;
//   justify-content: space-between;
//   width: 100%;
//   z-index: 50;

// `

// const DivBullet = styled.div<{ $filled?: boolean, $isSafari?:boolean, $isActive: boolean }>`
//   background-color: rgba(247, 18, 18, .25);
//   height: 100%;
//   border-radius: 2px;
//   margin: 0 3px;
//   position: relative;

//   .progress-bar {
//     animation-delay: 400ms;
//     animation-duration: 4s;
//     animation-name: ${bulletWidth};
//     animation-fill-mode: forwards;
//     animation-iteration-count: unset;
//     animation-play-state:  ${({ $isActive }) => $isActive ? 'running' : 'paused'};
//     animation-timing-function: linear;
//     background-color: red;
//     position: absolute;
//     left: 0;
//     top: 0;
//     height: 100%;
//     will-change: width;
//     ${({ $isActive, $filled }) => (!$isActive && $filled) && css`
//       width: 100% !important;
//     `}
//   }

// `
