import { memo } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { mq, vw, getP20_5, getP18_1 } from '@dy/commons/styles'

type TypeContentSectionBrand = {
  data: {
    description: string,
    logo: any,
    name: string
  }
}

export const ContentSectionBrand = memo(({ data }:TypeContentSectionBrand) => {
  return (
    <Article>
      <h2>{data.name}</h2>
      <img src={data.logo.url} alt={data.logo.alt}/>
      <div className='text-wrapper'>
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </div>
    </Article>
  )
})

export const DynamicContentSectionBrand = dynamic<TypeContentSectionBrand>(() => import('./').then((mod) => mod.ContentSectionBrand), { ssr: false })


const Article = styled.article<any>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${vw(138, 'nexus7')} 0 ${vw(40, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(138, 'nexus7')} 0 ${vw(40, 'nexus7')};
  }
  ${mq.greaterThan('tablet')} {
    margin: ${vw(180, 'desktop')} 0 0;
  }

  ${mq.greaterThan('desktop')} {
    margin: 180px 0 0;
  }

  img {
    flex-shrink: 0;
    margin-bottom: ${vw(20, 'mobile')};
    user-select: none;
    width: ${vw(125, 'mobile')};
    /* filter:invert(49%) sepia(37%) saturate(691%) hue-rotate(313deg) brightness(74%) contrast(127%); */

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'nexus7')};
      width: ${vw(125, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: 0;
      width: ${vw(165, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 165px;
    }
  }

  .text-wrapper {
    ${getP18_1()}
    color: ${({ theme }) => theme.colors.darkred};
    padding-top: ${vw(48, 'mobile')};
    width: 95%;
    ${mq.greaterThan('nexus7')} {
      padding-top: ${vw(48, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      width: 50%;
      padding-top: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-top: 60px;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.red};
    ${getP20_5()}
    letter-spacing: -.5;
    margin-bottom: ${vw(46, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(46, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 60px;
    }
  }

  h2, p, a {
    ${mq.greaterThan('tablet')} {
      text-align: left;
    }
  }
`
