import { memo, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Link } from '@dy/commons/components'
import { mq, vw, hex2Rgba, getP18InputLabel } from '@dy/commons/styles'
import { cookie } from '@dy/commons/utils'
import { useCustomer } from '@/hooks'
import { useCart } from '../../../commons/dist/hooks/useCart'
import { useModal } from '../../../commons/dist/hooks/useModal'
import { MODALS } from '../../../commons/dist/context/ModalsContext'
import { useMutation } from '@apollo/client'
import { CUSTOMER_LOGOUT } from 'api/graphql'

type Props = {
  data: any[]
}
//
export const getAccountListItems = (data, query, pathname, toggle, closeSession) => data.map((item, idx) => {
  return (
    <Li key={`acc-nav-item-${idx}`} $active={pathname === item.pathname && query.slug === item.slug} onClick={toggle}>
      {item.pathname ?
        <Link href={{
          pathname: item.pathname,
          ...item.slug && { query: { slug: item.slug }}
        }}>{item.name}</Link>
        :
        <button onClick={closeSession}>{item.name}</button>
      }
    </Li>
  )
})

export const NavAccount = memo(({ data }:Props) => {
  const { query, pathname, push } = useRouter()
  const { setCustomer } = useCustomer()
  const { bulkUpdateCart } = useCart()
  const { toggle } = useModal(MODALS.MENU_ACCOUNT)
  const [logout] = useMutation(CUSTOMER_LOGOUT)
  const closeSession = useCallback(async () => {

    const mutationData = {
      context: {
        endpoint: 'BC',
        isPrivatePath: true
      }
    }
    await logout(mutationData).then((res) => {
      console.log('res logout', res)
    }).catch((e) => {
      console.log('catch logout e', e)
    })
    cookie.removeCookie('SHOP_TOKEN')
    cookie.removeCookie('SHOP_SESSION_TOKEN')
    cookie.removeCookie('BC_CUSTOMER_ID')
    cookie.removeCookie('DY_CUSTOMER_TOKEN')
    setCustomer(null)
    bulkUpdateCart([], null, 0, 0, null, null, false, 0, 'fresh', null)
    push('/')
  },[setCustomer, bulkUpdateCart, push, logout])

  const ListItems = useMemo(() => Array.isArray(data) ? getAccountListItems(data, query, pathname, toggle, closeSession) : [], [data, query, pathname, toggle, closeSession])

  if(!data) return null
  return (
    <Nav>
      <ul>
        {ListItems}
      </ul>
    </Nav>
  )
})

const Nav = styled.nav<any>`
  display:none;

  ${mq.greaterThan('tablet')} {
    display: inline;
    grid-column: 1 / span 3;
    min-height:  ${vw(450, 'desktop')};
    padding: 0 ${vw(50, 'desktop')} 0 ${vw(30, 'desktop')};
  }
`

const Li = styled.li<any>`
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 20px;
  }

  button,
  a {
    ${getP18InputLabel()}
    color: ${({ theme, $active }) => $active ? theme.colors.darkred : hex2Rgba(theme.colors.darkred, .4)};
    transition: 300ms color ease-out;
    pointer-events: ${({ $active }) => $active ? 'none' : 'auto'};
  }
`
