import { memo, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useScroll, useTransform, useSpring } from 'framer-motion'
import { useActiveVariant, useTranslator } from '@/hooks'
import { useInView } from '@dy/commons/hooks'
import { pushAnalyticsEvent, getLang } from '@dy/commons/utils'
import { Figure, SliderFlickity } from '@dy/commons/components'
import { ProductCard } from '@/components'
import { mq, vw, designGrid, getP53 } from '@dy/commons/styles'
import { motion } from 'framer-motion'


export interface ISectionBestseller {
  data: {
    highlighted: any[]
    topSellers: any[]
  }
}

const mediaPastaLeft = {
  type: 'image' as const,
  alt: 'Icon pasta 1',
  default: {
    src: '/images/svg/pasta-left.svg',
    size: { width: 507, height: 844 }
  }
}

const mediaPastaRight = {
  type: 'image' as const,
  alt: 'Icon pasta 2',
  default: {
    src: '/images/svg/pasta-right.svg',
    size: { width: 608, height: 693 }
  }
}


const ga_list_highlighted = { id: 'list_highlighted', name: 'List Highlighted (slider)' }
// const ga_list = { id: 'list_bestsellers', name: 'List Bestsellers (slider)' }
const getSlides = (products, onClick, t) => products.map((product, idx) => (
  <div className='cart-wrapper' key={idx}>
    <ProductCard data={product} onClick={e=> {
      e.preventDefault()
      onClick(product,idx)
    }} pricePer={t('product_page.product.price_per.unit')} lastUnits={t('product_page.product.last_units')}/>
  </div>
))

export const SectionBestseller = memo<ISectionBestseller>(({ data }) => {
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { highlighted } = data || {}
  const { t } = useTranslator()
  const { setCardSelected } = useActiveVariant()

  const containerRef = useRef(null)
  const  { ref, inView } = useInView({
    rootMargin: '20px', // TOP  RIGHT
    threshold: 0,
    triggerOnce: true
  })

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end end']
  })

  const spring = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  const translateY = useTransform(spring, [0, 1], [-250, 0])
  const translateX = useTransform(spring, [0, 1], [70, -180])
  const y = useTransform(spring, [0, 1], [-340, -120])
  const x = useTransform(spring, [0, 1], [-200, 250])

  const onClick = (variant, idx) => {
    setCardSelected({
      variant_id: variant?.bigcommerceId,
      slug: variant.product.slug[locale],
      ga_list: ga_list_highlighted,
      idx
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const SlidesBestSeller = useMemo(() => getSlides([{}, ...topSellers, {}], onClick, t), [topSellers])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const SlidesHightlighted = useMemo(() => getSlides([{}, ...highlighted, {}], onClick, t), [highlighted])

  useEffect(() => {
    if(inView) {
      // pushAnalyticsEvent('view_item_list', { products: topSellers, ga_list })
      pushAnalyticsEvent('view_item_list', { products: highlighted, ga_list: ga_list_highlighted })
    }
  }, [inView, highlighted])

  return (
    <BestsellerSection ref={ref} className='bestseller-wrapper'>
      <PastaWrapper ref={containerRef} className='pasta-wrapper'>
        <ImageOverlay style={{ translateY, translateX }} className='bestseller-left'>
          <Figure media={mediaPastaLeft}/>
        </ImageOverlay>
        <ImageOverlay style={{ y, x }} className='bestseller-right'>
          <Figure media={mediaPastaRight}/>
        </ImageOverlay>
      </PastaWrapper>
      {/* {topSellers.length > 0 &&
        <ProductWrapper>
          <div className='headline-wrapper'><h2>{t('home.bestseller.new')}</h2></div>
          <SliderFlickity className={' slider--flickity slider--product-card'} options={{ pageDots:false, groupCells:'90%' }}>
            {SlidesBestSeller}
          </SliderFlickity>
        </ProductWrapper>
      } */}
      {highlighted.length > 0 &&
        <ProductWrapper>
          <div className='headline-wrapper'><h2>{t('home.bestseller.offer')}</h2></div>
          <SliderFlickity className={' slider--flickity slider--product-card'} options={{ pageDots:false, groupCells:'90%' }}>
            {SlidesHightlighted}
          </SliderFlickity>
        </ProductWrapper>
      }
    </BestsellerSection>
  )
})



const PastaWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  transform: translateY(-17%);
  user-select: none;
  pointer-events: none;
  z-index: 1;
  left: 0;
  right: 0;
  height: ${vw(882, 'mobile')};

  ${mq.greaterThan('tablet')} {
    height: ${vw(1356, 'desktop')};
    transform: translateY(-8%);
  }
`

const BestsellerSection = styled.section`
  ${designGrid({})}
  align-items: center;
  position: relative;
  padding: 0;
  margin-top: ${vw(70, 'mobile')};
  margin-bottom: ${vw(100, 'mobile')};
  padding-right: 0;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(100, 'nexus7')};
    margin-top: ${vw(70, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(200, 'desktop')};
    margin-top: ${vw(150, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 200px;
    margin-top: 150px;
  }
`

const ImageOverlay = styled(motion.div)`
  z-index: 1;
  position: absolute;
  user-select: none;
  pointer-events: none;

  &.bestseller-left {
    left: ${vw(-130, 'mobile')};
    top: ${vw(650, 'mobile')};

    figure {
      height: ${vw(418, 'mobile')};
      width: ${vw(240, 'mobile')};
    }
  }

  &.bestseller-right {
    right: ${vw(-40, 'mobile')};
    top: ${vw(380, 'mobile')};

    figure {
      height: ${vw(391, 'mobile')};
      width: ${vw(288, 'mobile')};
    }
  }

  ${mq.greaterThan('tablet')} {
    &.bestseller-left {
      left: ${vw(-120, 'desktop')};
      top: ${vw(685, 'desktop')};

      figure {
        height: ${vw(844, 'desktop')};
        width: ${vw(507, 'desktop')};
      }
    }

    &.bestseller-right {
      right: ${vw(-70, 'desktop')};
      top: ${vw(487, 'desktop')};

      figure {
        height: ${vw(842, 'desktop')};
        width: ${vw(621, 'desktop')};
      }
    }
  }
`


const ProductWrapper = styled.div`
  ${designGrid({})}
  padding: 0;

  ${mq.greaterThan('nexus7')} {
    ${designGrid({ withoutGutter: true })}
  }

  ${mq.greaterThan('desktop')} {
    ${designGrid({ withoutGutter: true })}
  }

  ${mq.greaterThan('desktop-wide')} {
    ${designGrid({})}
    max-width: 1649px;
    margin: 0 auto;
  }

  &:nth-last-child(2) {
    grid-area: 1 / 1 / span 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 1 / span 1 / span 12;
    }
  }

  &:last-of-type {
    grid-area: 2 / 1 / span 1 / span 6;
    margin-top: ${vw(100, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(100, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-area: 2 / 1 / span 1 / span 12;
      margin-top: ${vw(100, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 100px;
    }
  }

  .headline-wrapper {
    color: ${({ theme }) => theme.colors.red};
    align-items: center;
    display: flex;
    grid-area: 1 / 1 / span 1 / span 3;
    margin: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin: 0 ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 2 / span 1 / span 3;
      margin: 0;
    }
  }

  h2 {
    ${getP53()}
    text-align: center;
  }

  .slider--flickity {
    grid-area: 1 / 1 / span 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 1 / span 1 / span 12;
    }

    .flickity-viewport {
      overflow:visible;
    }

    .cart-wrapper:first-of-type article {
      width: 50vw;
      margin-right: 0;
      background-color: transparent;
      border: none;
      opacity: 0;
      pointer-events: none;

      &:hover {
        border-color: transparent;
      }

      ${mq.greaterThan('tablet')} {
        width: calc(100vw / 12 * 5);
      }

      ${mq.greaterThan('desktop')} {
        width: calc(100vw / 12 * 5);
      }

      ${mq.greaterThan('desktop-wide')} {
        width: calc(100vw / 16 * 5);
      }
    }

    .cart-wrapper:last-of-type article {
      background-color: transparent;
      opacity: 0;
      width: 0;

      &:hover {
        border-color: transparent;
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw(300, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 300px;
      }
    }

  }
`
