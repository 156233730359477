import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useScrollTracker } from '@dy/commons/hooks'
import { useCustomer, useActiveVariant } from '@/hooks'
import { Figure } from '@dy/commons/components'
import CatalogProductPage from '../../pages/catalog/product/[slug]'
import ShopProductPage from '../../pages/shop/product/[slug]'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, hideScrollbar, designGrid, hex2Rgba } from '@dy/commons/styles'
import { useRouter } from 'next/router'

export type TypeVariantOrigin = {
  variant_id: number,
  slug: any,
  ga_list?: {
    id: string,
    name: string
  },
  idx: number
}

export type TypeSectionProductDetail = {
  filters?: any
}

const mediaCloseIcon = {
  type: 'image' as const,
  alt: 'Icon close',
  default: {
    src:'/images/svg/i--close.svg',
    size: { width: 40, height: 40 }
  }
}

export const SectionProductDetail = memo(({ filters = {}}:TypeSectionProductDetail) => {
  const scrollState = useScrollTracker(0)
  const scrollTop = useMemo(() => scrollState === 'TOP', [scrollState])
  const filtersInSlider = typeof filters === 'object' ? Object.keys(filters)?.length >= 8 : false
  // const { isMobile } = useDeviceType()
  const { cardSelected, removeActiveVariant } = useActiveVariant()
  const { customer } = useCustomer()
  const { pathname } = useRouter()
  const [showLargeProductDetail, setShowLargeProductDetail] = useState(false)

  // const onMouseDown = useCallback(({ target }) => {
  //   if (!isMobile) {
  //     const section = document.getElementById('detail-page-wrapper')
  //     if (!section) return 
  //     const clickedInSection = section.contains(target)
  //     console.log(clickedInSection, 'clickedInSection', target, 'target')
  //     if (clickedInSection) return
      
  //     if (target instanceof HTMLAnchorElement || target instanceof HTMLButtonElement) {
  //       if (!target.classList.contains('product-card') && !target.classList.contains('btn-add') && !target.classList.contains('btn-bin')) removeActiveVariant()
  //     } else if (target instanceof HTMLImageElement) {
  //       console.log('.')
  //     } else {
  //       removeActiveVariant()
  //     }
      
  //   }
  // }, [isMobile, removeActiveVariant])

  const onKeyUp = useCallback((e) => {
    if (e.code === 'Escape' || e.key === 'Escape' || e.keyCode === 27) {
      removeActiveVariant()
    }
  }, [removeActiveVariant])

  useEffect(() => {
    if(!cardSelected) return

    window.addEventListener('keyup', onKeyUp)
    // window.addEventListener('mousedown', onMouseDown)

    return () => {
      window.removeEventListener('keyup', onKeyUp)
      // window.removeEventListener('mousedown', onMouseDown)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardSelected])

  useEffect(() => {
    if(cardSelected) setShowLargeProductDetail(true)
  }, [cardSelected])

  useEffect(() => {
    if (pathname === '/' || pathname === '/welcome') {

      if(cardSelected) removeActiveVariant()
      setShowLargeProductDetail(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if(!cardSelected?.variant_id) return <></>

  return (
    <Div key={cardSelected.variant_id} $activeVariant={!!cardSelected?.variant_id}>
      <ProductDetailSection id='detail-page-wrapper' className={'plp-page'} $showLargeProductDetail={showLargeProductDetail} $activeVariant={!!cardSelected?.variant_id} $filtersInSlider={filtersInSlider} $scrollTop={scrollTop} $scrollUp={scrollState === 'SCROLLING_UP'}>
        <CloseDetailsButton onClick={removeActiveVariant} name='Close details' $filtersInSlider={filtersInSlider} $activeVariant={!!cardSelected?.variant_id} >
          <Figure media={mediaCloseIcon}/>
        </CloseDetailsButton>
        {!!customer ?
          <ShopProductPage variant={cardSelected} />
          :
          <CatalogProductPage variant={cardSelected} />
        }
      </ProductDetailSection>
    </Div>
  )
})



const ProductDetailSection = styled<any>(motion.section)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ $activeVariant, theme: { colors }}) => $activeVariant ? `0 40px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
  height: calc(98vh - 60px);
  height: calc(98dvh - 60px);
  grid-column: 1 / span 6;
  left: 0;
  margin: auto;
  overflow-y: auto;
  position: fixed;
  top: 60px;
  transform: translateX(${({ $activeVariant }) => $activeVariant ? 0 : '100vw'});
  transition: 400ms transform ${({ theme }) => theme.ease}, 400ms margin ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease}, 300ms height ${({ theme }) => theme.ease};
  right: -10px;
  ${hideScrollbar()}
  width: 90vw;
  z-index: 110;
  will-change: translate, top, height;

  ${mq.greaterThan('nexus7')} {
    height: calc(95vh - ${vw(65, 'nexus7')});
    height: calc(95dvh - ${vw(65, 'nexus7')});
    top: ${vw(65, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    border-radius: 20px;
    grid-column: 8 / span 5;
    grid-row: 2 / auto;
    height: calc(95vh - ${vw(160, 'desktop')});
    height: calc(95dvh - ${vw(160, 'desktop')});
    left: auto;
    margin-bottom: 15px;
    margin-top: ${vw(40, 'desktop')};
    padding-bottom: ${vw(60, 'desktop')};
    top: ${vw(120, 'desktop')};
    transform: translateX(${({ $activeVariant }) => $activeVariant ? '-10px' : '100vw'});
    width: ${vw(580, 'desktop')};
    z-index: 110;
  }

  ${mq.greaterThan('desktop')} {
    height: calc(95vh - 160px);
    margin-top: 40px;
    padding-bottom: 60px;
    top: 120px;
    transform: translateX(${({ $activeVariant }) => $activeVariant ? '-20px' :'100vw'});
    width: 580px;
  }

  ${mq.greaterThan('large-screen')} {
    margin-right: 22vw;
  }

  &.plp-page {
    height: calc(100vh - ${vw(120, 'mobile')});
    height: calc(100dvh - ${vw(120, 'mobile')});
    top: ${vw(120, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: calc(100vh - ${vw(120, 'nexus7')});
      height: calc(100dvh - ${vw(120, 'nexus7')});
      top: ${vw(120, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: calc(100vh - ${({ $showLargeProductDetail, $scrollUp }) => $showLargeProductDetail ? $scrollUp ? vw(140, 'desktop') : vw(60, 'desktop') : vw(160, 'desktop')});
      top: ${vw(100, 'desktop')};
      z-index: 150;
    }

    ${mq.greaterThan('desktop')} {
      height: calc(100vh - ${({ $showLargeProductDetail, $scrollUp }) => $showLargeProductDetail ? $scrollUp ? '140px' : '60px' : '160px'});
      top: 100px;
    }

  }

  > div {
    margin-top: -${vw(40, 'mobile')};
    margin-bottom: 0;
    pointer-events: auto;
    background-color: transparent;

    ${mq.lessThan('tablet')} {
      margin-top: -${vw(40, 'nexus7')};
      padding-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: -${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: -40px;
    }
  }
`

const Div = styled.div<any>`
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  width: 100dvw;
  z-index: 150;

  ${mq.greaterThan('desktop-wide')} {
    ${designGrid({})}
  }
  > section {
    pointer-events: auto;

    ${mq.greaterThan('desktop-wide')} {
      grid-column: 8 / span auto;
      position: relative;
      margin-left: 50px;
    }
    @media (min-width: 1800px) {
      margin-left: 30px;
    }
    ${mq.greaterThan('large-screen')} {
      margin-left: 20px;
    }
    @media (min-width: 2000px) {
      margin-left: 10px;
    }
  }

`

const CloseDetailsButton = styled.button<any>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  display: flex;
  height: ${vw(40, 'mobile')};
  justify-content: center;
  pointer-events: auto;
  position: sticky;
  right: ${vw(20, 'mobile')};
  top: ${vw(20, 'mobile')};
  width: ${vw(40, 'mobile')};
  z-index: 200;
  transform: translateX(${({ $activeVariant }) => $activeVariant ? 0 : '100vw'});
  transition: 200ms transform ${({ theme }) => theme.ease}, 200ms margin ${({ theme }) => theme.ease};
  margin-right: 0;
  margin-left: auto;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(40, 'nexus7')};
    right: ${vw(20, 'nexus7')};
    top: ${vw(20, 'nexus7')};
    width: ${vw(40, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(40, 'desktop')};
    right: ${vw(30, 'desktop')};
    top: ${vw(40, 'desktop')};
    width: ${vw(40, 'desktop')};
    transform: translateX(${({ $activeVariant }) => $activeVariant ? 0 : vw(600, 'desktop')});
  }

  ${mq.greaterThan('desktop')} {
    height: 40px;
    right: 30px;
    top: 40px;
    width: 40px;
    transform: translateX(${({ $activeVariant }) => $activeVariant ? 0 : '600px'});
  }

  figure {
    height: ${vw(16, 'mobile')};
    width: ${vw(16, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(16, 'nexus7')};
      width: ${vw(16, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(16, 'desktop')};
      width: ${vw(16, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 16px;
      width: 16px;
    }
  }
`
