'use client'
import type { FC } from 'react'
import { memo, useEffect, useState} from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import jwtDecode from 'jwt-decode'
import { useMutation } from '@apollo/client'
import { useTranslator, useCustomer } from '@/hooks'
import { Form, Fieldset, Input, Button, DisplayError } from '@dy/commons/components'
import { autoComplete, inputPattern, loginCustomer } from '@dy/commons/utils'
import { defaultAuthModalLevel, TypeSectionAuthLevel } from '@/components'
import { CUSTOMER_ACTIVATE_ACCOUNT } from '@/api'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload?: { level: string, activationToken: string, [key: string]: any }
  animation?: any
}

type TypeFormInputs = {
  readonly email: string,
  password: string
}

export const FormUserActivateAccount:FC<Props> = memo(({ updateLevel, animation, payload }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, formState: { errors: formErrors }, setValue } = useForm<TypeFormInputs>()
  const [submitCustomError, setSubmitCustomError] = useState(null)
  const { push } = useRouter()
  const [customerActivate, { loading }] = useMutation(CUSTOMER_ACTIVATE_ACCOUNT)
  const { setLoaderActive } = useCustomer()

  const onSubmit: SubmitHandler<TypeFormInputs> = async data => {
    const { email, password } = data
    setLoaderActive(true)

    try {
      await customerActivate({
        variables: {
          password
        },
        context: {
          isPrivatePath: true,
          DYInviteToken: payload.activationToken
        }
      })

      await loginCustomer({ email, password, push })
      updateLevel('feedback_account_active')
    } catch (e) {
      setLoaderActive(false)
      console.warn(e)
      setSubmitCustomError(e)
    }
  }

  useEffect(() => {
    const decodeToken = async (token) => {
      try {
        const decoded: { [email: string]: string } = await jwtDecode(token)
        setValue('email', decoded.email)
      } catch (e) {
        console.log('decoding e', e)
        updateLevel(defaultAuthModalLevel, {}, true)
      }
    }

    if(payload.activationToken) decodeToken(payload.activationToken)
  }, [payload, setValue, updateLevel])

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('modals.auth.activation.text') as string}>
        <Input key='email-read' type='email' name='email' register={register} label={t('form.fields.email') as string} bgColor={'white'} readOnly placeholderVisible={true} />

        <Input key='password' type='password' name='password' register={register} label={t('form.fields.password') as string} autoComplete={autoComplete.password.new} icon='password' bgColor={'white'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} error={formErrors.password} />
      </Fieldset>
      <Button disabled={loading} type='submit'>{t('commons.actions.save')}</Button>
      {submitCustomError && <DisplayError key='display-error' error={submitCustomError} />}
    </Form>
  )
})
