"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.home = void 0;
/* eslint-disable quotes */
const utils_1 = require("../../../utils");
const hostnameUrl = 'https://' + utils_1.HOSTNAME;
const home = (data) => {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "@id": "",
        "name": "Mammafiore",
        "url": hostnameUrl,
        "isPartOf": {
            "@type": "WebSite"
        },
        "publisher": Object.assign(Object.assign(Object.assign({ "@type": "Store" }, (data.seo && { "name": `${data.seo.title}` })), (data.seo && { "description": `${data.seo.description}` })), { "brand": "Mammafiore", "email": "support@mammafiore.eu", "url": hostnameUrl, "sameAs": [
                'https://www.instagram.com/mammafiore_official/',
                'https://www.facebook.com/mammafiore/',
                'https://es.linkedin.com/company/mammafiore',
                'https://www.youtube.com/channel/UCuDpwGtXsWSSvaWn_ZBIavA'
            ], "image": `${hostnameUrl}/images/og_cover.jpg`, "priceRange": "45 - 300", "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "email": "supporto@mammafiore.eu",
                    "contactType": "customer service",
                    "availableLanguage": ["English", "Spanish", "Catalan", "French", "Italian", "Deutsch"]
                }
            ], "logo": "https://mammafiore.eu/images/logo--mammafiore.png", "openingHours": "Mo-Fr 09:00-18:30", "currenciesAccepted": "EUR", "paymentAccepted": "Credit Card, Visa" })
    });
};
exports.home = home;
