import {memo, useEffect, useMemo, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { Figure } from '@dy/commons/components'
import { useDeviceType, useInView } from '@dy/commons/hooks'
import { getTransition } from '@dy/commons/styles'
import { Question } from './Question'
import { faqListVariants } from './faqVariants'
import styled from 'styled-components'
import { mq, vw, getP53 } from '@dy/commons/styles'
import { motion } from 'framer-motion'

type Seo = {
  title: string,
  description: string
}

type Slug = {
  [locale: string]: string
}

type TypeFaqQuestion = {
  question: string,
  answer: string,
  parameterize: string
}

type TypeQuestionList = {
  data: TypeFaqCategory,
  idx: number,
  setActiveIndex: any,
  setActiveCategory: any,
  setActiveQuestion: any,
}

export type TypeFaqCategory = {
  name: string,
  seo: Seo,
  slug: Slug,
  faqs: TypeFaqQuestion[]
}

const mediaChevronIcon = {
  type: 'image' as const,
  alt: 'Icon chevron',
  default: {
    src: '/images/svg/i--chevron--lightred.svg',
    size: { width: 6, height: 8 }
  }
}



const getFaqs = (faqs, setActiveQuestion) => faqs.map((faq, idx) => <Question key={idx} data={faq} setActiveQuestion={setActiveQuestion} />)

export const SectionQuestionsList = memo(({ data, idx, setActiveIndex, setActiveCategory, setActiveQuestion }:TypeQuestionList) => {
  const { locale, query } = useRouter()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Faqs = useMemo(() => getFaqs(data.faqs, setActiveQuestion), [data])
  const [isExpanded, setIsExpanded] = useState(true)
  const { isMobile } = useDeviceType()

  const { ref, inView } = useInView({
    rootMargin: '100px 0px -50% 0px',
    threshold: 1,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView && data?.slug) {
      setActiveIndex(idx)
      setActiveCategory(data.slug[locale.split('-')[0]])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])


  useEffect(() => {
    if (isMobile) setIsExpanded(false)
  }, [isMobile])

  const handleOnClick = () => {
    setIsExpanded(!isExpanded)
  }
  const sectionRef = useCallback(node => {
    if (typeof window !== 'object') return

    // @ts-ignore
    if (node !== null && query?.slug && query.slug[0] === data.slug[locale.split('-')[0]]) {
      setTimeout(() => { node.scrollIntoView({ behavior: 'smooth' }) }, 1200)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 0 }} transition={{ duration: 1.2 }}>
      <div ref={sectionRef} className='idslug'></div>
      <h2 ref={ref}>
        {data.name}
        <Chevron aria-hidden={true} animate={{ rotate: isExpanded ? 90 : 0}} transition={getTransition()} onClick={handleOnClick}>
          <Figure media={mediaChevronIcon}/>
        </Chevron>
      </h2>
      <List {...(idx && { id: idx })} aria-expanded={isExpanded} tabIndex={-1} variants={faqListVariants} initial='initial' exit='exit' animate={isExpanded ? 'animate' : 'exit'}>
        {Faqs}
      </List>
    </Section>
  )
})


const Section = styled(motion.section)`
  grid-column: 1 / span 6;
  position: relative;
  grid-auto-rows: max-content;
  height: max-content;
  ${mq.greaterThan('tablet')} {
    grid-column: 5 / span 6;
    margin-bottom: ${vw(120, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 120px;
  }

  &:last-child {
    margin-bottom: ${vw(70, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(70, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(120, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 120px;
    }
  }

  .idslug {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: -80px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      top: -140px;
    }
  }

  h2 {
    height: ${vw(35, 'mobile')};
    justify-content: flex-start;
    margin-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(35, 'nexus7')};
      margin-bottom: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: auto;
      margin-bottom: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 30px;
    }

    &,
    button {
      align-items: center;
      color: ${({ theme }) => theme.colors.red};
      display: flex;
      ${getP53()}
      width: 100%;
    }

    button {
      justify-content: space-between;
      pointer-events: auto;
      user-select: auto;

      ${mq.greaterThan('tablet')} {
        pointer-events: none;
      }
    }
  }
  figure {
    height: ${vw(8, 'mobile')};
    width: ${vw(8, 'mobile')};
  }
`

const List = styled<any>(motion.dl)`
  height: 100%;

  &[aria-expanded='false'] {
    pointer-events: none;
    user-select: none;
  }

  &[aria-expanded='true'] {
    pointer-events: auto;
    user-select: auto;
  }
`

const Chevron = styled(motion.span)`
  display: inline-block;
  margin-left: 10px;
  user-select: none;

  ${mq.greaterThan('tablet')} {
    display: none;
  }

  img {
    height: ${vw(13, 'mobile')};
    width: ${vw(13, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(13, 'nexus7')};
      width: ${vw(13, 'nexus7')};
    }
  }
`
