import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP12_1, getP15_1B, getP26_1 } from '@dy/commons/styles'

export const SectionAccount = styled.section`
  grid-column: 1 / span 6;
  margin: ${vw(60, 'mobile')} 0;
  padding: 0;

  ${mq.greaterThan('tablet')} {
    grid-column: 4 / span 8;
    margin: 0;
  }

  h2 {
    color: ${({ theme }) => theme.colors.red};
  }

  p {
    ${getP26_1()}
    color: ${({ theme }) => theme.colors.red};
    margin-bottom:${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom:${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom:${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }
`

export const CardListItem = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${vw(20, 'mobile')};
  margin-bottom: ${vw(10, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    border-radius: ${vw(20, 'nexus7')};
    margin-bottom: ${vw(10, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    border-radius: ${vw(20, 'desktop')};
    margin-bottom: ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    border-radius: 20px;
    margin-bottom: 30px;
  }

  > ul {
    display: flex;
  }

  .mobile {
    display: block;

    ${mq.greaterThan('tablet')} {
      display: none;
    }

    .products_mobile {
      display:flex;
      flex-direction: column;
      padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(10, 'nexus7')} ${vw(20, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        display:none;
      }
    }

  }

  .desktop {
    display: none;

    ${mq.greaterThan('tablet')} {
      display: block;
    }
  }

  .headline_mobile {
    ${getP26_1()}
    color: ${({ theme }) => theme.colors.red};
    padding: ${vw(20, 'mobile')}  ${vw(20, 'mobile')}  ${vw(0, 'mobile')};
    &:first-child {
      margin-bottom:0;
    }
  }

  .icon__mobile {
    /* display:inline;
    position: relative;
    top: ${vw(30, 'mobile')};
    left: calc(100% - ${vw(45, 'mobile')}); */
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    > a {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 100px;
      min-width: 100px;

      figure {
        height: ${vw(13, 'mobile')};
        width: ${vw(8, 'mobile')};
      }
    }


  }

  .overview {
    border-bottom: none;
    padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};
    width:100%;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(10, 'nexus7')} ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
      display: flex;
      justify-content:space-between;
      padding: ${vw(12, 'desktop')} ${vw(40, 'desktop')} ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 12px 40px 15px;
    }

    &.mobile {
      display: block;

      > button {
        margin: 20px 0 0;
        position: relative;
        z-index:40;

      }
      ${mq.greaterThan('tablet')} {
        display: none;
      }
    }

    &.desktop {
      display: none;

      ${mq.greaterThan('tablet')} {
        display: flex;
      }
    }

    &.order {
      display:none;

      ${mq.greaterThan('tablet')} {
        display: block;
        padding: ${vw(12, 'desktop')} 0 ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 12px 0 15px;
      }
    }

    tr, td {
      ${getP15_1B()}
      color: ${({ theme }) => theme.colors.darkred};
      margin-bottom: ${vw(5, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(5, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        ${getP12_1()}
        margin-bottom: 0;
        width: ${vw(200, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 200px;
      }

      &.headline.mobile {
        padding: ${vw(20, 'mobile')} 0;

        ${mq.greaterThan('nexus7')} {
          padding: ${vw(20, 'nexus7')} 0;
        }

        ${mq.greaterThan('tablet')} {
          padding: 0;
        }
      }
    }

    td:last-child {
      width: unset;

      ${mq.greaterThan('tablet')} {
        width: ${vw(53, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 53px;
      }
    }

    > span {
      ${getP12_1()}
      display: block;
      padding-left:0;

      &:first-of-type {
        ${mq.greaterThan('tablet')} {
          padding-left: 0;
        }
      }

       > span:last-of-type {
        display: inline;
        padding-left: ${vw(3, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          padding-left: ${vw(3, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          display: block;
          padding-left: 0;
        }
      }
    }
  }

  .main-info {
    display: grid;
    grid-template-columns: 1fr;
    padding: ${vw(30, 'mobile')} ${vw(40, 'mobile')} ${vw(40, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(30, 'tablet')} ${vw(40, 'tablet')} ${vw(40, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      grid-template-columns: ${vw(445, 'desktop')} 1fr;
      grid-column-gap: ${vw(115, 'desktop')};
      padding: ${vw(30, 'desktop')} ${vw(40, 'desktop')} ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      grid-template-columns: 500px 1fr;
      grid-column-gap: 115px;
      padding: 30px 40px 40px;
    }
    > button {
      margin-top: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(60, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top: 60px;
      }
    }
    .products_desktop {
      display:none;
      ${mq.greaterThan('tablet')} {
        display:grid;
        grid-area: 1 / 1 / span 1 / span 1;
      }
    }

  
    .headline {
      ${getP26_1()}
      color: ${({ theme }) => theme.colors.red};
      display:none;

      ${mq.greaterThan('tablet')} {
        display:block;
        margin-bottom: ${vw(34, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 34px;
      }
    }

    .cta {
      display:none;

      ${mq.greaterThan('tablet')} {
        display:block;
        a:not(button > div > a ) {
          ${getP15_1B()}
          color: ${({ theme }) => theme.colors.red};
          display:block;
          text-align: center;
        }

        > button {
          width: 100%;
          margin: 0 0 ${vw(30, 'desktop')} 0;
        }
      }

      ${mq.greaterThan('desktop')} {
        > button {
          margin: 0 0 30px 0;
        }
      }
    }

    .invoice {
      display:block;
      padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(10, 'nexus7')} ${vw(20, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: 0;
      }

      > button {
        margin: auto 0;
      }
    }
  }

  .detail {
    color: ${({ theme }) => theme.colors.darkred};
    padding: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 12px 20px 15px;
    }

    h1 {
      ${getP26_1()};
      text-align:left;
      margin: ${vw(20, 'mobile')} 0;

      ${mq.greaterThan('nexus7')} {
        margin: ${vw(20, 'nexus7')} 0;
      }

      ${mq.greaterThan('tablet')} {
        margin: ${vw(20, 'desktop')} 0;
      }

      ${mq.greaterThan('desktop')} {
        margin: 20px 0;
      }
    }

  }
  .mobile {
    display: block;

    ${mq.greaterThan('tablet')} {
      display: none;
    }
  }
  .order_title {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
    padding: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding:${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 20px;
    }

    h1 {
      ${getP26_1()};
      text-align:left;
    }
  }

`

export const ProductListItem = styled.li`
  align-items: flex-start;
  display: grid;
  column-gap: ${vw(20, 'mobile')};
  grid-template-columns: min-content ${vw(100, 'mobile')} auto;
  margin-bottom: ${vw(20, 'mobile')};
  padding-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    column-gap: ${vw(20, 'tablet')};
    grid-template-columns: min-content ${vw(100, 'tablet')} auto;
    margin-bottom: ${vw(20, 'tablet')};
    padding-bottom: ${vw(20, 'tablet')};

  }

  ${mq.greaterThan('tablet')} {
    align-items: flex-start;
    display: grid;
    column-gap: ${vw(20, 'desktop')};
    grid-template-columns: min-content ${vw(221, 'desktop')} auto;
    margin-bottom: ${vw(20, 'desktop')};
    padding-bottom: ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    column-gap: 20px;
    grid-template-columns: min-content 221px auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
  }

  figure {
    position: relative;
    height: ${vw(55, 'mobile')};
    width: ${vw(55, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(55, 'tablet')};
      width: ${vw(55, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(55, 'desktop')};
      width: ${vw(55, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 55px;
      width: 55px;
    }

    &.placeholder {
      background-color: rgba(0, 0, 0, .15);
    }
  }

  span {
    ${getP12_1()}
    color: ${({ theme }) => theme.colors.darkred};
    display: block;
  }

  button {
    ${getP15_1B()}
    color: ${({ theme }) => theme.colors.red};
    
    &:disabled {
      opacity: .5;
      pointer-events: none;
      user-select: none;
    }
  }
`