import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'
import { useActiveVariant } from '@/hooks'
import { CategoriesAccordion } from '@dy/commons/components'
import { mq, vw, hideScrollbar, getP18_1 } from '@dy/commons/styles'


type Props = {
  categories: any[],
  brandCategory?: string
  pageCategory?: string
}

export const NavShop:FC<Props> = memo(({ categories, brandCategory = '', pageCategory = '' }) => {
  const { cardSelected } = useActiveVariant()

  return (
    <Section $activeVariant={!!cardSelected?.variant_id}>
      <CategoriesAccordion categories={categories} brandCategory={brandCategory} pageCategory={pageCategory} />
    </Section>
  )
})

const Section = styled.section<any>`
  display: none;
  ${hideScrollbar()}
  ${getP18_1()}

  ${mq.greaterThan('tablet')} {
    height: calc(100vh - 80px);
    display: flex;
    grid-column: 1 / span 3;
    grid-row: 1 / span 4;
    margin-bottom: ${vw(100, 'desktop')};
    overflow-y: auto;
    padding: 0 ${vw(30, 'desktop')};
    position: sticky;
    top: ${vw(150, 'desktop')};
    transform: translateX(${({ $activeVariant }) => $activeVariant ? `-${vw(120 * 3, 'desktop')}` : 0});
    transition: 200ms transform ${({ theme }) => theme.ease};
    z-index: 50;
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 100px;
    padding: 0 30px;
    top: 140px;
    transform: translateX(${({ $activeVariant }) => $activeVariant ? `-${120 * 3}px` : 0});
    max-width: 360px;
  }

  ${mq.greaterThan('desktop-wide')} {
    transform: translateX(${({ $activeVariant }) => $activeVariant ? `-${vw(140 * 4, 'desktop-wide')}` : 0});
  }
`