import { memo, useContext, useCallback } from 'react'
import { Button } from '@dy/commons/components'
import { useMutation } from '@apollo/client'
import { CUSTOMER_BULK_ADD_TO_CART } from '@/api'
import { useCart } from '@dy/commons/hooks'
import { cookie, CART_LINE_TYPE } from '@dy/commons/utils'
import { useToast } from '@dy/commons/hooks'
import { NewCartItemsContext } from '@/components'
import { useTranslator } from '@/hooks'


type TypeRepeatOrder = {
  orderLines?: any
}

const checkOrderLineAvailability = (orderLines) => {
  let disabled = false
  orderLines.forEach((orderLine) => {
    if(!orderLine.variant || orderLine.variant.importStatus === 'archived' || orderLine.variant.importStatus === 'to_delete') {
      disabled = true
    }
  })
  return disabled
}

export const RepeatOrder = memo(({ orderLines }:TypeRepeatOrder) => {
  const { setCartItems } = useContext(NewCartItemsContext) || {}
  const { addToast } = useToast()
  const { bulkUpdateCart } = useCart()
  const { t } = useTranslator()

  // graphQL mutations
  const [cartLinesAdd] = useMutation(CUSTOMER_BULK_ADD_TO_CART)

  const roundUpMultiple = (num, multipleNumber) => num + ((multipleNumber - (num%multipleNumber)) % multipleNumber)

  const onClick = useCallback(async (orderLines) => {
    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    let discardedOrderLines = false
    // Update CMS
    const mutationData = {
      variables: {
        cartLines: orderLines.map(item => {
          const { quantity, variant, product } = item

          if(!variant || !product) {
            discardedOrderLines = true
            return false
          }
          const { stock, minSaleUnits, importStatus } = variant
          const inventoryTracking = product?.inventoryTracking
          let quantityItem = quantity
          if(!stock && inventoryTracking || importStatus === 'archived' || importStatus === 'to_delete'  ) {
            discardedOrderLines = true
            return false
          } else if(stock < quantity && stock % minSaleUnits === 1) {
            //PENDING TO DO implement roundDownMultiple, para añadir el mínimo posible
            discardedOrderLines = true
            return false
          } else if((stock >= quantity) && (quantity % minSaleUnits === 1)) {
            //do nothing here
          } else if((stock >= quantity) && (quantity < minSaleUnits)) {
            quantityItem = roundUpMultiple(quantity, minSaleUnits)
          }
          return {
            variantId: item.variant.id,
            quantity: quantityItem,
            cartLineType: CART_LINE_TYPE.PHYSICAL
          }
        }).filter(Boolean)},
      context: {
        isPrivatePath: true,
        DYCustomerToken
      }
    }

    await cartLinesAdd(mutationData).then((res) => {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules } = res.data.cartLinesAdd.cart
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules)
      setCartItems(cartLines)
      addToast({
        message: t('account.order.repeat_order_feedback.success') as string,
        type: 'success'
      })
      if (discardedOrderLines) console.info('Some order lines were discarded due to lack of stock or archived')
    }).catch((error) => {
      console.warn('Error with mutation: cartLinesAdd:', error)
    })

  }, [addToast, bulkUpdateCart, cartLinesAdd, setCartItems, t])


  return (
    <Button disabled={checkOrderLineAvailability(orderLines)} onClick={() => onClick(orderLines)} bgType={'red'} outline>{t('account.repeat_order')}</Button>
  )
})
