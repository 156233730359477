"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalsProvider = exports.ModalsContext = exports.MODALS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
var MODALS;
(function (MODALS) {
    MODALS["ADD_TO_CART"] = "modal_add_to_cart";
    MODALS["AUTH"] = "modal_auth";
    MODALS["CART_TYPE"] = "modal_cart_type";
    MODALS["MENU_ACCOUNT"] = "modal_account";
    MODALS["MENU"] = "modal_menu";
    MODALS["PROMO"] = "modal_promo";
    MODALS["REGION_LANG"] = "modal_region_lang";
    MODALS["SEARCH"] = "modal_search";
    MODALS["COMERCIAL"] = "modal_comercial_region";
    MODALS["NEWSLETTER"] = "modal_newsletter";
    MODALS["FILTER"] = "modal_filters";
    MODALS["VIDEO"] = "modal_video";
    MODALS["STOCK_CART"] = "modal_stock_cart";
    MODALS["PUBLIC_INTERESTED"] = "modal_public_interested";
    MODALS["BUY_BOX"] = "modal_buy_box";
})(MODALS = exports.MODALS || (exports.MODALS = {}));
const initialState = {
    modals: {},
    setModal: () => { },
    someModalIsActive: false
};
exports.ModalsContext = (0, react_1.createContext)(initialState);
const ModalsProvider = ({ children }) => {
    const [modals, setModals] = (0, react_1.useState)({});
    const [someModalIsActive, setSomeModalIsActive] = (0, react_1.useState)(false);
    const setModal = (modal) => {
        setModals(prevModals => (Object.assign(Object.assign({}, prevModals), modal)));
        let someActive = false;
        const [modalKeyname, modalData] = Object.entries(modal)[0];
        if (modalData === null || modalData === void 0 ? void 0 : modalData.isActive)
            someActive = true;
        else {
            for (const [mKeyname, m] of Object.entries(modals)) {
                // Object.entries(modals) still have the old state for the current modal
                // so we only check if any other modal isActive
                if (m.isActive && modalKeyname !== mKeyname) {
                    someActive = true;
                    break;
                }
            }
        }
        setSomeModalIsActive(someActive);
    };
    (0, react_1.useEffect)(() => {
        document.body.style.overflow = someModalIsActive ? 'hidden' : 'initial';
        document.body.style.height = someModalIsActive ? '100vh' : 'unset';
    }, [someModalIsActive]);
    return ((0, jsx_runtime_1.jsx)(exports.ModalsContext.Provider, Object.assign({ value: { modals, setModal, someModalIsActive } }, { children: children })));
};
exports.ModalsProvider = ModalsProvider;
