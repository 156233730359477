import { type FC, memo } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import type { TypeMarqueeProps } from '@dy/commons/components'

const DynamicMarquee = dynamic<TypeMarqueeProps>(() => import('@dy/commons/components').then((mod) => mod.Marquee), { ssr: false })

type Props = {
  services: string
}

export const PreFooter:FC<Props> = memo<any>(({ services }) => {
  return (
    <Aside>
      <DynamicMarquee data={services.replace(/&nbsp;/g, ' ')} />
    </Aside>
  )
})

const Aside = styled.aside`
  overflow-x: hidden;
`
