"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.themeProject = void 0;
exports.themeProject = {
    colors: {
        'background': '#F3EFE6',
        'text': '#1E1E1E',
        'red': '#A92329',
        'darkred': '#4E1114',
        'lightred': '#D8C7C7',
        'creme': '#EFC881',
        'toast_success': '#E8A836',
        'toast_warning': '#A92329',
        'toast_error': '#FF0048',
        'darkcreme': '#E8A836',
        'white': '#FFFFFF',
        'black': '#000000',
        'input_error': '#FF4E15',
        'grey': '#979797',
        'greylight': '#F7F7F7',
        'transparent': 'transparent',
        'orange': '#F6A300',
        'frozen': '#616FC4',
        'dark_frozen': '#11294E'
    },
    fonts: {
        // eslint-disable-next-line quotes
        'primaryFont': `'Platform', sans-serif`,
        // eslint-disable-next-line quotes
        'secondaryFont': `'Graphik', sans-serif`,
        // eslint-disable-next-line quotes
        'tertiaryFont': `'Basier Circle', sans-serif`,
        // eslint-disable-next-line quotes
        'familyCode': `'monospace', monospace`,
        'size': 16,
        'lineHeight': 24 / 16,
        'headersize': 20,
        'headerLineHeight': 24 / 20
    },
    ease: 'cubic-bezier(.25, .1, .25, 1)'
};
