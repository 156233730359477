import { memo } from 'react'
import type { FC } from 'react'
import styled from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { Button } from '@dy/commons/components'
import { mq, vw } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'

type MenuProps = {}

export const ModalRegionRegister: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.ADD_TO_CART)
  const { toggle:openRegion } = useModal(MODALS.REGION_LANG)
  const { t } = useTranslator()

  const switchModals = () => {
    toggle()
    setTimeout(()=> {
      openRegion()
    }, 200)
  }

  return (
    <Modal isActive={isActive} close={toggle} title={t('modals.region_register.title')as string} xAlign='flex-end' size='s' >
      <span>{t('modals.region_register.description')}</span>
      <Actions isActive={isActive}>
        <Button onClick={switchModals} bgType={'red'} outline={true} >{t('modals.region_register.action_change')}</Button>
        <Button onClick={toggle} bgType={'red'}>{t('modals.region_register.action_stay')}</Button>
      </Actions>
    </Modal>
  )
})


const Actions = styled.div<any>`
  column-gap: ${vw(20, 'mobile')};
  display: grid;
  grid-template-columns: calc(50% - ${vw(10, 'mobile')}) calc(50% - ${vw(10, 'mobile')});
  margin-top: ${vw(20, 'mobile')} ;
  pointer-events: none;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    column-gap: ${vw(20, 'nexus7')};
    grid-template-columns: calc(50% - ${vw(10, 'nexus7')}) calc(50% - ${vw(10, 'nexus7')});
    margin-top: ${vw(20, 'nexus7')} ;
  }

  ${mq.greaterThan('tablet')} {
    column-gap: ${vw(20, 'desktop')};
    grid-template-columns: calc(50% - ${vw(10, 'desktop')}) calc(50% - ${vw(10, 'desktop')});
    margin-top: ${vw(40, 'desktop')} ;
  }

  ${mq.greaterThan('desktop')} {
    column-gap: 20px;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    margin-top: 40px;
  }

  button {
    height: ${vw(60, 'mobile')};
    pointer-events: ${({ isActive }) => isActive ? 'auto' : 'none'};
    white-space: nowrap;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(60, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 60px;
    }
  }

  .button__bg {
    border: none;
  }
`