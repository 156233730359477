"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getP12_3 = exports.getP12_2 = exports.getP12_1B = exports.getP12_1 = exports.getP12 = exports.getP13_3 = exports.getP15_6 = exports.getP15_5 = exports.getP15_4 = exports.getP15_3B = exports.getP15_3 = exports.getP15_2 = exports.getP15_2B = exports.getP15_1B = exports.getP15 = exports.getP15_1 = exports.getP16 = exports.getP18_1B = exports.getP18InputHasContentLabel = exports.getP18 = exports.getP18InputLabel = exports.getP18_4 = exports.getP18_3 = exports.getP18_2 = exports.getP18_1 = exports.getP20_Mobile = exports.getP20_4 = exports.getP20_3 = exports.getP20_2 = exports.getP20_5 = exports.getP20_1 = exports.getP20 = exports.getP25 = exports.getP26_4 = exports.getP26_3 = exports.getP26_2 = exports.getP26_1 = exports.getP26 = exports.getP30_2 = exports.getP30_1 = exports.getP30 = exports.getP35 = exports.getP45 = exports.getP53_2 = exports.getP50_1 = exports.getP53 = exports.getP93_1 = exports.getP93 = exports.getP113 = exports.getP141 = void 0;
exports.font12_15 = exports.getP17NavLinks = exports.getP20v2 = exports.getP27_P18 = exports.getP15Search = exports.getP14Search = exports.getP9 = exports.getP10 = exports.getP10_1 = void 0;
const styled_components_1 = require("styled-components");
const _1 = require(".");
const _2 = require(".");
// 1rem mobile -> 15px, 1rem desktop -> 18px
const rem = {
    baseMobile: 15,
    base: 18,
    get: (value, factor = null) => `${value / (factor !== null && factor !== void 0 ? factor : rem.baseMobile)}rem`
};
const getP141 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(75)};
    font-weight: 700;
    line-height: ${rem.get(64)};
    letter-spacing: 1.56px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(141, rem.base)};
      line-height: ${rem.get(122, rem.base)};
      letter-spacing: 2.94px;
    }
  `;
};
exports.getP141 = getP141;
const getP113 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(55)};
    font-weight: 700;
    line-height: ${rem.get(50)};
    letter-spacing: 0.53px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(113, rem.base)};
      line-height: ${rem.get(97, rem.base)};
      letter-spacing: 1px;
    }
  `;
};
exports.getP113 = getP113;
const getP93 = (lineHeightMobile = 54, lineHeightDesktop = 112) => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(45)};
    font-weight: bold;
    line-height: ${rem.get(lineHeightMobile)};
    letter-spacing: 1.45px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(93, rem.base)};
      line-height: ${rem.get(lineHeightDesktop, rem.base)};
      letter-spacing: 3px;
    }
  `;
};
exports.getP93 = getP93;
const getP93_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(45)};
    font-weight: 700;
    line-height: ${rem.get(40)};
    letter-spacing: 1.45px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(93, rem.base)};
      line-height: ${rem.get(83, rem.base)};
      letter-spacing: 1px;
    }
  `;
};
exports.getP93_1 = getP93_1;
const getP53 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(27)};
    font-weight: bold;
    line-height: ${rem.get(29)};
    letter-spacing: 0.56px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(53, rem.base)};
      line-height: ${rem.get(57, rem.base)};
      letter-spacing: 1px;
    }
  `;
};
exports.getP53 = getP53;
const getP50_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(50)};
    font-weight: regular;
    line-height: ${rem.get(54)};
    letter-spacing: -3.81px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(50, rem.base)};
      line-height: ${rem.get(54, rem.base)};
      letter-spacing: -3.81px;
    }
  `;
};
exports.getP50_1 = getP50_1;
// salePrice PDP
const getP53_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(27)};
    font-weight: 700;
    line-height: ${rem.get(29)};
    letter-spacing: 0.56px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(53, rem.base)};
      line-height: ${rem.get(45, rem.base)};
      letter-spacing: .59px;
    }
  `;
};
exports.getP53_2 = getP53_2;
const getP45 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(36)};
    font-weight: 700;
    line-height: ${rem.get(40)};
    letter-spacing: 0.37px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(45, rem.base)};
      line-height: ${rem.get(40, rem.base)};
      letter-spacing: 0.56px;
    }
  `;
};
exports.getP45 = getP45;
const getP35 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(28)};
    font-weight: 400;
    line-height: ${rem.get(32)};
    letter-spacing: -1.13px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(35, rem.base)};
      line-height: ${rem.get(38.8, rem.base)};
      letter-spacing: -1.67px;
    }
  `;
};
exports.getP35 = getP35;
const getP30 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20)};
    font-weight: 700;
    line-height: ${rem.get(24)};
    letter-spacing: -0.2px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(30, rem.base)};
      line-height: ${rem.get(42, rem.base)};
      letter-spacing: -0.3px;
    }
  `;
};
exports.getP30 = getP30;
const getP30_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: 300;
    line-height: ${rem.get(19)};
    letter-spacing: -0.8px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(30, rem.base)};
      line-height: ${rem.get(37, rem.base)};
      letter-spacing: -1.5px;
    }
  `;
};
exports.getP30_1 = getP30_1;
// salePrice PDP pricePer (/.ud, /caja.)
const getP30_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(24)};
    font-weight: 700;
    line-height: ${rem.get(35)};
    letter-spacing: 0.27px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(30, rem.base)};
      line-height: ${rem.get(42, rem.base)};
      letter-spacing: 0.33px;
    }
  `;
};
exports.getP30_2 = getP30_2;
const getP26 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20)};
    font-weight: 700;
    line-height: ${rem.get(24)};
    letter-spacing: -0.22px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(26, rem.base)};
      line-height: ${rem.get(29, rem.base)};
      letter-spacing: -.26px;
    }
  `;
};
exports.getP26 = getP26;
const getP26_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: regular;
    line-height: ${rem.get(19)};
    letter-spacing: -0.8px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(26, rem.base)};
      font-weight: 300;
      line-height: ${rem.get(29, rem.base)};
      letter-spacing: -0.3px;
    }
  `;
};
exports.getP26_1 = getP26_1;
const getP26_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20)};
    font-weight: 700;
    line-height: ${rem.get(24)};
    letter-spacing: 0.22px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(26, rem.base)};
      line-height: ${rem.get(30, rem.base)};
      letter-spacing: -0.26px;
    }
  `;
};
exports.getP26_2 = getP26_2;
// ProductCard -> salePrice
const getP26_3 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20)};
    font-weight: 700;
    line-height: ${rem.get(21)};
    letter-spacing: .22px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(26, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: .29px;
    }
  `;
};
exports.getP26_3 = getP26_3;
// contents
const getP26_4 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: 300;
    line-height: ${rem.get(20)};
    letter-spacing: -0.8px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(26, rem.base)};
      line-height: ${rem.get(34, rem.base)};
      letter-spacing: -1.41px;
    }
  `;
};
exports.getP26_4 = getP26_4;
const getP25 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(19)};
    font-weight: 700;
    line-height: ${rem.get(24)};
    letter-spacing: -0.22px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(25, rem.base)};
      line-height: ${rem.get(29, rem.base)};
      letter-spacing: -.26px;
    }
  `;
};
exports.getP25 = getP25;
const getP20 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20)};
    font-weight: 700;
    line-height: ${rem.get(24)};
    letter-spacing: 0.5px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(24, rem.base)};
      letter-spacing: 0.5px;
    }
  `;
};
exports.getP20 = getP20;
const getP20_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(18)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -0.36px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26, rem.base)};
      letter-spacing: -0.5px;
    }
  `;
};
exports.getP20_1 = getP20_1;
const getP20_5 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(18)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -0.36px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26, rem.base)};
      letter-spacing: -0.5px;
    }
  `;
};
exports.getP20_5 = getP20_5;
const getP20_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: 500;
    line-height: ${rem.get(19)};
    letter-spacing: -.8px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26, rem.base)};
      letter-spacing: -.5px;
    }
  `;
};
exports.getP20_2 = getP20_2;
const getP20_3 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: bold;
    line-height: ${rem.get(19)};
    letter-spacing: -.8px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(16, rem.base)};
      letter-spacing: -.5px;
    }
  `;
};
exports.getP20_3 = getP20_3;
const getP20_4 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: 300;
    line-height: ${rem.get(19)};
    letter-spacing: -.8px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26, rem.base)};
      letter-spacing: -.5px;
    }
  `;
};
exports.getP20_4 = getP20_4;
const getP20_Mobile = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20)};
    font-weight: 600;
    line-height: ${rem.get(25)};
    letter-spacing: -0.23px;
  `;
};
exports.getP20_Mobile = getP20_Mobile;
const getP18_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 300;
    line-height: ${rem.get(19)};
    letter-spacing: -0.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: -0.36px;
    }
  `;
};
exports.getP18_1 = getP18_1;
const getP18_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(19)};
    letter-spacing: -0.3px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(25, rem.base)};
      letter-spacing: -0.36px;
    }
  `;
};
exports.getP18_2 = getP18_2;
const getP18_3 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(18)};
    font-weight: 500;
    line-height: ${rem.get(22)};
    letter-spacing: -0.36px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(22, rem.base)};
    }
  `;
};
exports.getP18_3 = getP18_3;
const getP18_4 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 700;
    line-height: ${rem.get(19)};
    letter-spacing: -0.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: -0.36px;
    }
  `;
};
exports.getP18_4 = getP18_4;
const getP18InputLabel = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -.3px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: -.36px;
    }
  `;
};
exports.getP18InputLabel = getP18InputLabel;
const getP18 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: -.36px;
    }
  `;
};
exports.getP18 = getP18;
const getP18InputHasContentLabel = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(12)};
    font-weight: 500;
    line-height: ${rem.get(10)};
    font-family: ${_2.themeProject.fonts.secondaryFont};
    letter-spacing: -.3px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(10, rem.base)};
      letter-spacing: -.36px;
    }
  `;
};
exports.getP18InputHasContentLabel = getP18InputHasContentLabel;
const getP18_1B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(19)};
    letter-spacing: -0.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      font-weight: 700;
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: 0px;
    }
  `;
};
exports.getP18_1B = getP18_1B;
const getP16 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16)};
    font-weight: 500;
    line-height: ${rem.get(19)};
    letter-spacing: -0.8px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(16, rem.base)};
      line-height: ${rem.get(19, rem.base)};
      letter-spacing: 0;
    }
  `;
};
exports.getP16 = getP16;
const getP15_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(12)};
    font-weight: 300;
    line-height: ${rem.get(15)};
    letter-spacing: -0.4px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -0.5px;
    }
  `;
};
exports.getP15_1 = getP15_1;
const getP15 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(12)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: 0.13px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.17px;
    }
  `;
};
exports.getP15 = getP15;
const getP15_1B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(13)};
    font-weight: 300;
    line-height: ${rem.get(15)};
    letter-spacing: -0.4px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -0.5px;
    }
  `;
};
exports.getP15_1B = getP15_1B;
const getP15_2B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: -.36px;
    }
  `;
};
exports.getP15_2B = getP15_2B;
const getP15_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(19)};
    letter-spacing: -.38px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(19, rem.base)};
    }
  `;
};
exports.getP15_2 = getP15_2;
const getP15_3 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 700;
    line-height: ${rem.get(25)};
    letter-spacing: -.38px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.17px;
    }
  `;
};
exports.getP15_3 = getP15_3;
const getP15_3B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 700;
    line-height: ${rem.get(20)};
    letter-spacing: 0px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: 0px;
    }
  `;
};
exports.getP15_3B = getP15_3B;
const getP15_4 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(18)};
    letter-spacing: -.38px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(18, rem.base)};
    }
  `;
};
exports.getP15_4 = getP15_4;
const getP15_5 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 300;
    line-height: ${rem.get(14)};
    letter-spacing: -.38px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(14, rem.base)};
    }
  `;
};
exports.getP15_5 = getP15_5;
const getP15_6 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(14)};
    letter-spacing: -.38px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(14, rem.base)};
    }
  `;
};
exports.getP15_6 = getP15_6;
const getP13_3 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(9)};
    font-weight: 700;
    line-height: ${rem.get(15)};
    letter-spacing: -.38px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(13, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.17px;
    }
  `;
};
exports.getP13_3 = getP13_3;
const getP12 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(9)};
    font-weight: 700;
    line-height: ${rem.get(15)};
    letter-spacing: 0.1px;

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.13px;
    }
  `;
};
exports.getP12 = getP12;
const getP12_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(10)};
    font-weight: 300;
    line-height: ${rem.get(13)};
    letter-spacing: -.33px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(14, rem.base)};
      letter-spacing: -0.3px;
    }
  `;
};
exports.getP12_1 = getP12_1;
const getP12_1B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(10)};
    font-weight: 300;
    line-height: ${rem.get(13)};
    letter-spacing: -.33px;
    font-family: ${_2.themeProject.fonts.tertiaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(14, rem.base)};
      letter-spacing: -0.3px;
    }
  `;
};
exports.getP12_1B = getP12_1B;
const getP12_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(10)};
    font-weight: 300;
    line-height: ${rem.get(13)};
    letter-spacing: -.33px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(14, rem.base)};
      letter-spacing: -.3px;
    }
  `;
};
exports.getP12_2 = getP12_2;
const getP12_3 = () => {
    return (0, styled_components_1.css) `
  font-size: ${rem.get(10)};
  font-weight: regular;
  line-height: ${rem.get(25)};
  letter-spacing: -.5px;
  font-family: ${_2.themeProject.fonts.secondaryFont};
  ${_1.mq.greaterThan('tablet')} {
    font-size: ${rem.get(12, rem.base)};
    line-height: ${rem.get(20, rem.base)};
    letter-spacing: -.5px;
  }
`;
};
exports.getP12_3 = getP12_3;
const getP10_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(8.5)};
    font-weight: 300;
    line-height: ${rem.get(15)};
    letter-spacing: 0;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet')} {
      font-size:  ${rem.get(10)};
      line-height: ${rem.get(15)};
    }
  `;
};
exports.getP10_1 = getP10_1;
const getP10 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(10)};
    font-weight: 700;
    line-height: ${rem.get(15)};
    letter-spacing: .25px;

    ${_1.mq.greaterThan('tablet')} {
      font-size:  ${rem.get(10, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: .25px;
    }
  `;
};
exports.getP10 = getP10;
const getP9 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(10)};
    font-weight: 300;
    line-height: ${rem.get(12)};
    letter-spacing: .25px;

    ${_1.mq.greaterThan('tablet')} {
      font-size:  ${rem.get(10, rem.base)};
      line-height: ${rem.get(12, rem.base)};
      letter-spacing: .25px;
    }
  `;
};
exports.getP9 = getP9;
exports.getP14Search = (0, styled_components_1.css) `
  ${_1.mq.greaterThan('tablet')} {
    font-size: ${rem.get(14, rem.base)};
    line-height: ${rem.get(17, rem.base)};
  }
`;
exports.getP15Search = (0, styled_components_1.css) `
  font-size: ${rem.get(15, rem.base)};
  font-weight: 500;
  letter-spacing: -.38px;
  line-height: ${rem.get(18, rem.base)};

  ${_1.mq.greaterThan('tablet')} {
    font-size: ${rem.get(15, rem.base)};
    line-height: ${rem.get(18, rem.base)};
  }
`;
exports.getP27_P18 = (0, styled_components_1.css) `
  font-size: ${rem.get(27, rem.base)};
  font-weight: 700;
  line-height: ${rem.get(29, rem.base)};
  letter-spacing: .56px;

  ${_1.mq.greaterThan('tablet')} {
    font-size: ${rem.get(18, rem.base)};
    line-height: ${rem.get(22, rem.base)};
    letter-spacing: -.36px;
  }
`;
exports.getP20v2 = (0, styled_components_1.css) `
  font-size: ${rem.get(20, rem.base)};
  line-height: ${rem.get(24, rem.base)};
  letter-spacing: .5px;

  ${_1.mq.greaterThan('tablet')} {
    font-size: ${rem.get(20, rem.base)};
    line-height: ${rem.get(24, rem.base)};
    letter-spacing: .5px;
  }
`;
exports.getP17NavLinks = (0, styled_components_1.css) `
  font-weight: bold;
  letter-spacing: 0;
  font-family: ${_2.themeProject.fonts.primaryFont};

  ${_1.mq.greaterThan('tablet')} {
    font-size: ${rem.get(17, rem.base)};
    line-height: ${rem.get(18, rem.base)};
  }
`;
const font12_15 = (mobile = true, weight = 400) => (0, styled_components_1.css) `
  font-size: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
`;
exports.font12_15 = font12_15;
