import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { useTranslator, useCustomer } from '@/hooks'
import { menuUlVariants, menuListVariants } from '../layoutVariants'
import styled from 'styled-components'
import { mq, vw, getP53 } from '@dy/commons/styles'

interface IList {
  firstCategory?: any | null,
}

const NavItemList = styled(motion.ul)<any>`
  display: flex;
  flex-direction: column;

  ${mq.greaterThan('nexus7')} {
    grid-column: 1 /span 3;
    grid-row: 3;
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 2 / span 4;
    grid-row: 1 / span 2;
  }

  li {
    margin-bottom: ${vw(15, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(15, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 15px;
    }
  }

  a {
    ${getP53()}
    color: ${({ theme }) => theme.colors.creme};
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    padding-bottom: 4px;

    ${mq.greaterThan('tablet')} {
      font-weight: bold;
      padding: 0 ${vw(15, 'desktop')};
      position: relative;
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 15px;
    }

    &.active {
      border-bottom: 0;
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
    }

    > span {
      right: ${vw(30, 'desktop')};
      max-width: calc(100% - ${vw(30, 'desktop')});
    }
  }
`

const getListItems = (links, pathname, locale, firstCategory) => {
  const Links = []

  for (let idx = 0; idx < links.length; idx++) {
    const linkData = links[idx]
    const { name, slug, type } = linkData
    const isShopLink = slug === '/shop' || slug === '/catalog'


    if (isShopLink && firstCategory && typeof window !== 'undefined') {
      //@ts-ignore
      window.NO_URL_KLK = `/catalog/${firstCategory.slug?.[locale]}`
    }

    Links.push(
      <motion.li key={idx} variants={menuListVariants}>
        {type === 'external' ?
          <a rel='noreferrer' target='_blank' href={slug}>{name}</a> :
          <Link href={isShopLink && firstCategory ? `/catalog/${firstCategory.slug?.[locale]}` : slug} >
            <a className={pathname.includes(slug) ? 'active' : ''}>{name}</a>
          </Link>
        }
      </motion.li>
    )
  }
  return Links
}

export const List = memo(({  firstCategory = null }:IList) => {
  const { t } = useTranslator()
  const { locale:routerLocale, pathname } = useRouter()
  const locale = routerLocale.split('-')[0]
  const { customer } = useCustomer()
  const fakeList = useMemo(() => {
    if(customer) {
      return [
        { name: t('layout.nav.shop.name'), slug: t('layout.nav.shop.pathname') },
        { name: t('layout.nav.my_usuals.name'), slug: t('layout.nav.my_usuals.pathname') }
      ]
    } else {
      return [
        { name: t('layout.nav.products.name'), slug: t('layout.nav.products.pathname') },
        { name: t('layout.nav.catalogue.name'), slug: t('layout.nav.catalogue.pathname') },
        { name: t('layout.nav.contact_us.name'), slug: t('layout.nav.contact_us.pathname') }
      ]
    }
  },[ t, customer])

  const ListItems = useMemo(() => {
    return getListItems(fakeList, pathname, locale, firstCategory)
  }, [pathname, firstCategory, locale, fakeList])

  return (
    <NavItemList className='nav-list' initial='inactive' animate='active' exit='exit' variants={menuUlVariants}>
      <motion.li variants={menuListVariants}>
        <Link href='/'>
          <a className={pathname === '/' ? 'active' : ''}>{t('layout.nav.home.name')}</a>
        </Link>
      </motion.li>
      {ListItems}
    </NavItemList>
  )
})
