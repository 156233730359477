"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToast = void 0;
const react_1 = require("react");
const context_1 = require("../context");
const useToast = () => {
    const ctx = (0, react_1.useContext)(context_1.ToastContext);
    if (!ctx)
        throw new Error('useToast must be used within a ToastProvider');
    return ctx;
};
exports.useToast = useToast;
