"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = exports.noRegister = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../../styles");
const media_1 = require("../media");
const hasContentState = () => (0, styled_components_1.css) `
  ${(0, styles_1.getP18InputHasContentLabel)()}
  opacity: 1;
  transform: translateY(-${(0, styles_1.vw)(25, 'mobile')});

  ${styles_1.mq.greaterThan('tablet')} {
    transform: translateY(-${(0, styles_1.vw)(30, 'desktop')});
  }

  ${styles_1.mq.greaterThan('desktop')} {
    transform: translateY(-30px);
  }
`;
const mediaPasswordIcon = {
    type: 'image',
    alt: 'Password eye icon',
    default: {
        src: '/images/svg/i--password-eye.svg',
        size: { width: 18, height: 18 }
    }
};
// aria-invalid https://react-hook-form.com/advanced-usage
const getIcon = (icon, type, setInputType) => {
    if (!icon)
        return false;
    if (typeof icon === 'boolean' || typeof icon === 'string') {
        if (type === 'password') {
            const Icon = (0, jsx_runtime_1.jsx)("button", Object.assign({ type: 'button', className: 'btn--toggle-password', onClick: (e) => {
                    e.preventDefault();
                    setInputType(prevType => prevType === 'password' ? 'text' : 'password');
                } }, { children: (0, jsx_runtime_1.jsx)(media_1.Figure, { media: mediaPasswordIcon }) }), 'icon');
            return [Icon, true];
        }
        else if (type === 'search') {
            // Search and clear action
        }
    }
    else if ((0, react_1.isValidElement)(icon)) {
    }
};
const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null });
exports.noRegister = noRegister;
// Attributes https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
const Input = ({ type = 'text', name = '', defaultValue = '', label, autoComplete, placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, col, register = exports.noRegister, icon, className, textColor = 'darkred', bgColor = 'background', error, autoFocus = false, onKeyUp = null }) => {
    const requireProps = typeof required === 'boolean' ? { required } : required;
    const { onChange: registerOnChange, onBlur: registerOnBlur, name: registerName, ref: registerRef } = register(name, requireProps);
    const ref = (0, react_1.useRef)(null);
    const [inputType, setInputType] = (0, react_1.useState)(type);
    const [inputValue, setInputValue] = (0, react_1.useState)(defaultValue);
    const handleChange = e => {
        var _a;
        registerOnChange && registerOnChange(e);
        onChange && onChange(e);
        setInputValue((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
    };
    const handleBlur = e => {
        var _a;
        registerOnBlur && registerOnBlur(e);
        setInputValue((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
    };
    const ButtonIcon = (0, react_1.useMemo)(() => getIcon(icon, type, setInputType), [icon, type]);
    (0, react_1.useEffect)(() => {
        if (error)
            console.log(`input ${name} error`, error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    (0, react_1.useEffect)(() => {
        if (autoFocus && ref.current)
            ref.current.focus();
    }, [autoFocus]);
    return ((0, jsx_runtime_1.jsxs)(Label, Object.assign({ "$hasContent": !!defaultValue || !!inputValue, "$hidden": inputType === 'hidden', "$col": col, "$buttonIcon": !!ButtonIcon, "$placeholderVisible": placeholderVisible, "$textColor": textColor, "$bgColor": bgColor, "$hasError": !!error }, className && { className }, { children: [(0, jsx_runtime_1.jsx)("input", Object.assign({ type: inputType, name: registerName !== null && registerName !== void 0 ? registerName : name, disabled: disabled, readOnly: readOnly, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, onChange: handleChange, onBlur: handleBlur, "aria-invalid": !!error, onKeyUp: onKeyUp }, typeof required === 'boolean' && { required }, { ref: (e) => {
                    ref.current = e;
                    registerRef && registerRef(e);
                } }, autoComplete && { autoComplete }, defaultValue && { defaultValue }, (!defaultValue && !registerRef && inputValue && { value: inputValue }))), label && (0, jsx_runtime_1.jsx)("span", { children: label }), ButtonIcon && ButtonIcon, (error === null || error === void 0 ? void 0 : error.message) && (0, jsx_runtime_1.jsx)("span", Object.assign({ role: 'alert' }, { children: error.message }))] })));
};
exports.Input = Input;
const Label = styled_components_1.default.label `
  display: flex;
  flex-direction: column;
  margin-bottom: ${(0, styles_1.vw)(20, 'mobile')};
  padding: 0 ${(0, styles_1.vw)(4.25, 'mobile')};
  position: relative;
  width: 100%;

  ${styles_1.mq.greaterThan('nexus7')} {
    margin-bottom: ${(0, styles_1.vw)(20, 'nexus7')};
    padding: 0 ${(0, styles_1.vw)(4.25, 'nexus7')};
  }

  ${styles_1.mq.greaterThan('tablet')} {
    margin-bottom: ${(0, styles_1.vw)(30, 'desktop')};
    padding: 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  }

  ${styles_1.mq.greaterThan('desktop')} {
    margin-bottom: 30px;
    padding: 0 12.5px;
  }

  ${({ $hidden }) => $hidden && (0, styled_components_1.css) `
    opacity: 0;
    pointer-events: none;
    position: absolute;
    user-select: none;
    visibility: hidden;
  `}

  > input {
    ${(0, styles_1.getP18_3)()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor, $hasError }) => (0, styles_1.hex2Rgba)(theme.colors[$hasError ? 'input_error' : $textColor], $hasError ? 1 : .4)};
    color: inherit;
    height: ${(0, styles_1.vw)(60, 'mobile')};
    padding: ${(0, styles_1.vw)(25, 'mobile')} 0 ${(0, styles_1.vw)(12, 'mobile')};
    transition: 300ms border-color ease-out;
    width: 100%;

    ${styles_1.mq.greaterThan('nexus7')} {
      height: ${(0, styles_1.vw)(60, 'nexus7')};
      padding: ${(0, styles_1.vw)(25, 'nexus7')} 0 ${(0, styles_1.vw)(12, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      height: ${(0, styles_1.vw)(60, 'desktop')};
      padding: ${(0, styles_1.vw)(25, 'desktop')} 0 ${(0, styles_1.vw)(12, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      height: 60px;
      padding: 25px 0 12px;
    }

    &[readonly],
    &[disabled] {
      cursor: auto;
      pointer-events: none;
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }

    &[disabled] {
      opacity: .2;

      + span {
        opacity: .2;
      }
    }

    ${({ $buttonIcon }) => $buttonIcon && (0, styled_components_1.css) `
      padding-right: ${(0, styles_1.vw)(40, 'iphone6')};

      ${styles_1.mq.greaterThan('tablet')} {
        padding-right: ${(0, styles_1.vw)(40, 'desktop')};
      }

      > button {
        align-items: center;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        bottom: ${(0, styles_1.vw)(5, 'mobile')};
        display: flex;
        height: ${(0, styles_1.vw)(21, 'mobile')};
        position: absolute;
        right: ${(0, styles_1.vw)(4.25, 'mobile')};
        user-select: none;

        ${styles_1.mq.greaterThan('nexus7')} {
          bottom: ${(0, styles_1.vw)(5, 'nexus7')};
          height: ${(0, styles_1.vw)(21, 'nexus7')};
          right: ${(0, styles_1.vw)(4.25, 'nexus7')};
        }

        ${styles_1.mq.greaterThan('tablet')} {
          bottom: ${(0, styles_1.vw)(5, 'desktop')};
          height: ${(0, styles_1.vw)(21, 'desktop')};
          right: ${(0, styles_1.vw)(12.5, 'desktop')};
        }

        ${styles_1.mq.greaterThan('desktop')} {
          bottom: 5px;
          height: 21px;
          right: 12.5px;
        }
      }
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      -webkit-box-shadow: 0 0 0 500px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      box-shadow: 0 0 0 100px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      color: inherit;
    }

    ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
    `)}

    &:focus {
      border-color: ${({ theme, $textColor, $hasError }) => $hasError ? theme.colors['input_error'] : (0, styles_1.hex2Rgba)(theme.colors[$textColor], 1)};

      + span {
        ${hasContentState()}
      }
    }
  }

  > span {
    /* FLOATING LABEL */
    &:first-of-type {
      ${({ $placeholderVisible }) => $placeholderVisible ? hasContentState() : (0, styles_1.getP18InputLabel)()}
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      bottom: ${(0, styles_1.vw)(12, 'mobile')};
      color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      opacity: .4;
      position: absolute;
      transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
      white-space: nowrap;

      ${styles_1.mq.greaterThan('nexus7')} {
        bottom: ${(0, styles_1.vw)(12, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        bottom: ${(0, styles_1.vw)(12, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        bottom: 12px;
      }

      ${({ $hasContent }) => $hasContent ? (0, styled_components_1.css) `${hasContentState()}` : ''}
    }

    /* ERROR MESSAGE */
    &[role='alert'] {
      ${(0, styles_1.getP12_2)()}
      color: ${({ theme }) => theme.colors['input_error']};
      pointer-events: none;
      position: absolute;
      top: ${(0, styles_1.vw)(62, 'mobile')};

      ${styles_1.mq.greaterThan('nexus7')} {
        top: ${(0, styles_1.vw)(62, 'nexus7')};
      }

      ${styles_1.mq.greaterThan('tablet')} {
        top: ${(0, styles_1.vw)(65, 'desktop')};
      }

      ${styles_1.mq.greaterThan('desktop')} {
        top: 65px;
      }
    }
  }

  figure {
    height: ${(0, styles_1.vw)(15, 'mobile')};
    position: absolute;
    right: ${(0, styles_1.vw)(17, 'mobile')};
    top: 40%;
    width: ${(0, styles_1.vw)(23, 'mobile')};

    img {
      height: 100%;
      width: 100%;
    }

    ${styles_1.mq.greaterThan('nexus7')} {
      height: ${(0, styles_1.vw)(15, 'nexus7')};
      right: ${(0, styles_1.vw)(17, 'nexus7')};
      width: ${(0, styles_1.vw)(23, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      height: ${(0, styles_1.vw)(15, 'desktop')};
      right: ${(0, styles_1.vw)(17, 'desktop')};
      width: ${(0, styles_1.vw)(23, 'desktop')};
    }

    ${styles_1.mq.greaterThan('desktop')} {
      height: 15px;
      right: 17px;
      width: 23px;
    }
  }
`;
