import styled from 'styled-components'
import { mq, vw, getP30, getP20_1, getP18_1, getP53, getP15_1, hex2Rgba} from '@dy/commons/styles'



export const H2 = styled.h2`
  ${getP53()}
  color: ${({ theme }) => theme.colors.darkred};
  margin: ${vw(60, 'mobile')} 0 ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(80, 'nexus7')} 0 ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin: ${vw(80, 'desktop')} 0 ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: 80px 0 20px;
  }
`

export const P = styled.p`
  ${getP18_1()}
  color: ${({ theme }) => theme.colors.red};

  ${mq.greaterThan('tablet')} {
    ${getP20_1()}
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.red, .2)};
  }

  td {
    ${getP15_1()}
    padding: ${vw(16.5, 'mobile')} 0;
    vertical-align: top;
    width: 50%;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(16.5, 'nexus7')} 0;
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(16.5, 'desktop')} 0;
    }

    ${mq.greaterThan('desktop')} {
      padding: 16px 0;
    }

    &:nth-child(odd) {
      color: ${({ theme }) => theme.colors.darkred};
    }
  }
`

export const Dt = styled.dt`
  margin: ${vw(60, 'mobile')} 0 ${vw(24, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(60, 'nexus7')} 0 ${vw(24, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin: ${vw(60, 'desktop')} 0 ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: 60px 0 10px;
  }

  h2 {
    ${getP30()}
    color: ${({ theme }) => theme.colors.creme};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    text-align: left;
  }
`

export const SectionNutrition = styled.section`
  table td {
    &:nth-child(even) {
     text-align: right;
     padding-right: ${vw(25, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding-right: ${vw(25, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding-right: ${vw(25, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding-right: 25px;
      }
    }
  }
`

