"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shippingPostalCodeValid = void 0;
const shippingPostalCodeValid = (data, inputValue) => {
    if (typeof inputValue !== 'string')
        return false;
    if (Array.isArray(data) && data.length === 0)
        return true;
    let valid = false;
    for (let postalCode of data) {
        const { valueType, to, from, value } = postalCode;
        if (valueType === 'range') {
            const fromInt = parseInt(from);
            const toInt = parseInt(to);
            const valueInt = parseInt(inputValue);
            valid = fromInt < valueInt && valueInt < toInt;
        }
        else {
            valid = inputValue === value;
        }
        if (valid)
            break;
    }
    return valid;
};
exports.shippingPostalCodeValid = shippingPostalCodeValid;
