'use client'
import type { FC } from 'react'
import { memo, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { Form, Fieldset, Input, Button, DisplayError } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { useMutation } from '@apollo/client'
import { CUSTOMER_EMAIL_FORGOT_PASSWORD } from '@/api'
import { autoComplete, inputPattern } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'

type Props = {
  updateLevel?: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  animation?: any,
  bgColor?: TypeThemeColors
}

type TypeFormInputs = {
  email: string
}

export const FormUserForgotPassword: FC<Props> = memo(({ updateLevel, animation, bgColor = 'white' }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, reset, formState: { errors:formErrors } } = useForm<TypeFormInputs>()
  const [submitCustomError, setSubmitCustomError] = useState(null)

  const [customerEmailResetPassword, { loading }] = useMutation(CUSTOMER_EMAIL_FORGOT_PASSWORD)

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData, e) => {
    e.preventDefault()
    const { email } = formData

    try {
      await customerEmailResetPassword({
        variables: {
          email
        },
        context: { isPrivatePath: true }
      })

      updateLevel && updateLevel('feedback_email_reset_password')
    } catch(e) {
      console.warn(e)
      setSubmitCustomError(e)
    }

    reset()
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('modals.auth.forgot_password.text') as string}>
        <Input key={'email'} type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required={{
          required: t('form.errors.pattern_email') as string,
          pattern: inputPattern.email
        }} error={formErrors.email} bgColor={bgColor} />
      </Fieldset>
      <Button disabled={Object.keys(formErrors).length > 0 || loading} type='submit' bgType={'red'}>{t('commons.actions.send') as string}</Button>
      {submitCustomError && <DisplayError key='display-error' error={submitCustomError} />}
    </Form>
  )
})

