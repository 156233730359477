import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { currencyFormatter, getLang, parseDateString, cookie, downloadPDF } from '@dy/commons/utils'
import { Button } from '@dy/commons/components'
import type { TypeInvoices } from '@dy/commons/schema'
import { SectionAccount as Section, CardListItem } from './sharedAccountStyles'
import { useTranslator } from '@/hooks'
import { fetchAPI } from '@/api'
import { listVariants } from '../shop/shopVariants'
import { NoMessageP } from './SectionOrders'

type Props = {
  data: TypeInvoices
}

const getOrders = (orders, locale) => orders?.map((order, idx) => {
  const { dateCreated, totalExTax, bigcommerceId:code } = order
  const priceCurreny = currencyFormatter({ price: totalExTax, localeRegion: locale, decimals:2, trailingZeros: false, round: false})

  return (
    <CardListItem key={idx}>
      <table className={'overview order'}>
        <tbody>
          <tr><td>{code}</td> <td>{parseDateString({ dateString: dateCreated })}</td> <td>{priceCurreny}</td></tr>
        </tbody>
      </table>
    </CardListItem>
  )
})

const token = process.env.DY_PRIVATE_STORE_ACCESS_TOKEN

const onClickDownloadPdf = async (invoiceNumber) => {
  const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')

  if(!DYCustomerToken) throw new Error('no DYCustomerToken available to request pdf invoice')

  const response = await fetchAPI('download-customer-invoice-pdf', { slug: invoiceNumber }, {
    method: 'GET',
    headers: {
      'DY-Access-Token': `${token}`,
      'DY-Customer-Token': DYCustomerToken
    }
  }) as Blob

  downloadPDF(response, `mammafiore_invoice_${invoiceNumber}`)
}

const getInvoices = (items:TypeInvoices, t ,locale) => items.map((item, idx) => {
  const { id, invoiceDate, totalExTax, invoiceLines, number } = item
  const OrderList = getOrders(invoiceLines, locale)
  const priceCurreny = currencyFormatter({ price: totalExTax, localeRegion: locale, decimals:2, trailingZeros: false, round: false })

  return (
    <CardListItem key={idx}>
      <table className={'overview'} >
        <tbody>
          <tr><td className={'headline mobile'}>{t('account.invoice')} nº{id}</td></tr>
          <tr>
            <td>{t('account.invoice_issued')}</td>
            <td>{t('account.order.total')}</td>
          </tr>
          <tr>
            <td>{parseDateString({ dateString: invoiceDate, locale })}</td>
            <td>{priceCurreny}</td>
          </tr>
        </tbody>
      </table>
      <div className={'main-info'}>
        <div className={'products'}>
          <p className={'headline desktop'}>{t('account.invoice')} nº{number}</p>
          <ul>
            {OrderList}
          </ul>
        </div>
        <div className={'cta invoice'}>
          <Button bgType={'red'} outline onClick={() => onClickDownloadPdf(number)}>{t('account.invoice_download')}</Button>
        </div>
      </div>
    </CardListItem>
  )
})

export const SectionInvoices:FC<Props> = memo(({ data }) => {
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()



  const InvoiceList = useMemo(() => getInvoices(data, t, locale), [data, t, locale])

  return (
    <Section>
      {data.length > 0 ?
        <ul>
          {InvoiceList}
        </ul>
        :
        <NoMessageP initial={'inactive'} animate={'active'} exit={'inactive'} variants={listVariants}>{t('account.no_invoice')}</NoMessageP> 
      }
    </Section>
  )
})
