'use client'
import type { FC } from 'react'
import { memo} from 'react'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { defaultAuthModalLevel } from '@/components'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload?: { level: string, [key: string]: any }
  animation?: any
}

export const FeedbackAccountActivated:FC<Props> = memo(({ updateLevel }) => {
  const { t } = useTranslator()

  const onClick = () => {
    updateLevel(defaultAuthModalLevel, {}, true)
  }

  return (
    <Form>
      <Fieldset legend={t('modals.auth.feedback_account_active.text') as string}>
      </Fieldset>
      <Button type='button' bgType={'red'} outline={true} onClick={onClick}>{t('commons.actions.close')}</Button>
    </Form>
  )
})
