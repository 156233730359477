import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDeviceType, useMount, useInView } from '@dy/commons/hooks'
import { useTranslator } from '@/hooks'
// import { Figure, Link } from '@dy/commons/components'
import { Link } from '@dy/commons/components'
import { logoVariants, wrapperVariants } from './homeVariants'
import styled from 'styled-components'
import { mq, vw, designGrid, getP20_1 } from '@dy/commons/styles'

interface IBrandsSection {
  brands: any
}

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_LOCALE.split('-')[0]

const getLogos = (images) => images.map((img, idx) => {
  return (
    <AnimatePresence initial={false} key={idx} mode='wait'>
      <motion.article className='brand' key={img} variants={logoVariants} initial='initial' exit='exit' animate='animate'>
        <Link href={`/brand/${img?.slug?.[DEFAULT_LOCALE]}`}>
          {img?.logo?.url &&
            <img src={img?.logo?.url} alt={img?.alt ?? 'Brand logo'}></img>
          }
        </Link>
      </motion.article>
    </AnimatePresence>
  )
})

const getNewLogoArray = (prevLogoArray, images) => {
  const missingLogoArray = images.filter(img => {
    return !prevLogoArray.includes(img)
  })

  const newArrayLogo = Math.floor(Math.random() * missingLogoArray.length)
  const oldArrayLogo = Math.floor(Math.random() * prevLogoArray.length)

  const updatedArray = [...prevLogoArray]
  updatedArray[oldArrayLogo] = missingLogoArray[newArrayLogo]

  return updatedArray
}


export const SectionBrands = memo<IBrandsSection>(({ brands }) => {
  const { isMobile } = useDeviceType()
  const initialArray = brands.slice(0, isMobile ? 3 : 5)
  const [logoArray, changeLogoArray] = useState(initialArray)
  const isMounted = useMount()
  const { t } = useTranslator()

  const { ref, inView } = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: 1,
    triggerOnce: true
  })

  const Logos = useMemo(() => getLogos(logoArray), [logoArray])

  useEffect(() => {
    if (brands.length > initialArray.length) {
      const interval = setInterval(() => {
        changeLogoArray(prevLogoArray => getNewLogoArray(prevLogoArray, brands))
      }, 3200)

      return () => clearInterval(interval)
    }
  }, [isMounted, Logos, brands, initialArray])

  return (
    <Section ref={ref}>
      <h2>{t('home.brands.brands_title')}</h2>
      <Div variants={wrapperVariants} animate={inView ? 'active' : 'inactive'} initial='inactive'>
        {Logos}
      </Div>
    </Section>
  )
})


const Section = styled.section`
  margin-bottom: ${vw(73, 'mobile')};

  + .pack-highlighted-section {
    margin-top: -${vw(73, 'mobile')};
  }

  h2 {
    ${getP20_1()}
    grid-area: 1 / 1 / span 1 / span 6;
    text-align: center;
    margin-bottom: ${vw(28, 'mobile')};
    color: ${({ theme }) => theme.colors.darkred};
  }

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(73, 'nexus7')};

    + .pack-highlighted-section {
      margin-top: -${vw(73, 'nexus7')};
    }

    h2 {
      grid-area: 1 / 1 / span 1 / span 6;
      margin-bottom: ${vw(28, 'nexus7')};
    }
  }

  ${mq.greaterThan('tablet')} {
    ${designGrid({})}
    margin-bottom: ${vw(84, 'desktop')};

    + .pack-highlighted-section {
      margin-top: -${vw(84, 'desktop')};
    }

    h2 {
      grid-area: 1 / 1 / span 1 / span 12;
      margin-bottom: ${vw(40, 'desktop')};
    }
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 84px;

    + .pack-highlighted-section {
      margin-top: -84px;
    }

    h2 {
      margin-bottom: 40px;
    }
  }
`

const Div = styled(motion.div)<any>`
  grid-area: 1 / 1 / span 1 / span 6;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: ${vw(57, 'mobile')};
  margin: 0 ${vw(20, 'mobile')};

  article { height: inherit; }
  figure { height: 79px; }
  article {
    padding: ${vw(5, 'mobile')} ${vw(15, 'mobile')};
  }

  img {
    height: 100%;
    object-fit: contain;
    /* filter:invert(49%) sepia(37%) saturate(691%) hue-rotate(313deg) brightness(74%) contrast(127%); */
  }

  ${mq.greaterThan('nexus7')} {
    height: ${vw(57, 'nexus7')};
    margin: 0 ${vw(20, 'nexus7')};

    article {
      padding: ${vw(5, 'nexus7')} ${vw(15, 'nexus7')};
    }
  }

  ${mq.greaterThan('tablet')} {
    grid-area: 2 / 2 / span 1 / span 10;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
    height: ${vw(108, 'desktop')};

    article {
      padding: 0;
      margin: 0 ${vw(30, 'desktop')};
    }
  }

  ${mq.greaterThan('desktop')} {
    height: 108px;

    article {
      margin: 0 30px;
    }
  }
`
