'use client'
import { FC, useState } from 'react'
import { memo } from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { Form, Fieldset, Input, Button, DisplayError } from '@dy/commons/components'
import { useTranslator, useCustomer } from '@/hooks'
import { autoComplete, inputPattern, loginCustomer, DY_SIGNUP_ALLOWED } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { defaultAuthModalLevel } from '@/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  animation?: any,
  bgColor?: TypeThemeColors
}

type TypeFormInputs = {
  email: string,
  password: string
}

export const FormUserLogIn:FC<Props> = memo(({ updateLevel, animation, bgColor = 'white' }) => {
  const { t } = useTranslator()
  const { push } = useRouter()
  const { isActive } = useModal(MODALS.AUTH)
  const { register, handleSubmit, formState: { errors:formErrors } } = useForm<TypeFormInputs>()
  const [customError, setCustomError ] = useState(null)
  const { setLoaderActive } = useCustomer()

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData, e) => {
    e.preventDefault()
    const { email, password } = formData

    setLoaderActive(true)
    try {
      const res = await loginCustomer({ email, password, push })

      if(typeof res?.error === 'object' && Object.keys(res.error).length > 1) {
        setCustomError(res.error)
      } else {
        updateLevel(defaultAuthModalLevel, {}, true)
      }
      setLoaderActive(false)
    } catch(e) {
      console.warn('login catch e:', e)
      if(e?.error) setCustomError(e.error)
      setLoaderActive(false)
    }
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={false} ariaBusy={false} legend={t('modals.auth.login.text') as string}>
        <Input key={'email'} type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required={{
          required: t('form.errors.pattern_email') as string,
          pattern: inputPattern.email
        }} error={formErrors.email} bgColor={bgColor} />
        <Input key={'password'} type='password' name='password' register={register} label={t('form.fields.password') as string} placeholder='password' autoComplete={autoComplete.password.current} required icon='password' bgColor={bgColor} readOnly={!isActive} />
        <p className='p-subinput'>
          <button onClick={() => {
            updateLevel('forgot_password')
          }}>{t('modals.auth.login.forgot_password')}</button>
        </p>
      </Fieldset>
      <Button disabled={Object.keys(formErrors).length > 0} type='submit' bgType={'red'}>{t('commons.actions.login') as string}</Button>
      <DisplayError key='display-error--login' error={customError} />
      {DY_SIGNUP_ALLOWED && <p className='subtext'>{t('modals.auth.login.subtext')} <button type='button' onClick={() => { updateLevel('login_check_cif') }}>{t('modals.auth.login.subtext_action')}</button>.</p>}
    </Form>
  )
})

