import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { ThemeProvider }  from 'styled-components'
import { TranslationsProvider, CustomerProvider, ActiveVariantProvider } from '@/context'
import { ModalsProvider, CartProvider, ToastProvider } from '@dy/commons/context'
import { theme, Reset } from '@dy/commons/styles'
import { Layout } from '../components/layout/Layout'
import { GoogleTagManager } from '../components/layout/head/GoogleTagManager'
import 'flickity/css/flickity.css'
import { ApolloProvider } from '@apollo/client'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useApollo } from 'api/graphql'
import { PRO } from '@dy/commons/utils'

interface iProps {
  pageProps: {
    layout: any
    translations: any
    initialApolloState?: ApolloClient<NormalizedCacheObject>
  }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if(!metric.label) return
  const { label, name, startTime, value } = metric

  if (label === 'web-vital' && name === 'LCP') {
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
    console.info(`${label} ${name} | startTime: ${startTime} - value: ${value}`)
  }
}

const MyApp = ({ Component, pageProps }: AppProps & iProps) => {
  const { translations, layout, initialApolloState } = pageProps
  const apolloClient = useApollo(initialApolloState)

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <CustomerProvider>
          <ModalsProvider>
            <CartProvider>
              <ThemeProvider theme={theme}>
                <Reset />
                <TranslationsProvider localization={translations}>
                  <ToastProvider>
                    <ActiveVariantProvider>
                      <Layout data={layout} >
                        {PRO && <GoogleTagManager id={'GTM-K6MTWJM'} />}
                        <Component {...pageProps}/>
                      </Layout>
                    </ActiveVariantProvider>
                  </ToastProvider>
                </TranslationsProvider>
              </ThemeProvider>
            </CartProvider>
          </ModalsProvider>
        </CustomerProvider>
      </ApolloProvider>
    </>
  )
}

//@ts-ignore
export default MyApp
