import { getTransition } from '@dy/commons/styles'

export const menuUlVariants = {
  inactive: {
    opacity: 0,
    ...getTransition()
  },
  active: {
    opacity: 1,
    transition: {
      ...getTransition(),
      staggerChildren: .01
    }
  },
  exit: {
    opacity: 0,
    ...getTransition()
  }
}

export const menuListVariants = {
  inactive: {
    x: -10,
    opacity: 0,
    ...getTransition()
  },
  active: {
    x: 0,
    opacity: 1,
    ...getTransition()
  },
  exit: {
    opacity: 0
  }
}

export const userLoggedInVariants = {
  enter: {
    x: '100%',
    transition: { ...getTransition(.5) }
  },
  animate: {
    x: '0%',
    transition: { ...getTransition(.5) }
  },
  exit: {
    x: '100%',
    transition: { ...getTransition(.5) }
  }
}

export const firstNameVariants = {
  initial: {
    width: 0,
    transition: { ...getTransition(.3) }
  },
  animate: {
    width: '100%',
    transition: { ...getTransition(.3) }
  },
  exit: {
    width: 0,
    transition: { ...getTransition(.3) }
  }
}
