import { createContext, useEffect, useState } from 'react'
import type { FC, Dispatch, SetStateAction } from 'react'
import { useRouter } from 'next/router'
import type { Paths, TypeDictionaries, Locale, Localization } from '@dy/commons/schema'
import { NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'
import enTranslations from '../public/locales/en.json'
import esTranslations from '../public/locales/es.json'
import frTranslations from '../public/locales/fr.json'
import deTranslations from '../public/locales/de.json'
import ptTranslations from '../public/locales/pt.json'

const DEFAULT_LANG = NEXT_PUBLIC_LOCALE.split('-')[0] as Locale

// All translations keys level
export type TypeTranslationsJsonKeys = Paths<typeof esTranslations>

const dictionary:TypeDictionaries = {
  // @ts-ignore
  en: enTranslations,
  // @ts-ignore
  es: esTranslations,
  // @ts-ignore
  fr: frTranslations,
  // @ts-ignore
  de: deTranslations,
  // @ts-ignore
  pt: ptTranslations,
}

// First level only
type TypeTranslationsJsonRootKeys = keyof typeof enTranslations
type TypeNamespace = TypeTranslationsJsonRootKeys | TypeTranslationsJsonRootKeys[]


interface TranslationLocalization extends Localization {
  namespace: TypeNamespace
}

type TypeGetTranslations = (locale: string, namespace?: TypeNamespace) => TranslationLocalization

// to get translations data, used in getStaticProps of each page
export const getTranslations:TypeGetTranslations = (locale, namespace = null) => {
  const lang = typeof locale === 'string' ? locale.split('-')[0] as Locale : DEFAULT_LANG
  // const dict = await import(`../public/locales/${lang}.json`)
  const translations = {
    layout: dictionary[lang].layout,
    form: dictionary[lang].form,
    commons: dictionary[lang].commons,
    modals: dictionary[lang].modals
  }

  if(typeof namespace === 'string') {
    translations[namespace] = dictionary[lang][namespace]
  } else if(Array.isArray(namespace)) {
    for(let name of namespace) {
      if(typeof name === 'string') translations[name] = dictionary[lang][name]
    }
  }

  return { locale: lang, translations, namespace }
}

interface iTranslationsContextProps {
  readonly localization: Localization;
  readonly setTranslation: Dispatch<SetStateAction<Localization>>
}

const initialLocalization:Localization = {
  locale: DEFAULT_LANG,
  translations: dictionary[DEFAULT_LANG],
  namespace: null
}

export const TranslationsContext = createContext<iTranslationsContextProps>({
  localization: initialLocalization,
  setTranslation: () => null
})

type TypeTranslationProviderProps = {
  localization: Localization,
  children: JSX.Element
}

export const TranslationsProvider: FC<TypeTranslationProviderProps> = ({ localization, children }) => {
  const [localizationState, setLocalizationState] = useState(initialLocalization)
  const { locale:routerLocale } = useRouter()

  useEffect(() => {
    const locale = routerLocale.split('-')[0]

    if (localization?.locale && (locale !== localization?.locale || localizationState.namespace !== localization.namespace)) {
      setLocalizationState({
        locale: localization?.locale,
        translations: localization?.translations,
        namespace: localization?.namespace,
      })
    }
  }, [routerLocale, localization, localizationState])

  return (
    <TranslationsContext.Provider value={{ localization: localizationState, setTranslation: setLocalizationState }}>
      {children}
    </TranslationsContext.Provider>
  )
}

