import { memo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal, useCart } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { getP18_2, mq, vw} from '@dy/commons/styles'

import { useTranslator } from '@/hooks'
type MenuProps = {}

export const ModalCartType: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.CART_TYPE)
  const { t } = useTranslator()
  const { isFrozenCart } = useCart()

  return isActive && (
    <Modal isActive={isActive} close={toggle} title={''} xAlign='flex-end' dialogStyles={dialogStyles} size='s' hasHeader={true}>
      <p>{isFrozenCart ? t('product_page.product.frozen_cart') as string : t('product_page.product.fresh_cart') as string}</p>
    </Modal>
  )
})

const dialogStyles = css<any>`
  .modal__body {
    padding: ${vw(30, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(30, 'nexus7')} ${vw(40 - 4.25, 'nexus7')} ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(30, 'desktop')} ${vw(40 - 12.5, 'desktop')} ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 30px 40px 25px;
    }

    p {
      ${getP18_2()}
      margin-bottom: 5px;
      text-align: center;
    }
  }


`
