import { memo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { mq, vw} from '@dy/commons/styles'

import { useTranslator } from '@/hooks'
import { FormStore } from '..'
type MenuProps = {}

export const ModalRegion: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.REGION_LANG)
  const { t } = useTranslator()

  return isActive && (
    <Modal isActive={isActive} close={toggle} title={t('modals.region_lang.title') as string}  xAlign='flex-end' size='s' dialogStyles={dialogStyles} >
      <FormStore bgInputColor={'white'} textColorInput={'darkred'} textColorSubdividions={'darkred'} isModal={true} toggle={toggle} />
    </Modal>
  )
})

const dialogStyles = css<any>`
  .modal__body {
    padding: ${vw(30, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(30, 'nexus7')} ${vw(40 - 4.25, 'nexus7')} ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(30, 'desktop')} ${vw(40 - 12.5, 'desktop')} ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 30px 27.5px 25px;
    }

    span {
      margin-bottom: 5px;
    }
  }

  button[data-dismiss='modal'] {
    display: none;
  }

`
