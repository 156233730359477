import { memo } from 'react'
import { useTranslator } from '@/hooks'
import { H2, P } from './sharedDetailStyles'

interface ISectionIngredients {
  data: string
}

export const SectionIngredients = memo<ISectionIngredients>(({ data }) => {
  const { t } = useTranslator()
  return (
    <section>
      <H2>{t('product_page.product_description.nutritional_value.ingredients')}</H2>
      <P>{data}</P>
    </section>
  )
})
