import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'
import { GridMyProducts } from '..'
import {  Link } from '@dy/commons/components'
import { TypeOrderEdge } from '@dy/commons/schema'
import { getDynamicText, parseDateString } from '@dy/commons/utils'
import { getP12_2 } from '@dy/commons/styles'
import { mq, vw, hex2Rgba,  getP30, getP15, getP15_1, getP26_1 } from '@dy/commons/styles'
import { RepeatOrder } from 'components/cart'
import { TypeTranslationsJsonKeys } from 'context/TranslationContext'

export interface ISectionMyProducts {
  data: [TypeOrderEdge]
}

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    ${getP12_2()}
    color: ${({ theme }) => theme.colors.darkred};
    display: inline-block;
  }
`

const getOrders = (data, t) => data.map((item, idx) => {
  const { orderLines, id, deliveryDate } = item.node

  return (
    <ListOrders key={`${idx}-${id}`}>
      <Div>
        <h3>{deliveryDate ? getDynamicText(t('cart.delivery_date' as TypeTranslationsJsonKeys) as string, {
          deliveryDate: parseDateString({ dateString: deliveryDate, includeHour: false })
        }) : 'Llegará pronto'}</h3>
        {orderLines.map((item, idx) => {
          // We will display a maximum of 6 orderLines
          // to avoid broken layout for extra large order
          if(idx > 6) return false
          const { productName, quantity, id } = item

          return (
            <span key={`${idx}-${id}`}>{quantity} x {productName}{(orderLines.length - 1 !== idx) && ','}</span>
          )
        }).filter(Boolean)}
        {/* If more than 6 orderLines we display dots at the end */}
        {orderLines.length > 6 && <span>...</span>}
      </Div>
      <RepeatOrder orderLines={orderLines} />
    </ListOrders>
  )
})

export const SectionMyProducts = memo<ISectionMyProducts>(({ data }) => {
  const { t } = useTranslator()
  const Orders = useMemo(() => getOrders(data, t), [data, t])

  return (
    <SectionWrapper>
      <div className='left'>
        <div className='cta'>
          <h1>{t('account.home_my_products.last_orders')}</h1>
          <Link href={'/account/orders'}>{t('account.home_my_products.see_all')}</Link>
        </div>
        <ul>
          {Orders}
        </ul>
      </div>
      <div className='right'>
        <div className='cta'>
          <h1>{t('account.home_my_products.my_usuals')}</h1>
          <Link href={'/account/my-products'}>{t('account.home_my_products.see_all')}</Link>
        </div>
        <GridMyProducts/>
      </div>
    </SectionWrapper>
  )
})



const SectionWrapper = styled.section`
  background-color: ${({ theme }) => hex2Rgba(theme.colors.creme, .4)};
  color: ${({ theme }) => theme.colors.red};
  display: block;
  text-align: center;
  justify-content: unset;
  margin: ${vw(14, 'mobile')};
  padding: ${vw(40, 'mobile')} ${vw(25, 'mobile')};


  ${mq.greaterThan('nexus7')} {
    margin: ${vw(20, 'nexus7')};
    padding: ${vw(40, 'nexus7')} ${vw(25, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    display: flex;
    justify-content: space-between;
    padding: ${vw(50, 'desktop')} ${vw(70, 'desktop')};
    margin: ${vw(50, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 50px 70px;
    margin: 50px;
  }

  ${mq.greaterThan('desktop-wide')} {
    max-width: 1550px;
    margin: 50px auto;
  }

  h1 {
    ${getP30()}
    text-align:left;
  }

  a {
    ${getP15()}
  }

  .cta {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .left {
    width:unset;

    ul {
      display:block;
    }

    ${mq.greaterThan('tablet')} {
      width: 48%;
      ul {
        display:inline;
      }
    }
  }

  .right {
    width: unset;
    padding: ${vw(50, 'mobile')} 0;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(50, 'nexus7')} 0;
    }

    ${mq.greaterThan('tablet')} {
      width: 50%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left:${vw(120, 'desktop')};
      padding-right:0;
    }

    ${mq.greaterThan('desktop')} {
      padding-left: 120px;
    }

    p {
      ${getP15_1()}
    }
  }
`

const ListOrders = styled.li`
  display:flex;
  flex-direction: row;
  padding: ${vw(25, 'mobile')} 0;
  text-align: left;
  justify-content: space-between;
   align-items: center;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(25, 'nexus7')} 0 ;
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(30, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 30px 0;
  }


  > button {
    margin: 0;
    width:${vw(118, 'mobile')};
    height: ${vw(42, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width:${vw(118, 'nexus7')};
      height: ${vw(42, 'nexus7')};
    }
    ${mq.greaterThan('tablet')} {
      width:${vw(159, 'desktop')};
      height: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 159px;
      height: 40px;
    }
  }
  > div {
    flex-direction: column;
  }

  :first-child {
    padding-top: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-top: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-top: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-top: 40px;
    }
  }

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
  }

  h3 {
    ${getP26_1()}
    padding-bottom: ${vw(8, 'desktop')};
    text-align: left;
  }
`
