'use client'
import styled from 'styled-components'
import { vw, mq } from '@dy/commons/styles'
import { Figure } from '@dy/commons/components'

const closeMenuIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--burger-close.svg',
  alt: 'Close modal icon',
  size: { width: 12, height: 12 }
}

const burgerIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--burger.svg',
  alt: 'Burger menu icon',
  size: { width: 12, height: 12 }
}

export const Burger = ({ menuOpen = false, onClick }) => {
  return (
    <Button type='button' onClick={onClick}>
      <Figure media={menuOpen ? closeMenuIcon : burgerIcon} fill={false} />
    </Button>
  )
}

const Button = styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${vw(39, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    width: ${vw(39, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    display: none;
  }

  figure {
    width: ${vw(17, 'mobile')};

    ${mq.greaterThan('nexus7')} {
     width: ${vw(20, 'tablet')};
    }
  }
`