import { endpoints } from './endpoints'

type TypeFetchOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' // *GET
  mode?: 'cors' | 'no-cors' | 'same-origin', // no-cors, *cors, same-origin
  cache?: 'default' | 'no-cache' | 'no-cache' | 'reload' | 'force-cache' | 'only-if-cached', // *default
  credentials?: 'same-origin' | 'include' | 'omit', // *same-origin
  headers?: {
    'Accept'?: 'application/json' | 'application/pdf' | 'application/xml' | 'application/csv' | 'application/vnd.ms-excel',
    'Content-Type'?: 'application/json' | 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain'
    [key: string]: any
  },
  redirect?: 'follow' | 'manual' | 'error', // *follow
  referrerPolicy?: 'no-referrer' | 'no-referrer-when-downgrade' | 'origin' | 'origin-when-cross-origin' | 'same-origin' | 'strict-origin' | 'strict-origin-when-cross-origin' | 'unsafe-url' // *no-referrer-when-downgrade
  body?: any // body data type must match "Content-Type" header
}
// Any body that you want to add to your request: this can be a Blob, an ArrayBuffer, a TypedArray, a DataView, a FormData, a URLSearchParams, string object or literal

const defaultOptions: TypeFetchOptions = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
  body: null
}

type TypeQuery = {
  token?: string
  searchParams?: {
    q?: string,
    status?: 'ready' | 'pending' | 'active',
    page: number
  }
  q?: string
  slug?: string
  customerId?: string | number
  categorySlug?: string
  cartId?: string
  invoice_number?: string | number
}


type TypeFetchApi = (urlNamespace: keyof typeof endpoints, query?: TypeQuery, options?: TypeFetchOptions) => Promise<Response | Blob>

export const fetchAPI: TypeFetchApi = async (endpointNamespace, query = {}, fetchOptions) => {
  if (!endpointNamespace || !endpoints[endpointNamespace]) {
    throw new Error(`No endpoint with namespace: '${endpointNamespace}'`)
  }

  const url = endpoints[endpointNamespace](query)
  const options = !fetchOptions ? defaultOptions : { ...defaultOptions, ...fetchOptions }
  const body = options.body ?? null

  const res: Response = await fetch(url, { ...options, body })

  switch (res.status) {
    case 204:
      return res
    case 201:
    case 200:
      if (typeof window === 'undefined') return res
      if (endpointNamespace.indexOf('download') !== -1) {
        const file = await res.blob()
        return file
      }

      const data = await res.json()
      return data
    case 401:
      console.info('👮🏻‍♂️⛔️ Unauthorized')
      return res
    case 404:
      throw new Error(`DY API | 404 (Not found) for endpoint: ${endpointNamespace}`)
    case 422:
      console.log(`DY API | 422 (Unprocessable Entity) for endpoint: ${endpointNamespace}`)
      return res
    case 500:
      throw new Error('DY API | 500 - Some server error to check')
    default:
      throw new Error(`DY API | ${res.status} - Something went wrong with the fetch`)
  }
}
