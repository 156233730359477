import { getTransition } from '@dy/commons/styles'

const transition = { ...getTransition(.5) }

export const listVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  enter: {
    opacity: 1,
    transition: {
      ...getTransition(.5),
      staggerChildren: .01
    }
  },
  exit: {
    opacity: 0,
    transition
  }
}

export const itemVariants = {
  inactive: {
    y: -10,
    opacity: 0,
    transition
  },
  enter: {
    y: 0,
    opacity: 1,
    transition
  },
  exit: {
    y: -10,
    opacity: 0,
    transition
  },
}