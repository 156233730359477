import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useSlider } from '@dy/commons/hooks'
import { mq, vw, getTransition } from '@dy/commons/styles'

type Props = {
  banners: {
    titleSize: 'xl' | string,
    title: string
  }[]
}

export const SlimBanners: FC<Props> = memo(({ banners }) => {
  const { currentSlide } = useSlider(banners.length, true)

  const SlideData = useMemo(() => banners[currentSlide], [currentSlide, banners])

  return <Aside key={'slims'} $bannerFontSize={SlideData?.titleSize}>
    <AnimatePresence mode='wait'>
      <motion.div key={currentSlide} variants={bannerVariants} initial='inactive' animate='active' exit='exit' dangerouslySetInnerHTML={{ __html: SlideData?.title }} />
    </AnimatePresence>
  </Aside>
})

const Aside = styled.aside<{ $bannerFontSize?: 'xl' | string }>`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.creme};
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9998;

  ${mq.greaterThan('tablet')} {
    height: ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 30px;
  }

  div {
    display: flex;
    align-items: center;
    height: 100%;
  }

  /* Don't use flex in the paragraph, it removes the spaces between links when comes from CMS */
  p {
    width: 100%;
  }

  a {
    text-decoration: underline;
  }

  p, a {
    font-size: ${({ $bannerFontSize }) => $bannerFontSize === 'xl' ? '14px' : '10px'};
    line-height: ${({ $bannerFontSize }) => $bannerFontSize === 'xl' ? '18px' : '14px'};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    text-align: center;
    color: ${({ theme }) => theme.colors.red};
  }

  ~ .pdp-back-btn {
    ${mq.greaterThan('tablet')} {
      top: ${vw(200, 'desktop')};
    }
  }
`

const bannerVariants = {
  inactive: {
    y: '-10px',
    opacity: 0,
    ...getTransition()
  },
  active: {
    y: '0',
    opacity: 1,
    ...getTransition()
  },
  exit: {
    y: '10px',
    opacity: 0,
    ...getTransition()
  }
}
