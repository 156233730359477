import { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { SliderFlickity } from '@dy/commons/components'
import { ProductCard } from '@/components'
import { useTranslator } from '@/hooks'
import { useInView } from '@dy/commons/hooks'
import { pushAnalyticsEvent } from '@dy/commons/utils'
import { Dt } from './sharedDetailStyles'
import { mq, vw } from '@dy/commons/styles'

interface ISectionRelated {
  data: any,
  gaList: {
    id: string | number,
    name: string
  }
}


const getSlides = (products) => products.map((product, idx) => {
  return (
    <dd key={idx}>
      <ProductCard data ={{...product, idx: idx+1}} narrow/>
    </dd>
  )
})

export const SectionRelated = memo<ISectionRelated>(({ data, gaList }) => {
  const Slides = useMemo(() => getSlides(data), [data])
  const { t } = useTranslator()

  const { ref, inView } = useInView({
    rootMargin: '20px', // TOP  RIGHT
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    if (inView) {
      pushAnalyticsEvent('view_item_list', { products: data, ga_list: gaList })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, inView])

  return (
    <SectionRel ref={ref}>
      <dl>
        <Dt><h2>{t('product_page.bottom-page.related')}</h2></Dt>
        <SliderFlickity className={'slider--product-card'} options={{pageDots:false, groupCells: '90%', cellAlign: 'left', draggable: true, prevNextButtons: true }} >
          {Slides}
        </SliderFlickity>
      </dl>
    </SectionRel>
  )
})


const SectionRel = styled.section`
  margin-right: -${vw(20, 'mobile')};
  margin-left: -${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-left: -${vw(20, 'nexus7')};
    margin-right: -${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-left: -${vw(30, 'desktop')};
    margin-right: -${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-right: -30px;
    margin-left: -30px;
  }

  .flickity-slider dd:first-of-type, h2 {
    padding-left: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-left: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-left: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-left: 30px;
    }
  }

  .slider--flickity .previous {
    svg { display: none; }
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      content: url('/images/svg/i--arrow-left.svg');
      z-index: 100;
    }
  }

  .slider--flickity .next {
    svg { display: none; }
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      content: url('/images/svg/i--arrow-right.svg');
      z-index: 100;
    }
  }

`
