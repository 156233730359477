import { useEffect, useState } from 'react'
import type { FC, MouseEvent, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
// import type FlattenSimpleInterpolation from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { slugify } from '@dy/commons/utils'
import { useTranslator } from '@/hooks'
import { Figure } from '@dy/commons/components'
import { mq, vw, designGrid, hex2Rgba, grid, getP20 } from '@dy/commons/styles'

type xAlign = 'flex-start' | 'center' | 'flex-end' | 'right'
type size = 'full' | 'auto' | 's'
export interface iModal {
  title?: string,
  children: ReactNode,
  isActive: boolean,
  hasHeader?: boolean,
  isModalFilters?: boolean,
  zIndex?: number,
  readonly close: () => void,
  readonly xAlign?: xAlign,
  readonly variants?: (isActive:boolean) => {
    initial: { [key:string]: any },
    animate: { [key:string]: any },
    exit: { [key:string]: any },
    transition?: {
      duration: number,
      [key: string]: any
    }
  },
  readonly dialogStyles?: any,
  readonly onClickBack?: () => void
  readonly size?: size
}

//triggerbuild

const closeIcon = {
  type: 'svg' as const,
  src: '/images/svg/close.svg',
  alt: 'Close modal icon',
  size: { width: 12, height: 12 }
}

const backIcon = {
  type: 'svg' as const,
  src: '/images/svg/chevron--left.svg',
  alt: 'Go back icon',
  size: { width: 12, height: 12 }
}

const defaultDialogVariants = (isActive: boolean) => ({
  initial: { y: '10px' },
  animate: { y: isActive ? 0 : '10px' },
  exit: { y: '10px' },
  transition: { duration: .3, delay: .1 }
})

export const Modal:FC<iModal> = ({ title = '', children, isActive, close, xAlign = 'center', variants = defaultDialogVariants, dialogStyles = null, onClickBack = null, size = 'auto', hasHeader = true, zIndex = 600, isModalFilters = false }) => {
  const [mounted, setMounted] = useState(false)
  const { t } = useTranslator()
  const titleAsSlug = slugify(title)
  const onClick = (e:MouseEvent) => {
    if((e.target as HTMLElement).getAttribute('tabindex') === '-1') close()
  }

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return (mounted ? createPortal(
    <AnimatePresence>
      <ModalStyled tabIndex={-1} aria-hidden={!isActive} initial={{ opacity: 0 }} animate={{ opacity: isActive ? 1 : 0 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }} onClick={onClick} $xAlign={xAlign} $zIndex={zIndex} $isModalFilters={isModalFilters} $isActive={isActive}>
        <DialogStyled $size={size} role='dialog' aria-modal='true' aria-labelledby={titleAsSlug} {...variants(isActive)} {...(dialogStyles && { $dialogStyles: dialogStyles })} $xAlign={xAlign} $isActive={isActive}>
          {hasHeader &&
          <header>
            {onClickBack && <ButtonHeader className='btn--back' type='button' onClick={onClickBack}>
              <Figure media={backIcon} fill={false} />
            </ButtonHeader>}
            <h2 id={titleAsSlug}>{title}</h2>
            <ButtonHeader type='button' data-dismiss='modal' aria-label={t('commons.actions.close') as string} onClick={close}>
              <Figure media={closeIcon} fill={false} />
            </ButtonHeader>
          </header>
          }
          <div className='modal__body'>
            {children}
          </div>
        </DialogStyled>
      </ModalStyled>
    </AnimatePresence>, document.body) : null
  )
}


// VER DE SACAR el Aside como un overlay externo para minimizar el componente del Modal pero tiene que funcionar un self-justify para reemplazar al xAlign que tenemos ahora
const ModalStyled = styled(motion.aside) <{ $xAlign: xAlign, $zIndex, $isModalFilters, $isActive }>`
  ${designGrid({})}
  align-items: flex-start;
  background-color: rgba(0, 0, 0, .2);
  /* background-color: rgba(255, 255, 255, .85); */
  bottom: 0;
  display: flex;
  height: ${({ $isModalFilters  }) => $isModalFilters ? '100%' :' 100vh'};
  height: ${({ $isModalFilters  }) => $isModalFilters ? '100%' :' 100dvh'};
  justify-content: ${({ $xAlign }) => $xAlign};
  left: 0;
  opacity: 0;
	outline: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  width: 100%;
  will-change: opacity;
  z-index: ${({ $zIndex, $isActive }) => !$isActive ? 0 : $isActive && $zIndex ? $zIndex : 600};

  &[aria-hidden='false'] {
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
  }

  .modal__body {
    position: relative;
    width: 100%;
    height: ${({ $isModalFilters  }) => $isModalFilters ? '100vh' :'unset'};
    height: ${({ $isModalFilters  }) => $isModalFilters ? '100dvh' :'unset'};

  }
  /* MOBILE LANDSCAPE */
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    width: 100%;
    .modal__body {
      height: unset;
    }
  }

  ${mq.greaterThan('tablet')} {
    height: ${({ $isModalFilters  }) => $isModalFilters ? '100%' :' 100vh'};
  }
`

export const ButtonHeader = styled.button`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  max-width: 48px;
  width: ${vw(48, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('nexus7')} {
    width: ${vw(48, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    width: ${vw(48, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 48px;
    width: 48px;
  }

  figure {
    max-height: 28px;
    max-width: 28px;
    width: ${vw(10, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width: ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      width: ${vw(10, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 10px;
    }
  }

  &.btn--back {
    left: 0;
    position: absolute;
  }
`

const DialogStyled = styled<any>(motion.div)`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  box-shadow: 0 25px 50px rgba(0, 0, 0, .15);
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  grid-column: 1 / span 6;
  margin: ${({ $xAlign }) => $xAlign === 'right' ? '0px 0px 0px auto' : `110px auto ${vw(200, 'mobile')}`};
  max-width: ${({ $size }) => $size === 'full' ? 'unset' : $size === 's' ? '550px' : '740px'};
  position: relative;
  width: calc(100% - ${grid.mobile.columns.gutter.width}px);
  ${({ $xAlign }) => $xAlign === 'right' && 'height: 100vh;'}
  ${({ $xAlign }) => $xAlign === 'right' && 'height: 100dvh;'}
  ${({ $isActive }) => !$isActive && css`
    user-select: none;
    pointer-events: none;

    > *,
    li, dd,
    button, a, form, input, fieldset,
    figure, div {
      user-select: none;
      pointer-events: none;
    }
  `}

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
    margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : `50px auto ${vw(200, 'desktop')}`};
    max-width: ${({ $size }) => $size === 's' ? vw(550, 'desktop'): 'unset'};
    width: calc(100% - ${grid.tablet.columns.gutter.width}px);
  }

  ${mq.greaterThan('desktop')} {
    grid-column: 1 / span 12;
    margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : '50px auto 200px'};
    max-width: ${({ $size }) => $size === 's' ? '550px': 'unset'};
    width: calc(100% - ${grid.tablet.columns.gutter.width}px);
  }

  ${mq.greaterThan('desktop-xs')} {
    width: 100%;
  }

  > header {
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
    display: flex;
    height: ${vw(50, 'mobile')};
    justify-content: center;
    padding-left: ${vw(48, 'mobile')};
    position: relative;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(50, 'nexus7')};
      padding-left: ${vw(48, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(50, 'desktop')};
      padding-left: ${vw(48, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 50px;
      padding-left: 48px;
    }

    h2 {
      ${getP20}
      color: ${({ theme }) => theme.colors.red};
      font-weight: 500;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .modal__body {
    padding: ${vw(50, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(75, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(50, 'nexus7')} ${vw(40 - 4.25, 'nexus7')} ${vw(75, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(50, 'desktop')} ${vw(40 - 12.5, 'desktop')} ${vw(75, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 50px 27.5px 75px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : '40px auto' };
    height: max-content;

    > header {
      height: ${vw(30, 'mobile')};
    }
    .modal__body {
      padding: ${vw(20, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(75, 'mobile')};
      min-height: 100%;
    }
  }

  ${({ $dialogStyles }) => $dialogStyles && $dialogStyles}
`
