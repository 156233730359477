'use client'
import type { FC } from 'react'
import { memo } from 'react'
import { Form, Fieldset, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { useTranslator } from '@/hooks'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  animation?: any
}

export const FormUserPreLogin:FC<Props> = memo(({ updateLevel, animation }) => {
  const { t } = useTranslator()

  return (
    <Form {...animation && animation}>
      <Fieldset legend={t('modals.auth.prelogin.text') as string}>
      </Fieldset>
      <Button type='button' onClick={() => { updateLevel('login') }} bgType={'red'}>{t('modals.auth.prelogin.actions.primary')}</Button>
      <Button type='button' onClick={() => { updateLevel('login_check_cif') }} bgType={'red'} outline={true}>{t('modals.auth.prelogin.actions.secondary')}</Button>
      <p className='subtext'>{t('modals.auth.prelogin.subtext')} <button type='button' onClick={() => { updateLevel('signup_cif') }}>{t('modals.auth.prelogin.subtext_action')}</button>.</p>
    </Form>
  )
})

