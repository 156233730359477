import { memo, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { getCommercialsByCountry, getCommercials, defaultCommercial } from '@/components'
import { mq, vw, getP20_4, getP18_1, createUnderline } from '@dy/commons/styles'
import { cookie, getDynamicText } from '@dy/commons/utils'
import { useTranslator } from '@/hooks'
import { NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'


const catalogRegionName = {
  'ES-IB': 'Ibiza-Formentera',
  'ES-PM': 'Palma de Mallorca',
  'ES-ME': 'Menorca',
  'ES-NC': 'Navarra',
  'ES-PC': 'Ibiza-Formentera',
  'ES-AN': 'Andalucía',
  'ES-VC': 'País Vasco',
  'ES-CT': 'Cataluña',
  'ES-CN': 'Canarias',
  'ES-MD': 'Madrid',
  'ES-ES': 'España',
  'FR-FR': 'France',
  'EN-GB': 'United Kingdom',
  'DE-DE': 'Deutschland',
  'PT-PT': 'Portugal',
}

type MenuProps = {}
const regionWithComercials = ['ES-IB', 'ES-PM', 'ES-ME', 'ES-NC', 'ES-PC', 'ES-AN', 'ES-VC']
export const ModalComercialRegion: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.COMERCIAL)
  const { t } = useTranslator()
  const [catalogRegion, setCatalogRegion] = useState('España')
  const [commercials, setCommercials] = useState([])
  const ComercialsList = useMemo(() => getCommercials(commercials.length > 0 ? commercials : defaultCommercial, 'white', true, t), [commercials, t])
  const shouldDisplay = useMemo(() => regionWithComercials.indexOf(catalogRegion) === 0 ? true : false, [catalogRegion])
  const shouldDisplayCatalogRegionName = (NEXT_PUBLIC_LOCALE !== 'en-GB' && NEXT_PUBLIC_LOCALE !== 'fr-FR' && NEXT_PUBLIC_LOCALE !== 'de-DE' && NEXT_PUBLIC_LOCALE !== 'pt-PT') 

  useEffect(() => {
    if(isActive) {
      const cookieCatalogRegion = cookie.get('DY_CATALOG_REGION')
      if (cookieCatalogRegion) {
        setCatalogRegion(cookieCatalogRegion)
      }

      getCommercialsByCountry('ES').then((res) => {
        const arrOfCommercials = Array.from(res)
        if (cookieCatalogRegion) {
          // @ts-ignore
          const commercialsFiltered = arrOfCommercials.filter(item => item.operate_in.find(area => area === cookieCatalogRegion))
          setCommercials(commercialsFiltered)
        }
      })
    }
  }, [isActive, catalogRegion])
  return (
    <Modal isActive={isActive} close={toggle} title={t('modals.commercial.title') as string} xAlign='flex-end' dialogStyles={dialogStyles} size={'s'}>
      {['ES-CT', 'ES-MD', 'ES-CN'].includes(catalogRegion) ?
        <>
          <div>
            <p>{t('modals.comercial_region.professional')}<a href={t('modals.comercial_region.mail_to_link') as string}>{t('modals.comercial_region.mail_to')}</a> {t('modals.comercial_region.open_account')}</p>
            {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
            <p>{t('modals.comercial_region.b2c')} <a target='_blank' href={t('modals.comercial_region.link_to_b2c') as string} >{t('modals.comercial_region.link_b2c')}</a></p>
          </div>
        </>
        :
        <>
          {shouldDisplayCatalogRegionName && <span>
            {getDynamicText(t('modals.commercial.in') as string, {
              storeName:catalogRegionName[catalogRegion] ?? 'España'
            })}
          </span>}
          <ul>
            {ComercialsList}
          </ul>
          <div className='actions'>
            {shouldDisplay && <p>{t('modals.public_interested.contact')} <a href={`mailto:${t('modals.public_interested.mail')}`}>{t('modals.public_interested.mail')}</a></p>}
            {/* <p>{t('modals.public_interested.particular')} {t('modals.public_interested.cta_hm')} <a target='_blank' href='https://hellomamma.es' rel="noreferrer">{t('modals.public_interested.online')}</a></p> */}
          </div>
        </>
      }
    </Modal>
  )
})

const dialogStyles = css`
  span {
    ${getP20_4()}
    color: ${({ theme }) => theme.colors.red};
    text-align: left;
    margin-bottom: 2px;
    padding-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(10, 'desktop')};
    }
  }

  .actions {
    margin-top:${vw(50, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-top:${vw(50, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top:${vw(50, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 50px;
    }
  }

  p {
    ${getP18_1()}
    color: ${({ theme }) => theme.colors.darkred};
    margin-bottom:${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom:${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom:${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }

  ul {
    margin-top: 6px;
  }

  span,
  p {
    a {
      color: ${({ theme }) => theme.colors.red};
      ${createUnderline({ color: 'red', height: 1, reverse: true })}
    }
  }

  .modal__body {
    padding: ${vw(50, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(50, 'nexus7')} ${vw(40 - 4.25, 'nexus7')} ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(50, 'desktop')} ${vw(40 - 12.5, 'desktop')} ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 50px 27.5px 20px;
    }
  }
`

