
import { memo } from 'react'
import styled from 'styled-components'
// DEV ONLY
import { Susy } from '@dy/commons/styles'

export const LayoutEmpty = memo<any>(({ children }) => {
  return (
    <Div>
      {children}
      <Susy />
    </Div>
  )
})


const Div = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  min-height: 100vh;
  min-height: 100dvh;
`