import { gql } from '@apollo/client'
import { FRAGMENT_PRODUCT_REQUIRED, FRAGMENT_VARIANT_REQUIRED } from './'

const FRAGMENT_CART_LINES = gql`
  fragment FragmentCartLines on Cart {
    cartLines {
      id
      quantity
      cartLineType
      listPrice
      product {
        ...FragmentProductRequired
        brand {
          name
          logo
        }
        labels {
          name
        }
      }
      variant {
        ...FragmentVariantRequired
      }
    }
  }
  ${FRAGMENT_PRODUCT_REQUIRED}
  ${FRAGMENT_VARIANT_REQUIRED}
`

const FRAGMENT_CART = gql`
  fragment FragmentCart on Cart {
    id
    bigcommerceId
    customer {
      bigcommerceId
    }
    promotions {
      id
      name
      rules {
        ruleableType
        ruleable
      }
    }
    discountAmount
    subtotalExTax
    totalExTax
    multipleDeliveryDates
    deliveryDate
    deliveryDateOptions
    deliveryType
    deliverySchedules {
      name
      agency
      minimumPurchase
      minimumPurchaseFrozen
      agencyText
    }
    ...FragmentCartLines
  }
  ${FRAGMENT_CART_LINES}
`

const FRAGMENT_BOUGHT_TOGETHER_VARIANTS = gql`
  fragment FragmentboughtTogether on Cart {
    boughtTogether {
      ...FragmentVariantRequired
      product {
        ...FragmentProductRequired
        description
        ingredients
        seo
        labels {
          name
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_REQUIRED}
  ${FRAGMENT_PRODUCT_REQUIRED}
`

export const GET_CART = gql`
  query GET_CART {
    customer {
      id
      cart {
        ...FragmentCart
      }
    }
  }
  ${FRAGMENT_CART}
`

export const MUTATION_CART_SYNC = gql`
  mutation MUTATION_CART_SYNC {
    cartSync(input: {}) {
      ok
      cart {
        ...FragmentCart
      }
      cartUrl
      checkoutUrl
    }
  }
  ${FRAGMENT_CART}
`

export const MUTATION_CART_DELIVERY_DATE = gql`
  mutation MUTATION_CART_DELIVERY_DATE($deliveryDate: String!) {
    cartDeliveryDate(input: {
      deliveryDate: $deliveryDate
    }) {
      ok
      cart {
        ...FragmentCart
      }
    }
  }
  ${FRAGMENT_CART}
`

export const GET_CART_RELATEDS = gql`
  query GET_CART_RELATEDS {
    customer {
      id
      cart {
        ...FragmentboughtTogether
      }
    }
  }
  ${FRAGMENT_BOUGHT_TOGETHER_VARIANTS}
`

export const CUSTOMER_ADD_TO_CART = gql`
  mutation CUSTOMER_ADD_TO_CART($variantId: Int!, $quantity: Int!, $cartLineType: CartLineTypeEnum!) {
    cartLinesAdd(
      input: {
        cartLines: {
          variantId: $variantId,
          quantity: $quantity,
          cartLineType: $cartLineType
        }
      }
    ) {
      ok
      cart {
        ...FragmentCart
      }
    }
  }
  ${FRAGMENT_CART}
`

export const CUSTOMER_UPDATE_CART_ITEM = gql`
  mutation CUSTOMER_UPDATE_CART_ITEM($variantId: Int!, $quantity: Int!, $cartLineType: CartLineTypeEnum!) {
    cartLinesUpdate(
      input: {
        cartLines: {
          variantId: $variantId,
          quantity: $quantity,
          cartLineType: $cartLineType
        }
      }
    ) {
      ok
      cart {
        ...FragmentCart
      }
    }
  }
  ${FRAGMENT_CART}
`

export const CUSTOMER_DELETE_FROM_CART = gql`
  mutation CUSTOMER_DELETE_FROM_CART($variantId: Int!) {
    cartLinesDelete(
      input: {
        cartLines: {
          variantId: $variantId
        }
      }
    ) {
      ok
      cart {
        ...FragmentCart
      }
    }
  }
  ${FRAGMENT_CART}
`

export const CUSTOMER_BULK_ADD_TO_CART = gql`
  mutation CUSTOMER_BULK_ADD_TO_CART($cartLines: [CartLineFieldsInput!]!) {
    cartLinesAdd(
      input: {
        cartLines: $cartLines
      }
    ) {
      ok
      cart {
        ...FragmentCart
      }
    }
  }
  ${FRAGMENT_CART}
`

