import { FC, ReactNode, Dispatch, SetStateAction, useEffect } from 'react'
import { createContext, useState } from 'react'
import { TypeSlug, TypeVariant } from '@dy/commons/schema'
import { useRouter } from 'next/router'

type TypeCardClickedFrom = {
  variant_id: number
  slug: TypeSlug
  ga_list?: {
    id: string
    name: string
  }
  idx: number
}

type ContextType = {
  cardSelected: TypeCardClickedFrom
  setCardSelected: Dispatch<SetStateAction<TypeCardClickedFrom>>,
  activeVariant: TypeCardClickedFrom & TypeVariant,
  setActiveVariant: Dispatch<SetStateAction<TypeVariant>>
  removeActiveVariant: () => void
}

export const ActiveVariantContext = createContext<ContextType | undefined>(undefined)

interface Props {
  children: ReactNode
}

export const ActiveVariantProvider: FC<Props> = ({ children }) => {
  const [activeVariant, setActiveVariant] = useState<TypeCardClickedFrom & TypeVariant>(undefined)
  const [cardSelected, setCardSelected] = useState<TypeCardClickedFrom>(undefined)
  const { pathname } = useRouter()

  const removeActiveVariant = () => {
    setActiveVariant(null)
    setCardSelected(null)

    if (typeof window !== 'undefined' && (pathname !== '/' && pathname !== '/welcome')) window.history.back()
  }

  useEffect(() => {
    if (cardSelected) {
      console.log('cardHasBeenClickedFrom', cardSelected)
      // APOLLO ASK FOR VARIANT DATA
    }
  }, [cardSelected])

  return <ActiveVariantContext.Provider value={{
    activeVariant,
    cardSelected,
    setActiveVariant,
    setCardSelected,
    removeActiveVariant
  }}>{children}</ActiveVariantContext.Provider>
}
