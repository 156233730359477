'use client'
import type { FC } from 'react'
import { memo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import { inputPattern } from '@dy/commons/utils'
import type { TypeSectionAuthLevel } from '@/components'
import { CUSTOMER_RESET_PASSWORD } from '@/api'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  payload: { level: string, resetToken: string, [key: string]: any }
  animation?: any
}

type TypeFormInputs = {
  password: string
}

export const FormUserResetPassword:FC<Props> = memo(({ payload, updateLevel }) => {
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm<TypeFormInputs>()
  const [customerResetPassword, { loading }] = useMutation(CUSTOMER_RESET_PASSWORD)
  const { t } = useTranslator()
  console.log('🍻 FormUserResetPassword:', payload)

  const onSubmit: SubmitHandler<TypeFormInputs> = async data => {
    const { password } = data

    try {
      await customerResetPassword({
        variables: {
          password,
        },
        context: {
          isPrivatePath: true,
          DYResetPasswordToken: payload?.resetToken
        }
      })

      updateLevel('feedback_password_updated')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit)}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('modals.auth.reset_password.text') as string}>
        <Input key='password' type='password' name='password' label={t('form.fields.new_password') as string} register={register} autoComplete='new-password' placeholder='password' icon='password' bgColor={'white'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} error={formErrors.password} />
      </Fieldset>
      <Button disabled={loading} type='submit'>{t('commons.actions.save')}</Button>
    </Form>
  )
})
