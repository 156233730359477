import { memo } from 'react'
import type { FC } from 'react'
import styled from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { useTranslator } from '@/hooks'
import { MODALS } from '@dy/commons/context'
import { Figure } from '@dy/commons/components'
import { mq, vw, getP14Search } from '@dy/commons/styles'

const iconSearch = (fillColor = 'darkred') => ({
  type: 'svg' as const,
  src: `/images/svg/i--search${fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'Logo',
  size: { width: 16, height: 16 }
})

type TypeButtonTriggerSearch = {
  fillColor: 'darkred' | 'white'
}

export const ButtonTriggerSearch: FC<TypeButtonTriggerSearch> = memo(({ fillColor = 'darkred' }) => {
  const { toggle: toggleSearch } = useModal(MODALS.SEARCH)
  const { t } = useTranslator()

  return (
    <Button onClick={toggleSearch}>
      <Figure media={iconSearch(fillColor)} fill={false} />
      <span>{t('layout.search.what_are_you_looking_for')}</span>
    </Button>
  )
})


const Button = styled.button`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: ${vw(39, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    width: ${vw(39, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    background-color: white;
    border-radius: ${vw(24.5, 'desktop')};
    height: ${vw(40, 'desktop')};
    max-width: ${vw(406, 'desktop')};
    padding: 0 ${vw(20, 'desktop')};
    width: 100%;
  }

  ${mq.greaterThan('desktop')} {
    border-radius: 24.5px;
    height: 40px;
    max-width: 406px;
    padding: 0 20px;
  }

  figure {
    width: ${vw(21, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width: ${vw(21, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      width: ${vw(16, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 16px;
    }
  }

  >span {
    ${getP14Search}
    display: none;
    min-width: unset;

    ${mq.greaterThan('tablet')} {
      display: inline-block;
      margin-left: ${vw(13, 'desktop')};
      min-width: ${vw(180, 'desktop')};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${mq.greaterThan('desktop')} {
      margin-left: 13px;
      min-width: 180px;
    }
  }
`
