import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { useModal, useScrollTracker } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '..'
import { Link, Figure } from '@dy/commons/components'
import styled, { css } from 'styled-components'
import { mq, vw, getP113, getP27_P18, getP18_1, getP26 } from '@dy/commons/styles'
import { dialogVariants, listVariants, listItemVariants, detailUlvariants, detailvariants } from './modalMobileMenuVariants'
import { getRegion, NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'
import { getCountries, getSubdivisions }  from '@/components'
import { useTranslator, useCustomer } from '@/hooks'
import {useDropdown } from '@dy/commons/components'

type MenuProps = {
  hasBanners?: boolean,
  data: any
}

const backIconLight = {
  type: 'svg' as const,
  src: '/images/svg/chevron--left--light.svg',
  alt: 'Go back icon',
  size: { width: 12, height: 12}
}

const locationIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--location--white.svg',
  alt: 'Location icon',
  size: { width: 14, height: 18 }
}

const iconChevronAccordion = {
  type: 'svg' as const,
  src: '/images/svg/chevron--select--light.svg',
  alt: 'Go back icon',
  size: { width: 12, height: 8}
}

// COMMENT  DETAIL IS A DIV FOR NOW SINCE ITS NOT POSSIBLE TO ANIMATE CLOSING ATTRIBUTTE
const LANG = NEXT_PUBLIC_LOCALE.split('-')[0]
const TRANSLATIONS = {
  'es': 'Ver todo',
  'en': 'See all',
  'de': 'Alles sehen',
  'fr': 'Voir tout',
  'pt': 'Ver tudo'
}
const getSubcategories = (subcategories) => subcategories.map((subcategory) => {
  const subcategoryData = {
    name: subcategory.name,
    href: `/catalog/${subcategory?.slug[LANG]}`,
    type: 'link',
  }
  return subcategoryData
})

const cleanData = (categories) => categories.map((category) => {
  if(category.subcategories.length === 0) {
    const cat = {
      name: category.name,
      href: `/catalog/${category?.slug[LANG]}`,
      type: 'link',
    }
    return cat
  } else {
    const categorySubcategory = {
      name: category.name,
      href: `/catalog/${category?.slug[LANG]}`,
      type: 'link',
      childrenAs: 'accordion',
      children:getSubcategories(category.subcategories)
    }
    return categorySubcategory
  }

})
const DetailDropdown = ({ item, setLevel, level, idx }) => {
  const [triggerRef, dropdownRef, expanded, toggleExpanded] = useDropdown()

  const onClick= (e) => {
    e.preventDefault()
    toggleExpanded()
  }

  const parsedChildrenIncludingAll = [{name: TRANSLATIONS[LANG], href: `${item.href}`, type: 'link'}, ...item.children]
  return (<Details {...(open && { open })} animate={expanded ? 'active' : 'inactive'} variants={detailvariants} idx={idx} ref={triggerRef}  >
    <summary onClick={(e) => {onClick(e)}} >
      {item.name}
      <Figure media={iconChevronAccordion}  fill={false} />
    </summary>
    <AnimatePresence mode='wait'>
      {expanded &&
         <motion.ul ref={dropdownRef} initial='inactive' animate={expanded ? 'active' : 'exit'} exit='exit' variants={detailUlvariants}>
           {getMenuItems(parsedChildrenIncludingAll, level, setLevel, true)}
         </motion.ul>
      }
    </AnimatePresence>
  </Details>)
}

const itemByType = {
  'link': (item, setLevel, idx, level,) => {
    if(!item.children) return (<Link href={item.href}>{item.name}</Link>)
    if(item.childrenAs === 'sublevel') {
      return (<><Link href={item.href}>{item.name}</Link><button onClick={() => { setLevel(prevLevel => [...prevLevel, idx]) }}>CHEVRON</button></>)
    } else if (item.childrenAs === 'accordion') {
      return <DetailDropdown item={item} setLevel={setLevel} level={level} idx={idx}/>
    }
    return (<Link href={item.href}>{item.name}</Link>)
  },
  'button-level': (item, setLevel, idx) => <button onClick={() => { setLevel(prevLevel => [...prevLevel, idx]) }}>{item.name}</button>,
  'modal-trigger': (item) => `Modal trigger TODO ${item}`,
  'link-external': (item) => <a href={item.href} rel='noreferrer' target='_blank'>{item.name}</a>
}

const getMenuItems = (data, level, setLevel, accordionList = false) => {
  let list = data
  let reachLevel = 0

  if(!accordionList) {
    while(reachLevel < level.length) {
      list = list[level[reachLevel]]?.children
      reachLevel++
    }
  }

  return list.map((item, idx) => {
    return <Li key={`${level?.length}-${item.type}-${idx}`} variants={listItemVariants}>
      {itemByType[item.type](item, setLevel, idx, level)}
    </Li>
  })
}

const getMenuNavList = (customer, t, categories) => {
  if (customer) {
    return [
      {
        name: t('layout.nav.home.name'),
        href: t('layout.nav.home.pathname'),
        type: 'link',
      },
      {
        name: t('layout.nav.shop.name'),
        href: t('layout.nav.shop.pathname'),
        type: 'button-level',
        childrenAs: 'sublevel',
        children: cleanData(categories)
      },
      {
        name: t('layout.nav.my_usuals.name'),
        href: t('layout.nav.my_usuals.pathname'),
        type: 'link',
      },
    ]
  } else {
    return [
      {
        name: t('layout.nav.home.name'),
        href: t('layout.nav.home.pathname'),
        type: 'link',
      },
      {
        name: t('layout.nav.products.name'),
        href: t('layout.nav.products.pathname'),
        type: 'button-level',
        childrenAs: 'sublevel',
        children: cleanData(categories)
      },
      {
        name: t('layout.nav.catalogue.name'),
        href: t('layout.nav.catalogue.pathname'),
        type: 'link',
        // type: 'link-external',
      },
      {
        name: t('layout.nav.about_us.name'),
        href: t('layout.nav.about_us.pathname'),
        type: 'link',
      },
      {
        name: t('layout.nav.events.name'),
        href: t('layout.nav.events.pathname'),
        type: 'link',
      },
      {
        name: t('layout.nav.panettiamo.name'),
        href: t('layout.nav.panettiamo.pathname'),
        type: 'link',
      },
      {
        name: t('layout.nav.contact_us.name'),
        href: t('layout.nav.contact_us.pathname'),
        type: 'link',
      }
    ]
  }
}

let prevUrl = null
export const ModalMenu: FC<MenuProps> = memo(({ hasBanners, data }) => {
  const { categories } = data
  const { customer } = useCustomer()
  const { t } = useTranslator()
  const { isActive, toggle } = useModal(MODALS.MENU)
  const { toggle:toggleModalRegion } = useModal(MODALS.REGION_LANG)
  const [level, setLevel] = useState([])
  const [storeLocation, setStoreLocation] = useState('')
  const [country, setCountry] = useState([])
  const [subdivisions, setSubdivisions] = useState([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { locale, asPath }  = useRouter()
  const scrollPos = useScrollTracker(1)
  const scrollTop = useMemo(() => scrollPos === 'TOP', [scrollPos])
  const animate = useMemo(() => hasBanners && scrollTop ? true : false, [hasBanners, scrollTop])
  const fakeList = useMemo(() => getMenuNavList(customer, t, categories), [customer, t, categories])

  const Items = useMemo(() => getMenuItems(fakeList, level, setLevel), [fakeList, level, setLevel])

  const goOneLevelBack = useCallback(() => {
    setLevel(prevLevel => {
      const incomingLevel = [...prevLevel]
      incomingLevel.pop()
      return incomingLevel
    })
  }, [setLevel])

  useEffect(() => {
    if(!prevUrl) prevUrl = asPath
    else if(prevUrl !== asPath && isActive){
      prevUrl = asPath
      toggle()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  useEffect(() => {
    if(country?.length === 0) {
      getCountries().then((res)=>{
        setCountry(Array.from(res))
      })
    }
  }, [country])

  useEffect(() => {
    getSubdivisions('ES').then((res) => {
      setSubdivisions(Array.from(res))
    })

  }, [country])

  useEffect(()=> {
    if(locale.includes('es')){
      const region = subdivisions.find((el)=> el.value === locale.toUpperCase())
      setStoreLocation(region?.name ?? 'España')
    } else {
      const region = getRegion(locale)
      const countryLocalization = country.find((el)=> el.value === region)
      setStoreLocation(countryLocalization?.name)
    }

  }, [locale, country, subdivisions])

  return (
    <Modal isActive={isActive} close={toggle} title='Menu' xAlign='flex-start' variants={dialogVariants} dialogStyles={dialogStyles(animate)} hasHeader={false} zIndex={150} isModalFilters={level.length === 0 ? true : false} >
      {level.length > 0 && <ButtonMenuBack key={'menu-back-button'} onClick={goOneLevelBack} className='button-back'>
        <Figure media={backIconLight} fill={false} />
      </ButtonMenuBack>}
      <Ul key={`list-menu-${level?.length}`} $levelDepth={level?.length} initial='inactive' animate={'active'} exit='exit' variants={listVariants}>
        {Items}
      </Ul>
      {(level.length === 0  && customer) && <button onClick={toggleModalRegion} title={'Region'} className='store_localization'>
        <Figure media={locationIcon} fill={false} />
        <span>{storeLocation}</span>
      </button>}
    </Modal>
  )
})



const Ul = styled(motion.ul) <{ $levelDepth: number }>`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  a,
  button,
  summary {
    ${({ $levelDepth }) => $levelDepth === 0 ? getP113 : getP27_P18}
    align-items: center;
    color: inherit;
    display: flex;
    max-height: ${vw(80, 'mobile')};
    justify-content: flex-start

    ${mq.greaterThan('nexus7')} {
      max-height: ${vw(80, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 60px;
    }
  }

  div {
    > dl,
    > ol,
    > ul {

      button,
      summary {
        font-weight: 500;
      }
      a {
        ${getP18_1()}
        text-decoration: none;
        font-family: ${({ theme }) => theme.fonts.secondaryFont};
        color: ${({ theme }) => theme.colors.white};
      }

    }
    li:first-child{
      margin-top: ${vw(30, 'mobile')};
    }

    li:last-child {
      margin-bottom:${vw(16, 'mobile')};
    }

  }
  li:nth-child(4), li:nth-child(3), li:nth-child(5) {
    margin-bottom:${vw(40, 'mobile')};

  }
`

const Li = styled(motion.li)`
  display: flex;
  width: 100%;
  margin-bottom: ${vw(26, 'mobile')};
`

const Details = styled(motion.div)<any>`
  width: 100%;

  &[open] {
    height: auto;

    summary {
      figure {
        transform: rotate(180deg);
      }
    }
  }

  summary {
    justify-content: space-between;

    figure {
      max-height: 28px;
      max-width: 28px;
      transition: 200ms transform ease-out;
      transform-origin: center;
      width: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 10px;
      }
    }
  }
`

const ButtonMenuBack = styled.button`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${vw(48, 'mobile')};
  justify-content: center;
  max-width: 48px;
  transform: translateX(-30%);
  width: ${vw(48, 'mobile')};
  z-index: 2;
  margin-bottom:${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(48, 'nexus7')};
    margin-bottom:${vw(20, 'nexus7')};
    width: ${vw(48, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(48, 'desktop')};
    width: ${vw(48, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 48px;
    width: 48px;
  }

  figure {
    max-height: 28px;
    max-width: 28px;
    width: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      width: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 10px;
    }
  }
`

const dialogStyles = (animate) => {
  return css`
    background: ${({ theme }) => theme.colors.red};
    display: block;
    margin: 0;
    min-height: 100%;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      display:none;
    }

    .modal__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: ${ animate ? `${vw(120, 'mobile')} ${vw(40, 'mobile')} ${vw(40, 'mobile')} ${vw(10, 'mobile')}` : `${vw(80, 'mobile')} ${vw(40, 'mobile')} ${vw(40, 'mobile')} ${vw(10, 'mobile')}`};
      overflow-y: hidden;
      transition: padding 300ms ease;

      .store_localization {
        ${getP26()}
        align-items: baseline;
        color: ${({ theme }) => theme.colors.white};
        display:flex;
        flex-direction: row;

        figure {
          width: ${vw(14, 'mobile')};
          height: ${vw(18, 'mobile')};
          margin-right:${vw(5, 'mobile')};

          ${mq.greaterThan('nexus7')} {
            width: ${vw(14, 'nexus7')};
            height: ${vw(18, 'nexus7')};
            margin-right:${vw(5, 'nexus7')};
          }
        }
      }

      > ul,  > button:not(.button-back) {
        padding-left: ${vw(10, 'mobile')};
      }
    }

    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
      width: 100%;
      height: unset;
      .modal__body {
        height: unset;
        overflow-y: unset;
      }
    }
  `
}
