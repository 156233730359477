import { Accordion } from '@dy/commons/components'
import styled from 'styled-components'
import { mq, vw, hideScrollbar } from '@dy/commons/styles'

export const SectionFaqCategories = ({ categories, activeIndex, activeCategory, activeQuestion }) => {
  return (
    <CategoriesSection>
      <Accordion items={categories} activeIndex={activeIndex} activeCategory={activeCategory} activeQuestion={activeQuestion} />
    </CategoriesSection>
  )
}



const CategoriesSection = styled.section`
  display: none;

  ${mq.greaterThan('tablet')} {
    display: inline-grid;
    grid-column: 1 / span 3;
    grid-auto-rows: max-content;
    height: fit-content;
    // min-height: calc(100vh - 80px);
    overflow: auto;
    padding: ${vw(20, 'desktop')} ${vw(50, 'desktop')} ${vw(50, 'desktop')} ${vw(30, 'desktop')};
    position: sticky;
    top: 80px;
    z-index: 60;
    ${hideScrollbar()}
  }

  ${mq.greaterThan('desktop')} {
    padding: 20px 50px 50px 30px;
  }
`
