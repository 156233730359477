import { getTransition } from '@dy/commons/styles'

export const listVariants = {
  active: {
    opacity: 1,
    transition: {
      ...getTransition()
    }
  },
  inactive: {
    opacity: 0,
    transition: {
      ...getTransition()
    }
  }
}
