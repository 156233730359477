import { memo } from 'react'
import { useRouter } from 'next/router'
import { Figure } from '@dy/commons/components'
import { currencyFormatter } from '@dy/commons/utils'
import { _Price } from '../'
import { itemVariants } from './cartVariants'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP12 } from '@dy/commons/styles'
interface ICartItem {
  product: any,
  id: number | string,
  onClick?: () => void,
  type?: string
}

const cartItemSizes = `(min-width: 768px) ${vw(55, 'desktop')}, ${vw(55, 'mobile')}`
export const CartItem = memo<ICartItem>(({ product, id, onClick, type = 'all' }) => {
  const { locale } = useRouter()
  const { product:cartItemProduct, variant, quantity } = product
  const { name } = cartItemProduct
  const { media, price } = variant
  const priceQuantity = price?.salePrice ? { salePrice: price.salePrice * quantity, retailPrice: price.retailPrice * quantity } : null

  return (
    <ModalListItem variants={itemVariants} id={id} {...(onClick && { ...onClick })} $type={type}>
      <article>
        {media?.[0]?.medium ?  <Figure className={'product_image'} media={media?.[0].medium} lazy={false} sizes={cartItemSizes} quality={50}/>: <figure className='product_image placeholder'></figure>}
        <div className='info'>
          <div>
            <h5>{quantity} x {name}</h5>
            <span className='total'>
              {priceQuantity && currencyFormatter({price:priceQuantity.retailPrice, localeRegion: locale, round: false, trailingZeros: true })}
            </span>
          </div>
          <_Price>
            {currencyFormatter({ price: price?.retailPrice, localeRegion: locale, round: false, trailingZeros: true })}/ud.
          </_Price>
        </div>
      </article>
    </ModalListItem>
  )
})


const ModalListItem = styled<any>(motion.dd)`
  background-color: ${({ theme }) => theme.colors.greylight};
  padding-bottom: ${vw(10, 'mobile')};
  min-height: ${vw(55, 'mobile')};
  overflow-x: hidden;
  transition: 300ms opacity ${({ ease }) => ease};
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    padding-bottom: ${vw(10, 'nexus7')};
    min-height: ${vw(55, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    padding-bottom: ${vw(10, 'desktop')};
    min-height: ${vw(55, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding-bottom: 10px;
    min-height: 55px;
  }

  &:first-of-type {
    padding-top: ${vw(20.5, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-top: ${vw(20.5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-top: ${vw(20.5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-top: 20.5px;
    }

    ${({ $type }) => $type === 'new' && css`border-top-left-radius: 20px; border-top-right-radius: 20px;`};
  }

  &:last-child {
    padding-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-bottom: 30px;
    }


  }

  article {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 20px;
    }
  }

  .info {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: ${vw(20, 'mobile')};
    width: 80%;

    ${mq.greaterThan('nexus7')} {
      padding-left: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-left: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-left: 20px;
    }

    > div {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      h5, .total {
        width: auto;
        max-width: 100%;
      }

      h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .total {
        font-weight: bold;
        flex-shrink: 0;
        margin-left: ${vw(20, 'mobile')};
        width: auto;

        ${mq.greaterThan('nexus7')} {
          margin-left: ${vw(20, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          margin-left: ${vw(20, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-left: 20px;
        }
      }
    }

    h5, .total, span {
      ${getP12()}
      font-family: ${({ theme }) => theme.fonts.tertiaryFont};
      color: ${({ theme }) => theme.colors.red};
      text-align: left;
    }

    span {
      font-weight: 500;
      width: 100%;
    }
  }

  .product__price {
    p {
      ${getP12()}

      .value,
      .decimals,
      .unit {
        ${getP12()}
        font-family: ${({ theme }) => theme.fonts.tertiaryFont};
        font-weight: 500;
      }
    }
  }

  .product_image {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: ${vw(55, 'mobile')};
    overflow: hidden;
    width: ${vw(55, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(55, 'nexus7')};
      width: ${vw(55, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(55, 'desktop')};
      width: ${vw(55, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 55px;
      width: 55px;
    }

    &.placeholder {
      background-color: rgba(0, 0, 0, .15);
    }

    img {
      height: ${vw(55, 'mobile')};
      object-fit: contain;
      object-position: center;
      width: ${vw(55, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(55, 'nexus7')};
        width: ${vw(55, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(55, 'desktop')};
        width: ${vw(55, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 55px;
        width: 55px;
      }
    }
  }

  .total {
    margin-bottom: 0;
  }
`
