import { memo, useMemo } from 'react'
import { H2, Table } from './sharedDetailStyles'
import { useTranslator } from 'hooks/useTranslator'

type ProductInfoSpecification = {
  name: string,
  value: string | number
}

type TypeSectionMoreInfo = {
  data: ProductInfoSpecification[]
}

const getInfo = details => details.map((detail, idx) => {
  return (
    <tr key={idx}>
      <td>{detail.name}</td><td>{detail.value}</td>
    </tr>
  )
})

export const SectionMoreInfo = memo<TypeSectionMoreInfo>(({ data }) => {
  const { t } = useTranslator()
  const Information = useMemo(() => getInfo(data), [data])

  return (
    <section>
      <H2>{t('product_page.product_description.more_info.title')}</H2>
      <Table>
        <tbody>
          {Information}
        </tbody>
      </Table>
    </section>
  )
})
