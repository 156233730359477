import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { mq, vw } from '@dy/commons/styles'
import dynamic from 'next/dynamic'
import { getLang } from '@dy/commons/utils'
import type { TypeMarqueeProps }  from '@dy/commons/components'
import { useTranslator } from '@/hooks'

const DynamicMarquee = dynamic<TypeMarqueeProps>(() => import('@dy/commons/components').then((mod)=> mod.Marquee), { ssr: false })

interface IHeaderShop {
  data: any,
  isBrandPage?: boolean
  isFallback?: boolean
}

const getCategoriesAndSubcategories = (categories, isBrandPage, isFallback) => {
  let parsedCategories= []
  if(!isBrandPage && !isFallback) {
    categories.map((category) => {
      parsedCategories.push(category)
      category?.subcategories?.map((subcategory) => {
        parsedCategories.push(subcategory)
      })
    })
  } else {
    parsedCategories= categories

  }
  return parsedCategories
}

const replaceHyphensWithSpaces = (inputString, textBrand) => {
  if (typeof inputString !== 'string') {
    throw new Error('Input must be a string')
  }
  return textBrand.toUpperCase() + ' ' + inputString.replace(/-/g, ' ').toUpperCase()
}

export const HeaderShop = memo<IHeaderShop>(({ data, isBrandPage = false, isFallback = false }) => {
  const { locale:localeRouter, query } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const allCategoriesAndSubcategories = getCategoriesAndSubcategories(data, isBrandPage, isFallback)
  const queryParsed = (query?.slug?.length > 1 && Array.isArray(query?.slug) && !isFallback) ? query?.slug?.join('/') : (query.slug?.[0] && !isFallback) ? query.slug[0]  : ''

  const currentPage = useMemo(() => !isFallback && !isBrandPage ? allCategoriesAndSubcategories.find(category => queryParsed ===(category.slug[locale])) : isBrandPage ? replaceHyphensWithSpaces(data[0].slug[locale], t('layout.search.brand') as string) : data.name, [isFallback, isBrandPage, allCategoriesAndSubcategories, data, locale, t, queryParsed])

  return currentPage && (
    <Header $isBrandPage={isBrandPage}>
      <DynamicMarquee data={currentPage?.name? currentPage?.name : currentPage} />
    </Header>
  )
})

const Header = styled.header<any>`
  margin-top:  ${({ $isBrandPage }) => $isBrandPage ?  0: '80px'};
  overflow-x: hidden;

  ${mq.greaterThan('nexus7')} {
    margin-top:  ${({ $isBrandPage }) => $isBrandPage ?  `${vw(20, 'nexus7')}`: `${vw(60, 'nexus7')}`};
  }

  ${mq.greaterThan('tablet')} {
    margin-top:  ${({ $isBrandPage }) => $isBrandPage ?  `${vw(80, 'desktop')}`: `${vw(80, 'desktop')}`};
  }

  ${mq.greaterThan('desktop')} {
    margin-top: 80px;
  }

  .marquee {
    ${mq.greaterThan('tablet')} {
      margin-bottom:  ${vw(40, 'desktop')};
      padding: ${vw(30, 'desktop')} 0 0;
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 40px;
      padding: 30px 0 0;
    }
  }
`
