import { memo } from 'react'
import { Figure } from '@dy/commons/components'
import styled from 'styled-components'
import { mq, vw, getP20 } from '@dy/commons/styles'

interface ISvg {
  readonly isActive: boolean
}

const Svg = memo(({ isActive }:ISvg) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='29' viewBox='0 0 27 29'><path d='M23.75 6.13l-8-4.54c-1.39-.79-3.11-.79-4.5 0l-8 4.54A4.4 4.4 0 0 0 1 9.96v9.08a4.4 4.4 0 0 0 2.25 3.83l8 4.54c1.39.79 3.11.79 4.5 0l8-4.54A4.4 4.4 0 0 0 26 19.04V9.96a4.4 4.4 0 0 0-2.25-3.83' fill={isActive ? '#4E1114' : '#D8C7C7'} stroke='#4E1114'/></svg>
  )
})

interface IButtonFilterCheckbox {
  data: any,
  onClick: () => void,
  isActive: boolean
}

const mediaTick = {
  type: 'image' as const,
  alt: 'Icon tick',
  default: {
    src: '/images/svg/i--tick.svg',
    size: { width: 9, height: 9 }
  }
}

export const ButtonFilterCheckbox = memo(({ data, onClick, isActive }:IButtonFilterCheckbox) => {
  return (
    <CheckboxButton onClick={onClick} isActive={isActive} isMultiple={data.type === 'multiple'}>
      {data.name}
      <div>
        <Svg isActive={isActive} />
        {data.type === 'multiple' && data.param !== 'sort' ?
          <Figure media={mediaTick} lazy={true} />
          :
          <CheckboxCircle isActive={isActive}/>
        }
      </div>
    </CheckboxButton>
  )
})


const CheckboxCircle = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.lightred};
  border-radius: 100px;
  height: ${vw(9, 'mobile')};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(${({ isActive }) => isActive ? 1 : 0});
  transition: transform 200ms ${({ theme }) => theme.ease};
  user-select: none;
  width: ${vw(9, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(9, 'nexus7')};
    width: ${vw(9, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(9, 'desktop')};
    width: ${vw(9, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 9px;
    width: 9px;
  }
`


const CheckboxButton = styled.button<any>`
  align-items: center;
  border: 0;
  color: ${({ theme }) => theme.colors.darkred};
  display: flex;
  ${getP20()}
  height: ${vw(50, 'mobile')};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  font-weight: 700;
  justify-content: space-between;
  opacity: ${({ isActive, isMultiple }) => isMultiple && !isActive ? .5 : 1};
  padding: 0 ${vw(40, 'mobile')};
  width: 100%;
  white-space: normal;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(50, 'nexus7')};
    padding: 0 ${vw(40, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(50, 'desktop')};
    padding: 0 ${vw(40, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 50px;
    padding: 0 40px;
  }

  > div {
    position: relative;
  }

  svg {
    display: block;
    opacity: ${({ isActive }) => isActive ? 1 : .4};
    transition: opacity 200ms ${({ theme }) => theme.ease};
    width: ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width: ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      width: ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 25px;
    }

    path {
      transition: fill 200ms ${({ theme }) => theme.ease};
    }
  }

  figure {
    display: flex;
    height: ${vw(12, 'mobile')};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(${({ isActive }) => isActive ? 1 : 0});
    transition: transform 200ms ${({ theme }) => theme.ease};
    user-select: none;
    width: ${vw(12, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(12, 'nexus7')};
      width: ${vw(12, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(12, 'desktop')};
      width: ${vw(12, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 12px;
      width: 12px;
    }
  }
`
