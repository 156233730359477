// Customer forms
export * from './FormCustomerUpdate'
export * from './FormCustomerUpdateAddresses'

// Authentication onBoarding flow forms
export * from './FormUserCheckCIF'
export * from './FormUserInitial'
export * from './FormUserSignUpCIF'
export * from './FormUserSignUp'
export * from './FormUserActivateAccount'
export * from './FormUserPreLogin'
export * from './FormUserLogIn'
export * from './FormUserResetPassword'
export * from './FormUserForgotPassword'

// Other forms
export * from './FormNewsletter'
export * from './FormStore'

// Feedbacks after previously submitted forms (not real forms)
export * from './feedbacks'
