import { type FC, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslator } from 'hooks/useTranslator'
import { Table, H2, SectionNutrition } from './sharedDetailStyles'
import styled from 'styled-components'
import { mq, vw, getP12_1} from '@dy/commons/styles'
import type { TypeSpecification } from '@dy/commons/schema'

type Props = {
  data: TypeSpecification[]
}

const localeString = {
  'en': 'en-GB',
  'es': 'es-ES',
}

const getNutritional = (details, locale) => details.map((detail, idx) => {
  const value = detail.value < 1 ? '< 1' : detail.value.toLocaleString(localeString[locale])
  return (
    <tr key={idx}>
      <td>{detail.name}</td><td>{value}</td>
    </tr>
  )
})

export const SectionNutritionChart:FC<Props> = memo(({ data }) => {
  const { locale } = useRouter()
  const { t } = useTranslator()

  const energy = data.filter((item) => item.nutritionType === 'energy')[0]
  const chartData = data.filter((item) => item.nutritionType === 'weight')

  const dataWithPercentages = useMemo(() => getNutritional(chartData, locale), [chartData, locale])

  return (
    <SectionNutrition>
      <H2>{t('product_page.product_description.nutritional_value.title')}</H2>
      <P>
        <span>{t('product_page.product_description.nutritional_value.average_value_per')} 100gr.</span>
        {energy && <span>{t('product_page.product_description.nutritional_value.energy')}: {energy.value} / {Math.round(typeof energy.value === 'string' ? parseInt(energy.value) : Number(energy.value) * 4.184)}kJ</span>}
      </P>
      <Table>
        <tbody>
          {dataWithPercentages}
        </tbody>
      </Table>
    </SectionNutrition>
  )
})

const P = styled.p`
  align-items: center;
  display: flex;
  height: ${vw(25, 'mobile')};
  justify-content: center;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(25, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(25, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 25px;
  }

  span {
    align-items: center;
    color: ${({ theme }) => theme.colors.red};
    display: flex;
    ${getP12_1()}
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    height: 100%;
    justify-content: center;
    padding: 0 ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 25px;
    }

    &:nth-child(2) {
      border-left: 1px solid ${({ theme }) => theme.colors.red};
    }
  }
`

