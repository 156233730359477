import { memo, useMemo, useCallback, useContext, useEffect } from 'react'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import { Figure } from '@dy/commons/components'
import { getLang, currencyFormatter, parseDateString, cookie, CART_LINE_TYPE } from '@dy/commons/utils'
import type { TypeCMSOrder } from '@dy/commons/schema'
import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP15_1B } from '@dy/commons/styles'
import { SectionAccount as Section, CardListItem, ProductListItem } from './sharedAccountStyles'
import { useTranslator } from '@/hooks'
import { RepeatOrder } from 'components/cart'
import { CUSTOMER_ADD_TO_CART } from '@/api'
import { useCart } from '@dy/commons/hooks'
import { useMutation } from '@apollo/client'
import { NewCartItemsContext } from '@/components'

type Props = {
  data: TypeCMSOrder
}

// const mediaPastaLeft = {
//   type: 'image' as const,
//   alt: 'Icon pasta 1',
//   default: {
//     src: '/images/pasta.jpeg',
//     size: { width: 30, height: 20 }
//   }
// }

const userDetails = (item)  => {
  const { city, country, first_name, last_name, phone, zip, state, street_1, street_2 } = item
  return(
    <>
      <span>{first_name} {last_name}</span>
      <span>{street_1} {street_2}, {city}</span>
      <span>{state} {country}</span>
      <span>{zip}</span>
      {phone && <span>{phone}</span>}
    </>
  )
}

const getUserDetailOrder = (item, t) => {
  const Shipping = userDetails(item.shippingAddress)
  const Billing = userDetails(item.billingAddress)
  const DYcustomerPayment = cookie.get('DY_CUSTOMER_PAYMENT_METHOD').toLocaleUpperCase()

  return (
    <CardListItem className='big'>
      <div className={'detail'}>
        <h1>{t('account.order.order_details.shipment')}</h1>
        <div>
          {Shipping}
        </div>
        <h1> {t('account.order.order_details.billing')} </h1>
        <div>
          {Billing}
        </div>
        <h1> {t('account.order.order_details.payment')} </h1>
        {/* <Figure media={mediaPastaLeft}/> */}
        <span>{DYcustomerPayment}</span>
      </div>
    </CardListItem>
  )
}

//comented until we know for sure how its the schema for orderStatus
// const getOrderStatus = (item) =>{
//   const { date, sended, delivering } = item
//   return(
//     <OrderLine>
//       <div className='progress_bar'>
//         <div className='completed'>
//           <span className='completed'></span>
//         </div>
//         <div className={sended ? 'completed': 'inactive' }>
//           <span className={sended ? 'completed': 'inactive'}></span>
//         </div>
//         <div className={delivering ? 'completed': 'inactive' }>
//           <span className={delivering ? 'completed': 'inactive'}></span>
//         </div>
//         <div className={item.delivering_date ? 'completed': 'inactive' }>
//           <span className={item.delivering_date ? 'completed': 'inactive' }></span>
//         </div>
//       </div>
//       <div className={'order_status'}>
//         <span>Ordered <span>{date}</span></span>
//         <span>Shipped <span>{sended}</span></span>
//         <span>En reparto <span>{''}</span></span>
//         <span>Llegada <span>{item.delivering_date? item.delivering_date : ''}</span></span>
//       </div>
//     </OrderLine>
//   )
// }

const variantAvailable = importStatus => (importStatus !== 'to_delete')
const getOrderLines = (products, locale, noStockText, buyAgainText, repeatOrderLine) => products.map((item, idx) => {
  const { priceExTax, quantity, productName, brandName, variantSku, variantName, variant } = item
  const isVariantAvailable = typeof variant === 'object' ? variantAvailable(variant?.importStatus) : false
  const priceCurrency = currencyFormatter({ price: priceExTax, localeRegion: locale, decimals:2, trailingZeros: false, round: false})
  // const pricePerWeightCurrency = currencyFormatter({price: pricePerWeight, localeRegion: locale})
  const thumbnail = variant?.media?.[0]?.medium

  return (
    <ProductListItem key={`${idx}-${variantSku}`}>
      {thumbnail ? <Figure media={thumbnail} /> : <figure className='placeholder' />}
      <div className={'info'}>
        <span>{quantity} x {productName}</span>
        <span>{brandName} | {variantName} </span>
        <span>{priceCurrency}</span>
      </div>
      <button disabled={!isVariantAvailable} onClick={() => {
        repeatOrderLine(item)
      }}>{isVariantAvailable ? buyAgainText : noStockText}</button>
    </ProductListItem>
  )
})

export const getOrderSummary = (item, locale, t) => {
  const { subtotalExTax, discountAmount, couponDiscount, shippingCostExTax, totalIncTax, orderLinesLength } = item
  // deliveryDate// totalTax,
  return (
    <CardListItem className='small'>
      <div className='order_title'>
        <h1>{t('account.order.order_details.payment_summary')} ({orderLinesLength})</h1>
      </div>
      <table>
        <tbody>
          <tr>
            <td>{t('account.order.order_details.total_products')}</td>
            <td>{currencyFormatter({ price: subtotalExTax, localeRegion: locale, round: false, trailingZeros: true })}</td>
          </tr>
          <tr>
            <td>{t('account.order.order_details.voucher')} </td>
            <td>-{currencyFormatter({ price: couponDiscount, localeRegion: locale, round: false, trailingZeros: true })}</td>
          </tr>
          <tr>
            <td> {t('account.order.order_details.discount')}</td>
            <td>-{currencyFormatter({ price: discountAmount, localeRegion: locale, round: false, trailingZeros: true })}</td>
          </tr>
          <tr>
            <td>{t('account.order.order_details.shipment')}</td>
            <td>{currencyFormatter({ price: shippingCostExTax, localeRegion: locale, round: false, trailingZeros: true })}</td>
          </tr>
        </tbody>
      </table>
      <div className='summary-total'>
        <span>{t('account.order.order_details.total_inc_tax')}</span>
        <span>{currencyFormatter({price: totalIncTax, localeRegion: locale, round: false, trailingZeros: true })}</span>
      </div>
    </CardListItem>
  )
}

const checkOnDemandItems = (orderLines, deliveryDate) => {
  let arrayOfDeliveryDates = [
    {
      'delivery' : deliveryDate,
      'products' : []
    }
  ]

  orderLines.forEach(line => {
    const { deliveryType, deliveryDate:orderLineDeliveryDate } = line.variant
    if (deliveryType === 'on_demand') {
      arrayOfDeliveryDates.push({ delivery: orderLineDeliveryDate, products:[line] })
    } else {
      arrayOfDeliveryDates[0].products.push(line)
    }
  })

  return arrayOfDeliveryDates
}

const getOrderLinesByDeliveryDate = (arrayOfDeliveryDates, locale, deliveryText, noStockText, buyAgainText, repeatOrderLine) => arrayOfDeliveryDates.map((el, idx) =>{

  const {delivery, products} = el
  const OrderLinesItems = useMemo(() => getOrderLines(products, locale, noStockText, buyAgainText, repeatOrderLine), [products])

  return (
    <CardListItem key={idx}>
      <div className={'main-info'}>
        <div className={'products'}>
          <p className={'headline'}> {deliveryText}: {parseDateString({ dateString: delivery })} </p>
          <ul>
            {OrderLinesItems}
          </ul>
        </div>
        <RepeatOrder orderLines={products} />
      </div>
    </CardListItem>
  )
})

export const OrderDetails:FC<Props> = memo(({ data }) => {
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const [cartLinesAdd, { data:dataAfterAdd }] = useMutation(CUSTOMER_ADD_TO_CART)
  const { bulkUpdateCart } = useCart()
  const { setCartItems } = useContext(NewCartItemsContext) || {}

  const {
    bigcommerceId, orderLines, // Timeline & orderLines
    billingAddress, shippingAddress, paymentMethod, // Main <section>
    deliveryDate, subtotalExTax, discountAmount, couponDiscount, shippingCostExTax, totalIncTax, totalTax // Summary
  } = data

  // const OrderStatus = useMemo(() => getOrderStatus(data, t), [data, t])

  const UserDetailOrder = useMemo(() => getUserDetailOrder({ billingAddress, shippingAddress, paymentMethod }, t), [billingAddress, shippingAddress, paymentMethod, t])
  const OrderSummary = useMemo(() => getOrderSummary({
    deliveryDate,
    subtotalExTax,
    discountAmount,
    couponDiscount,
    shippingCostExTax,
    totalIncTax,
    totalTax,
    orderLinesLength: orderLines.length,
    orderLines: orderLines
  }, locale, t), [deliveryDate, subtotalExTax, discountAmount, couponDiscount, shippingCostExTax, totalIncTax, totalTax, locale, orderLines, t])
  const orderLinesByDeliveryDate = checkOnDemandItems(orderLines, deliveryDate)


  const repeatOrderLine = useCallback(async orderLine => {
    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    // Update CMS
    const mutationData = {
      variables: {
        variantId: orderLine.variant.id,
        quantity: orderLine.quantity,
        cartLineType: CART_LINE_TYPE.PHYSICAL
      },
      context: {
        isPrivatePath: true,
        DYCustomerToken
      }
    }

    await cartLinesAdd(mutationData).then((res) => {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions,  multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules } = res.data.cartLinesAdd.cart
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules)
      setCartItems(cartLines)
    }).catch((error) => {
      console.warn('Error with mutation: cartLinesAdd:', error)
    })

  }, [bulkUpdateCart, cartLinesAdd, setCartItems])

  const OrderLines = getOrderLinesByDeliveryDate(orderLinesByDeliveryDate, locale, t('product_page.product.on_demand') as string, t('account.order.no_stock') as string, t('account.buy_again') as string, repeatOrderLine)


  useEffect(() => {
    if (!dataAfterAdd) return
    let cartRes = dataAfterAdd?.cartLinesAdd?.cart
    console.log('📀📀 CMS cartRes dataAfterAdd', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId: cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions,  multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules } = cartRes
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterAdd])


  return(
    <Section>
      <p>{t('account.order.number')} {bigcommerceId}</p>
      {/* <OrderStatus */}
      <Wrapper className='detail'>
        {UserDetailOrder}
        {OrderSummary}
      </Wrapper>
      <ul>
        {OrderLines}
      </ul>
    </Section>
  )
})



const Wrapper = styled.ul`
  display: block;

  ${mq.greaterThan('tablet')} {
    display: flex;
    place-content: space-between;
  }

  .big {
    height: unset;
    width: unset;

    ${mq.greaterThan('tablet')} {
      height: 100%;
      width: 63%;
    }

    h1, span {
      color: ${({ theme }) => theme.colors.darkred};
    }

    h1 {
      margin-top: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(53, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top:  53px;
      }
    }

    span {
      ${getP15_1B()}
      color: ${({ theme }) => hex2Rgba(theme.colors.darkred, .7)};

      ${mq.greaterThan('tablet')} {
        color: ${({ theme }) => theme.colors.darkred};
      }
    }

    figure {
      display: inline-block;
      height: ${vw(30, 'mobile')};
      width: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(30, 'nexus7')};
        width: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(30, 'desktop')};
        width: ${vw(30, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 30px;
        width: 30px;
      }

      + span {
        display: inline;
        padding-left: ${vw(10, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          padding-left: ${vw(10, 'nexus7')};
        }

        ${mq.greaterThan('tablet') } {
          padding-left: ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop') } {
          padding-left: 10px;
        }
      }
    }

    .detail {
      div > span { display: block;}
    }
  }

  .small {
    height: unset;
    width: unset;

    ${mq.greaterThan('tablet')} {
      height: 90%;
      width: 33%;
    }

    h1, td, span {
      color: ${({ theme }) => theme.colors.red};
    }
    td, span {
      ${getP15_1B()}
    }

    table {
      width:100%;
      padding: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('tablet')} {
        padding: 12px 20px 15px;
      }

      tr {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: ${vw(5, 'mobile')} 0;

        ${mq.greaterThan('nexus7')} {
          padding: ${vw(5, 'nexus7')} 0;
        }

        ${mq.greaterThan('tablet')} {
          padding: ${vw(10, 'desktop')} 0;
        }

        ${mq.greaterThan('desktop')} {
          padding: 10px 0;
        }
      }
    }

    .summary-total {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
      padding: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 12px 20px 15px;
      }
    }
  }
`

// const OrderLine = styled.div`
//   display:grid;
//   grid-template-columns: ${vw(100, 'mobile')} auto;
//   height:${vw(300, 'mobile')};
//   margin: 0 0 ${vw(30, 'mobile')};

//   ${mq.greaterThan('nexus7')} {
//     grid-template-columns: ${vw(100, 'nexus7')} auto;
//     height:${vw(300, 'nexus7')};
//     margin: 0 0 ${vw(30, 'nexus7')};
//   }

//   ${mq.greaterThan('tablet')} {
//     display:block;
//     grid-template-columns: unset;
//     grid-template-rows: unset;
//     height:unset;
//   }

//   .progress_bar , .order_status {
//     display:grid;

//     ${mq.greaterThan('tablet')} {
//       grid-template-columns: repeat(4, 1fr);
//     }
//   }

//   .progress_bar {
//     grid-template-rows: repeat(4,25%);
//     justify-items: center;

//     ${mq.greaterThan('tablet')} {
//       grid-template-rows: unset;
//       justify-items: unset;
//     }

//     > div {
//       align-items: center;
//       background-color: ${({ theme }) => hex2Rgba(theme.colors.red, .2)};
//       display:grid;
//       height:100%;
//       justify-content: center;
//       text-align: center;
//       width: 10px;
//       z-index: -20;

//       ${mq.greaterThan('tablet')} {
//         display:block;
//         height: ${vw(5, 'desktop')};
//         width: 100%;
//       }

//       ${mq.greaterThan('desktop')} {
//         height: 5px;
//       }

//       &.completed {
//         background-color: ${({ theme }) => theme.colors.red};
//       }

//       &:first-child {
//         border-top-left-radius:  ${vw(10, 'mobile')};
//         border-top-right-radius:  ${vw(10, 'mobile')};
//         border-bottom-left-radius: 0;

//         ${mq.greaterThan('nexus7')} {
//           border-top-left-radius:  ${vw(10, 'nexus7')};
//           border-top-right-radius:  ${vw(10, 'nexus7')};
//         }

//         ${mq.greaterThan('tablet')} {
//           border-top-left-radius:  ${vw(10, 'desktop')};
//           border-top-right-radius: 0;
//           border-bottom-left-radius:  ${vw(10, 'desktop')};
//         }

//         ${mq.greaterThan('desktop')} {
//           border-top-left-radius:  10px;
//           border-bottom-left-radius:  10px;
//         }
//       }

//       &:last-child {
//         border-bottom-left-radius:  ${vw(10, 'mobile')};
//         border-bottom-right-radius:  ${vw(10, 'mobile')};
//         border-top-right-radius: 0;

//         ${mq.greaterThan('nexus7')} {
//           border-bottom-left-radius:  ${vw(10, 'nexus7')};
//           border-bottom-right-radius:  ${vw(10, 'nexus7')};
//         }

//         ${mq.greaterThan('tablet')} {
//           border-bottom-left-radius: 0;
//           border-top-right-radius:  ${vw(10, 'desktop')};
//           border-bottom-right-radius:  ${vw(10, 'desktop')};
//         }

//         ${mq.greaterThan('desktop')} {
//           border-top-right-radius:  10px;
//           border-bottom-right-radius:  10px;
//         }
//       }

//       span.completed:after {
//         ${getP26_1()}
//         color: white;
//         content:'✓';
//         left:${vw(3, 'mobile')};
//         position: relative;
//         top:${vw(-18, 'mobile')};
//         z-index: 1;

//         ${mq.greaterThan('nexus7')} {
//           left:${vw(3, 'nexus7')};
//           top:${vw(-18, 'nexus7')};
//         }

//         ${mq.greaterThan('tablet')} {
//           ${getP10()}
//           left:0;
//           top:${vw(-16, 'desktop')};
//         }

//         ${mq.greaterThan('desktop')} {
//           top:16px;
//         }
//       }

//       span.completed:before {
//         background: ${({ theme }) => theme.colors.red};
//         border-radius: 50%;
//         content:'';
//         display: block;
//         height: ${vw(20, 'mobile')};
//         margin: ${vw(20, 'mobile')} 0 0;
//         width: ${vw(20, 'mobile')};

//         ${mq.greaterThan('nexus7')} {
//            height: ${vw(20, 'nexus7')};
//            margin: ${vw(20, 'nexus7')} 0 0;
//            width: ${vw(20, 'nexus7')};
//         }

//         ${mq.greaterThan('tablet')} {
//           height: ${vw(15, 'desktop')};
//           margin: ${vw(-5, 'desktop')} auto;
//           width: ${vw(15, 'desktop')};
//         }

//         ${mq.greaterThan('desktop')} {
//           height: 15px;
//           margin: -5px auto;
//           width: 15px;
//         }
//       }
//     }
//   }

//   .order_status {
//     align-items: center;
//     grid-template-rows: repeat(4,25%);
//     justify-items:unset;
//     margin: 0;

//     ${mq.greaterThan('tablet')} {
//       align-items: unset;
//       grid-template-rows: unset;
//       justify-items: center;
//       margin-bottom: ${vw(55, 'desktop')};
//       margin-top: ${vw(30, 'desktop')};
//     }

//     ${mq.greaterThan('desktop')} {
//       margin-bottom: 55px;
//       margin-top: 30px;
//     }

//     > span {
//       ${getP12_1()}
//       color: ${({ theme }) => theme.colors.darkred};
//       display:block;
//       text-align:left;

//       ${mq.greaterThan('tablet')} {
//         display: inline-grid;
//         text-align: center;
//       }
//     }
//   }
// `
