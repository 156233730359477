"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputPattern = void 0;
// https://rgxdb.com/r/3EZMSVWM
exports.inputPattern = {
    email: /^[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)*@[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)+$/i,
    postalCode: {
        'BE': /^(?:(?:[1-9])(?:\d{3}))$/,
        'CH': /^[1-9]\d{3}$/,
        'DE': /^\d{5}$/,
        'DK': /^(?:[1-24-9]\d{3}|3[0-8]\d{2})$/,
        'ES': /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
        'FR': /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/,
        'GB': /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/,
        'IT': /^\d{5}$/,
        'PT': /^\d{4}-\d{3}?$/,
        'US': /^\d{5}(?:-\d{4})?$/
    },
    password: /(?=.*[a-zA-Z])((?=.*[0-9])|(?=.*([[:print:]][^[[:alnum:]]]))).{8,}/,
};
