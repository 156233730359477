import { FC, memo } from 'react'
import { Figure } from '@dy/commons/components'
import styled from 'styled-components'
import { mq, vw, getP15_5 } from '@dy/commons/styles'
import { useActiveVariant, useTranslator } from '@/hooks'
import { getDynamicText } from '@dy/commons/utils'

interface IViewMore {
  pagination: any,
}

const mediaViewMore = {
  type: 'image' as const,
  alt: 'View more',
  default: {
    src: '/images/svg/btn-hexagon.svg',
    size: { width: 91, height: 99 }
  }
}


export const ViewMore:FC<IViewMore> = memo(({ pagination }) => {
  const { productsDisplayed, totalProducts } = pagination
  const { t } = useTranslator()
  const { cardSelected } = useActiveVariant()

  return (
    <Wrapper $activeVariant={!!cardSelected?.variant_id}>
      <Figure media={mediaViewMore}/>
      <span> 
        {getDynamicText(t('shop.displayed_products') as string, {
          productsDisplayed:productsDisplayed,
          totalProducts: totalProducts
        })} 
      </span>
    </Wrapper>
  )
})


const Wrapper = styled.div<any>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto ${vw(100, 'mobile')};
  grid-column: 1 / span 6;

  ${mq.greaterThan('nexus7')} {
    margin: 0 auto ${vw(100, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 8 / span 1;
    grid-row:6/ auto;
    margin: 0 auto ${vw(100, 'desktop')};
    transform: translateX(${({ $activeVariant }) => $activeVariant ? `-${vw(120 * 4, 'desktop')}` : 0});
    transition: 400ms transform ${({ theme }) => theme.ease}, 400ms margin ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease}, 300ms height ${({ theme }) => theme.ease};
    left: auto;
  }

  ${mq.greaterThan('desktop')} {
    margin: 0 auto 100px;
    transform: translateX(${({ $activeVariant }) => $activeVariant ? `-${120 * 4}px` : 0});
  }

  > figure {
      height:${vw(99, 'mobile')};
      margin-bottom:${vw(20, 'mobile')};
      width:${vw(91, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height:${vw(99, 'nexus7')};
      margin-bottom:${vw(20, 'nexus7')};
      width:${vw(91, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height:${vw(99, 'desktop')};
      margin-bottom:${vw(20, 'desktop')};
      width:${vw(91, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 99px;
      margin-bottom: 20px;
      width: 91px;
    }
  }

  &:last-child {
    ${getP15_5()}
    color: ${({ theme }) => theme.colors.darkcreme};
  }
`