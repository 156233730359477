'use client'
import { memo, useCallback, useEffect } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useTranslator } from '@/hooks'
import { useModal } from '@dy/commons/hooks'
import { DY_SIGNUP_ALLOWED, DY_LOGIN_ALLOWED } from '@dy/commons/utils'
import { MODALS } from '@dy/commons/context'
import { Modal } from './'
import { SectionUserAuth, defaultAuthModalLevel } from '@/components'
import type { TypeSectionAuthLevel } from '@/components'
import { mq, vw } from '@dy/commons/styles'

export interface iModalAuthPayload {
  payload: {
    level: TypeSectionAuthLevel
  }
}

const buttonBack = {
  ...DY_SIGNUP_ALLOWED && {
    'signup_cif': 'start',
    'signup': 'signup_cif',
    'signup_active_cif': 'signup_cif',
  },
  ...DY_LOGIN_ALLOWED && {
    'prelogin': 'start',
    'login': 'prelogin',
    'login_existing_cif': 'prelogin',
    'feedback_cif_not_found': 'prelogin',
    'login_check_cif': 'prelogin',
  },
  'forgot_password': 'login',
  'reset_password': 'login',
  'activation': 'login'
}


export const ModalAuth: FC = memo(() => {
  const { isActive, payload, updatePayload } = useModal(MODALS.AUTH)
  const { t } = useTranslator()

  const onClickBack = useCallback(() => {
    updatePayload({ payload: { level: buttonBack[payload?.level ?? defaultAuthModalLevel]} })
  }, [updatePayload, payload])

  const onClose = useCallback(() => {
    updatePayload({ payload: { level: defaultAuthModalLevel }, close: true })
  }, [updatePayload])

  useEffect(() => {
    if (!payload) updatePayload({ payload: { level: defaultAuthModalLevel }})
  }, [payload, updatePayload])

  return isActive && (
    <Modal isActive={isActive} close={onClose} title={t(`modals.auth.${payload?.level as TypeSectionAuthLevel ?? defaultAuthModalLevel}.title`) as string} xAlign='flex-start' onClickBack={buttonBack[payload?.level] ? onClickBack : null} size='s' dialogStyles={dialogStyles}>
      <SectionUserAuth payload={payload} updatePayload={updatePayload} />
    </Modal>
  )
})

const dialogStyles = css`
  > .modal__body {
    padding-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-bottom: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-bottom: 30px;
    }
  }
`
