import { memo, useMemo } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { SliderFlickity } from '@dy/commons/components'
import { useActiveVariant } from '@/hooks'
import { mq, vw, getP20_1, getP15_2, getTransition } from '@dy/commons/styles'

type TypeFilterValue = {
  name: string,
  value?: string,
  description?: string,
  media?: {
    type: string,
    alt: string,
    url: string
    size: {width: number, height: number}
  }
}

const getSlides = (filters, isBrandPage) => filters.map((filter, idx) => {
  return (
    <li key={`slide-${idx}`}>
      <Article className={`param-${filter.param}`} $isBrandPage={isBrandPage}>
        <img src={filter.media.url} alt={filter.media.alt} />
        <div className='text-wrapper'>
          <h2>{filter.name}</h2>
          <div dangerouslySetInnerHTML={{ __html: filter.description }} />
        </div>
      </Article>
    </li>

  )
})

type TypeSectionFiltersFeatured = {
  filters?: TypeFilterValue[],
  isBrandPage?: boolean
}

export const SectionFiltersFeatured = memo(({ filters = [], isBrandPage }:TypeSectionFiltersFeatured) => {
  const { activeVariant } = useActiveVariant()
  const Slides = useMemo(() => getSlides(filters, isBrandPage), [filters, isBrandPage])
  return (
    <Section variants={variants} initial='initial' exit='exit' animate={filters?.length < 1 ? 'exit' : 'animate'} $activeVariant={!!activeVariant} $isBrandPage={isBrandPage}>
      <SliderFlickity className={'slider--product-card'} options={{ freeScroll: false, pageDots: false, groupCells: '80%', cellAlign: 'left' }}>
        {Slides}
      </SliderFlickity>
    </Section>
  )
})

export const DynamicSectionFiltersFeatured = dynamic<TypeSectionFiltersFeatured>(() => import('./').then((mod) => mod.SectionFiltersFeatured), { ssr: false })

const Section = styled<any>(motion.section)`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  margin-top: ${({ $isBrandPage }) => $isBrandPage ? `${vw(48, 'mobile')}` : 0};

  ${mq.greaterThan('tablet')} {
    grid-column: 4 / span 9;
    grid-row: 1;
    margin-top: ${({ $isBrandPage }) => $isBrandPage ? `${vw(48, 'desktop')}` : 0};
  }

  > div {
    ${mq.greaterThan('tablet')} {
      padding: ${vw(80, 'desktop')} 0 0 ${vw(10, 'desktop')};
      transform: translateX(${({ $activeVariant }) => $activeVariant ? `-${vw(350, 'desktop')}` : 0});
      transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
      width: 100%;
    }

    ${mq.greaterThan('desktop')} {
      padding: 80px 0 0 60px;
      transform: translateX(${({ $activeVariant }) => $activeVariant ? '-350px' : 0});
    }

    li {
      padding: ${vw(40, 'mobile')} ${vw(20, 'mobile')};
      width: ${vw(414, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(40, 'nexus7')} ${vw(20, 'nexus7')};
        width: ${vw(603, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: 0 ${vw(158, 'desktop')} 0 0;
        padding: ${({ $isBrandPage }) => $isBrandPage ? '0' : `0 ${vw(158, 'desktop')} 0 0`};
        width: ${({ $isBrandPage }) => $isBrandPage ? 'unset' : '80%'};
      }

      ${mq.greaterThan('desktop')} {
        padding: 0 158px 0 0;
      }
    }
  }
`

const Article = styled.article<any>`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mq.greaterThan('tablet')} {
    align-items: flex-start;
    flex-direction: row;
    margin: 0;
  }

  img {
    flex-shrink: 0;
    margin-bottom: ${vw(20, 'mobile')};
    user-select: none;
    width: ${vw(125, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'nexus7')};
      width: ${vw(125, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: 0;
      margin-right: ${vw(38, 'desktop')};
      width: ${vw(165, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-right: 38px;
      width: 165px;
    }
  }

  &.param-marca,
  &.param-brand,
  &.param-marque {
    figure {
      align-items: center;
      background: ${({ theme }) => theme.colors.darkred};
      border-radius: 50%;
      display: flex;
      height: ${vw(125, 'mobile')};
      justify-content: center;
      padding: ${vw(12, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(125, 'nexus7')};
        padding: ${vw(12, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(165, 'desktop')};
        padding: ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 165px;
        padding: 20px;
      }

      img {
        filter: invert(.95);
        height: 100%;
      }
    }
  }

  .text-wrapper {
    padding-top: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding-top: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding-top: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding-top: 20px;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.darkred};
    ${getP20_1()}
    font-weight: bold;
    letter-spacing: -.5;
    margin-bottom: ${vw(5, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 5px;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.darkred};
    ${getP15_2()}
    text-align: center;
  }

  h2, p {
    ${mq.greaterThan('tablet')} {
      text-align: left;
    }
  }
`

const variants = {
  initial: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .5,
      delay: .4,
    }
  },
  exit: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  }
}
