'use client'
import type { FC } from 'react'
import { useEffect, memo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { autoComplete, cookie, inputPattern } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'
import { useToast } from '@dy/commons/hooks'

type Props = {
  animation?: any,
  bgColor?: TypeThemeColors
  defaultEmail?: string
}

type TypeFormInputs = {
  email: string,
  password: string,
}

export const FormCustomerUpdate:FC<Props> = memo(({ animation, bgColor = 'background', defaultEmail }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, formState: { errors:formErrors } } = useForm<TypeFormInputs>()
  const { addToast } = useToast()

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData, e) => {
    e.preventDefault()
    const { email, password } = formData

    try {
      const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
      const res = await fetch('/api/customer/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, DYCustomerToken }),
      })

      if(res.ok) {
        const { data } = await res.json()
        addToast({ 
          type: 'success', 
          message: t('modals.auth.feedback_password_updated.text') as string 
        })
        console.log('@@@ customerUpdate onSubmit res data', data)
      }
    } catch(e) {
      addToast({ 
        type: 'error', 
        message: t('commons.newsletter_feedback.error') as string 
      })
      console.error('Customer update failed:', e)
    }
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  useEffect(() => {
    if(Object.keys(formErrors).length > 0) {
      console.log('CustomerUpdate formErrors:', formErrors)
      addToast({ 
        type: 'warning', 
        message: t('form.errors.pattern_password') as string 
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={false} ariaBusy={false} legend={t('modals.auth.login.text') as string}>
        <Input type='email' name='email' register={register} label={t('form.fields.email') as string} defaultValue={defaultEmail} placeholder='email' autoComplete={autoComplete.email} required={{
          required: t('form.errors.pattern_email') as string,
          pattern: inputPattern.email
        }} error={formErrors.email} bgColor={bgColor}/>
        <Input type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.current} icon={'password'} bgColor={bgColor} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
      </Fieldset>
      <Button type='submit' bgType={'red'}>{t('commons.actions.save') as string}</Button>
    </Form>
  )
})

