import { useState, useMemo, useCallback } from 'react'
import type { FC } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { css } from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { MODALS } from '@dy/commons/context'
import { useModal, useScrollTracker } from '@dy/commons/hooks'
import { Modal, SearchResults, SearchByCategory } from '@/components'
import { Figure, Form, Input } from '@dy/commons/components'
import { mq, vw, designGrid, grid } from '@dy/commons/styles'
import { cookie, pushAnalyticsEvent } from '@dy/commons/utils'
import { GET_SEARCH_PRODUCTS } from '@/api'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'

type ModalSearchProps = {
  hasBanners?: boolean,
  categories:[],
}

type emptySearchResult = {
  search: [],
  articles: [],
  brands: [],
  categories: [],
  variants: [],
}

const emptySearchResult = {
  search: [],
  articles: [],
  brands: [],
  categories: [],
  variants: [],
}

const searchClearIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--search-clear.svg',
  alt: 'Clear search icon',
  size: { width: 12, height: 12 }
}

const searchGoPageIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--search-go-page.svg',
  alt: 'Search go page icon',
  size: { width: 12, height: 12 }
}

const dialogStyles = ( animate )=> {
  return css`
    background: ${({ theme }) => theme.colors.white};
    height: 100vh;
    height: 100dvh;
    margin: ${ animate ? `${vw(40, 'mobile')} 0 0` : '0 0 0' };
    width: 100vw;
    transition: margin 200ms ease;
    z-index: ${ animate ? 660 : 0 };


    ${mq.greaterThan('nexus7')} {
      margin: ${ animate ? `${vw(40, 'nexus7')} 0 0` : '0 0 0' };
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(625, 'desktop')};
      max-height: 95vh;
      margin: ${ animate ? `${vw(30, 'desktop')} 0 200px` : '0 0 200px' };
      width: 100vw;
    }

    ${mq.greaterThan('desktop')} {
      height: 625px;
      margin: ${ animate ? '30px 0 200px' : '0 0 200px' };
    }

    header {
      border-bottom: 0;
      position: absolute;
      right: 0;

      h2 {
        display: none;
      }

      button {
        &[data-dismiss='modal'] {
          height: ${vw(62, 'mobile')};
          width: ${vw(62, 'mobile')};
          max-width: unset;

          ${mq.greaterThan('nexus7')} {
            height: ${vw(62, 'nexus7')};
            width: ${vw(62, 'nexus7')};
          }

          ${mq.greaterThan('tablet')} {
            height: ${vw(62, 'desktop')};
            padding-top: ${vw(40, 'desktop')};
            width: ${vw(62, 'desktop')};
          }

          ${mq.greaterThan('desktop')} {
            height: 62px;
            padding-top: 40px;
            width: 62px;
          }

          figure {
            width: ${vw(18, 'mobile')};

            ${mq.greaterThan('nexus7')} {
              width: ${vw(18, 'nexus7')};
            }

            ${mq.greaterThan('tablet')} {
              width: ${vw(18, 'desktop')};
            }

            ${mq.greaterThan('desktop')} {
              width: 18px;
            }
          }
        }
      }
    }

    > .modal__body {
      ${designGrid({})}
      grid-template-rows: auto 1fr;
      height: 100%;
      padding: 0;
      width: 100%;
    }

    form {
      grid-column: 1 / span 6;
      /* margin-top: ${vw(20, 'mobile')}; */

      ${mq.greaterThan('tablet')} {
        grid-column: 2 / span 10;
        margin-top: ${vw(20, 'desktop')};
        position: relative;
      }

      ${mq.greaterThan('desktop')} {
        margin-top: 20px;
      }

      input[type='search'] {
        padding-bottom: ${vw(16, 'mobile')};
        padding-top: ${vw(15, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          padding-bottom: ${vw(16, 'nexus7')};
          padding-top: ${vw(15, 'nexus7')};
        }

        ${mq.lessThan('tablet')} {
          margin-right: auto;
          width: 80vw;
        }

        ${mq.greaterThan('tablet')} {
          padding-bottom: ${vw(30, 'desktop')};
          padding-top: ${vw(15, 'desktop')};
        }


        ${mq.greaterThan('desktop')} {
          padding-bottom: 30px;
          padding-top: 15px;
        }
      }

      button {
        align-items: center;
        display: flex;
        height: ${vw(50, 'mobile')};
        justify-content: center;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        right: ${vw(grid.mobile.columns.gutter.width + (grid.mobile.columns.gutter.width * .5) + grid.mobile.columns.width, 'mobile')};
        top: 0;
        transition: 300ms opacity ease-out;
        user-select: none;
        width: ${vw(50, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(50, 'nexus7')};
          width: ${vw(50, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(50, 'desktop')};
          right: ${vw(grid.desktop.columns.width, 'desktop')};
          width: ${vw(50, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 50px;
          width: 50px;
        }

        &:hover {
          figure {
            opacity: .8;
          }
        }

        figure {
          opacity: .2;
          transition: 300ms opacity ease-out;
          width: ${vw(26, 'mobile')};

          ${mq.greaterThan('nexus7')} {
            width: ${vw(26, 'nexus7')};
          }

          ${mq.greaterThan('tablet')} {
            width: ${vw(26, 'desktop')};
          }

          ${mq.greaterThan('desktop')} {
            width: 26px;
          }
        }
      }

      a {
        opacity: 0;
        display: none;
        pointer-events: none;
        transition: 300ms opacity ease-out;
        user-select: none;

        ${mq.greaterThan('tablet')} {
          align-items: center;
          display: flex;
          justify-content: center;
          position: absolute;
          right: 0;
        }

        figure {
          ${mq.greaterThan('tablet')} {
            transition: 300ms transform ease-out;
            width: ${vw(50, 'desktop')};
          }

          ${mq.greaterThan('desktop')} {
            width: 50px;
          }
        }

        &:hover {
          figure {
            transform: scale(1.1);
          }
        }
      }

      &.dirty {
        button,
        a {
          opacity: 1;
          pointer-events: auto;
          user-select: auto;
        }
      }
    }

    label {
      padding: 0;
      margin-bottom: 0;
    }
  `
}

const dialogVariants = (isActive: boolean) => ({
  initial: { y: '-100%', zIndex: 0},
  animate: { y: isActive ? 0 : '-100%', zIndex: isActive ? 660 : 0},
  exit: { y: '-100%',  zIndex: 0},
  transition: { duration: .3 }
})

type TypeFormInputs = {
  search: string,
  getDataForSearch: () => void
}

export const ModalSearch:FC<ModalSearchProps> = ({ hasBanners, categories }) => {
  const { isActive, toggle } = useModal(MODALS.SEARCH)
  const { register, handleSubmit, reset } = useForm<TypeFormInputs>()
  const [qValue, setQValue] = useState('')
  const [results, setResults] = useState(emptySearchResult)
  const scrollPos = useScrollTracker(1)
  const scrollTop = useMemo(() => scrollPos === 'TOP', [scrollPos])
  const animate = useMemo(() => hasBanners && scrollTop ? true : false, [hasBanners, scrollTop])
  const [getDataForSearch, {}] = useLazyQuery(GET_SEARCH_PRODUCTS)
  const { push, pathname } = useRouter()
  const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')

  const onSubmit: SubmitHandler<TypeFormInputs> = async formData => {
    const { search: searchValue } = formData
    if(searchValue?.length < 2) return {}

    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    const { data } = await getDataForSearch({ variables: { search: searchValue },
      context: DYCustomerToken ? {
        isPrivatePath: true,
        DYCustomerToken
      } : { isPrivatePath: false }
    })

    if (!data) return {}
    const arrayOfVariantNodes = Array.isArray(data?.productSearch?.variants?.edges) ? data.productSearch.variants.edges.map(item => item.node) : []
    setResults({ ...data.productSearch, variants: arrayOfVariantNodes } as emptySearchResult)
  }

  const onKeyUp = useCallback((e) => {
    if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
      const href = `${DYCustomerToken ? '/shop': '/catalog'}/search?q=${e.target.value}`
      if(pathname === `${DYCustomerToken ? '/shop': '/catalog'}/search`) {
        push(href, undefined, { shallow: true })
      } else {
        push(href)
      }
      reset({ search: '' })
      toggle()

    }
  }, [DYCustomerToken, pathname, push, reset, toggle])

  const onChange = async ({ target }) => {
    const searchValue = target?.value
    if (typeof searchValue !== 'string') return
    if(searchValue?.length < 3) return {}
    setQValue(searchValue)

    pushAnalyticsEvent('search', searchValue as any)

    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    const { data } = await getDataForSearch({ variables: { search: searchValue },
      context: DYCustomerToken ? {
        isPrivatePath: true,
        DYCustomerToken
      } : { isPrivatePath: false }
    })

    if (!data) return {}
    const arrayOfVariantNodes = Array.isArray(data?.productSearch?.variants?.edges) ? data.productSearch.variants.edges.map(item => item.node) : []
    setResults({ ...data.productSearch, variants: arrayOfVariantNodes } as emptySearchResult)
  }

  const onClickClearSearch = () => {
    console.info('Clearing')
    setQValue('')
    reset({ search: '' }, {
      keepErrors: false,
      keepDirty: true,
    })
  }
  const onClickResultClearAndClose = () => {
    toggle()
    onClickClearSearch()
  }

  return (
    <Modal isActive={isActive} close={toggle} title='Menu' xAlign='flex-start' variants={dialogVariants} dialogStyles={dialogStyles(animate)} size='full'>
      <Form onSubmit={handleSubmit(onSubmit)} className={`${qValue?.length > 2 ? 'dirty' : ''}`}>
        <Input type='search' name='search' label='' col={12} register={register} onChange={onChange} bgColor='white' autoFocus={isActive} onKeyUp={onKeyUp}/>
        <button type='button' onClick={onClickClearSearch} >
          <Figure media={searchClearIcon} fill={false} />
        </button>
        <a href={`${DYCustomerToken ? '/shop': '/catalog'}/search?q=${qValue}`} onClick={toggle}>
          <Figure media={searchGoPageIcon} fill={false} />
        </a>
      </Form>
      <AnimatePresence>
        <SearchResults key='results-list' data={{ search:[{ name:`${qValue}`, href: `${DYCustomerToken ? '/shop': '/catalog'}/search?q=${qValue}`}], ...results}} close={onClickResultClearAndClose} />
        <SearchByCategory data={categories} close={onClickResultClearAndClose} />
      </AnimatePresence>
    </Modal>
  )
}
