"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupByDate = exports.getDateFormatted = exports.getDayOfWeekName = exports.parseDateString = void 0;
const isValidDate = (dateString) => !isNaN(Date.parse(dateString));
function parseDateString({ dateString, locale = 'es-ES', includeHour = false, includeSeconds = false, includeTimezone = false }) {
    if (!isValidDate(dateString)) {
        console.warn('🗓️ dateString passed is not a valid date pattern and cannot be parsed');
        return '';
    }
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    if (includeHour) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        if (includeSeconds)
            options.second = '2-digit';
    }
    if (includeTimezone)
        options.timeZoneName = 'short';
    return date.toLocaleString(locale, options);
}
exports.parseDateString = parseDateString;
function getDayOfWeekName(lang, dayOfWeek) {
    const options = { weekday: 'long', locale: lang };
    const formatter = new Intl.DateTimeFormat(lang, options);
    // Get the name of the week day based on the day of the week integer
    const dayName = formatter.format(new Date(Date.UTC(new Date().getFullYear(), 0, dayOfWeek)));
    return dayName;
}
exports.getDayOfWeekName = getDayOfWeekName;
function getDateFormatted(locale, dateString) {
    const date = new Date(dateString);
    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat(locale, options);
    const parts = formatter.formatToParts(date);
    const formattedDate = `${parts[0].value} ${parts[2].value}/${parts[4].value}`;
    return formattedDate;
}
exports.getDateFormatted = getDateFormatted;
function groupByDate(data = []) {
    if (!data.length)
        return [];
    const cartLines = [...data];
    // Sort the cartLines array by deliveryDate in ascending order
    cartLines.sort((a, b) => {
        const dateA = new Date(a.variant.deliveryDate);
        const dateB = new Date(b.variant.deliveryDate);
        return dateA.getTime() - dateB.getTime();
    });
    // Create a Map to store cartLines grouped by date
    const groupedByDate = new Map();
    // Iterate over the sorted array of cartLines
    cartLines.forEach(item => {
        const deliveryDate = new Date(item.variant.deliveryDate);
        // Create a key based on year, month, and day (ignoring hours and minutes)
        const key = new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()).toString();
        // Add the object to the corresponding group in the Map
        if (groupedByDate.has(key)) {
            groupedByDate.get(key).push(item);
        }
        else {
            groupedByDate.set(key, [item]);
        }
    });
    // Convert the Map values to an array of arrays
    const result = Array.from(groupedByDate.values());
    return result;
}
exports.groupByDate = groupByDate;
