"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageSlider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styled_components_1 = __importStar(require("styled-components"));
const framer_motion_1 = require("framer-motion");
const __1 = require("../");
const styles_1 = require("../../styles");
const hooks_1 = require("../../hooks");
const Timeline = (0, react_1.memo)(({ currentIdx, nSlides }) => {
    const controls = (0, framer_motion_1.useAnimation)();
    const [spanVariants] = (0, react_1.useMemo)(() => {
        const variants = {};
        const percentage = 100 / nSlides;
        for (let idx = 0; idx < nSlides; idx++) {
            variants[idx] = {
                width: (idx + 1) * percentage + '%',
                transition: {
                    ease: 'linear',
                    duration: hooks_1.SLIDE_TIME / 1000,
                },
            };
            variants[idx + 'prev'] = {
                width: (idx) * percentage + '%',
                transition: {
                    ease: 'linear',
                    duration: 0.1,
                },
            };
        }
        return [variants];
    }, [nSlides]);
    const changeSpan = (0, react_1.useCallback)((idx) => __awaiter(void 0, void 0, void 0, function* () {
        yield controls.start(idx + 'prev');
        controls.start(idx + '');
    }), []);
    (0, react_1.useEffect)(() => {
        changeSpan(currentIdx);
        return () => {
            controls.stop();
        };
    }, [currentIdx]);
    return ((0, jsx_runtime_1.jsx)(TimelineContainer, Object.assign({ className: 'second' }, { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: 'span-container' }, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.span, { variants: spanVariants, animate: controls }) })) })));
});
const imageVariants = {
    initial: {
        opacity: 0,
        transition: Object.assign(Object.assign({}, (0, styles_1.getTransition)()), { delay: 0 })
    },
    animate: {
        opacity: 1,
        transition: Object.assign(Object.assign({}, (0, styles_1.getTransition)()), { duration: 1, delay: .3 })
    },
    exit: {
        opacity: 0,
        transition: Object.assign(Object.assign({}, (0, styles_1.getTransition)()), { duration: .2, delay: 0 })
    }
};
const slideSizes = `(min-width: 768px) ${(0, styles_1.vw)(520, 'desktop')}, ${(0, styles_1.vw)(374, 'mobile')}`;
const getSlide = (image, idx) => {
    var _a;
    if (!image[idx])
        return null;
    return ((0, jsx_runtime_1.jsx)(__1.Figure, { media: (_a = image[idx]) === null || _a === void 0 ? void 0 : _a.medium, lazy: idx > 5, sizes: slideSizes, quality: 99 }));
};
exports.ImageSlider = (0, react_1.memo)(({ data, isPack = false }) => {
    if (!data)
        return (0, jsx_runtime_1.jsx)(SliderStyled, {});
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { currentSlide, triggerRef, nextSlide, prevSlide, setSlide, startAnimation } = (0, hooks_1.useSlider)(data.length);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const Image = (0, react_1.useMemo)(() => getSlide(data, currentSlide), [data, currentSlide]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const nSlides = (0, react_1.useMemo)(() => data.length, [data.length]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (0, react_1.useEffect)(() => {
        startAnimation();
        setSlide(0);
    }, [data]);
    return ((0, jsx_runtime_1.jsxs)(SliderStyled, Object.assign({ layout: true, ref: triggerRef, isPack: isPack }, { children: [(0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, Object.assign({ mode: 'wait' }, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, Object.assign({ variants: imageVariants, initial: 'initial', exit: 'exit', animate: 'animate' }, { children: Image }), currentSlide) })), data.length > 1 &&
                (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("button", Object.assign({ onClick: () => prevSlide(), className: 'control-btn prev' }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/i--prevslide.svg', width: '40', height: '40' }) })), (0, jsx_runtime_1.jsx)("button", Object.assign({ onClick: () => nextSlide(), className: 'control-btn next' }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/i--nextslide.svg', width: '40', height: '40' }) })), (0, jsx_runtime_1.jsx)(Timeline, { nSlides: nSlides, currentIdx: currentSlide })] })] })));
});
const SliderStyled = (0, styled_components_1.default)(framer_motion_1.motion.div) `
  min-height: ${(0, styles_1.vw)(325, 'mobile')};
  position: relative;

  ${styles_1.mq.greaterThan('nexus7')} {
    min-height: ${(0, styles_1.vw)(325, 'nexus7')};
  }

  ${styles_1.mq.greaterThan('tablet')} {
    min-height: ${(0, styles_1.vw)(430, 'desktop')};

    &:hover .control-btn {
      display: block;
    }
  }

  ${styles_1.mq.greaterThan('desktop')} {
    min-height: 430px;
  }

  figure {
    height: ${({ isPack }) => isPack ? `${(0, styles_1.vw)(245, 'mobile')}` : `${(0, styles_1.vw)(324, 'mobile')}`};
    margin: ${(0, styles_1.vw)(15, 'mobile')} auto;
    max-width: 100%;
    width: ${(0, styles_1.vw)(374, 'mobile')};

    ${({ isPack }) => { if (isPack) {
    return (0, styled_components_1.css) `width: 100%;`;
} }}

    ${styles_1.mq.greaterThan('nexus7')} {
      height: ${({ isPack }) => isPack ? `${(0, styles_1.vw)(245, 'nexus7')}` : `${(0, styles_1.vw)(324, 'nexus7')}`};
      margin: ${(0, styles_1.vw)(15, 'nexus7')} auto;
      width: ${(0, styles_1.vw)(374, 'nexus7')};
    }

    ${styles_1.mq.greaterThan('tablet')} {
      width: ${(0, styles_1.vw)(520, 'desktop')};
      height: ${({ isPack }) => isPack ? `${(0, styles_1.vw)(407, 'desktop')}` : `${(0, styles_1.vw)(428, 'desktop')}`};
      margin: ${(0, styles_1.vw)(10, 'desktop')} auto;
    }

    ${styles_1.mq.greaterThan('desktop')} {
      width: 520px;
      height: ${({ isPack }) => isPack ? '407px' : '428px'};
      margin: 10px auto;
    }
  }

  img {
    object-fit: ${({ isPack }) => isPack ? 'cover' : 'contain'};
    height: 100%;
  }

  .control-btn {
    display: none;
    position: absolute;
    top: 50%;
    left: ${(0, styles_1.vw)(20, 'mobile')};

    &.next {
      right: ${(0, styles_1.vw)(20, 'mobile')};
      left: auto;
    }

    ${styles_1.mq.greaterThan('nexus7')} {
      left: ${(0, styles_1.vw)(20, 'nexus7')};

      &.next {
        right: ${(0, styles_1.vw)(20, 'nexus7')};
      }
    }

    ${styles_1.mq.greaterThan('tablet')} {
      left: ${(0, styles_1.vw)(20, 'desktop')};
      top: calc(50% - ${(0, styles_1.vw)(10, 'desktop')});

      &.next {
        right: ${(0, styles_1.vw)(20, 'desktop')};
      }
    }

    ${styles_1.mq.greaterThan('desktop')} {
      left: 20px;
      top: calc(50% - 10px);

      &.next {
        right: 20px;
      }
    }
  }
`;
const TimelineContainer = styled_components_1.default.div `
  .span-container {
    background-color: ${({ theme }) => (0, styles_1.hex2Rgba)(theme.colors.red, 0.3)};
    position: relative;
    display: block;
    height: ${(0, styles_1.vw)(2, 'mobile')};
    border-radius: 3px;

    span {
      background-color: ${({ theme }) => theme.colors.red};
      position: absolute;
      display: block;
      height: ${(0, styles_1.vw)(2, 'mobile')};
      border-radius: 3px;
    }
  }

  ${styles_1.mq.greaterThan('tablet')} {
    margin-top: ${(0, styles_1.vw)(10, 'desktop')};
    margin-bottom: ${(0, styles_1.vw)(10, 'desktop')};

    .span-container {
      height: ${(0, styles_1.vw)(3, 'desktop')};

      span { height: ${(0, styles_1.vw)(3, 'desktop')}; }
    }
  }

  ${styles_1.mq.greaterThan('desktop')} {
    margin-top: 10px;
    margin-bottom: 10px;

    .span-container {
      height: 3px;

      span { height: 3px; }
    }
  }
`;
