import { memo, useMemo } from 'react'
import type { FC } from 'react'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { useTranslator } from '@/hooks'
import { Button } from '@dy/commons/components'
import { css } from 'styled-components'

type MenuProps = {
  productsToDelete: any
}


const getProductsToDelete = (data) => data.map((item, idx)=> {
  return(
    <span key={idx}>{item}</span>
  )
})

export const ModalStockCart: FC<MenuProps> = memo(({productsToDelete}) => {
  const { isActive, toggle } = useModal(MODALS.STOCK_CART)
  const { t } = useTranslator()
  const ProductsToDelete = useMemo(() => getProductsToDelete(productsToDelete), [productsToDelete])

  const onClick = () => {
    toggle()
  }
  return isActive && (
    <Modal isActive={isActive} close={onClick} title={t('modals.stock_cart.title') as string}  xAlign='flex-end' size='s' dialogStyles={dialogStyles} >
      <p>{t('modals.stock_cart.description')}</p>
      <ul>
        {ProductsToDelete}
      </ul>
      <Button onClick={onClick}>{t('modals.stock_cart.ok')}</Button>
    </Modal>
  )
})

const dialogStyles = css`
  .modal__body p {
    margin-bottom: 25px;
    text-align: center;
  }
  span {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    
    &:last-child{
      margin-bottom: 25px;
    }
  }



`