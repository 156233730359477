'use client'
import type { FC } from 'react'
import { memo } from 'react'
import { mq, vw } from '@dy/commons/styles'
import { Form, Fieldset, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { useTranslator } from '@/hooks'
import { DY_SIGNUP_ALLOWED } from '@dy/commons/utils'
import styled from 'styled-components'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, extraPayload?: { [key: string]: any }, close?: boolean) => void,
  animation?: any
}

// temp loading until useMutation updated
let loading = false

const FormStyled = styled(Form)`
  button:last-child {
    margin-top: 0;

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(10, 'nexus7')};
    }

    ${ mq.greaterThan('tablet') } {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    button:last-child {
      margin-top: 0;
    }
  }
`
export const FormUserInitial:FC<Props> = memo(({ updateLevel, animation }) => {
  const { t } = useTranslator()

  return (
    <FormStyled key='form-user-start' {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('modals.auth.start.text') as string}>
      </Fieldset>
      <Button type='button' onClick={() => { updateLevel('prelogin') }} bgType={'red'}>{t('modals.auth.start.actions.primary')}</Button>
      {DY_SIGNUP_ALLOWED && <Button type='button' onClick={() => { updateLevel('signup_cif') }} bgType={'red'} outline={true}>{t('modals.auth.start.actions.secondary')}</Button>}
    </FormStyled>
  )
})

