import { gql } from '@apollo/client'
import { FRAGMENT_PRODUCT_REQUIRED, FRAGMENT_VARIANT_REQUIRED } from './'

export const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION($firstName: String!, $lastName: String!, $companyName: String!, $email: String!, $password: String!, $formFields: CustomerRegisterFormFieldsInput!, $addresses: [CustomerAddressesInput!]!) {
    customerRegister(
      input: {
        customer: {
          firstName: $firstName
          lastName: $lastName
          companyName: $companyName
          credentials: {
            email: $email
            password: $password
          }
          formFields: $formFields
          addresses: $addresses
        }
      }
    ) {
      ok
      errors
      token
      customer {
        bigcommerceId
      }
    }
  }
`

export const GET_CUSTOMERS_BY_VAT_NUMBER = gql`
  query GET_CUSTOMERS_BY_VAT_NUMBER($vatNumber: String!) {
    customers(vatNumber: $vatNumber) {
      edges {
        node {
          emailObfuscated
          vatNumber
          onboardingStatus
        }
      }
    }
  }
`

export const GET_CUSTOMER = gql`
  query GET_CUSTOMER {
    customer {
      id
      bigcommerceId
      firstName
      lastName
      email
      paymentMethod
      vatNumber
    }
  }
`

export const GET_CUSTOMER_ID = gql`
  query GET_CUSTOMER_ID {
    customer {
      id
    }
  }
`

const FRAGMENT_ADDRESS = gql`
  fragment FragmentAddress on Address {
      bigcommerceId
      addressType
      city
      countryCode
      firstName
      lastName
      postalCode
      region
      street1
      street2
  }
`

export const GET_CUSTOMER_ADDRESSES = gql`
  query GET_CUSTOMER_ADDRESSES {
    customer {
      id
      bigcommerceId
      vatNumber
      shippingAddress {
      ...FragmentAddress
      }
      billingAddress {
      ...FragmentAddress
      }
    }
  }
  ${FRAGMENT_ADDRESS}

`

const FRAGMENT_ORDERS = gql`
  fragment FragmentOrders on Customer {
    orders {
      edges {
        cursor
        node {
          bigcommerceId
          dateCreated
          orderStatus
          storeCreditAmount
          totalExTax
          orderLines {
            orderId
            productName
            quantity
            priceExTax
            brandName
            variantName
            variantSku
            product {
              inventoryTracking
            }
            variant {
              id
              importStatus
              media {
                medium(variant: "grid")
                mediaType
              }
              minSaleUnits
              stock
              stockWarning
            }
          }
        }
      }
    }
  }
`

export const GET_CUSTOMER_ORDERS = gql`
  query GET_CUSTOMER_ORDERS {
    customer {
      bigcommerceId
      firstName
      ...FragmentOrders
    }
  }
  ${FRAGMENT_ORDERS}
`

export const GET_ORDER_BY_BC_ID = gql`
  query GET_ORDER_BY_BC_ID($bigcommerceId: Int!) {
    customer {
      bigcommerceId
    }
    order(bigcommerceId: $bigcommerceId) {
      bigcommerceId
      dateCreated
      deliveryDate
      paymentMethod
      subtotalExTax
			discountAmount
      couponDiscount
      shippingCostExTax
      totalIncTax
      totalTax
      orderLines {
        orderId
        basePrice
        quantity
        bigcommerceId
        priceExTax
        brandName
        productName
        variantName
        variant {
          id
          importStatus
          bigcommerceId
          media {
            medium(variant: "grid")
            mediaType
          }
          minSaleUnits
          stock
          stockWarning
        }
      }
      comments
      billingAddress
      shippingAddress
    }
  }
`

export const GET_INVOICES = gql`
  query GET_INVOICES {
    customer {
      bigcommerceId
    }
    invoices {
      id
      number
      invoiceDate
      totalExTax
      invoiceLines {
        bigcommerceId
        dateCreated
        totalExTax
      }
    }
  }
`


export const LOGIN_MUTATION = gql`
  mutation LOGIN_MUTATION($email: String!, $password:String!) {
    login(email: $email, password: $password) {
      result
      customer {
        entityId
        firstName
        lastName
      }
    }
  }
`

export const CUSTOMER_ACTIVATE_ACCOUNT = gql`
  mutation CUSTOMER_ACTIVATE_ACCOUNT($password: String!) {
    customerActivate(
      input: {
        password: $password
      }
    ) {
      ok
      customer {
        bigcommerceId
      }
      errors
    }
  }
`

export const CUSTOMER_RESET_PASSWORD = gql`
  mutation CUSTOMER_RESET_PASSWORD($password: String!) {
    customerResetPassword(
      input: {
        password: $password
      }
    ) {
      ok
      customer {
        bigcommerceId
      }
      errors
    }
  }
`

export const CUSTOMER_UPDATE_MUTATION = gql`
  mutation CUSTOMER_UPDATE_MUTATION($password: String!) {
    customerUpdate(
      input: {
        customer: {
          password: $password
        }
      }
    )
    {
      ok
      customer {
        bigcommerceId
        email
      }
      errors
    }
  }
`

export const CUSTOMER_UPDATE_ADDRESSES_MUTATION = gql`
  mutation CUSTOMER_UPDATE_ADDRESSES_MUTATION($addresses: [CustomerAddressesUpdateInput!]) {
    customerUpdate(
      input: {
        customer: {
          addresses: $addresses
        }
      }
    )
    {
      ok
      errors
    }
  }
`

export const CUSTOMER_EMAIL_FORGOT_PASSWORD = gql`
  mutation CUSTOMER_EMAIL_FORGOT_PASSWORD($email: String!) {
    customerEmailResetPassword(
      input: {
        email: $email
      }
    ) {
      ok
      customer {
        bigcommerceId
        emailObfuscated
      }
    }
  }
`


export const CUSTOMER_EMAIL_INVITE = gql`
  mutation CUSTOMER_EMAIL_INVITE($vatNumber: String!) {
    customerEmailInvite(
      input: {
        vatNumber: $vatNumber
      }
    ) {
      ok
      customer {
        bigcommerceId
        firstName
        lastName
        vatNumber
        emailObfuscated
        onboardingStatus
      }
    }
  }
`

export const GET_CUSTOMER_GRID_MY_PRODUCTS = gql`
  query GET_CUSTOMER_GRID_MY_PRODUCTS($first: Int!) {
    customer {
      bigcommerceId
      email
      myUsual(first: $first) {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
            product {
              ...FragmentProductRequired
            }
          }
        }
      }
      categories {
        name
        slug
        subcategories {
          name
          slug
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_REQUIRED}
  ${FRAGMENT_PRODUCT_REQUIRED}
`

export const GET_CUSTOMER_HOME_SLIDER_MY_PRODUCTS = gql`
  query GET_CUSTOMER_HOME_SLIDER_MY_PRODUCTS($first: Int!) {
    customer {
      id
      orders(first: $first) {
        edges {
          cursor
          node {
            id
            deliveryDate
            orderLines {
              bigcommerceId
              quantity
              orderId
              productName
              priceExTax
              brandName
              variantName
              product {
                inventoryTracking
              }
              variant {
                id
                importStatus
                media {
                  medium(variant: "grid")
                  mediaType
                }
                minSaleUnits
                stock
                stockWarning
              }
            }
          }
        }
      }
    }
  }
  `


export const CUSTOMER_GENERATE_TOKEN = gql`
  mutation CUSTOMER_GENERATE_TOKEN($email: String!, $password: String!) {
    generateToken(input: {
      email: $email
      password: $password
    }) {
      ok
      token
    }
  }
`


export const CUSTOMER_LOGOUT = gql`
  mutation LOGOUT_CUSTOMER {
    logout {
      result
    }
  }
`
